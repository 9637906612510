// TODO: this component should be renamed and organized as client contextual button/menu with multiple actions CS can perform, similar could be done for expert and project

import templateUrl from './button-create-incident.html'
import './button-create-incident.scss'

import IncidentFormDialog from '../incident-form.dialog.js'

const ButtonCreateIncidentIssueComponent = {
  bindings: {
    styleSmall: '<',
    user: '<',
    project: '<',
    halIssue: '<'
  },
  templateUrl,
  controller: class ButtonCreateIncidentIssueComponent {
    constructor ($mdToast, $state, $window, IssuesService, $mdDialog) {
      'ngInject'
      this._identify = 'ButtonCreateIncidentIssueComponent'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.$state = $state
      this.$window = $window
      this.IssuesService = IssuesService

      this.incidentHalIssue = null
      this.isLoading = false
    }

    $onInit () {
      if (this.user || this.project) {
        this.isLoading = true
        this.IssuesService.checkIncidents({ user: this.user, project: this.project })
          .then(response => {
            this.count = response.count
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }

    openIncidentFormDialog () {
      console.log('openIncidentFormDialog')
      const incidentContextData = {
        user: this.user ? this.user : null,
        project: this.project ? this.project : null,
        halIssue: this.halIssue ? this.halIssue : null,
        isEdit: false
      }
      this.$mdDialog
        .show({
          ...IncidentFormDialog,
          locals: {
            incidentContextData
          }
        })
    }
  }
}

export default ButtonCreateIncidentIssueComponent
