import DashboardComponent from './dashboard.component'

const DashboardModule = angular.module('cdbl.dashboard', [
  'ui.router'
])
  .component('cdblDashboard', DashboardComponent)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'dashboard',
        url: '/dashboard',
        component: 'cdblDashboard',
        resolve: {
          permissionResolver: ($state, Configuration, AuthService) => {
            'ngInject'
            console.log('[permissionResolver] user', AuthService.user)
            const allow = !Configuration.isBuild && Configuration.CDBL_ENV === 'develop' && AuthService.isAuthenticated && AuthService.user.id === 13
            if (!allow) {
              console.log('[permissionResolver] > reject')
              $state.go('tasks.state')
            }
          }
        }
      })
  })
  .name

export default DashboardModule
