(function() {
  'use strict';

  function UsersResource($q, Restangular, ResourceManipulation, $http, Configuration) {
		var local = {};
    var user;

		var getUsers = function(options) {
			return Restangular.all('admin').all('users').get('', options);
		};

    var getUser = function(user_id, options, manualLoad) {
      user = Restangular.all('admin').one('users', user_id);
      var returnObject = {};

      if (!manualLoad) {
        options.forEach(function(option) {
          var resource = local['get' + option.capitaliseFirstLetter()].call(this);
          returnObject[option] = ResourceManipulation.resolveError(resource);
        });

        return $q.all(returnObject);
      } else {
        options.forEach(function(option) {
          var resource = local['get' + option.capitaliseFirstLetter()];
          returnObject[option] = resource;
        });

        return returnObject;
      }
    };

    var updateUser = function(user_id, options) {
      return Restangular.all('admin').one('users', user_id).put(options);
    };

    var disableWithdrawals = function(user_id) {
      return $http.post(`${Configuration.apiUrl}/admin/users/${user_id}/withdrawals_lock`)
    }

    var enableWithdrawals = function(user_id) {
      return $http.delete(`${Configuration.apiUrl}/admin/users/${user_id}/withdrawals_lock`)
    }

    var updateUserAttributes = function(user_id, options) {
      return Restangular.all('admin').one('users', user_id).customPUT(options);
    };

    var temporaryPasswordChange = function(user_id) {
      return Restangular.all('admin').one('users', user_id).all('temp_pass_change').customPUT();
    };

    var deleteUser = function(user_id) {
      return Restangular.all('admin').one('users', user_id).one('delete-user').customPUT();
    }

    var regenerateVaultKey = function(user_id) {
      return Restangular.all('admin').one('users', user_id).all('generate-vault-key').customPOST();
    };

    var getUserPreferences = function(user) {
      console.log('-- UserResource#getUserPreferences: ', user.id);

      return Restangular.all('admin').one('users', user.id).all('preferences').customGET();
    };

    var updateUserPreferences = function(user, updates) {
      console.log('-- UserResource#updateUserPreferences: ', user.id, updates);

      return Restangular.all('admin').one('users', user.id).all('preferences').customPUT(updates);
    };

    var invalidateToken = function(user) {
      return Restangular.all('admin').one('users', user.id).one('invalidate-token').customPUT();
    }

    local.getUser = function() {
      return user.get();
    };

    local.getTasks = function(options) {
      return user.all('tasks').getList(options);
    };

    local.getTransactions = function(options) {
      return user.all('transactions').getList(options);
    };

    local.getEarningCertificates = function(options) {
      return user.all('earning-certificates').getList(options);
    };

    local.getReviews = function(options) {
      return user.all('reviews').getList(options);
    };

    local.getEstimatedContractorTasks = function(options) {
      return user.all('estimated-contractor-tasks').getList(options);
    };
    local.getCommentedContractorTasks = function(options) {
      return user.all('commented-contractor-tasks').getList(options);
    };

    local.getKpis = function() {
      return user.all('kpis').get('');
    };

    local.getTickets = function (options) {
      return user.all('tickets').getList(options)
    }

    var sendEmail = function(user, subject, text) {
      return Restangular.all('admin').all('email_templates').all('send').post({
        subject: subject,
        text: text,
        recipient_id: user.id
      });
    };


    return {
      getUsers: getUsers,
      getUser: getUser,
      updateUser: updateUser,
      updateUserAttributes: updateUserAttributes,
      sendEmail: sendEmail,
      temporaryPasswordChange: temporaryPasswordChange,
      deleteUser: deleteUser,
      regenerateVaultKey: regenerateVaultKey,
      getUserPreferences: getUserPreferences,
      updateUserPreferences: updateUserPreferences,
      invalidateToken: invalidateToken,
      disableWithdrawals: disableWithdrawals,
      enableWithdrawals: enableWithdrawals
    };
  }

  window.app.service('UsersResource', ['$q', 'Restangular', 'ResourceManipulation', '$http', 'Configuration', UsersResource]);
})();
