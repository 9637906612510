(function() {
  'use strict';

  function WithdrawalRequestConfirmationCtrl($scope, $modalInstance, $filter, WithdrawalsResource, withdrawal) {
    // /sb/ this _must_ come from the server.
    var wireFee = 50.00,
      paypalFee = 10.00;

    var original  = withdrawal;
    // /sb/ makeLocalCopyForEditing = function(withdrawal) {
    $scope.withdrawal = JSON.parse( JSON.stringify(withdrawal) );

		$scope.close = function() {
			$modalInstance.close();
		};

		$scope.confirmPayment = function(withdrawal) {
      console.log('-- confirmPayment:', withdrawal);

      var confirmationResponse;

      confirmationResponse  = WithdrawalsResource.confirmWithdrawalRequest(withdrawal);

      console.log('-- -- confirmPayment response:', confirmationResponse);
      updateModelWithChanges(withdrawal);

      $modalInstance.close();
		};

    $scope.paymentMethodChanged  = function(withdrawal) {
      setPaymentInfo(withdrawal);
    };

    $scope.paymentMethodIsWire  = function(withdrawal) {
      return (withdrawal.paymentMethod === 'wire');
    };

    $scope.calculateFinalPayoutAmount  = function(withdrawal) {
      var payoutAmount  = withdrawal.amount - withdrawal.paymentFees;
      withdrawal.paymentAmount= $filter('number')(payoutAmount, 2);
    };

    var setPaymentInfo  = function(withdrawal) {
      var fee = paypalFee;
      
      if ( $scope.paymentMethodIsWire(withdrawal) ) {
        fee = wireFee;
      }
      withdrawal.paymentFees= $filter('number')(fee, 2);
      $scope.calculateFinalPayoutAmount(withdrawal);
    };

    var updateModelWithChanges = function(changes) {
      original.amount=        changes.amount;
      original.paymentFees=   changes.paymentFees;
      original.paymentAmount= changes.paymentAmount;
      original.paymentMethod= changes.paymentMethod;
      original.paymentId=     changes.paymentId;
      original.expertPaymentAccountEmail= changes.expertPaymentAccountEmail;
      original.status=        'confirming';
    };

  }

  window.app.controller('WithdrawalRequestConfirmationCtrl', ['$scope', '$modalInstance', '$filter', 'WithdrawalsResource', 'withdrawal', WithdrawalRequestConfirmationCtrl]);

})();
