import templateUrl from './project-widget-prices.html'
import './project-widget-prices.scss'

import DialogProjectEditPrices from './dialog-project-edit-prices'

const ProjectWidgetPricesComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetPrices {
    constructor ($mdDialog) {
      'ngInject'
      this._identify = 'ProjectWidgetPricesComponent'
      this.$mdDialog = $mdDialog
    }

    openEditDialog () {
      this.$mdDialog.show({
        ...DialogProjectEditPrices,
        locals: {
          project: this.project
        }
      })
    }

    get hasAddTasks () {
      return this.project && this.project.subtasks
    }

    get retainerTasksBasePriceTotal () {
      if (!this.project || !this.project.subtasks) {
        return null
      }

      const result = this.project.subtasks.reduce((sum, subtask) => {
        if (subtask.retainer) {
          sum += subtask.paymentHistory.basePriceTotal
        }

        return sum
      }, 0)

      return result
    }

    get retainerTasksClientPriceTotal () {
      if (!this.project || !this.project.subtasks) {
        return null
      }

      const result = this.project.subtasks.reduce((sum, subtask) => {
        if (subtask.retainer) {
          sum += subtask.paymentHistory.clientPaymentsTotal
        }

        return sum
      }, 0)

      return result
    }

    get addTasksBasePriceTotal () {
      if (!this.project || !this.project.subtasks) {
        return null
      }

      const result = this.project.subtasks.reduce((sum, subtask) => {
        if (!subtask.retainer) {
          sum += subtask.prices.basePrice
        }

        return sum
      }, 0)

      return result
    }

    get addTasksClientPriceTotal () {
      if (!this.project || !this.project.subtasks) {
        return null
      }

      const result = this.project.subtasks.reduce((sum, subtask) => {
        if (!subtask.retainer) {
          sum += subtask.prices.priceWithClientFee
        }

        return sum
      }, 0)

      return result
    }


    get amountDepositedTotal () {
      if (!this.project) {
        return null
      }

      const allowedStates = ['paid', 'completed', 'refunded', 'abandoned']
      let totalDeposited = 0

      if (allowedStates.includes(this.project.state)) {
        totalDeposited += this.project.prices.priceWithClientFee
      }

      if (this.project.subtasks) {
        totalDeposited += this.project.subtasks.reduce((sum, subtask) => {
          if (subtask.retainer) {
            sum += subtask.paymentHistory.clientPaymentsTotal
          } else if (allowedStates.includes(subtask.state)) {
            sum += subtask.prices.priceWithClientFee
          }

          return sum
        }, 0)
      }

      return totalDeposited
    }
  }
}

export default ProjectWidgetPricesComponent
