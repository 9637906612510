// By default, shades 500, 300 800 and A100 are used for primary and warn intentions, while A200, A100, A400 and A700 are used for accent.

const primaryPalette = {
  '50': 'e7f5fb',
  '100': 'c4e5f5',
  '200': '9dd4ef',
  '300': '76c2e9',
  '400': '58b5e4',
  '500': '3ba8df',
  '600': '35a0db',
  '700': '2d97d7',
  '800': '268dd2',
  '900': '0E81BE',
  'A100': 'fdfeff',
  'A200': 'cae6ff',
  'A400': '97cfff',
  'A700': '7ec3ff',
  'contrastDefaultColor': 'light',
  'contrastDarkColors': [
    '50',
    '100',
    '200',
    '300',
    '600',
    'A100',
    'A200',
    'A400',
    'A700'
  ],
  'contrastLightColors': [
    '400',
    '500',
    '700',
    '800',
    '900'
  ]
}


const primaryPaletteHues = {
  'default': '500',
  'hue-1': '700',
  'hue-2': '900',
  'hue-3': '400'
}

const accentPalette = {
  '50': 'fef4e5',
  '100': 'fce4bd',
  '200': 'fad391',
  '300': 'f8c165',
  '400': 'f7b344',
  '500': 'f5a623',
  '600': 'f49e1f',
  '700': 'f2951a',
  '800': 'f08b15',
  '900': 'ee7b0c',
  'A100': 'ffffff',
  'A200': 'fff1e5',
  'A400': 'ffd4b2',
  'A700': 'ffc698',
  'contrastDefaultColor': 'light',
  'contrastDarkColors': [
    '50',
    '100',
    '200',
    '300',
    '400',
    'A100',
    'A200',
    'A400',
    'A700'
  ],
  'contrastLightColors': [
    '500',
    '600',
    '700',
    '800',
    '900'
  ]
}

const accentPaletteHues = {
  'default': '500',
  'hue-1': '700',
  'hue-2': '900',
  'hue-3': '400'
}





const greenPalette = {
  '50': 'f1f6e8',
  '100': 'dde8c5',
  '200': 'c7d99e',
  '300': 'b0ca77',
  '400': '9fbe59',
  '500': '8eb33c',
  '600': '86ac36',
  '700': '7ba32e',
  '800': '719a27',
  '900': '5f8b1a',
  'A100': 'e6ffc3',
  'A200': 'd1ff90',
  'A400': 'bcff5d',
  'A700': 'b1ff43',
  'contrastDefaultColor': 'light',
  'contrastDarkColors': [
    '50',
    '100',
    '200',
    '300',
    '400',
    'A100',
    'A200',
    'A400',
    'A700'
  ],
  'contrastLightColors': [
    '500',
    '600',
    '700',
    '800',
    '900'
  ]
}

const greenPaletteHues = {
  'default': '500',
  'hue-1': '700',
  'hue-2': '900',
  'hue-3': '400'
}


const graphitePalette = {
  '50': 'ebedef',
  '100': 'ced2d7',
  '200': 'adb5bc',
  '300': '8c97a1',
  '400': 'b9c8d2',
  '500': '7c8d98',
  '600': '526270',
  '700': '485765',
  '800': '3f4d5b',
  '900': '333e48',
  'A100': '96cdff',
  'A200': '63b4ff',
  'A400': '309cff',
  'A700': '1790ff',
  'contrastDefaultColor': 'light',
  'contrastDarkColors': [
    '50',
    '100',
    '200',
    '300',
    '400',
    'A100',
    'A200',
    'A400'
  ],
  'contrastLightColors': [
    '500',
    '600',
    '700',
    '800',
    '900',
    'A700'
  ]
}

const graphitePaletteHues = {
  'default': '500',
  'hue-1': '700',
  'hue-2': '900',
  'hue-3': '400'
}

const warnPaletteHues = {
  'default': '500',
  'hue-1': '700',
  'hue-2': '900',
  'hue-3': '400'
}

export { primaryPalette, primaryPaletteHues, accentPalette, accentPaletteHues, greenPalette, greenPaletteHues, graphitePalette, graphitePaletteHues, warnPaletteHues }
