import SettingsComponent from './settings.component'
// import SettingsService from './settings.service'

import TemplatesIntercomModule from './templates-intercom'
import TemplatesCommentModule from './templates-comment'

import SettingsAccountModule from './settings-account'
import SettingsSuperusersModule from './settings-superusers'

import SettingsUnlayerTemplatesModule from './settings-unlayer-templates'

const SettingsModule = angular.module('cdbl.settings', [
  // 'ui.router'
  TemplatesIntercomModule,
  TemplatesCommentModule,

  SettingsAccountModule,
  SettingsSuperusersModule,

  SettingsUnlayerTemplatesModule
])
  .component('cdblSettings', SettingsComponent)
// .service('SettingsService', SettingsService)

// .config($uiRouterProvider => {
//   'ngInject'
//   $uiRouterProvider.stateRegistry
//     .register({
//       // parent: '',
//       name: 'settings',
//       url: '/settings',
//       component: 'cdblSettings'
//     })
// })
  .name

export default SettingsModule
