import AuthService from './auth.service'
import AuthLoginModule from './auth-login'

const AuthModule = angular.module('cdbl.auth', [
  AuthLoginModule
])
  .service('AuthService', AuthService)
  .config(($uiRouterProvider, $urlRouterProvider) => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'auth',
        abstract: true
      })
  })
  .name

export default AuthModule
