(function() {
  'use strict';

  function comment(marked, newlinesFilter, $http, Configuration) {
    var link = function($scope) {
      $scope.editComment = false;
      $scope.editCommentAllowed = false;
      $scope.showEditComment = false;
      $scope.comment.textMarked = marked(newlinesFilter($scope.comment.text.escape())); // New lines filter must be before marked

      $scope.postEditComment = function(comment) {
        console.log('clicked');
        $scope.editComment = false;

        const payload = {
          text: $scope.comment.text
        }
        $http.put(`${Configuration.apiUrl}/admin/tasks/${$scope.task.id}/comments/${$scope.comment.id}`, payload).then(response => {
          $scope.comment.edited = true;
          $scope.comment.textMarked = marked(newlinesFilter($scope.comment.text.escape())); // New lines filter must be before marked
        });
      };
    };

    return {
      restrict: 'E',
      replace: true,
      scope: {
        comment: '=',
        task: '='
      },
      templateUrl: '/app/scripts/tasks/comments/comment.html',
      link: link
    };
  }

  app.directive('comment', ['marked', 'newlinesFilter', '$http', 'Configuration', comment]);
})();
