const OnEnterTriggerDirective = function (Configuration) {
  'ngInject'
  return {
    restrict: 'A',
    // require: 'ngModel',
    link ($scope, $element, $attrs, ngModelCtrl) {
      $element.bind('keydown', $event => {
        var code = $event.keyCode || $event.which

        if (code === 13) {
          $event.preventDefault()
          $scope.$apply($attrs.onEnterTrigger)
        }
      })
    }
  }
}

export default OnEnterTriggerDirective
