const OnCtrlEnterTriggerDirective = function (Configuration) {
  'ngInject'
  return {
    restrict: 'A',
    require: 'ngModel',
    link ($scope, $element, $attrs, ngModelCtrl) {
      $element.bind('keydown', $event => {
        var code = $event.keyCode || $event.which

        if ($event.ctrlKey && code === 13) {
          if (!$event.shiftKey) {
            $event.preventDefault()
            $scope.$apply($attrs.onCtrlEnterTrigger)
          }
        }
      })
    }
  }
}

export default OnCtrlEnterTriggerDirective
