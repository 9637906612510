const GrowthTargetsService = class GrowthTargetsService {
  constructor ($http, Configuration, LoadingService) {
    'ngInject'
    this._identify = 'GrowthTargetsService'
    this.$http = $http
    this.Configuration = Configuration
    this.LoadingService = LoadingService
  }

  getTemplateList () {
    const loaderName = 'GrowthTargetsService::getTemplateList'
    this.LoadingService.start(loaderName)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/comment_templates`)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  getTemplate (templateId) {
    const loaderName = 'GrowthTargetsService::getTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/comment_templates/${templateId}`)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  updateTemplate (templateId, payload) {
    const loaderName = 'GrowthTargetsService::getTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/comment_templates/${templateId}`, payload)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  createNewTemplate (payload) {
    const loaderName = 'GrowthTargetsService::createNewTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/comment_templates`, payload)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  saveTemplate (template) {
    const payload = {
      text: template.text,
      name: template.name ? template.name : null
    }

    if (template.hasOwnProperty('id')) {
      return this.updateTemplate(template.id, payload)
    } else {
      return this.createNewTemplate(payload)
    }
  }

  deleteTemplate (templateId) {
    const loaderName = 'GrowthTargetsService::deleteTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/comment_templates/${templateId}`)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }
}
export default GrowthTargetsService


