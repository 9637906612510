const TextTruncateFilter = ($filter) => {
  'ngInject'
  return function (text, maxLength) {
    if (typeof maxLength !== 'number' || maxLength < 1 || text.length < maxLength) {
      return text
    }
    const truncatedText = $filter('limitTo')(text, maxLength)
    return `${truncatedText}...`
  }
}
export default TextTruncateFilter
