(function() {
  'use strict';

  function completeSubTask(SubTasksResource) {
    var link = function($scope) {
      $scope.complete = function(task, subTask) {
        console.log('true');

        SubTasksResource.completeSubTask(task.id, subTask.id).then(function(data) {
          subTask.state = 'completed';
        });
      };
    };

    return {
      restrict: 'E',
      link: link,
      scope: {
        task: '=',
        subTask: '='
      },
      templateUrl: '/app/scripts/tasks/actions/complete_sub_task_button.html'
    };
  }

  app.directive('completeSubTask', ['SubTasksResource', completeSubTask]);
})();