(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('tasks', {
      abstract: true,
      url: '/tasks',
      template: '<ui-view/>'
    })
    .state('tasks.state', {
      url: '?state&withExpertPods',
      templateUrl: '/app/scripts/tasks/tasks.state.html',
      controller: 'TasksCtrl'
    })
    .state('tasks.preferred', {
      url: '/preferred?filter',
      templateUrl: '/app/scripts/tasks/tasks.preferred.html',
      controller: 'PreferredCtrl'
    })
    .state('tasks.display', {
      url: '/{id:[0-9]{1,9}}?subTaskId?tab',
      params: {
        subTaskId: {dynamic: true},
        tab: {dynamic: true},
      },
      templateUrl: '/app/scripts/tasks/tasks.display.html',
      controller: 'TaskCtrl',
      resolve: {
        taskResource: ['TasksResource', '$stateParams', function(TasksResource, $stateParams) {
          return TasksResource.getTask($stateParams.id, ['task', 'comments', 'transactions', 'commentTemplates', 'reviews', 'estimates', 'attachments', 'activities', 'transitions', 'tickets'], true);
        }]
      }
    });
  }

  window.app.config(['$stateProvider', states]);
})();
