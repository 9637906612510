/* eslint-disable */
(function() {
  'use strict';

  function MoneyTransfersCtrl($scope, $modalInstance, MoneyTransfersServer, sender, UserService) {
    const CONSTANTS = {
      PROJECT_REASON: 'project',
      SUBTASK_REASON: 'subtask',
      EXPERT: 'expert',
      CDBL: 'cdbl',
      ANOTHER: 'another-expert',
      MONEY_TRANSFER: 'money-transfers',
      EXPERT_DEBIT: 'expert-debits',
      EXPERT_CREDIT: 'expert-credits',
      SUCCESS: 'success',
      FAILURE: 'failure',
      TRANSFER_SUCCESSFUL: 'Transfer successful.',
      TRANSFER_FAILED: 'Transfer failed: ',
    };

    const INITIAL_TRANSFER = {
      fromId: CONSTANTS.EXPERT,
      toId: null,
      recipient: CONSTANTS.CDBL,
      amount: 0.0,
      reason: CONSTANTS.PROJECT_REASON,
      type: CONSTANTS.EXPERT_DEBIT
    };

    const OUTCOME = { data: { status: '', reason: '' } };

    $scope.outcome = OUTCOME;
    $scope.outcomeMessage = '';
    $scope.expert = sender;
    $scope.expertDebit = CONSTANTS.EXPERT_DEBIT;
    $scope.expertCredit = CONSTANTS.EXPERT_CREDIT;
    $scope.expertTransfer = CONSTANTS.MONEY_TRANSFER;
    $scope.transfer = { ...INITIAL_TRANSFER };
    $scope.submitted = false;

    $scope.transferTypeChanged = function(transfer = {}) {
      const { type } = transfer;

      const transferActions = {
        [CONSTANTS.EXPERT_DEBIT]: () => {
          transfer.fromId = CONSTANTS.EXPERT;
          transfer.recipient = CONSTANTS.CDBL;
          transfer.toId = null;
        },
        [CONSTANTS.EXPERT_CREDIT]: () => {
          transfer.recipient = CONSTANTS.EXPERT;
          transfer.fromId = CONSTANTS.CDBL;
          transfer.toId = null;
        },
        [CONSTANTS.MONEY_TRANSFER]: () => {
          transfer.fromId = CONSTANTS.EXPERT;
          transfer.recipient = CONSTANTS.ANOTHER;
          transfer.toId = null;
        },
      };

      (transferActions[type] || function() {})();
      this.transfer = transfer;
    };

    $scope.isExpertMoneyTransfer = function() {
      return this.transfer.type === CONSTANTS.MONEY_TRANSFER;
    };

    $scope.submitTransferRequest = function(transfer = {}) {
      $scope.outcomeMessage = 'Transferring ...';
      $scope.prepareRequestInput(transfer);

      MoneyTransfersServer.submitTransferRequest(transfer)
        .then((outcome = {}) => {
          $scope.outcome = outcome;
          $scope.submitted = true;
        })
        .catch((errors = {}) => {
          $scope.outcome = errors.data;
          $scope.submitted = false;
        })
        .finally(() => {
          $scope.formatOutcomeMessage();
          if ($scope.expert) {
            UserService.fetchUser($scope.expert.id)
              .then(response => {
                $scope.expert = response
              })
              .catch(err => {
                console.log(err)
              })
          }
        });
    };

    $scope.prepareRequestInput = function(transfer = {}) {
      const { type, id } = this.transfer;

      const transferPrepActions = {
        [CONSTANTS.EXPERT_DEBIT]: () => {
          transfer.fromId = this.expert.id;
          transfer.toId = null;
        },
        [CONSTANTS.EXPERT_CREDIT]: () => {
          transfer.fromId = CONSTANTS.CDBL;
          transfer.toId = this.expert.id;
        },
        [CONSTANTS.MONEY_TRANSFER]: () => {
          transfer.fromId = this.expert.id;
        },
      };

      (transferPrepActions[type] || function() {})();
      this.transfer = transfer;
    };

    $scope.reasonIsAdditionalTask = function(transfer = {}) {
      return transfer.reason === CONSTANTS.SUBTASK_REASON;
    };

    $scope.reasonIsProject = function(transfer = {}) {
      return transfer.reason === CONSTANTS.PROJECT_REASON;
    };

    $scope.formatOutcomeMessage = function() {
      const { status, reason } = $scope.outcome;

      switch(status) {
        case CONSTANTS.SUCCESS:
          $scope.outcomeMessage = CONSTANTS.TRANSFER_SUCCESSFUL;
          $scope.hasError = false;
          break;
        case CONSTANTS.FAILURE:
          $scope.outcomeMessage = `${CONSTANTS.TRANSFER_FAILED}${reason}`;
          $scope.hasError = true;
          break;
        default:
          $scope.outcomeMessage = '';
      }
    };

    $scope.cancel = function() {
      $modalInstance.close();
    };

    $scope.close = function() {
      $modalInstance.close();
      location.reload();
    };
  }

  window.app.controller('MoneyTransfersCtrl', ['$scope', '$modalInstance', 'MoneyTransfersServer', 'sender', 'UserService', MoneyTransfersCtrl]);
})();
