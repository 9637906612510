import templateUrl from './dialog-expert-pods-bulk-edit.dialog.html'
import './dialog-expert-pods-bulk-edit.dialog.scss'

const DialogExpertPodsBulkEdit = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogExpertPodsBulkEdit {
    constructor ($mdDialog, $mdToast, ExpertPodsService, Configuration) {
      'ngInject'
      this._identify = 'DialogExpertPodsBulkEdit'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.ExpertPodsService = ExpertPodsService
      this.Configuration = Configuration
    }

    $onInit () {
      this.isLoading = false
      this.failureMessages = {
        'account_not_found': 'was not found',
        'already_exists_in_pod': 'is already inside the pod',
        'account_inactive': 'is inactive',
        'not_an_expert_account': 'is not a contractor',
        'account_not_confirmed': 'account is not confirmed',
        'cannot_be_added': 'can not be addded',
      }
      this.datasets = {
        importTypes: [
          { id: 'rawIds', value: 'Ids' },
          { id: 'rawEmails', value: 'Emails' }
        ]
      }
      this.formData = {
        bulkText: '',
        importType: 'rawEmails'
      }
    }

    submit () {
      this.error = null
      this.results = null
      this.isProcesing = true
      this.ExpertPodsService.bulkUpdate(this.id, this.formData)
        .then(response => {
          console.log('🚀 ~ file: dialog-expert-pods-bulk-edit.dialog.js:40 ~ DialogExpertPodsBulkEdit ~ submit ~ response:', response)
          this.results = {
            failures: response.failures,
            numFailed: response.failures ? response.failures.length : 0,
            numAdded: response.success ? response.success : 0
          }
          this.results.numTotal = this.results.numFailed + this.results.numAdded
        })
        .catch(() => {
          // show generic error
          this.error = 'Something went wrong, please try again.'
        })
        .finally(() => {
          this.isProcesing = false
        })
    }

    renderFailure (failure) {
      const message = this.failureMessages[failure.reason] ? this.failureMessages[failure.reason] : 'unknown reason'
      const value = failure.id ? failure.id : failure.email ? failure.email : 'N/A'
      return `${value} ${message}.`
    }

    close (attr = false) {
      this.$mdDialog.hide(attr)
    }
  }
}

export default DialogExpertPodsBulkEdit
