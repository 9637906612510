(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('kpis', {
      abstract: true,
      url: '/kpis',
      template: '<ui-view/>'
    })
    .state('kpis.global', {
      url: '/overview',
      templateUrl: '/app/scripts/kpis/global/global.kpis.html',
      controller: 'GlobalKpisCtrl'
    })
    .state('kpis.tasks', {
      url: '/tasks',
      templateUrl: '/app/scripts/kpis/tasks/task.kpis.html',
      controller: 'KpisCtrl'
    })
    .state('kpis.cohort', {
      url: '/cohort',
      templateUrl: '/app/scripts/kpis/cohort/cohort.kpis.html',
      controller: 'CohortKpisCtrl'
    })
    .state('kpis.users', {
      url: '/users',
      templateUrl: '/app/scripts/kpis/users/user.kpis.html',
      controller: 'UserKpisCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
