(function() {
  'use strict';

  function GlobalKpisCtrl($scope, $location, $stateParams, KpisResource) {

    $scope.dateRange = {
      start_date: moment().subtract('days', 1).format("DD-MM-YYYY"),
      end_date: moment().format("DD-MM-YYYY")
    };

    var filter = function() {
      KpisResource.utmRevenueBreakdown($scope.dateRange).then(function(data) {
        console.log(data);
      });

      KpisResource.globalKpisNew($scope.dateRange).then(function(response) {
        console.log(response);
        $scope.globalKpisNew = response;
      });
      KpisResource.globalKpis($scope.dateRange).then(function(response) {
        $scope.globalKpis = response;
        // Charts

        $scope.discountsAggregates = $scope.globalKpis.discounts.discountedTasks.reduce((p, c) => {
          var temp = Object.assign(p);
          if (!p[c.code]) {
            temp[c.code] = { amount: parseFloat(c.amount), ids: [c.id] };
          } else {
            temp[c.code].amount = p[c.code].amount + parseFloat(c.amount);
            temp[c.code].ids.push(c.id);
          }
          return temp;
        }, {});

        $scope.profitChartConfig = {
          options: {
              chart: {
                  type: 'pie',
                   plotBackgroundColor: null,
                  plotBorderWidth: null,
                  plotShadow: false

              },
              plotOptions: {
                  pie: {
                      dataLabels: {
                          enabled: true,
                          formatter: function() {
                              return Math.round(this.percentage*100)/100 + ' %';
                          }
                      },
                      showInLegend: false
                  }
              }
          },
          series: [{
              type: 'pie',
              data: [
                  ['Task Fees', response.codeableFees],
                  ['Contractor Fees', response.expectedContractorFees],
                  ['Withdrawal Fees', response.contractorWithdrawalFees]
              ]
          }],
          title: {text: "Profit Breakdown"},

          loading: false
        };

        var contractor_earnings = response.clientDeposits - (response.codeableFees + response.expectedContractorFees + response.contractorWithdrawalFees);
        $scope.incomeChartConfig = {
          options: {
              chart: {
                  type: 'pie',
                   plotBackgroundColor: null,
                  plotBorderWidth: null,
                  plotShadow: false

              },
              plotOptions: {
                  pie: {
                      dataLabels: {
                          enabled: true,
                          formatter: function() {
                              return Math.round(this.percentage*100)/100 + ' %';
                          }
                      },
                      showInLegend: false
                  }
              }
          },
          series: [{
              type: 'pie',
              data: [
                  ['Contractor Earnings', contractor_earnings],
                  ['Payment Fees', response.paymentFees],
                  ['Task Fees', response.codeableFees],
                  ['Contractor Fees', response.expectedContractorFees],
                  ['Withdrawal Fees', response.contractorWithdrawalFees]
              ]
          }],
          title: {text: "Income Breakdown"},

          loading: false
        };
      });

      var referooOptions = {
        start: $scope.dateRange.start_date,
        end: $scope.dateRange.end_date
      };

      KpisResource.referooNumbers(referooOptions, { Accept: 'application/vnd.referoo.v2+json' }).then(function(results) {
        $scope.referooKpis = {
          revenueSummary: results.plain().results.summary.revenueSummary,
          partnerEarnings: results.plain().results.summary.revenueSummary.partnerEarnings,
          withdrawals: results.plain().results.summary.revenueSummary.partnerWithdrawals,
          summary: results.plain().results.summary.eventsSummary
        };
      });

    };

    filter();

    $scope.filter = filter;

    $scope.goTo = function (path) {
      $location.path(path);
    };

  }

  window.app.controller('GlobalKpisCtrl', ['$scope', '$location', '$stateParams', 'KpisResource', GlobalKpisCtrl]);

})();
