import { ReferralAttributionsComponent } from '@/components/dialogs/referrals'
import { ReferralAttributionsPastProjectsComponent } from '../../../src/components/dialogs/referrals';

(function() {
  'use strict';
  /*globals $state, alert*/
  function UserCtrl($scope, $stateParams, $modal, $mdDialog, userResource, UsersResource, WatchlistResource, NotesResource, $location, PermissionsService) {

    $scope.data = {
      activities: []
    };
    $scope.$mdDialog = $mdDialog;

    $scope.loading = true;
    $scope.notesLoading = true;

    userResource.user().then(function(user) {
       $scope.user = user;
       $scope.loading = false;
    });

    NotesResource.getNotes({note_subject_id: $stateParams.id, note_subject_type: 'User'}).then(function(notes) {
      $scope.notes = notes;
      $scope.notes.userId = $stateParams.id;

      if (notes.length === 0) {
        $scope.notes.text = '';
        $scope.notes.createNew = true;
      } else {
        $scope.notes.text = notes[0].text;
        $scope.notes.id = notes[0].id;
        $scope.notes.createNew = false;
      }

      $scope.notesLoading = false;
    });

    $scope.tasks = [];
    $scope.estimatedContractorTasks = [];
    $scope.commentedContractorTasks = [];
    $scope.reviews = [];
    $scope.transactions = [];
    $scope.earningCertificates = [];
    $scope.canTransferFunds = PermissionsService.canCreate('expert-transfers');
    

    $scope.load = function(resourceName) {
      $scope['onSelectPage' + resourceName.capitaliseFirstLetter()] = function(page) {
        userResource[resourceName]({page: page.toString()}).then(function(data) {

          $scope[resourceName] = data;
          $scope.data[resourceName] = data;

          // Pagination
          $scope.data[resourceName].pagination = {};
          $scope.data[resourceName].pagination.totalItems = data.headers('X-Total-Count');
          $scope.data[resourceName].pagination.itemsPerPage = 20;
          $scope.data[resourceName].pagination.currentPage = page.toString();
          $scope.data[resourceName].pagination.numPages = data.headers('X-Page-Count');
          $scope.data[resourceName].pagination.maxSize = 5;
        });
      };

      $scope['onSelectPage' + resourceName.capitaliseFirstLetter()]('1');
    };

    $scope.confirmPromoteUserToContractor = function(user) {
      UsersResource.updateUser(user.id, {role: 'contractor'}).then(function() {
        $state.go('users.display', {id: $scope.user.id});
      });
    };

    $scope.confirmPromoteUserToClient = function(user) {
      UsersResource.updateUser(user.id, {role: 'client'}).then(function() {
        $state.go('users.display', {id: $scope.user.id});
      });
    };

    $scope.confirmDisableUser = function(user, value) {
      UsersResource.updateUser(user.id, {active: value}).then(function() {
        user.active = value;
        user.disabling = undefined;
      });
    };

    $scope.watch = function(task) { // TODO refactor into directive
      if (task.watchlist) {
        WatchlistResource.addWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = true;
        });
      } else {
        WatchlistResource.removeWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = false;
        });
      }
    };

    $scope.update = function(user) {
      UsersResource.updateUser(user.id, {eligible_for_wire_withdrawals: user.eligibleForWireWithdrawals}).then(function() {
        $state.go('users.display', {id: $scope.user.id});
      });
    };

    $scope.updateWithdrawalsEnabled = function(user) {
      if (user.withdrawalsEnabled) {
        UsersResource.enableWithdrawals(user.id).then(function() {
          $state.go('users.display', {id: $scope.user.id});
        });
      } else {
        UsersResource.disableWithdrawals(user.id).then(function() {
          $state.go('users.display', {id: $scope.user.id});
        });
      }
    };

    $scope.selectTab = function (tabId, callback) {
      $scope.activeTab = tabId;

      if (callback) {
        callback();
      }
    }

    $scope.updateMaxOpenTasks = function(user) {
      UsersResource.updateUserAttributes(user.id, {max_open_tasks: user.maxOpenTasks});
    };

    $scope.updateWarned = function(user) {
      UsersResource.updateUserAttributes(user.id, {warned: user.warned});
    };

    $scope.updatePro = function(user) {
      UsersResource.updateUserAttributes(user.id, {pro: user.pro});
    };

    $scope.updateHideReviewsFromPublicProfiles = function(user) {
      UsersResource.updateUserAttributes(user.id, {hideReviewsFromPublicProfiles: user.hideReviewsFromPublicProfiles});
    };

    $scope.updateNotes = function(notes) {
      NotesResource.updateNotes(notes).then(function(note) {
        notes.createNew = false;
        notes.id = note.id;
      });
    };

    $scope.updateFeePercentage = function(user) {
      UsersResource.updateUserAttributes(user.id, {customServiceFeePercentage: user.customServiceFeePercentage}).then(function() {
        alert('Custom fee percentage was set');
      });
    };

    $scope.temporaryPasswordChange = function(user) {
      UsersResource.temporaryPasswordChange(user.id);
    };

    $scope.regenerateVaultKey = function(user) {
      UsersResource.regenerateVaultKey(user.id).then(function(data) {
        alert(data.message);
      });
    };

    $scope.isClient = function(user) {
      var result = false,
        expectedRole = 'client';

      if (user !== undefined) {
        result = (user.role === expectedRole);
      }

      return result;
    };

    $scope.isReferral = function(user) {
      var result = false;

      if (this.isClient(user)) {
        result = (user.referralPartner !== null);
      }

      return result;
    };

    $scope.isAttributable = function(user) {
      var result = false;

      if (this.isReferral(user)) {
        result = user.referralPartner.platform === 'referoo'
      }

      return result;
    };

    $scope.canAssignRef = function() {
      return PermissionsService.canCreate('assignRefcode');
    };

    $scope.openReferralAttributionDialog = function(user) {
      const client = user;

      console.log('-- opening ReferralAttributionDialog client:', client.fullName)

      $scope
        .$mdDialog
        .show({
          ...ReferralAttributionsComponent,
          locals: {
            client: client
          }
        })
        .then(response => {
          console.log('[INFO] UserCtrl > openReferralAttributionDialog >  dialogResponse', response)
        }, closed  => {
          console.log('[INFO] UserCtrl > openReferralAttributionDialog >  closed', closed)
          if (closed.attributed) {
            $scope.user = closed.client
          }
        })
    };

    $scope.openReferralAttributionPastProjectsDialog = function(user) {
      const client = user;

      $scope
        .$mdDialog
        .show({
          ...ReferralAttributionsPastProjectsComponent,
          locals: {
            client: client
          }
        })
        .then(response => {
          console.log('[INFO] UserCtrl > openReferralAttributionDialog >  dialogResponse', response)
        }, closed  => {
          console.log('[INFO] UserCtrl > openReferralAttributionDialog >  closed', closed)
          if (closed.attributed) {
            $scope.user = closed.client
          }
        })
    };

    $scope.isExpert = function(user) {
      var result = false,
        expectedRole = 'contractor';

      if (user !== undefined) {
        result = (user.role === expectedRole);
      }

      return result;
    };

    $scope.canDelete = function() {
      var user = $scope.user;

      return user !== undefined && !user.purged && !user.active && $scope.isClient(user)
    }

    $scope.hideFromAdmin = function (user) {
      if (user == null) return

      if (user.role === 'superuser') {
        return false
      } else {
        return true
      }
    }

    $scope.openCouponsModal = function(client) {
      $scope.client = client;
      $modal.open({
        templateUrl: '/app/scripts/users/coupons/issueCouponModal.html',
        controller: 'CouponsCtrl',
        resolve: {
          client: function() {
            return $scope.client;
          }
        }
      });
    };

    $scope.openDeleteAccountModal = function(client) {
      $scope.client = client;
      $modal.open({
        templateUrl: '/app/scripts/users/userRemoval/deleteAccountModal.html',
        controller: 'DeleteAccountCtrl',
        resolve: {
          client: function() {
            return $scope.client;
          }
        }
      });
    };

    $scope.invalidateUserToken = function(user) {
      if (confirm("Are you sure you want to invalidate user token?")) {
        UsersResource.invalidateToken(user).then(function() {
          alert("Token invalidated!");
        });
      }
    }

    // UserPreferences

    $scope.getUserPreferences = function(user) {
      console.log('UserPreferencesCtrl#getUserPreferences', user);

      var params = {id: user.id};
      UsersResource.getUserPreferences(params).then(function(preferences) {
        $scope.preferences = preferences.preferences;
        $scope.notifications = preferences.preferences.notifications;
        $scope.marketingEmails = preferences.preferences.notifications.marketing;

        console.log('UserPreferencesCtrl#getUserPreferences: callback: ', $scope.marketingEmails);
      });
    };

    $scope.updateUserPreferences = function(user) {
      console.log('UserPreferencesCtrl#updateUserPreferences', user);
      var params = {id: user.id};
      var updates = { preferences: $scope.preferences };

      UsersResource.updateUserPreferences(params, updates).then(function(_emptyResponse) {
       // /sb/ how do I display success?
      });
    };

    $scope.walletEnabled = function(user) {
      return user.walletEnabled;
    };

    $scope.walletBalance = function(user) {
      if (!this.walletEnabled(user) ) return 0.0;

      const balance = user.walletBalance || 0.0;

      return balance;
    };

    $scope.moneyTransferRequest = function(user) {
      console.log('UserCtrl#moneyTransferRequest', user);

      $scope.sender = user;

      $modal.open({
        templateUrl: '/app/scripts/moneyTransferRequests/requestModal.html',
        controller: 'MoneyTransfersCtrl',
        resolve: {
          sender: function() {
            return $scope.sender;
          }
        }
      });
    };


    $scope.goToLookerDashboard = function (userId, $event) {
      if ($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }

      window.open(`https://codeable.eu.looker.com/dashboards/86?Expert ID=${userId}&Expert name=-NULL`, '_blank')
    }

    $scope.updateFlaggedBad = function(user) {
      UsersResource.updateUserAttributes(user.id, {flaggedBad: user.flaggedBad});
    };

    $scope.disableAccountAccessPermitted = function (user) {
      if ($scope.isClient(user) || $scope.isExpert(user)) {
        return true
      }
      // Check if current user has permission to manage enable/disable and lock/unlock on the admin accounts
      return PermissionsService.canCreate('admin-lock')
    }
  }

  window.app.controller('UserCtrl', ['$scope', '$stateParams', '$modal', '$mdDialog', 'userResource', 'UsersResource', 'WatchlistResource', 'NotesResource', '$location', 'PermissionsService', UserCtrl]);

})();
