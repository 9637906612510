(function() {
  'use strict';

  function KpisResource($q, Restangular, Configuration) {

		var taskCounts = function(options) {
			return Restangular.all('admin').all('kpis').all('tasks').get('', options);
		};

    var monthSums = function(options) {
      return Restangular.all('admin').all('kpis').all('month_sums').get('', options);
    };

    var globalKpis = function(options) {
      return Restangular.all('admin').all('kpis').all('global').get('', options);
    };

    var globalKpisNew = function(options) {
      return Restangular.all('admin').all('kpis').all('revenues').get('', options);
    };

    var cohortData = function(options) {
      return Restangular.all('admin').all('kpis').all('weekly_cohorts').get('', options);
    };

    var updateCohort = function(id, options) {
      return Restangular.all('admin').all('kpis').one('weekly_cohorts', id).put(options);
    };

    var userStats = function() {
      return Restangular.all('admin').all('kpis').all('user_stats').get('');
    };

    var utmStats = function() {
      return Restangular.all('admin').all('utm_funnels').get('');
    };

    var utmRevenueBreakdown = function(options) {
      return Restangular.all('admin').all('kpis').all('utm_revenue_breakdown').get('', options);
    };

    var referooNumbers = function(options, headers) {
      return Restangular.oneUrl('referoo', Configuration.referooApiUrl).all('revenues').get('', options, headers);
    };

    return {
      globalKpisNew: globalKpisNew,
      taskCounts: taskCounts,
      monthSums: monthSums,
      globalKpis: globalKpis,
      cohortData: cohortData,
      updateCohort: updateCohort,
      userStats: userStats,
      utmStats: utmStats,
      utmRevenueBreakdown: utmRevenueBreakdown,
      referooNumbers: referooNumbers
    };
  }

  window.app.service('KpisResource', ['$q', 'Restangular', 'Configuration', KpisResource]);
})();
