import templateUrl from './action-cancel.html'
import './action-cancel.scss'

import DialogWarning from '@/common/components/dialog-warning'

const ProjectActionCancelComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectActionCancel {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectActionCancelComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }

    get isActionAllowed () {
      return this.ProjectsService.isActionAllowed('cancel', this.project)
    }

    confirmAction () {
      this.isProcessing = true
      this.ProjectsService.cancelProject(this.project.id)
        .then(response => {
          // TODO: the response should be updated project so FE could update the data for the rest of the application - currently BE does not return anything back
          this.ProjectsService.updateProject(this.project, {
            state: 'canceled'
          })
          this.ToastService.show('Project canceled')
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    openActionDialog () {
      const vm = this
      this.$mdDialog.show({
        ...DialogWarning,
        locals: {
          text: `Are you sure you want to cancel project "${this.project.title}"?`,
          confirmCallback: function () {
            vm.confirmAction()
          }
        }
      })
    }
  }
}

export default ProjectActionCancelComponent
