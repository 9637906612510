import templateUrl from './action-watch.html'
import './action-watch.scss'

const ProjectActionWatchComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectActionWatch {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectActionWatchComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }

    get isActionAllowed () {
      return this.ProjectsService.isActionAllowed('watch', this.project)
    }

    watchProject () {
      this.isProcessing = true
      this.ProjectsService.watchProject(this.project.id)
        .then(response => {
          // TODO: the response should be updated project so FE could update the data for the rest of the application - currently BE does not return anything back
          this.ProjectsService.updateProject(this.project, { watchlist: true })
          this.ToastService.show('Project added to watchlist')
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    unwatchProject () {
      this.isProcessing = true
      this.ProjectsService.unwatchProject(this.project.id)
        .then(response => {
          // TODO: the response should be updated project so FE could update the data for the rest of the application - currently BE does not return anything back
          this.ProjectsService.updateProject(this.project, { watchlist: false })
          this.ToastService.show('Project removed from watchlist')
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default ProjectActionWatchComponent
