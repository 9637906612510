import defaultAvatarImage from '@/assets/img/defaultAvatarImage.jpg'
import transparentImage from '@/assets/img/transparentImage.png'
import systemAvatarImage from '@/assets/img/systemAvatarImage.png'

import templateUrl from './issue-view-history.html'
import './issue-view-history.scss'

const IssueViewHistoryComponent = {
  bindings: {
    issue: '<'
  },
  require: {
    issueViewCtrl: '^cdblIssueView'
  },
  templateUrl,
  controller: class IssueViewHistory {
    constructor (IssuesService, IssueViewService) {
      'ngInject'
      this._identify = 'IssueViewHistoryComponent'
      this.IssuesService = IssuesService
      this.IssueViewService = IssueViewService

      this.historyList = null
    }


    $onInit () {
      // Get notes list
      this.IssueViewService.getHistoryList(this.issue.id)
        .then(response => {
          this.historyList = response
        }, error => {
          console.log(error)
        })

      // TODO: subscribe to history events (issue updated/actions) - issue channel
    }


    /**
     * Return user avatar url from user object
     * @param  {Object}  userId         user id or email
     * @param  {Boolean} fallbackAvatar use fallback avatar, default true
     * @return {String}                 avatar url
     */
    userAvatar (userId, fallbackAvatar = true) {
      if (userId === 'system') {
        return systemAvatarImage
      }

      if (this.historyList.users) {
        const userData = this.historyList.users.find(u => u.email === userId || u.id === userId)
        return (userData && userData.avatar) ? userData.avatar.smallUrl : defaultAvatarImage
      }

      return fallbackAvatar ? defaultAvatarImage : transparentImage
    }

    userName (userId) {
      if (userId === 'system') {
        return 'System'
      }

      if (this.historyList.users) {
        const userData = this.historyList.users.find(u => u.email === userId || u.id === userId)
        return (userData && userData.fullName) ? userData.fullName : `[User object does not exist]`
      }
    }
  }
}

export default IssueViewHistoryComponent
