import templateUrl from './file-select.html'
import './file-select.scss'

const FileSelectComponent = {
  bindings: {
    _config: '<?config',
    onSelect: '&?',
    onSelectedFileRemoved: '&?'
  },
  templateUrl,
  require: {
    ngModel: 'ngModel'
  },
  transclude: true,
  controller: class FileSelect {
    constructor ($element, $attrs, $timeout, $window) {
      'ngInject'
      this._identify = 'FileSelectComponent'
      this.$element = $element
      this.$attrs = $attrs
      this.$timeout = $timeout
      this.$window = $window

      this.onFileSelectHandlerProxy = ($event) => {
        console.log('[FileSelectComponent] > onFileSelectHandlerProxy > this', this)
        this.onFileSelectHandler($event)
      }
    }

    $onInit () {
      this.config = {
        maxSize: null,
        multiple: false,
        showRemoveIcon: false,
        showFileName: false,
        showOriginalModelValue: false,
        previewWidth: 200,
        previewHeight: 150,
        accept: '*',
        preview: true,

        ...this._config
      }

      console.log('[FileSelectComponent] > $onInit', this.config)

      this.elFileSelector = this.$element[0].querySelector('#cdbl-file-select__selector')
      this.filesList = []

      // this.ngModel.$render = () => {
      //   this.filesList = []
      // }

      this.$timeout(() => {
        this.checkValidity() // wait until next tick

        // extract original value from ngModel
        if (this.config.showOriginalModelValue) {
          this.originalModelValue = this.processOriginalModelValue()
        }

        // set ng as empty so that files are not reuploaded
        this.ngModel.$setViewValue(null)
      })
    }

    $doCheck () {
      this.$timeout(() => {
        this.checkValidity() // wait until next tick
      })
    }

    processOriginalModelValue () {
      const data = this.ngModel.$viewValue
      console.log(!Array.isArray(data) && typeof data === 'object' && data.mediumUrl)

      // handle avatar image and trasform it to fileWrapper structure
      if (!Array.isArray(data) && typeof data === 'object' && data.mediumUrl) {
        return [{ src: data.mediumUrl }]
      }

      return data
    }

    openFileSelectDialog () {
      console.log('[FileSelectComponent] > openFileSelectDialog')
      this.elFileSelector.click()
    }

    onFileSelectHandler ($event) {
      $event.preventDefault()
      const files = Array.from($event.currentTarget.files)
      console.log('[FileSelectComponent] > onFileSelectHandler > files', this.filesList)

      if (!files || files.length === 0) {
        this.ngModel.$setTouched()
        return
      }

      this.filesList = files.filter(file => {
        if (this.config.maxSize && file.size > this.config.maxSize) {
          // this.errorHandler.add(new FileSizeError(file.name))
          return false
        }
        return true
      }).map(file => {
        return {
          file: file,
          src: null
        }
      }).map(fileWrapper => {
        if (this.config.preview) {
          this.loadPreviewImage(fileWrapper)
        }
        return fileWrapper
      })


      // Reset file input to allow removal and selection of same file
      this.elFileSelector.value = null

      // Update ngModel
      this.updateNgModel(this.filesList.map(fileWrapper => {
        console.log('updateNgModel', fileWrapper, this.filesList)
        return fileWrapper.file
      }))
    }

    loadPreviewImage (fileWrapper) {
      const reader = new this.$window.FileReader()
      const self = this
      reader.addEventListener('load', function () {
        self.$timeout(() => {
          fileWrapper.src = reader.result
        })
      }, false)
      if (fileWrapper.file) {
        reader.readAsDataURL(fileWrapper.file)
      }
    }

    previewImageStyle (fileWrapper) {
      return {
        display: 'inline-block',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${fileWrapper.src})`,
        width: `${this.config.previewWidth}px`,
        height: `${this.config.previewHeight}px`
      }
    }

    updateNgModel (newValue) {
      this.ngModel.$setViewValue(newValue)
      this.ngModel.$setDirty()
      this.ngModel.$setTouched()
      this.checkValidity()
    }

    get filesListAsText () {
      return this.filesList.map(fileWrapper => fileWrapper.file.name)
    }

    removeItemFromFilesList () {
      // TODO
    }

    checkValidity () {
      if (typeof this.filesList === 'undefined') {
        return
      }

      if ('required' in this.$attrs && this.$attrs.required) {
        if (this.filesList.length === 0) {
          this.ngModel.$setValidity('required', false)
        } else {
          this.ngModel.$setValidity('required', true)
        }
      }

      if ('max' in this.$attrs) {
        if (this.filesList.length > parseInt(this.$attrs.max)) {
          this.ngModel.$setValidity('max', false)
        } else {
          this.ngModel.$setValidity('max', true)
        }
      }

      if ('min' in this.$attrs) {
        if (this.filesList.length < parseInt(this.$attrs.min)) {
          this.ngModel.$setValidity('min', false)
        } else {
          this.ngModel.$setValidity('min', true)
        }
      }
    }
  }
}

export default FileSelectComponent
