(function() {
  'use strict';

  function CouponsResource($q, Restangular) {
		var getAdminCoupons = function(options) {
			var coupons = Restangular.all('admin').all('coupons').get('', options);

      return coupons;
    };

    var issueCouponToClient = function(couponCode, client) {
      var result, responseObject, payload, input;

      payload = {
        clientId: client.id,
        couponCode: couponCode,
      };
      input = { coupon: payload };

      responseObject = Restangular
                          .all('admin')
                          .one('clients', client.id)
                          .all('coupon')
                          .customPUT(input);  // `customPUT` because of the nested payload

      result  = handleResponse(responseObject);

      return result;
    };

    var handleResponse = function(responseObject) {
      responseObject
        .then(
          function(success) {
            console.log('CouponsResource#handleResponse(success): ', success);
            //ctrl.handleSuccess();
          },
          function(error) {
            console.log('CouponsResource#handleResponse(error): ', error);
            //ctrl.handleError();
          });
    };


    return {
      getAdminCoupons: getAdminCoupons,
      issueAdminCoupon: issueCouponToClient,
    };

  }

  window.app.service('CouponsResource', ['$q', 'Restangular', CouponsResource]);

})();
