(function() {
  'use strict';
  window.app.service('RevenueBreakdownResource', ['$q', 'Restangular', function($q, Restangular) {

    var getRevenueBreakdown = function(options) {
      return Restangular.all('admin').all('revenue_breakdown').get('',{
        from: options.fromdate,
        to: options.todate,
      });
    };
    var removeRevenueBreakdownItem = function(item_id, item_type) {
      return Restangular.all('admin').all('watchlist').remove({
        item_id: item_id,
        item_type: item_type
      });
    };

    return {
      getRevenueBreakdown: getRevenueBreakdown,
      removeRevenueBreakdownItem: removeRevenueBreakdownItem
    };
  }]);

})();
