import templateUrl from './project-widget-dates.html'
import './project-widget-dates.scss'

const ProjectWidgetDatesComponent = {
  bindings: {
    project: '<',
    isWidget: '<'
  },
  templateUrl,
  controller: class ProjectWidgetDates {
    constructor () {
      'ngInject'
      this._identify = 'ProjectWidgetDatesComponent'
    }
  }
}

export default ProjectWidgetDatesComponent
