import ProjectAttachmentsComponent from './project-attachments.component'
import ProjectAttachmentsService from './project-attachments.service'

import ProjectAttachmentModule from './attachment'

const ProjectAttachmentsModule = angular.module('cdbl.project-page.project-attachments', [
  ProjectAttachmentModule
])
  .component('cdblProjectAttachments', ProjectAttachmentsComponent)
  .service('ProjectAttachmentsService', ProjectAttachmentsService)
  .name

export default ProjectAttachmentsModule
