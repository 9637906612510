import templateUrl from './header-search.html'
import './header-search.scss'

const HeaderSearchComponent = {
  bindings: {},
  templateUrl,
  controller: class HeaderSearch {
    constructor ($state, $timeout, AuthService, ToastService) {
      'ngInject'
      this._identify = 'HeaderSearchComponent'
      this.$state = $state
      this.$timeout = $timeout
      this.AuthService = AuthService
      this.ToastService = ToastService
    }


    $onInit () {
      this.toolbarSearchVisible = false
      this.toolbarSearchInput = ''
      this.searchType = {
        selected: null,
        list: [
          {
            id: 'user',
            name: 'User',
            placeholder: 'ID, first or last name'
          },
          {
            id: 'email',
            name: 'Email',
            placeholder: 'Email, domain, name@'
          },
          {
            id: 'project',
            name: 'Project',
            placeholder: 'ID'
          }
        ]
      }

      this.searchType.selected = this.searchType.list[0]
    }


    toolbarSearchOpen (event) {
      console.log('toolbarSearchOpen', event, event.currentTarget.nextElementSibling.getElementsByTagName('input')[0])
      this.toolbarSearchVisible = true
      this.$timeout(() => {
        event.currentTarget.nextElementSibling.getElementsByTagName('input')[0].focus()
      })
    }

    toolbarSearchClose () {
      this.toolbarSearchVisible = false
      this.toolbarSearchInput = ''
    }

    toolbarSearch () {
      if (this.toolbarSearchInput === '') {
        this.ToastService.show('Please add search query you would like to search for')
        return
      }

      let searchString = this.cleanSearchString(this.toolbarSearchInput)

      // return id as string or false
      const extractId = (str) => {
        const id = str.replace(/ /g, '').match(/\d+/g)
        return id ? id[0] : false
      }

      const extractedId = extractId(searchString)
      const hasId = Boolean(extractedId)
      const searchType = this.searchType.selected.id
      if (searchType === 'project') {
        if (hasId) {
          // open project directly if id found
          this.$state.go('tasks.display', { id: extractedId })
          return
        }

        this.ToastService.show('Projects are currently searchable only by ID')
      } else if (searchType === 'user') {
        if (hasId) {
          // open project directly if id extracted
          this.$state.go('users.display', { id: extractedId })
          return
        }

        const params = {
          q: searchString,
          type: this.searchType.selected.id
        }
        this.$state.go('header-search', params, { reload: true })
      } else if (searchType === 'email') {
        if (!this.toolbarSearchInput.includes('@')) {
          // prepend '@' so that BE will search for email field if @ is not provided
          searchString = `@${searchString}`
        }

        const params = {
          q: searchString,
          type: this.searchType.selected.id
        }
        this.$state.go('header-search', params, { reload: true })
      }

      // const lower = searchString.toLowerCase()
      // if (lower.indexOf('task:') > -1 && !!extractedId) {
      //   this.$state.go('tasks.display', { id: extractedId })
      // } else if (lower.indexOf('user:') > -1 && !!extractedId) {
      //   this.$state.go('users.display', { id: extractedId })
      // } else if (lower.indexOf('utm:') > -1) {
      //   let term = lower.replace(/task:/, '').replace(/user:/, '').replace(/utm:/, '')
      //   this.$state.go('search.tasks', { q: $.trim(term), searchType: 'utm' })
      // } else {
      //   let term = lower.replace(/task:/, '').replace(/user:/, '')
      //   this.$state.go('search.users', { q: $.trim(term), searchType: 'users' })
      // }
    }

    cleanSearchString (searchString = '') {
      return searchString.trim().toLowerCase().replace(/task:/, '').replace(/user:/, '')
    }


    onToolbarSearchInputChange ($event) {
      const searchString = this.toolbarSearchInput.trim()
      if (searchString.includes('task:') || searchString.includes('project:')) {
        this.searchType.selected = this.searchType.list.find(t => t.id === 'project')
      }

      if (searchString.includes('user:')) {
        this.searchType.selected = this.searchType.list.find(t => t.id === 'user')
      }
    }
  }
}

export default HeaderSearchComponent
