(function() {
  'use strict';
  /*globals moment*/
  function AlertsCtrl($scope, $location, $stateParams, AlertsResource) {

    AlertsResource.taskAlerts().then(function(alerts) {
      $scope.alerts = alerts;
      $scope.numberFiltered = alerts.overEstimated.length;
    });

    $scope.formatDateTime = function(str) {
      return moment(str).format('D.M.YYYY, H:mm');
    };

    $scope.filters = {
      number: 5,
      results: 50
    };

    $scope.$watch('filters.number', function() {
      if ($scope.alerts && parseInt($scope.filters.number, 10) > 0) {
        $scope.numberFiltered = $scope.alerts.overEstimated.filter(function(x) {
          return parseInt(x.count, 10) > parseInt($scope.filters.number, 10);
        }).length;
      }
    });

  }

  window.app.controller('AlertsCtrl', ['$scope', '$location', '$stateParams', 'AlertsResource', AlertsCtrl]);

})();
