import templateUrl from './dialog-quality-review-confirmation.html'
import './dialog-quality-review-confirmation.scss'

const DialogQualityReviewConfirmationComponent = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  controller: class DialogQualityReviewConfirmation {
    constructor ($mdDialog, ExpertPodsService) {
      'ngInject'
      this._identify = 'DialogQualityReviewConfirmationComponent'
      this.$mdDialog = $mdDialog
      this.ExpertPodsService = ExpertPodsService
      this.eligibleExpertsList = []

      this.formData = {
        template: null,
        content: null,
        private: false,
        searchText: ''
      }

      this.modelOptions = {
        allowInvalid: true,
        updateOn: 'default blur',
        debounce: {
          'default': 500,
          'blur': 0,
          '*': 500
        }
      }
    }


    $onInit () {
      this.isLoading = true
      this.ExpertPodsService.getExpertPod(this.podId)
        .then(response => {
          this.eligibleExpertsList = response.expertPodsMembers.map(expert => expert.member)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    selectExpert (template) {
      this.action.payload = { ...this.action.payload, expertId: this.formData.expert.id }
      this.clearSearchText()
    }

    clearSearchText () {
      this.formData.searchText = ''
    }

    searchTemplateOnKeydown ($event) {
      console.log('searchTemplateOnKeydown', $event)
      const ignoreKeys = ['Escape', 'ArrowDown', 'ArrowUp']
      if (!ignoreKeys.includes($event.key)) {
        $event.stopPropagation()
      }
    }
  }
}

export default DialogQualityReviewConfirmationComponent
