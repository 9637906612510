import templateUrl from './settings-account.html'
import './settings-account.scss'

import DialogSettingsAccountEdit from './dialog-settings-account-edit'
import DialogSettingsAccountChangePassword from './dialog-settings-account-change-password'
import DialogSettingsAccountMfa from './dialog-settings-account-mfa'
import DialogSettingsAccountMfaDisable from './dialog-settings-account-mfa-disable'

const SettingsAccountComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsAccount {
    constructor (SettingsAccountService, $mdDialog) {
      'ngInject'
      this._identify = 'SettingsAccountComponent'

      this.SettingsAccountService = SettingsAccountService
      this.$mdDialog = $mdDialog
    }

    $onInit () {
      // fetch fresh user data
      this.getAccountSettings()
    }

    getAccountSettings () {
      this.isLoading = true
      return this.SettingsAccountService.getSettings()
        .then(response => {
          this.userAccount = response
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    openAccountEditDialog () {
      this.$mdDialog
        .show({
          ...DialogSettingsAccountEdit,
          locals: {
            userAccount: {
              ...this.userAccount
            }
          }
        })
        .then(response => {
          console.log('[] > openAccountEditDialog > response', response)
          this.userAccount = response
        })
        .finally(() => {
          // this.getAccountSettings()
        })
    }

    openChangePasswordDialog () {
      this.$mdDialog
        .show({
          ...DialogSettingsAccountChangePassword
        })
    }

    openMfaEnableDialog () {
      this.$mdDialog
        .show({
          ...DialogSettingsAccountMfa
        })
        .finally(() => {
          this.getAccountSettings()
        })
    }

    openMfaDisableDialog () {
      this.$mdDialog
        .show({
          ...DialogSettingsAccountMfaDisable
        })
        .finally(() => {
          this.updateUserData()
        })
    }


    get canEdit () {
      return this.userAccount && this.userAccount.actions.updateAccountDetails
    }

    get canChangePassword () {
      return true // return this.userAccount && this.userAccount.actions.updateAccountDetails
    }
  }
}

export default SettingsAccountComponent
