// TODO: this component should be renamed and organized as client contextual button/menu with multiple actions CS can perform, similar could be done for expert and project

import templateUrl from './button-create-issue.html'
import './button-create-issue.scss'

const ButtonCreateIssueComponent = {
  bindings: {
    client: '<'
  },
  templateUrl,
  controller: class ButtonCreateIssue {
    constructor ($mdToast, $state, $window, IssuesService) {
      'ngInject'
      this._identify = 'ButtonCreateIssueComponent'

      this.$mdToast = $mdToast
      this.$state = $state
      this.$window = $window
      this.IssuesService = IssuesService

      this.opportunity = null
      this.isLoading = false
    }

    $onInit () {
      this.isLoading = true
      this.IssuesService.getOpportunityIssue(this.client.id)
        .then(response => {
          if ('id' in response) {
            this.opportunity = response
          }
        })
        .finally(() => {
          this.isLoading = false
          console.log('[ButtonCreateIssueComponent] > getOpportunityIssue > finally', this.opportunity, this.isLoading)
        })
    }

    createOpportunity () {
      this.isLoading = true
      this.IssuesService.createOpportunityIssue(this.client.id)
        .then(response => {
          this.opportunity = response
          console.log(response, this.client)
          this.$mdToast.show(this.$mdToast
            .simple()
            .textContent('An opportunity ticket has been created for this client.')
            .position('top right')
            .hideDelay(3000))
        })
        .catch(err => {
          console.log(err)
          this.$mdToast.show(this.$mdToast
            .simple()
            .textContent(`An error occured${err.message ? ': ' + err.message : ''}`)
            .position('top right')
            .hideDelay(3000))
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    redirectToOpportunity () {
      const params = this.IssuesService.generateRedirectIssueParams(this.opportunity)
      // this.$state.go('issueView', params)
      this.$window.open(this.$state.href('issueView', params), '_blank')
    }
  }
}

export default ButtonCreateIssueComponent
