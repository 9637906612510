import ExpertPodsComponent from './expert-pods.component'
import ExpertPodsService from './expert-pods.service'

import ExpertPodsListModule from './expert-pods-list'
import ExpertPodsViewModule from './expert-pods-view'

const ExpertPodsModule = angular.module('cdbl.expert-pods', [
  ExpertPodsListModule,
  ExpertPodsViewModule
])
  .component('cdblExpertPods', ExpertPodsComponent)
  .service('ExpertPodsService', ExpertPodsService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
      // parent: '',
        abstract: true,
        name: 'expertPods',
        url: '/expert-pods',
        component: 'cdblExpertPods',
        // deepStateRedirect: {
        //   default: { state: 'expertPods.podList' }
        // },
        resolve: {
          permissionResolver: (Configuration, AuthService) => {
            'ngInject'
            return AuthService.isAuthenticated
          }
        }
      })
  })
  .name

export default ExpertPodsModule
