(function() {
  'use strict';
  /*globals confirm*/
  function ReviewsCtrl($scope, $location, $stateParams, ReviewsResource) {

    $scope.onSelectPage = function(page) {

      ReviewsResource.getReviews({page: page.toString()}).then(function(reviews) {

        $scope.reviews = reviews;
				// Pagination
				$scope.totalItems = reviews.headers('X-Total-Count');
				$scope.itemsPerPage = 20;
				$scope.currentPage = page.toString();
				$scope.numPages = reviews.headers('X-Page-Count');
				$scope.maxSize = 5;

      });

    };

		$scope.onSelectPage('1');

    $scope.updateReview = function(review, type) {
      /*var index = _.findIndex($scope.reviews, function(rv) {
        return rv.timestamp === review.timestamp;
      });*/

      if (review['updating' + type] === undefined) {
        review['updating'+type] = true;
      } else {
        ReviewsResource.updateReview(review.id, review).then(function() {
          review['updating' + type] = undefined;
        });
      }
    };

    $scope.deleteReview = function(review) {
      if (confirm('Are you sure you would like to delete this review?')) {
        ReviewsResource.deleteReview(review.id).then(function() {
          review.hide = true;
        });
      }
    };

  }

  window.app.controller('ReviewsCtrl', ['$scope', '$location', '$stateParams', 'ReviewsResource', ReviewsCtrl]);

})();
