(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('transactions', {
      url: '/transactions/:type',
      params: {
        type: {
          value: 'client_deposit'
        }
      },
      templateUrl: '/app/scripts/transactions/transactions.html',
      controller: 'TransactionsCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
