import templateUrl from './templates-comment.html'
import './templates-comment.scss'

import TemplateEditDialog from './template-edit.dialog.js'

const TemplatesCommentComponent = {
  bindings: {},
  templateUrl,
  controller: class TemplatesComment {
    constructor ($mdDialog, $mdToast, $state, $stateParams, TemplatesCommentService) {
      'ngInject'
      this._identify = 'TemplatesCommentComponent'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.$state = $state
      this.$stateParams = $stateParams
      this.TemplatesCommentService = TemplatesCommentService

      this.togglePreviewAllState = false
      this.templateList = []
    }

    $onInit () {
      this.isLoading = true
      this.TemplatesCommentService.getTemplateList()
        .then(response => {
          console.log('[TemplatesCommentService]', response)
          this.templateList = response.data.reverse()

          console.log('$stateParams', this.$stateParams)
          if (this.$stateParams.templateId) {
            const openModalTemplate = this.templateList.find(item => item.id.toString() === this.$stateParams.templateId)

            if (openModalTemplate) {
              this.openEditModal(openModalTemplate)
            }
          }
        }).finally(() => {
          this.isLoading = false
        })
    }

    toggleContent (item, value) {
      item._showFullContent = typeof value === 'undefined' ? !item._showFullContent : value
    }


    togglePreviewAll () {
      if (this.templateList && this.templateList.length) {
        this.togglePreviewAllState = !this.togglePreviewAllState
        this.templateList.forEach(item => {
          this.toggleContent(item, this.togglePreviewAllState)
        })
      }
    }


    openEditModal (item) {
      item = { ...item } // copy item to new object to break reference to the item in list
      this.$mdDialog
        .show({
          ...TemplateEditDialog,
          locals: {
            item
          }
        })
        .then(updatedItem => {
          console.log('updatedItem', updatedItem)
          const index = this.templateList.findIndex(itemInList => itemInList.id === updatedItem.id)

          if (index >= 0) {
            this.templateList[index] = updatedItem
            this.$mdToast.showSimple('Template updated')
          } else {
            this.templateList.unshift(updatedItem)
            this.$mdToast.showSimple('Template created')
          }
        }, () => {
          // this.$mdToast.showSimple('Template not saved')
        })
        .finally(() => {
          if (this.$stateParams.templateId) {
            this.$state.go('settings-templates-comment', {
              ...this.$stateParams,
              templateId: null
            }, {
              notify: false
            })
          }
        })
    }

    deleteTemplate (item) {
      this.$mdDialog
        .show(this.$mdDialog.confirm({
          title: `Delete template #${item.id}`,
          textContent: 'Are you sure you want to delete template?',
          ok: 'Delete',
          cancel: 'Cancel',
          theme: 'md-red'
        }))
        .then(dialogResponse => {
          this.TemplatesCommentService
            .deleteTemplate(item.id)
            .then(response => {
              const index = this.templateList.findIndex(itemInList => itemInList.id === item.id)

              if (index >= 0) {
                this.templateList.splice(index, 1)
              }

              this.$mdToast.showSimple('Template deleted')
            }, error => {
              if (error) {
                console.log(error.stack)
              }
              this.$mdToast.showSimple('Template not deleted because of an error')
            })
        }, dialogResponse => {
          // this.$mdToast.showSimple('Rejected', dialogResponse)
        })
    }
  }
}

export default TemplatesCommentComponent
