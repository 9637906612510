import templateUrl from './page.html'
import './page.scss'

const PageComponent = {
  bindings: {
    isLoading: '<?',
    pageLayout: '@?'
  },
  transclude: {
    'headerTop': '?pageHeaderTop',
    'title': '?pageTitle',
    'titleActions': '?pageTitleActions',
    'subtitle': '?pageSubtitle',
    'meta': '?pageMeta',
    'actions': '?pageActions',
    'contentTop': '?pageContentTop',
    'contentBottom': '?pageContentBottom',
    'content': '?pageContent',
    'sidebar': '?pageSidebar',
    'footer': '?pageFooter',
  },
  templateUrl,
  controller: class Page {
    constructor ($scope, $state, $stateParams) {
      'ngInject'
      this._identify = 'PageComponent'
      this.$scope = $scope
      this.$state = $state
      this.$stateParams = $stateParams

      this.breadcrumbList = []
      this.title = ''
    }

    $onInit () {
      console.log('[PageComponent] > ', this.$state)

      this.pageLayout = this.pageLayout || 'content-only'

      this.$scope.$on('$stateChangeSuccess', () => {
        // Add the current state and params to the scope
        // $scope.current = $state.$current;
        // $scope.params = $stateParams;
        this.generateBreadcrumbs(this.$state.$current, this.$stateParams)
      })
    }
    get showPageControls () {
      // TODO: integrate with breadcrumbs and not just with back button to parent state
      return this.showGoBackButton
    }

    get showGoBackButton () {
      return this.$state.$current && this.$state.$current.data && this.$state.$current.data.showBackLink
    }

    get goBackButtonLabel () {
      let label = 'Go back'
      const parent = this.$state.$current.parent
      if (parent && parent.data && parent.data.breadcrumb) {
        label = `${parent.data.breadcrumb}`
      }
      return label
    }

    goBack () {
      if (this.$state.$current && this.$state.$current.parent && this.$state.$current.parent.name !== '') {
        const parentState = this.$state.$current.parent

        // TODO - only use params that are defined on parent state config object
        this.$state.go(parentState.name, this.$stateParams)
      }
    }

    generateBreadcrumbs (state, params) {
      if (state.parent) {
        this.generateBreadcrumbs(state.parent, params)
      }

      if (state.breadcrumb) {
        if (state.breadcrumb.title) {
          // addBreadcrumb($interpolate(state.breadcrumb.title)(state.locals.globals), state.name);

          let displayName = ''
          // Loop through ownParams and replace any expressions with the matching value
          console.log(params)
          console.log(Object.keys(params))

          Object.keys(params).forEach((param, index) => {
            console.log(param)
            displayName = state.breadcrumb.title.replace('{:' + param + '}', params[param])
          })
          this.addBreadcrumb(displayName, state.name)
        }
      }


      this.generateTitle()
    }

    generateTitle () {
      this.title = ''

      this.breadcrumbList.forEach((breadcrumb, index) => {
        this.title += this.appendTitle(breadcrumb.title, index)
      })
    }

    appendTitle (translation, index) {
      let title = translation

      if (index < this.breadcrumbList.length - 1) {
        title += ' > '
      }

      return title
    }

    addBreadcrumb (title, state) {
      this.breadcrumbList.push({
        title: title,
        state: state
      })
    }
  }
}

export default PageComponent
