(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('payments', {
      url: '/payments',
      templateUrl: '/app/scripts/payments/list.payments.html',
      controller: 'PaymentsCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
