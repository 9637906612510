(function() {
  'use strict';

  function OfflinePaymentsCtrl($scope, Restangular) {
    var paypalPaymentType  = 'paypal';
    var wirePaymentType    = 'wire';
    var referooPaymentType = 'referoo';
    var defaultPaymentType = wirePaymentType;

    function isWire() {
      return $scope.payment.type === wirePaymentType;
    }

    function handleSuccess() {
        console.log('Offline Payment: payment successful.');
        $scope.task.state = 'paid';
    }

    function handleFailure(response) {
      console.log('Offline Payment: payment failed!', response.status);
    }

    $scope.payment  = {
      'type'          : defaultPaymentType,
      'realPayment'   : false,
      'transaction'     : {
        'id'            : undefined,
        'amount'        : $scope.task.prices.clientPriceAfterDiscounts,
        'bankCharges'   : '0.00',
        'description'   : ''
      }
    };

    $scope.recordOfflinePayment = function() {
      console.log('Offline Payment:', $scope.payment);
      $scope.task.one('payments').customPOST({
        payment : $scope.payment
      })
      .then(handleSuccess, handleFailure);
    };

    $scope.recordOfflinePaymentSubTask = function(subTask) {
      console.log('Offline Payment:', $scope.subTaskPayment);
      Restangular.all('admin')
      .one('tasks', subTask.id)
      .one('payments').customPOST({
        payment : $scope.subTaskPayment
      }).then(
        function(){
          subTask.state = 'paid';
        },
        function(data){
          console.log(data);
        }
      );
    };

    $scope.showBankCharges   = function() {
      return isWire();
    };

  }

  window.app.controller('OfflinePaymentsCtrl', ['$scope', 'Restangular', OfflinePaymentsCtrl]);

})();
