import templateUrl from './dialog-quality-review-history.html'
import './dialog-quality-review-history.scss'

const DialogQualityReviewHistoryComponent = {
  bindings: {},
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: true,
  clickOutsideToClose: true,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogQualityReviewHistory {
    constructor ($mdDialog) {
      'ngInject'
      this._identify = 'DialogQualityReviewHistoryComponent'
      this.$mdDialog = $mdDialog
    }
  }
}

export default DialogQualityReviewHistoryComponent
