const UserRoleFormatFilter = () => {
  'ngInject'
  return function (role) {
    switch (role) {
    case 'contractor':
      return 'expert'
    }

    return role
  }
}
export default UserRoleFormatFilter
