(function() {
  'use strict';

  function formatForPer() {
    return function(amount) {
      return amount
          .toString()
          .replace(',', '-')
          .replace('.', ',')
          .replace('-', '.');
    };
  }

  window.app.filter('formatForPer', formatForPer);

})();
