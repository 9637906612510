(function() {
  'use strict';

  function commentsForm(UserService, TokenService, $mdToast, Configuration, $stateParams) {
    return {
      restrict: 'E',
      scope: {
        task: '=',
        comment: '=',
        tokenValidator: '<',
        tokenValues: '<',
        commentTemplates: '<',
        addActivity: '&'
      },
      templateUrl: '/app/scripts/tasks/comments/post.comments.html',
      link: function($scope) {
        $scope.selectedCommentTemplate = null;
        $scope.select2Options = {
          'multiple': false,
          'simple_tags': true,
          'allowClear': true
        };

        // Token validation and replacement
        $scope.tokenValues = {
          'cdbl_current_user_first_name': UserService.user.firstName,
          'cdbl_current_user_full_name': UserService.user.fullName,
          'cdbl_workroom_url': `${Configuration.appUrl}/tasks/${$stateParams.id}`
        }
        $scope.tokenValidator = [
          'cdbl_current_user_first_name',
          'cdbl_current_user_full_name',
          'cdbl_workroom_url'
        ]
        if ($scope.task.client) {
          $scope.tokenValues['cdbl_client_first_name'] = $scope.task.client.fullName.split(' ')[0]
          $scope.tokenValues['cdbl_client_full_name'] = $scope.task.client.fullName
          $scope.tokenValidator = [...$scope.tokenValidator, ...['cdbl_client_first_name', 'cdbl_client_full_name']]
        }
        if ($scope.task.contractor) {
          $scope.tokenValues['cdbl_expert_first_name'] = $scope.task.contractor.fullName.split(' ')[0]
          $scope.tokenValues['cdbl_expert_full_name'] = $scope.task.contractor.fullName
          $scope.tokenValidator = [...$scope.tokenValidator, ...['cdbl_expert_first_name', 'cdbl_expert_full_name']]
        }
        // -----

        $scope.isProcessing = false;

        $scope.postComment = function () {
          let contentWithReplacedTokens;
          try {
            contentWithReplacedTokens = TokenService.replace($scope.comment.text, $scope.tokenValidator, $scope.tokenValues);
          } catch (err) {
            $mdToast.showSimple(`Tokens error! Comment not sent.`);
            return;
          }

          const payload = {
            text: contentWithReplacedTokens,
            isContractorsOnly: $scope.comment.isContractorsOnly
          }

          $scope.isProcessing = true;
          $scope.task.all('comments').post(payload).then(comment => {
            comment.verb = 'create_comment';
            comment.allowed = true;

            if ($scope.addActivity) {
              $scope.addActivity({
                activity: comment
              })
            }


            $scope.comment.text = '';
            $scope.commentForm.$setPristine();

            $scope.isProcessing = false;
            console.log('[commentsForm] isProcessing', $scope.isProcessing, $scope.comment);
          }, error => {
            $scope.isProcessing = false;
            console.log('[commentsForm] isProcessing', $scope.isProcessing);
          });
        };


        $scope.setCommentTemplate = function(template) {
          if(template !== null){
            if(typeof template.text !== 'undefined'){
              $scope.comment.text = template.text;
            }else{
              template = angular.fromJson(template);
              $scope.comment.text = template.text;
            }
          }

          $scope.selectedCommentTemplate = null;
        };

      }
    };
  }

  app.directive('commentsForm', ['UserService', 'TokenService', '$mdToast', 'Configuration', '$stateParams', commentsForm]);
})();
