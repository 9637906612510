import ReferralAttributionsService from './referral-attributions.service'

const ReferralAttributionsModule = angular.module('cdbl.referral-attributions', [
  'ui.router'
])

  .service('ReferralAttributionsService', ReferralAttributionsService)
  .name

export default ReferralAttributionsModule
