import templateUrl from './resource-client-stats.html'
import './resource-client-stats.scss'

const IssueResourceClientStatsComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,

  controller: class IssueResourceClientStats {
    constructor (Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourceClientStatsComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService

      this.clientStats = null
    }

    $onInit () {
      this.IssueViewService.getResourceClientStats(this.issue.id)
        .then(response => {
          this.clientStats = response
        }, error => {
          console.log(error)
        })
    }
  }
}

export default IssueResourceClientStatsComponent
