const ExpertTeamService = class ExpertTeamService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'ExpertTeamService'
    this.$http = $http
    this.Configuration = Configuration
  }

  fetchExpertTeam (expertId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/experts/${expertId}/team`)
      .then(response => response.data)
  }

  updateExpertTeamMember (config, payload) {
    return this.$http({
      method: config.verb,
      url: `${this.Configuration.apiUrl}${config.url}`,
      data: payload
    })
      .then(response => response.data)
  }

  toggleEnablement (config) {
    return this.$http({
      method: config.verb,
      url: `${this.Configuration.apiUrl}${config.url}`
    })
      .then(response => response.data)
  }

  fetchExpertRoles (config) {
    return this.$http({
      method: config.verb,
      url: `${this.Configuration.apiUrl}${config.url}`
    })
      .then(response => response.data)
  }
}
export default ExpertTeamService
