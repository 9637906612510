import templateUrl from './folder-list.html'
import './folder-list.scss'

// const OTHER_INBOX_TOGGLE = 'issues--other-inbox-list--toggle'
const IS_TEAM_VISIBLE = 'issues--is-team-visible'
const IS_FOLDER_OPEN = 'issues--is-folder-open'
const FolderListComponent = {
  bindings: {

  },
  templateUrl,
  controller: class FolderList {
    constructor ($state, $stateParams, UserService, StorageService, IssuesService, LoadingService) {
      'ngInject'
      this._identify = 'FolderListComponent'
      this.$state = $state
      this.$stateParams = $stateParams
      this.UserService = UserService
      this.StorageService = StorageService
      this.LoadingService = LoadingService
      this.IssuesService = IssuesService

      this.isTeamVisible = false
      this.isFolderOpen = {
        'inbox:me': true
      }
    }

    $onInit () {
      const _isFolderOpen = this.StorageService.get(IS_FOLDER_OPEN)
      if (_isFolderOpen) {
        this.isFolderOpen = _isFolderOpen
      }

      const _isTeamVisible = this.StorageService.get(IS_TEAM_VISIBLE)
      if (_isTeamVisible) {
        this.isTeamVisible = _isTeamVisible
      }

      this.isInboxListLoading = true
      this.LoadingService.loader('IssuesService::getInboxList').then(() => {
        console.log('[FolderList] > loader finished')
        this.isInboxListLoading = false

        // open team inbox if conditions met
        if (this.$stateParams.folderId && this.$stateParams.folderId.includes('inbox:') && this.$stateParams.folderId !== `inbox:${this.UserService.user.id}`) {
          this.isTeamVisible = true
          this.isFolderOpen[this.$stateParams.folderId] = true
        }
      })
    }

    get folderList () {
      return this.IssuesService.folderList.filter(folder => {
        if (!this.isTeamVisible) {
          return !(folder.id.includes('inbox') && folder.id !== 'inbox:me')
        }

        return true
      })
    }

    // toggleOtherInbox () {
    //   this.showOtherInboxes = !this.showOtherInboxes
    //   this.StorageService.set(OTHER_INBOX_TOGGLE, this.showOtherInboxes)
    // }


    toggleFolderOpen (folder) {
      this.isFolderOpen[folder.id] = !this.isFolderOpen[folder.id]
      this.StorageService.set(IS_FOLDER_OPEN, this.isFolderOpen)

      // On inbox open navigate to all subfolder automatically
      // const folderId = folder.id === 'inbox:me' ? `inbox:${this.UserService.user.id}` : folder.id
      if (folder.id === 'all' && this.isFolderOpen[folder.id]) {
        this.$state.go('issueList', {
          ...this.$stateParams,
          folderId: 'all', // folderId
          subFolderId: 'all'
        })
      }
    }


    toggleTeamVisibility () {
      this.isTeamVisible = !this.isTeamVisible
      this.StorageService.set(IS_TEAM_VISIBLE, this.isTeamVisible)
    }
  }
}

export default FolderListComponent
