import ExpertPodsListComponent from './expert-pods-list.component'

const ExpertPodsListModule = angular.module('cdbl.expert-pods-list', [

])
  .component('cdblExpertPodsList', ExpertPodsListComponent)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'expertPods.podList',
        url: '?page', // without slug - makes it a default page after abstract parent
        params: {
          page: { squash: true, dynamic: true }
        },
        views: {
          '@expertPods': 'cdblExpertPodsList'
        },
        data: {
          breadcrumb: 'Expert pods',
        },
        resolve: {
          permissionResolver: (Configuration, AuthService) => {
            'ngInject'
            return AuthService.isAuthenticated
          }
        }
      })
  })
  .name

export default ExpertPodsListModule
