import templateUrl from './expert-pods.html'
import './expert-pods.scss'

// import TemplateEditDialog from './template-edit.dialog.js'

const ExpertPodsComponent = {
  bindings: {},
  templateUrl,
  controller: class ExpertPods {
    constructor ($mdDialog, $mdToast, ExpertPodsService) {
      'ngInject'
      this._identify = 'ExpertPodsComponent'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.ExpertPodsService = ExpertPodsService

      this.isLoading = false
      this.podList = null
    }


    $onInit () {
      this.podList = null
      this.isLoading = false
      this.fetchPodList()
    }

    fetchPodList () {
      this.isLoading = true
      this.ExpertPodsService.getExpertPodsList()
        .then(response => {
          this.podList = response
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}

export default ExpertPodsComponent
