(function() {
  'use strict';

  function ReviewsResource($q, Restangular) {

		var getReviews = function(options) {
			return Restangular.all('admin').all('reviews').get('', options);
		};

    var getReview = function(id) {
      return Restangular.all('admin').one('reviews', id).get();
    };

    var postReview = function(options) {
      return Restangular.all('admin').all('reviews').post(options);
    };

    var updateReview = function(id, options) {
      console.log(id, options);

      return Restangular.all('admin').one('reviews', id).put(options);
    };

    var deleteReview = function(id) {
      return Restangular.all('admin').one('reviews', id).remove();
    };

    return {
      getReviews: getReviews,
      getReview: getReview,
      postReview: postReview,
      updateReview: updateReview,
      deleteReview: deleteReview
    };
  }

  window.app.service('ReviewsResource', ['$q', 'Restangular', ReviewsResource]);
})();
