import templateUrl from './dialog-settings-account-mfa.dialog.html'
import './dialog-settings-account-mfa.dialog.scss'

const DialogSettingsAccountMfa = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogSettingsAccountMfa {
    constructor ($sce, $mdDialog, $mdToast, $window, Configuration, SettingsAccountService) {
      'ngInject'
      this._identify = 'DialogSettingsAccountMfa'
      this.$sce = $sce
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.$window = $window
      this.Configuration = Configuration
      this.SettingsAccountService = SettingsAccountService
    }

    $onInit () {
      this.isLoading = false
      this.mfa = {
        otpSecret: null,
        otpQrCode: null,
        completed: false,
        error: null,
        backupCodesList: null,
        isCopied: false
      }
      this.formData = {
        password: null,
        otpAttempt: null
      }
      // request shared key from BE
      this.isLoading = true
      this.SettingsAccountService.setupMfaInit()
        .then(response => {
          this.mfa = response
          this.mfa.otpQrCode = this.$sce.trustAsHtml(this.mfa.otpQrCode)
        })
        .catch(err => {
          console.log(err)
          this.mfa.error = true
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    $onDestroy () {

    }

    resetErrors () {
      this.mfa.error = null
      this.mfa.isCopied = false
    }

    copyToClipboard () {
      const textarea = document.createElement('textarea')
      let text = ''
      this.mfa.backupCodesList.forEach(code => {
        text += `${code}\n`
      })
      textarea.value = text
      console.log(text)

      // Avoid scrolling to bottom
      textarea.style.opacity = '0'
      textarea.style.top = '0'
      textarea.style.left = '0'
      textarea.style.position = 'fixed'

      document.body.appendChild(textarea)
      textarea.focus()
      textarea.select()

      try {
        this.mfa.isCopied = document.execCommand('copy')
        console.log('Fallback: Copying text command was ' + this.mfa.isCopied)
      } catch (err) {
        console.log('Fallback: Oops, unable to copy', err)
      }
      document.body.removeChild(textarea)
    }

    confirmMfaEnable () {
      this.isProcessing = true
      this.resetErrors()
      this.SettingsAccountService.setupMfaEnable(this.formData)
        .then(response => {
          this.mfa.completed = true
          this.mfa.backupCodesList = response.backupCodes
        })
        .catch(err => {
          console.log(err)
          this.mfa.error = true
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogSettingsAccountMfa
