(function() {
  'use strict';

  function taskUrgencyToString() {
    return function(taskUrgency) {
      var taskUrgencyString = '';

      switch (taskUrgency) {
        case 0:
          taskUrgencyString = 'Urgent';

          break;
        case 1:
          taskUrgencyString = 'Today or tomorrow';

          break;
        case 2:
          taskUrgencyString = 'In a week';

          break;
        case 3:
          taskUrgencyString = 'Anytime';

          break;
      }

      return taskUrgencyString;
    };
  }

  app.filter('taskUrgencyToString', taskUrgencyToString);
})();
