import templateUrl from './auth-login.html'
import './auth-login.scss'

const AuthLoginComponent = {
  bindings: {},
  templateUrl,
  controller: class AuthLogin {
    constructor ($state, AuthService, Configuration) {
      'ngInject'
      this._identify = 'AuthLoginComponent'
      this.$state = $state
      this.AuthService = AuthService
      this.Configuration = Configuration
    }


    $onInit () {
      if (this.AuthService.isAuthenticated) {
        this.redirectToDefaultPage()
      }

      this.formData = {
        username: null,
        password: null,
        otpAttempt: null
      }

      this.otpRequired = false
    }

    redirectToDefaultPage () {
      this.$state.go(this.Configuration.navigation.defaultPage.sref, this.Configuration.navigation.defaultPage.params)
    }

    reset () {
      this.error = null
      this.otpRequired = false
      this.formData.username = null
      this.formData.password = null
      this.formData.otpAttempt = null
    }

    login () {
      this.AuthService.login(this.formData)
        .then(response => {
          console.log('[AuthLoginComponent] > login > response', response)
          if (response.otpRequiredForLogin && typeof response.id === 'undefined') {
            this.otpRequired = true
            return
          }
          this.redirectToDefaultPage()
        })
        .catch(err => {
          this.error = err
        })
    }
  }
}

export default AuthLoginComponent
