import templateUrl from './dialog-edit-expert-availabiltiy.dialog.html'
import './dialog-edit-expert-availabiltiy.dialog.scss'

const DialogEditExpertAvailabiltiy = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogEditExpertAvailabiltiy {
    constructor ($mdDialog, ToastService, $http, Configuration) {
      'ngInject'
      this._identify = 'DialogEditExpertAvailabiltiy'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.$http = $http
      this.Configuration = Configuration

      this.isLoading = false
      this.showConfirmation = false
      this.availability = {
        generalAvailability: false,
        preferredProjects: false,
        preferredFromPreviousClients: false
      }
      this.payload = null
    }

    $onInit () {
      this.$http
        .get(`${this.Configuration.apiUrl}/admin/users/${this.id}/preferences/project_availability`)
        .then(response => {
          const preferred = response.data.preferred
          this.handleResponse(preferred)
        }).catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    handleResponse (preferred) {
      this.availability = {
        preferredProjects: (preferred === 'old' || preferred === 'all'),
        preferredFromPreviousClients: (preferred === 'all'),
        generalAvailability: (preferred !== 'none')
      }
      this.payload = {
        preferred: preferred
      }
    }

    onToggleAll () {
      if (this.availability.generalAvailability) {
        this.availability.preferredFromPreviousClients = true
        this.availability.preferredProjects = true
        this.payload = { preferred: 'all' }
      } else {
        this.availability.preferredFromPreviousClients = false
        this.availability.preferredProjects = false
        this.payload = { preferred: 'none' }
      }
    }

    onTogglePreferredProjects () {
      this.payload.preferred = this.availability.preferredProjects ? 'all' : 'none'
      this.availability.preferredFromPreviousClients = this.availability.preferredProjects
      this.availability.generalAvailability = this.availability.preferredProjects
    }

    onToggle () {
      if (!this.availability.preferredFromPreviousClients && this.availability.preferredProjects) {
        this.payload.preferred = 'old'
      } else if (this.availability.preferredFromPreviousClients && this.availability.preferredProjects) {
        this.payload.preferred = 'all'
      } else if (!this.availability.preferredProjects) {
        this.payload.preferred = 'none'
        this.availability.preferredFromPreviousClients = false
      }
      this.availability.generalAvailability = this.availability.preferredProjects
    }

    showEditConfirmation () {
      this.showConfirmation = true
    }

    hideEditConfirmation () {
      this.showConfirmation = false
    }


    confirmEdit () {
      const payload = {
        ...this.payload
      }
      this.isProcessing = true
      this.$http
        .put(`${this.Configuration.apiUrl}/admin/users/${this.id}/preferences/project_availability`, payload)
        .then(response => {
          this.$mdDialog.cancel()
          this.ToastService.show('Expert availability updated')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogEditExpertAvailabiltiy
