(function() {
  'use strict';

  function TransactionsResource($q, Restangular) {

		var getTransactions = function(options) {
      console.log('request', options);
			return Restangular.all('admin').all('transactions').get('', options);
		};

    return {
      getTransactions: getTransactions
    };
  }

  window.app.service('TransactionsResource', ['$q', 'Restangular', TransactionsResource]);
})();
