import templateUrl from './badge-project-referoo-agency.html'
import './badge-project-referoo-agency.scss'

const BadgeProjectReferooAgencyComponent = {
  bindings: {
    project: '<',
    shortcodeType: '<',
    text: '<',
    classNames: '<',
  },
  // transclude: true,
  templateUrl,
  controller: class BadgeProjectReferooAgency {
    constructor () {
      'ngInject'
      this._identify = 'BadgeProjectReferooAgencyComponent'
    }

    get hasAgent () {
      return this.project && this.project.referooPartner &&
        this.project.referooPartner.partnerType === 'agency' &&
        this.project.referooPartner.shortcodeType === this.shortcodeType
    }
  }

}

export default BadgeProjectReferooAgencyComponent
