(function() {
  'use strict';

  function debugVar() {
    return {
      scope: {
        var: '='
      },
      replace: true,
      restrict: 'E',
      templateUrl: '/app/scripts/directives/debug-var.html',
      link: function(scope, elem, attrs) {

      }
    };
  }

  app.directive('debugVar', debugVar);
})();
