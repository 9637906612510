const SettingsUnlayerTemplatesService = class SettingsUnlayerTemplatesService {
  constructor ($http, Configuration, AuthService, $timeout, $q) {
    'ngInject'
    this._identify = 'SettingsUnlayerTemplatesService'

    this.$http = $http
    this.Configuration = Configuration
    this.AuthService = AuthService

    this.$timeout = $timeout
    this.$q = $q
  }

  getTemplates () {
    // const deferred = this.$q.defer()
    // this.$timeout(() => {
    //   deferred.resolve([
    //     {
    //       id: 1,
    //       name: 'My dog stepped on a bee 1',
    //       unlayerLastUpdatedAt: new Date(),
    //       mandrillLastUpdatedAt: new Date(new Date().getTime() - 11 * 60000),
    //       actions: {
    //         updateMandrillEmailTemplate: {
    //           method: 'PUT',
    //           url: '/admin/mandrill_email_templates/1'
    //         }
    //       }
    //     },
    //     {
    //       id: 2,
    //       name: 'My dog stepped on a bee 2',
    //       unlayerLastUpdatedAt: new Date(),
    //       mandrillLastUpdatedAt: new Date(new Date().getTime() - 11 * 60000),
    //       actions: {
    //         updateMandrillEmailTemplate: {
    //           method: 'PUT',
    //           url: '/admin/mandrill_email_templates/2'
    //         }
    //       }
    //     },
    //     {
    //       id: 3,
    //       name: 'My dog stepped on a bee 3',
    //       unlayerLastUpdatedAt: new Date(),
    //       mandrillLastUpdatedAt: new Date(new Date().getTime() - 11 * 60000),
    //       actions: {
    //         updateMandrillEmailTemplate: {
    //           method: 'PUT',
    //           url: '/admin/mandrill_email_templates/3'
    //         }
    //       }
    //     },
    //     {
    //       id: 4,
    //       name: 'My dog stepped on a bee 4',
    //       unlayerLastUpdatedAt: new Date(),
    //       mandrillLastUpdatedAt: new Date(new Date().getTime() - 11 * 60000),
    //       actions: {
    //         updateMandrillEmailTemplate: {
    //           method: 'PUT',
    //           url: '/admin/mandrill_email_templates/4'
    //         }
    //       }
    //     }
    //   ])

    //   // deferred.reject({
    //   //   message: 'Something went wrong loading data from Mandrill'
    //   // })
    // }, 1000)
    // return deferred.promise

    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/unlayer_templates`)
      .then(response => response.data)
  }

  async syncTemplate (template) {
    // const deferred = this.$q.defer()
    // this.$timeout(() => {
    //   deferred.resolve({
    //     unlayerLastUpdatedAt: new Date(),
    //     mandrillLastUpdatedAt: new Date(),
    //   })
    //   // deferred.reject({
    //   //   message: 'Something went wrong with Mandrill'
    //   // })
    // }, 1000)
    // return deferred.promise


    return this.$http({
      url: `${this.Configuration.apiUrl}${template.actions.updateMandrillTemplate.url}`,
      method: template.actions.updateMandrillTemplate.method
    })
      .then(response => response.data)
  }
}
export default SettingsUnlayerTemplatesService
