(function() {
  'use strict';

  function RefundsSubTasksCtrl($scope, $modalInstance, Restangular, task, subTask) {

    $scope.task = task;
    $scope.subTask = subTask;

    $scope.close = function() {
      $modalInstance.close();
    };

    $scope.finished = false;
    $scope.transactions = '';

    $scope.postRefund = function(refund) {
      $scope.processing = true;

      Restangular.one('admin').one('tasks', $scope.task.id).one('sub_tasks', $scope.subTask.id).all('refunds').post(refund).then(function(transaction) {
        $scope.finished = true;
        $scope.transaction = transaction;
        $scope.subTask.state = 'refunded';
      }).catch(function (errors) {
        $scope.serverErrors = errors;
      }).finally(function() {
        $scope.processing = false;
      });
    };

    $scope.onPercentageChange = function (refund) {
      if (refund.percentage === '1.0') {
        refund.keep_fee = true
      } else {
        refund.keep_fee = false
      }
    }
  }

  window.app.controller('RefundsSubTasksCtrl', ['$scope', '$modalInstance', 'Restangular', 'task', 'subTask', RefundsSubTasksCtrl]);

})();
