(function () {
  'use strict';

  function radio() {
    var link = function ($scope, element, attrs) {
      $scope.right = 'rightIcon' in attrs;

      if ('required' in attrs) {
        $scope.isRequired = true;
      }
    };

    return {
      restrict: 'E',
      scope: {
        name: '@',
        model: '=',
        disable: '=',
        value: '@',
        label: '@',
        sublabel: '@',
        for: '@'
      },
      replace: true,
      templateUrl: '/app/scripts/radio/radio.html',
      link: link
    };
  }

  app.directive('radio', [radio]);
})();
