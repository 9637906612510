import ProjectsListComponent from './projects-list.component'

const ProjectsListModule = angular.module('cdbl.projects.projects-list', [

])
  .component('cdblProjectsList', ProjectsListComponent)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'projects.projectsList',
        url: '?page&state&preferred&withExpertPods&trackingName&tag&csProjectQuality&csCancelTask&convertedToPaid&pro', // without slug - makes it a default page after abstract parent
        params: {
          page: { squash: true, dynamic: true },
          state: { squash: true, dynamic: true },
          preferred: { squash: true, dynamic: true },
          withExpertPods: { squash: true, dynamic: true },
          trackingName: { squash: true, dynamic: true },
          tag: { squash: true, dynamic: true },
          csProjectQuality: { squash: true, dynamic: true },
          csCancelTask: { squash: true, dynamic: true },
          convertedToPaid: { squash: true, dynamic: true },
          pro: { squash: true, dynamic: true }
        },
        views: {
          '@projects': 'cdblProjectsList'
        },
        data: {
          breadcrumb: 'Projects',
        },
        resolve: {
          permissionResolver: (Configuration, AuthService) => {
            'ngInject'
            return AuthService.isAuthenticated
          }
        }
      })
  })
  .name

export default ProjectsListModule
