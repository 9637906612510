import ListingComponent from './listing.component'

import ListingItemModule from './listing-item'

const ListingModule = angular.module('cdbl.listing', [
  ListingItemModule
])
  .component('cdblListing', ListingComponent)
  .name

export default ListingModule
