import templateUrl from './referral-attributions-past-projects.html'
import './referral-attributions.scss'

const ReferralAttributionsPastProjectsComponent = {
  templateUrl,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: class ReferralAttributionsPastProjects {
    constructor ($mdDialog, ReferralAttributionsService) {
      'ngInject'
      this.$mdDialog = $mdDialog
      this.ReferralAttributionsService = ReferralAttributionsService
      this.projects = null
      this.error = null
      this.isLoading = false
      this.selectedProjects = []
    }

    $onInit () {
      this.fetchReferralProjects()
    }

    async fetchReferralProjects () {
      try {
        this.isLoading = true
        const response = await this.ReferralAttributionsService.getReferralProjects(this.client.id)
        this.projects = response.data
        console.log(this.projects)
        this.error = null
      } catch (error) {
        this.projects = null
        this.error = error.data
      } finally {
        this.isLoading = false
      }
    }

    async assignPastProjects () {
      try {
        this.isLoading = true
        await this.ReferralAttributionsService.attributeToPastProjects(this.client.id, this.selectedProjects)
        this.apply()
      } catch (error) {
        this.error = error.data
      } finally {
        this.isLoading = false
      }
    }

    updateSelectedProjects (project) {
      if (project.selected && this.selectedProjects.indexOf(project.id) === -1) {
        this.selectedProjects.push(project.id)
      } else if (!project.selected) {
        var index = this.selectedProjects.indexOf(project.id)
        if (index !== -1) {
          this.selectedProjects.splice(index, 1)
        }
      }
    };

    toggleAllProjects (selectAll) {
      this.projects.forEach(project => {
        if (project.attributable) {
          project.selected = selectAll
          this.updateSelectedProjects(project)
        }
      })
    };

    cancel () {
      const { client } = this
      this.$mdDialog.cancel({ client, attributed: false })
    }

    apply () {
      const { client, partnerInfo: partner } = this
      this.$mdDialog.cancel({ client, partner, attributed: true })
    }
  }
}

export default ReferralAttributionsPastProjectsComponent
