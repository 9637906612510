import TokenHelpComponent from './token-help/token-help.component'
import TokenPreviewComponent from './token-preview/token-preview.component'
import TokenService from './token.service'
import TokenValidatorDirective from './token-validator.directive'

const TokenModule = angular.module('cdbl.token', [])
  .component('cdblTokenHelp', TokenHelpComponent)
  .component('cdblTokenPreview', TokenPreviewComponent)
  .service('TokenService', TokenService)
  .directive('cdblTokenValidator', TokenValidatorDirective)
  .name

export default TokenModule
