(function() {
  'use strict';
  /*globals $*/
  function dateRangePicker() {

    var link = function() {
      //var nowTemp = new Date();
      //var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

      var checkin = $('#range-from').fdatepicker().on('changeDate', function (ev) {

          if (ev.date.valueOf() > checkout.date.valueOf()) {
              var newDate = new Date(ev.date);
              newDate.setDate(newDate.getDate() + 1);
              checkout.setValue(newDate);
          }

          checkin.hide();
          $('#range-to')[0].focus();

      }).data('datepicker');

      var checkout = $('#range-to').fdatepicker({

          onRender: function (date) {
              return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
          }

      }).on('changeDate', function () {

          checkout.hide();

      }).data('datepicker');
    };

    return {
      restrict: 'E',
      templateUrl: '/app/scripts/transactions/date.range.picker.html',
      link: link
    };
  }

  app.directive('dateRangePicker', dateRangePicker);
})();
