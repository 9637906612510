import templateUrl from './growth-targets.html'
import './growth-targets.scss'

import GrowthTargetsDialog from './growth-targets-dialog/growth-targets.dialog.js'

const GrowthTargetsComponent = {
  bindings: {},
  templateUrl,
  controller: class TemplatesComment {
    constructor (
      $mdDialog,
      $mdToast,
      $state,
      $stateParams,
      PaginationService,
      GrowthTargetsService,
      $filter,
      $location,
      $timeout,
      $mdDateLocale
    ) {
      'ngInject'
      this._identify = 'GrowthTargetsComponent'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.$state = $state
      this.$stateParams = $stateParams
      this.PaginationService = PaginationService
      this.GrowthTargetsService = GrowthTargetsService
      this.$filter = $filter
      this.$location = $location
      this.$timeout = $timeout
      this.$mdDateLocale = $mdDateLocale

      this.monthlyTargets = []
    }

    $onInit () {
      this.isLoading = false
      this.pagination = this.PaginationService.init({
        itemsPerPage: 10,
        mode: 'server'
      })

      if (this.$location.search().page) {
        this.pagination.setCurrentPage(this.$location.search().page)
      }

      this.fetchMonthlyTargets()
      this.mdDateLocaleScope = {
        formatDate: (date) => {
          return date ? this.$filter('date')(date, 'yyyy-MM') : ''
        },
        parseDate: (dateString) => {
          var m = this.$filter('date')(dateString, 'yyyy-MM')
          return m ? new Date(dateString) : new Date(NaN)
        },
        isDateComplete: (dateString) => {
          dateString = dateString.trim()
          var re = /^(([a-zA-Z]{3,}|[0-9]{1,4})([ .,]+|[/-]))([a-zA-Z]{3,}|[0-9]{1,4})/
          return re.test(dateString)
        }
      }
    }

    fetchMonthlyTargets () {
      const paginationParams = this.pagination ? {
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage,
      } : {}

      const date = this.$filter('date')(this.date, 'yyyy-MM')

      this.isLoading = true
      this.GrowthTargetsService.getMonthlyTargets(paginationParams, date)
        .then((response) => {
          this.pagination.updateFromHeaders(response)
          this.$location.search({})
          this.$location.search(paginationParams)
        })
        .catch(err => {
          if (err.status === 404) {
            this.GrowthTargetsService.monthlyTargetsList = []
          }
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    onDateChanged () {
      this.fetchMonthlyTargets()
    }

    onPageChangeHandler () {
      this.fetchMonthlyTargets()
    }

    openEditModal (item) {
      this.$mdDialog
        .show({
          ...GrowthTargetsDialog,
          locals: {
            item: {
              ...item,
              ...{ mdDateLocaleScope: this.mdDateLocaleScope }
            }
          }
        })
        .then(updatedItem => {
          console.log('updatedItem', updatedItem)
          const index = this.monthlyTargets.findIndex(row => row.id === updatedItem.id)

          if (index >= 0) {
            this.monthlyTargets[index] = updatedItem
            this.$mdToast.showSimple('Growth target updated')
          } else {
            this.monthlyTargets.unshift(updatedItem)
            this.$mdToast.showSimple(`Growth target ${item.id ? 'updated' : 'created'}`)
          }
        })
    }
  }
}

export default GrowthTargetsComponent
