import ProjectPreviewDialog from './dialog-project-preview'
import projectsConfig from './projects.config.js'

const CONSULTATION = 'consultation'

// TODO merge here all project.service (temporary helper service) functions and replace it with projects.service

const ProjectsService = class ProjectsService {
  constructor ($http, $mdDialog, Configuration) {
    'ngInject'
    this._identify = 'ProjectsService'
    this.$http = $http
    this.$mdDialog = $mdDialog
    this.Configuration = Configuration
    this.projectsConfig = projectsConfig
  }

  // ------------------------------------------------------
  // Helper functions

  hasPreferredExperts (project) {
    return project && project.preferredContractors && project.preferredContractors.length > 0
  }

  hasExpertPods (project) {
    return project && project.expertPodsProjects && project.expertPodsProjects.length > 0
  }

  isPublic (project) {
    return !this.hasPreferredExperts(project) && !this.hasExpertPods(project)
  }

  isConsultation (project) {
    // check if project is consultation by checking new Type attribute nad fallback to kind parameter for old projects which do not have Type set
    // TODO: decide what to do with kind - retire it or not
    return project && (project.projectType === CONSULTATION || project.kind === CONSULTATION)
  }

  isActionAllowed (actionName, project = null) {
    if (actionName && project && project.state) {
      const stateConfig = this.projectStateConfig(project)
      if (stateConfig) {
        return stateConfig.actions.includes(actionName)
      }
    }
    return false
  }

  projectStateConfig (stateOrProject) {
    const stateId = typeof stateOrProject === 'string' ? stateOrProject : stateOrProject.state

    return this.projectsConfig.states.find(state => state.id === stateId)
  }


  updateProject (oldProject, newProject) {
    Object.assign(oldProject, newProject)
  }


  // Preview dialog
  openPreviewDialog (projectListing, projectList = null) {
    this.$mdDialog
      .show({
        ...ProjectPreviewDialog,
        locals: {
          projectListing: projectListing,
          projectList: projectList
        }
      })
      .then(dialogResponse => {
        console.log('dialogResponse', dialogResponse)
      }, dialogError => {
        console.log('dialogError', dialogError)
      })
  }

  // ------------------------------------------------------
  // API

  getProjectsList (params = {}) {
    const config = {
      params
    }

    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/tasks`, config)
      // .then(response => response.data)
  }

  loadProject (projectId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/tasks/${projectId}`)
      .then(response => response.data)
  }

  projectConvertType (projectId) {
    const payload = {}
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/cs/tasks/${projectId}/type_conversion`, payload)
      .then(response => response.data)
  }

  projectPreferredPublishForAll (projectId) {
    const payload = {}
    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/publish_preferred_task`, payload)
      .then(response => response.data)
  }

  unhireExpertFromProject (projectId) {
    const payload = {}
    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/remove_contractor`, payload)
      .then(response => response.data)
  }

  hireExpertFromProject (projectId, expertId) {
    const payload = {
      user_id: expertId
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/invitations`, payload)
      .then(response => response.data)
  }

  fetchProjectEstimates (projectId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/estimates`)
      .then(response => response.data)
  }

  updateProjectPricing (projectId, pricesData) {
    const payload = {
      price: pricesData.basePrice,
      feePercentage: pricesData.clientFeePercentage / 100,
      contractorFeePercentage: pricesData.expertFeePercentage / 100
    }

    return this.$http
      .patch(`${this.Configuration.apiUrl}/admin/tasks/${projectId}`, payload)
      .then(response => response.data)
  }

  updateProjectInfo (projectId, formData) {
    const payload = {
      title: formData.title,
      description: formData.description
    }

    return this.$http
      .patch(`${this.Configuration.apiUrl}/admin/tasks/${projectId}`, payload)
      .then(response => response.data)
  }

  updateProjectBudget (projectId, formData) {
    const payload = {
      budget: formData.budget,
      budgetRange: formData.predictedBudget
    }

    return this.$http
      .patch(`${this.Configuration.apiUrl}/admin/tasks/${projectId}`, payload)
      .then(response => response.data)
  }

  updateProjectComplexityUrgency (projectId, formData) {
    const payload = {}

    if (typeof formData.complexity !== 'undefined') {
      payload.complexity = formData.complexity
    }

    if (typeof formData.urgency !== 'undefined') {
      payload.urgency = formData.urgency
    }


    return this.$http
      .patch(`${this.Configuration.apiUrl}/admin/tasks/${projectId}`, payload)
      .then(response => response.data)
  }


  cancelProject (projectId) {
    const payload = {}

    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/cancel`, payload)
      .then(response => response.data)
  }

  abandonProject (projectId) {
    const payload = {}

    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/abandon`, payload)
      .then(response => response.data)
  }

  revertProject (projectId) {
    const payload = {}

    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/revert_to_previous_state`, payload)
      .then(response => response.data)
  }

  completeProject (projectId) {
    const payload = {}

    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/complete`, payload)
      .then(response => response.data)
  }

  watchProject (projectId) {
    const payload = {
      item_id: projectId,
      item_type: 'Task' // TODO: ideally project object should have a property on itself that classifies what type of entity it is (communicate with BE to add this in)
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/watchlist`, payload)
      .then(response => response.data)
  }

  unwatchProject (projectId) {
    const config = {
      params: {
        item_id: projectId,
        item_type: 'Task' // TODO: ideally project object should have a property on itself that classifies what type of entity it is (communicate with BE to add this in)
      }
    }

    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/watchlist`, config)
      .then(response => response.data)
  }

  markPaidOfflineProject (projectId, formData) {
    const payload = {
      payment: {
        ...formData
      }
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/payments`, payload)
      .then(response => response.data)
  }

  withholdProject (projectId) {
    const payload = {
      item_id: projectId
    }
    console.log('withholdProject request', payload)

    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/projects/withheld`, payload)
      .then(response => response.data)
  }

  releaseProject (projectId) {
    const params = {
      params: {
        item_id: projectId
      }
    }
    console.log('releaseProject request', params)

    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/projects/withheld`, params)
      .then(response => response.data)
  }
}

export default ProjectsService
