import templateUrl from './issue-view.html'
import './issue-view.scss'

import ContactExpertDialog from './dialog-contact-expert'
import PostCommentDialog from './dialog-post-comment'

const IssueViewComponent = {
  bindings: {},
  templateUrl,
  controller: class IssueView {
    constructor ($stateParams, $state, $timeout, $mdDialog, $mdToast, Configuration, IssuesService, IssueViewService, IssueResourceUnresponsiveService, UserService, StorageService, EventBus, mdcDateTimeDialog) {
      'ngInject'
      this._identify = 'IssueViewComponent'
      this.$stateParams = $stateParams
      this.$state = $state
      this.$timeout = $timeout
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.StorageService = StorageService
      this.EventBus = EventBus
      this.mdcDateTimeDialog = mdcDateTimeDialog

      this.Configuration = Configuration
      this.IssuesService = IssuesService
      this.IssueViewService = IssueViewService
      this.IssueResourceUnresponsiveService = IssueResourceUnresponsiveService
      this.UserService = UserService
      this.isProcessing = false

      this.formData = null
    }

    $onInit () {
      console.log('[IssueView] > $onInit', this.$stateParams.issueId)
      this.issueId = this.$stateParams.issueId
      this.fieldsConfig = this.IssuesService.fieldsConfig

      // const vm = this
      this.IssuesService.getIssue(this.issueId).then(response => {
        // TODO: remove on deployment - mocking BE
        // response.resources.clientStats = {}
        // ---

        this.setFormData()
        this.IssueViewService.subscribeToIssue(this.issueId, {
          // global handlers for pusher issue events
          update: () => {
            console.log('[IssueView] > onIssueUpdatedHandler')
            this.setFormData() // TODO: set form data only for fields that are not dirty by user
          }
        })

        let forceRedirect = false
        if (this.$stateParams.redirect) {
          forceRedirect = true
          delete this.$stateParams.redirect
        }

        this.IssuesService.redirectIfIssueMoved(forceRedirect)
        this.IssuesService.redirectIfIssueIsChild()
      }, error => {
        if (error) {
          console.log(error.stack)
        }
      })
    }

    $onDestroy () {
      console.log('unsubscribe from issue channel')
      this.IssueViewService.unsubscribeFromIssue(this.issueId)
      this.IssuesService.clearIssue()
    }


    get issue () {
      return this.IssuesService.issue
    }

    setFormData () {
      this.formData = {
        status: this.issue.status || null,
        assignee: this.issue.assignedTo ? this.issue.assignedTo.id : null,
        snoozed: this.issue.snoozed ? 'on' : 'off'
      }
    }

    // onFieldChange (fieldName) {
    //   console.log('[IssueView] > onFieldChange', fieldName)
    //   // TODO: make update request
    //   // TODO: Update local data from request response
    //   // TODO: handle errors
    // }
    userAvatar (userId, fallbackImage) {
      return this.IssuesService.inboxUserAvatar(userId, fallbackImage)
    }

    // For issue history where there is only email
    userName (userId) {
      return this.IssuesService.inboxUserName(userId)
    }

    actionResolve () {
      this.formData.status = 'resolved'
      this.onStatusChange()
    }

    onStatusChange () {
      this.isProcessing = true
      console.log('[IssueView] > onStatusChange')
      this.IssuesService.executeAction(this.issue.actions.status, {
        'issue': {
          'status': this.formData.status
        }
      }, true).finally(() => {
        this.setFormData()
        this.isProcessing = false
        this.IssuesService.reloadIssueView()
      })
    }

    onAssigneeChange () {
      this.isProcessing = true
      console.log('[IssueView] > onAssigneeChange')
      this.IssuesService.executeAction(this.issue.actions.assignee, {
        'issue': {
          'assigneeId': this.formData.assignee
        }
      }, true).finally(() => {
        this.setFormData()
        this.isProcessing = false
      })
    }

    assignToMe () {
      this.formData.assignee = this.UserService.userId
      this.onAssigneeChange()
    }

    startSnooze (untilDate) {
      this.isProcessing = true
      console.log('[IssueView] > snoozeIssue', untilDate)
      this.IssuesService.executeAction(this.issue.actions.snooze, {
        'issue': {
          'snooze': untilDate
        }
      }, true).finally(() => {
        this.setFormData()
        this.isProcessing = false
      })
    }

    cancelSnooze () {
      this.startSnooze(0)
    }

    openContactExpertDialog () {
      this.$mdDialog
        .show({
          ...ContactExpertDialog,
          locals: {
            actionConfig: this.issue.actions.contactExpert
          }
        })
        .then(dialogResponse => {
          console.log('dialogResponse', dialogResponse)
        }, dialogError => {
          console.log('dialogError', dialogError)
        })
    }

    openPostCommentDialog () {
      this.$mdDialog
        .show({
          ...PostCommentDialog,
          locals: {
            actionConfig: this.issue.actions.postComment
          }
        })
        .then(dialogResponse => {
          console.log('dialogResponse', dialogResponse)
        }, dialogError => {
          console.log('dialogError', dialogError)
        })
    }

    displayCustomSnooze () {
      this.mdcDateTimeDialog.show({
        minDate: new Date(),
        time: true,
        shortTime: true,
      }).then(date => {
        this.startSnooze(date.toISOString())
      }, () => {
        console.log('Selection canceled')
      })
    }

    // Condition checking for status for current issue in view
    get statusCustomValidator () {
      const customValidator = {
        valid: null,
        invalid: null,
        errors: {}
      }

      // Default invalid state
      let invalid = !this.IssuesService.currentUserHasInbox || this.issue.actions.status.disabled || this.isProcessing

      // Per issue type specific invalid state conditions
      switch (this.issue.type) {
      case 'unresponsive_expert':
        if (this.IssueResourceUnresponsiveService.occurrencesList) {
          this.IssueResourceUnresponsiveService.occurrencesList.forEach(occurrenceIssue => {
            // override disabled if not all occurrences have root cause selected
            if (!occurrenceIssue.rootCause) {
              invalid = true
              customValidator.errors.rootCause = 'Set root cause for all occurrences.' // validator message
            }
          })
          // console.log('[IssueViewComponent] > statusCustomValidator', this.issue.type, invalid)
        } else {
          invalid = true // make it invalid until occurrences loads
        }
        break
      // case 'project_quality_assessement':
      //   invalid = true // make it invalid indefinetly - will disable field
      //   break
      }


      customValidator.valid = !invalid
      customValidator.invalid = invalid
      return customValidator
    }
  }
}

export default IssueViewComponent
