import md5 from 'crypto-js/md5'

(function() {
  'use strict';

  function ActivitiesCtrl($scope, $stateParams, RestangularizeElements, TasksResource) {
    var taskResource = TasksResource.getTask($stateParams.id, ['activities'], true);

    var tempActivity;

    $scope.activities = [];
    $scope.loading = true;

    const allowedVerbs = [
      'complete_sub_task',
      'complete_task',
      'create_attachment',
      'create_comment',
      'create_endorsment',
      'create_estimate',
      'create_sub_task',
      'destroy_attachment',
      'payment_successful',
      'sub_task_payment_successful'
    ];

    $scope.addActivity = activity => {
      console.log('[ActivitiesCtrl] > addActivity', activity)
      $scope.activities.unshift(processActivity(activity))
    }

    const processActivity = rawActivity => {
      let activity
      if (!rawActivity.trackableType) {
        // impose new activity structure
        const verb = rawActivity.verb || null
        delete rawActivity.verb // remove verb from actual trackable object
        activity = {
          // TODO: use exising BE id or create temporary unique id if activity is added to the list by FE from other features
          id: md5(`${rawActivity.id}${verb}${rawActivity.createdAt}${new Date().getTime()}`).toString(), // simulate id creation
          verb: verb,
          trackableType: verb.includes('attachment') ? 'Attachment' : verb.includes('comment') ? 'Comment' : verb.includes('estimate') ? 'Estimate' : 'undefined',
          trackable: {
            ...rawActivity
          }
        }
      } else {
        // Handle activities that already support trackable returned by BE
        activity = rawActivity
      }

      return activity
    }

    const filterValidAndProcess = activityList => {
      const reducer = (filteredList, activity, index) => {
        const proccessedActivity = processActivity(activity)
        const previousActivity = filteredList[filteredList.length - 1]
        const isValid = allowedVerbs.includes(proccessedActivity.verb) &&
                        !removeSequentialAttachmentActivites(proccessedActivity, previousActivity) &&
                        !removeSequentialEstimateActivites(proccessedActivity, previousActivity)
        if (isValid) {
          filteredList.push(proccessedActivity)
        }

        return filteredList
      }

      const result = activityList.reduce(reducer, [])
      console.log('[ActivitiesCtrl] > filterValidAndProcess', result)
      return result
    }

    const removeSequentialAttachmentActivites = (activity, previousActivity) => {
      // If Check for previous actvity and combine or remove duplicates
      if (activity.verb === 'create_attachment' &&
          typeof previousActivity !== 'undefined' &&
          previousActivity !== null) {
        // Check previous activity and combine sequential create_attachment activites (file uploads) into one activity with multiple files as attachments
        if (previousActivity.verb === activity.verb &&
            previousActivity.trackable.user.id === activity.trackable.user.id) {
          if (!previousActivity.trackable.attachments) {
            previousActivity.trackable.attachments = []
            previousActivity.trackable.attachments.push({
              ...previousActivity.trackable
            })
          }
          if (!previousActivity.trackable.attachments.find(attachment => attachment.id === activity.trackable.id)) {
            previousActivity.trackable.attachments.push({
              ...activity.trackable
            })
          }
          // remove current activity from list as we insert attachment into previous activity
          return true
        }
      }
      return false
    }

    const removeSequentialEstimateActivites = (activity, previousActivity) => {
      // If Check for previous actvity and combine or remove duplicates
      if (activity.verb === 'create_estimate' &&
          typeof previousActivity !== 'undefined' &&
          previousActivity !== null) {
        // Check previous activity and remove create_estimate duplicates
        if (previousActivity.verb === activity.verb &&
            previousActivity.trackable.estimator.id === activity.trackable.estimator.id) {
          // remove current activity from list as its a duplicate of previous activity
          return true
        }
      }
      return false
    }

    taskResource.activities().then(activityList => {
      $scope.activities = filterValidAndProcess(activityList)
      $scope.loading = false;
    });
  }

  app.controller('ActivitiesCtrl', ['$scope', '$stateParams', 'RestangularizeElements', 'TasksResource', ActivitiesCtrl]);
})();
