import UserService from './services/user.service'
import PermissionsService from './services/permissions.service'
import NavigationService from './services/navigation.service'

import PusherService from './services/pusher.service'
import LoadingService from './services/loading.service'
import StorageService from './services/storage.service'
import SoundService from './services/sound.service'
import ResourceService from './services/resource.service'
import EventBus from './services/event-bus.service'
import ToastService from './services/toast.service'

import DateFormatFilter from './filters/date-format.filter'
import PriceFormatFilter from './filters/price-format.filter'
import Newline2brFilter from './filters/newline2br.filter'
import Marked2htmlFilter from './filters/marked2html.filter'
import Links2tabFilter from './filters/links2tab.filter'
import UserRoleFormatFilter from './filters/user-role-format.filter'
import ProjectUrgencyFormatFilter from './filters/project-urgency-format.filter'
import ProjectComplexityFormatFilter from './filters/project-complexity-format.filter'
import UrlProtocolPrefixerFilter from './filters/url-protocol-prefixer.filter'
import BudgetRangeFormatFilter from './filters/budget-range-format.filter'
import TextTruncateFilter from './filters/text-truncate.filter'




import EmailTokensValidatorDirective from './directives/email-tokens.validator.directive'

import OnCtrlEnterTriggerDirective from './directives/on-ctrl-enter-trigger.directive'
import OnEnterTriggerDirective from './directives/on-enter-trigger.directive'
import OnErrorSrcDirective from './directives/on-error-src.directive'
import NgFormDirective from './directives/ngform.directive.js'
import CompareToDirective from './directives/compare-to.directive.js'
import OnFileSelectDirective from './directives/on-file-select.directive.js'

import HalIconModule from './components/hal-icon'
import PaginationModule from './components/pagination'
import TokenModule from './components/token'

import NoticebarModule from './components/noticebar'
import LoaderModule from './components/loader'
import DropdownModule from './components/dropdown'
import WidgetModule from './components/widget'
import ButtonModule from './components/button'
import BadgeModule from './components/badge'
import ListSelectorModule from './components/list-selector'
import ListingModule from './components/listing'
import PageModule from './components/page'

import FileSelectModule from './components/file-select'


const CommonModule = angular.module('app.common', [
  HalIconModule,
  PaginationModule,
  TokenModule,
  LoaderModule,
  NoticebarModule,
  DropdownModule,
  WidgetModule,
  ButtonModule,
  BadgeModule,
  ListSelectorModule,
  ListingModule,
  PageModule,
  FileSelectModule
])
  .service('UserService', UserService)
  .service('PermissionsService', PermissionsService)
  .service('NavigationService', NavigationService)
  .service('PusherService', PusherService)
  .service('LoadingService', LoadingService)
  .service('StorageService', StorageService)
  .service('SoundService', SoundService)
  .service('ResourceService', ResourceService)
  .service('EventBus', EventBus)
  .service('ToastService', ToastService)

  .filter('dateFormat', DateFormatFilter)
  .filter('priceFormat', PriceFormatFilter)
  .filter('newline2br', Newline2brFilter)
  .filter('marked2html', Marked2htmlFilter)
  .filter('links2tab', Links2tabFilter)
  .filter('userRoleFormat', UserRoleFormatFilter)
  .filter('projectUrgencyFormat', ProjectUrgencyFormatFilter)
  .filter('projectComplexityFormat', ProjectComplexityFormatFilter)
  .filter('urlProtocolPrefixer', UrlProtocolPrefixerFilter)
  .filter('budgetRangeFormat', BudgetRangeFormatFilter)
  .filter('textTruncate', TextTruncateFilter)



  .directive('emailtokens', EmailTokensValidatorDirective)
  .directive('onCtrlEnterTrigger', OnCtrlEnterTriggerDirective)
  .directive('onEnterTrigger', OnEnterTriggerDirective)
  .directive('onErrorSrc', OnErrorSrcDirective)
  .directive('ngForm', NgFormDirective)
  .directive('form', NgFormDirective)
  .directive('compareTo', CompareToDirective)
  .directive('onFileSelect', OnFileSelectDirective)



  .value('EventEmitter', payload => ({ $event: payload })) // helper event wrapper for '&' bindings
  .name

export default CommonModule
