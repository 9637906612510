const StorageService = class StorageService {
  constructor (Configuration, localStorageService) {
    'ngInject'
    this._identify = 'StorageService'
    this.Configuration = Configuration
    this.Storage = localStorageService
    // .set(key, val)
    // .get(key)
    // .remove(key)

    this.set = localStorageService.set
    this.get = localStorageService.get
    this.remove = localStorageService.remove
  }
}
export default StorageService
