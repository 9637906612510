(function() {
  'use strict';

  function fullHeight($timeout, $window, $transitions) {
    function link($scope, element) {
      angular.element($window).bind('resize', function() {
        resize();
      });

      $transitions.onStart({ }, function(trans) {
        resize()
        trans.promise.finally(resize);
      });

      function resize() {
        var windowHeight = $window.innerHeight;
        var elementOffsetTop = element.offset().top;
        var elementHeight = (windowHeight - elementOffsetTop);

        if (angular.element('.main-section').height() > elementHeight) {
          elementHeight = '100%';
        } else {
          elementHeight = elementHeight + 'px';
        }

        element.css({ height: elementHeight });
      }

      resize();
    }

    return {
      restrict: 'A',
      link: link
    };
  }

  window.app.directive('fullHeight', ['$timeout', '$window', fullHeight]);
})();
