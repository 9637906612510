import templateUrl from './elements.html'
import './elements.scss'

const ElementsComponent = {
  bindings: {},
  templateUrl,
  controller: class Elements {
    constructor ($state, $mdDialog, TokenService) {
      'ngInject'
      this._identify = 'ElementsComponent'
      this.$state = $state
      this.$mdDialog = $mdDialog

      // --- Token demo
      this.TokenService = TokenService
      this.tokenPreview = null
      this.tokenValues = {
        'first_name': 'Deadpool',
        'cdbl_author_name': 'Jan',
        'cdbl_task_id': 123,
      }
      this.tokenValidator = TokenService.initValidator([
        'first_name',
        'cdbl_author_name',
        'cdbl_task_id'
      ])

      this.formData = {
        content: `Hello {{ first_name | fallback:"there" }}!

Your INVALID task ID is {{ task_id }}.
Your VALID task ID is {{ cdbl_task_id }}.`
      }
      this.modelOptions = {
        updateOn: 'default blur',
        debounce: {
          'default': 500,
          'blur': 0,
          '*': 500
        }
      }

      this.replaceTokens = () => {
        try {
          this.tokenPreview = this.TokenService.replace(this.formData.content, this.tokenValidator, this.tokenValues)
        } catch (err) {
          console.log(err.message)
        }
      }
      // ----

      this.title1 = 'Button'
      this.title4 = 'Warn'
      this.googleUrl = 'http://google.com'


      this.checkboxes = [true, true, true, true]
      this.startDate = new Date()

      this.project = {
        description: 'Nuclear Missile Defense System',
        rate: 500,
        tos: false,
        special: true,
        clientEmail: ''
      }
    }


    showConfirm (event) {
      // Appending dialog to document.body to cover sidenav in docs app
      const confirm = this.$mdDialog.confirm()
        .title('Would you like to delete your debt?')
        .textContent('All of the banks have agreed to forgive you your debts.')
        .ariaLabel('Lucky day')
        .targetEvent(event)
        .ok('Please do it!')
        .cancel('Sounds like a scam')

      this.$mdDialog.show(confirm).then(() => {
        this.confirmDialogStatus = 'You decided to get rid of your debt.'
      }, () => {
        this.confirmDialogStatus = 'You decided to keep your debt.'
      })
    }
  }
}

export default ElementsComponent
