(function() {
  'use strict';

  function markPaidSubTask(SubTasksResource) {
    var link = function($scope) {
      $scope.task.markPaidOption = 'wire';

      $scope.markPaid = function(subTask) {
        SubTasksResource.markPaid(subTask.id, subTask.markPaidOption).then(function(data) {
          subTask.state = 'paid';
        });
      };
    };

    return {
      restrict: 'E',
      link: link,
      scope: {
        task: '=',
        subTask: '='
      },
      templateUrl: '/app/scripts/tasks/actions/mark_paid_sub_task.html'
    };
  }

  app.directive('markPaidSubTask', ['SubTasksResource', markPaidSubTask]);
})();