(function() {
  'use strict';

  function morethan() {
    return function(items, count, number) {
      var cnt = parseInt(count, 10);
      var output = [];
      if (Array.isArray(items) && !isNaN(cnt)) {
        output = items.filter(function(item) {
          return parseInt(item.count, 10) >= cnt;
        });
        if (parseInt(number, 10) > 0) {
          return output.slice(0, parseInt(number, 10));
        }
        return output;
      }
      return items;
    };
  }

  app.filter('morethan', morethan);
})();
