(function() {
  'use strict';
  window.app.config(['$stateProvider', function($stateProvider) {
      $stateProvider
      .state('revenuebreakdown', {
        url: '/revenuebreakdown',
        templateUrl: '/app/scripts/revenuebreakdown/revenuebreakdown.html',
        controller: 'revenuebreakdownCtrl'
      });
    }]);
})();
