const Links2tabFilter = function () {
  'ngInject'
  return function (html) {
    var el = document.createElement('div')
    el.innerHTML = html

    el.querySelectorAll('a').forEach(aNode => {
      aNode.setAttribute('target', '_blank')
      aNode.setAttribute('rel', 'noreferrer')
    })

    const processedHtml = el.innerHTML
    return processedHtml
  }
}
export default Links2tabFilter
