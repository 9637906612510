(function() {
  'use strict';

  function UserEmailCtrl($scope, $state, $location, $stateParams, $modal, userResource, UsersResource, SettingsResource) {

    $scope.user = userResource.user;

    $scope.email = {
      subject: '',
      text: ''
    };

    SettingsResource.emailTemplates().then(function(response) {
      $scope.emailTemplates = response;
    });

    $scope.send = function(user, subject, text) {
      $scope.sending = true;
      UsersResource.sendEmail(user, subject, text).then(function(response) {
        console.log(response);
        $scope.sending = undefined;
        $state.go('users.display', {id: $scope.user.id});
      });
    };
    
    $scope.setTemplate = function(email, emailTemplate) {
      email.subject = emailTemplate.subject;
      email.text = emailTemplate.text;
    };
    
  }

  window.app.controller('UserEmailCtrl', ['$scope', '$state', '$location', '$stateParams', '$modal', 'userResource', 'UsersResource', 'SettingsResource', UserEmailCtrl]);
	
})();
