import templateUrl from './contact-expert.dialog.html'

const ContactExpertDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class ContactExpertDialog {
    constructor ($mdDialog, $mdToast, $timeout, Configuration, IssuesService) {
      'ngInject'
      this._identify = 'ContactExpertDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.$timeout = $timeout
      this.Configuration = Configuration
      this.IssuesService = IssuesService

      this.isLoaded = false

      this.isSending = false
      this.contactableList = null
      this.formData = {
        subject: null,
        text: null,
        mode: null,
        contactables: []
      }
    }

    $onInit () {
      console.log('[ContactExpertDialog]', this.actionConfig)
      this.formData.mode = this.actionConfig.values.mode[0] // set default mode to first item

      // if contactables attribute exist fetch list of users
      if (this.actionConfig.values.contactables) {
        this.IssuesService.callEndpoint({ url: this.actionConfig.values.contactables.url })
          .then(response => {
            console.log('[ContactExpertDialog] > fetch contactables > response', response)
            this.contactableList = response

            // go through the list and populate form
            this.contactableList.forEach(contact => {
              if (contact.checked) {
                this.formData.contactables.push(contact.id)
              }
            })

            this.$timeout(() => {
              if (this.formData.contactables.length) {
                this.contactForm.contactableListRequired.$setViewValue(true)
              }
            })
            // ---
          }, error => {
            console.log('[ContactExpertDialog] > fetch contactables > err', error)
          })
          .finally(() => {
            this.isLoaded = true
          })
      } else {
        this.isLoaded = true
      }
    }

    $onDestroy () {
      console.log('[ContactExpertDialog] > onDestroy')
    }

    onChangeContact (contact) {
      let checked = false
      this.contactableList.forEach(c => {
        if (c.checked) {
          checked = true
        }
      })

      if (contact.checked) {
        this.formData.contactables.push(contact.id)
      } else {
        this.formData.contactables = this.formData.contactables.filter(contactId => contactId !== contact.id)
      }

      console.log('[ContactExpertDialog] > onChangeContact', checked)
      this.contactForm.contactableListRequired.$setViewValue(checked)
    }

    sendMessage () {
      this.isSending = true
      console.log('[ContactExpertDialog] > sendMessage', this.formData, this.actionConfig)
      this.IssuesService.executeAction(this.actionConfig, {
        message: this.formData
      })
        .then(response => {
          this.formData.text = ''
          this.contactForm.$setPristine()
          this.$mdToast.show(
            this.$mdToast.simple()
              .textContent('Message sent!')
              .position('top right')
          )
          console.log('[ContactExpertDialog] > sendMessage > response', response)
          this.$mdDialog.hide()
        }, error => {
          console.log('[ContactExpertDialog] > sendMessage > error', error)
          this.$mdToast.show(
            this.$mdToast.simple()
              .textContent('Error occured!')
              .position('top right')
          )
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}

export default ContactExpertDialog
