(function() {
  'use strict';

  function AuthInterceptor($q, $injector, Configuration) {
    var request = function(config) {
      // We need to manually fetch Auth to avoid circular dependency
      var Auth = $injector.get('Auth');
      config.headers.Authorization = 'Bearer ' + Auth.getToken();
      return config || $q.when(config);
    };

    var responseError = function(response) {
      var Auth = $injector.get('Auth');
      var $state = $injector.get('$state');
      var $location = $injector.get('$location');

      if (response.status === 401) {
        Auth.signOut();

        $state.go(Configuration.navigation.loginPage.sref, { redirect: $location.url() });
      }

      return $q.reject(response);
    };

    return {
      request: request,
      responseError: responseError
    };
  }

  window.app.factory('AuthInterceptor', ['$q', '$injector', 'Configuration', AuthInterceptor]);

  window.app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('AuthInterceptor');
    $httpProvider.defaults.headers.common.Accept = 'application/json, application/vnd.codeable.v1';
  }]);
})();
