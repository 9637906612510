(function() {
  'use strict';

  function removeTag(TagsResource) {
    var link = function($scope) {
      $scope.remove = function(tag) {
        TagsResource.deleteTag(tag.id).then(function(data) {
          tag.removed = true;
        });
      };
    };

    return {
      restrict: 'E',
      link: link,
      templateUrl: '/app/scripts/tags/actions/remove_tag.html'
    };
  }

  app.directive('removeTag', ['TagsResource', removeTag]);
})();