import templateUrl from './incident-form.dialog.html'

const IncidentFormDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: true,
  clickOutsideToClose: true,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class IncidentFormDialog {
    constructor ($mdDialog, $window, $mdToast, $timeout, $q, $state, $http, Configuration, IssuesService) {
      'ngInject'
      this._identify = 'IncidentFormDialog'
      this.$mdDialog = $mdDialog
      this.$window = $window
      this.$mdToast = $mdToast
      this.$timeout = $timeout
      this.$q = $q
      this.$state = $state
      this.$http = $http
      this.Configuration = Configuration
      this.IssuesService = IssuesService

      this.formData = {
        id: null,
        occuredAt: null,
        createdAt: null,
        updatedAt: null,
        trigger: null,
        triggerDetails: null,
        source: null,
        sourceDetails: null,
        status: null,
        docs: '',
        projectId: undefined,
        participants: []
      }
      this.modelOptions = {

      }
      this.usersSearchText = ''
      this.selectedUser = null
      this.datasets = {}
      this.incidentHalIssue = null
    }

    async $onInit () {
      console.log('[IncidentFormDialog] > $onInit()', this.incidentContextData)
      this.isLoading = true
      await this.IssuesService.getIncidentReportDatasets().then(response => {
        this.datasets = response
        this.isLoading = false
      })

      if (this.incidentContextData) {
        // set default autogenerated data for creating an incident
        if (!this.incidentContextData.isEdit) {
          this.formData.occuredAt = new Date()
          this.formData.status = 'not_started'

          if (this.incidentContextData.user) {
            this.addParticipant(this.incidentContextData.user)
          }
          if (this.incidentContextData.project) {
            this.formData.projectId = this.incidentContextData.project.id

            if (this.incidentContextData.project.client) {
              this.addParticipant(this.incidentContextData.project.client)
            }
            if (this.incidentContextData.project.contractor) {
              this.addParticipant(this.incidentContextData.project.contractor)
            }
          }
          if (this.incidentContextData.halIssue) {
            const issue = this.incidentContextData.halIssue
            const project = issue && issue.resources && issue.resources.task && issue.resources.task.data ? issue.resources.task.data : null
            const client = issue && issue.resources && issue.resources.client && issue.resources.client.data ? issue.resources.client.data : null
            const expert = issue && issue.resources && issue.resources.expert && issue.resources.expert.data ? issue.resources.expert.data : null
            if (project) {
              this.formData.projectId = project.id
            }
            if (client) {
              this.addParticipant(client)
            }
            if (expert) {
              this.addParticipant(expert)
            }
          }
        } else if (this.incidentContextData.incident) {
          // eslint-disable-next-line no-undef
          this.formData = structuredClone(this.incidentContextData.incident)
          this.formData.occuredAt = new Date(this.formData.occuredAt) // fix for datepicker
        }
      }
    }

    $onDestroy () {
      console.log('[IncidentFormDialog] > $onDestroy')
    }

    queryUserSearch (query) {
      if (query.length <= 2) {
        const deferred = this.$q.defer()
        deferred.resolve([])
        return deferred.promise
      }

      const payload = {
        params: {
          q: query,
          per_page: 100
        }
      }

      return this.$http.get(`${this.Configuration.apiUrl}/admin/search/users`, payload)
        .then(response => response.data)
    }

    get participantsValidation () {
      const isEmpty = this.formData.participants.length === 0
      return {
        $error: { required: isEmpty },
        $invalid: isEmpty
      }
    }
    addSelectedUser () {
      this.addParticipant(this.selectedUser)
      this.selectedUser = null
    }

    addParticipant (user) {
      const exists = this.formData.participants.find(p => p.user.id === user.id)
      if (exists) {
        this.$mdToast
          .show(this.$mdToast
            .simple()
            .textContent('User already in the list of participants')
            .position('top right')
            .hideDelay(3000)
          )
        return
      }

      this.formData.participants.push({
        user: {
          id: user.id,
          fullName: user.fullName,
          role: user.role
        },
        participantRole: null
      })
    }

    removeParticipant (participant) {
      this.formData.participants = this.formData.participants.filter(p => p.user.id !== participant.user.id)
    }

    prepareIncidentPayload (incident) {
      // eslint-disable-next-line no-undef
      const payload = structuredClone(incident)
      payload.participants.forEach(p => {
        p.userId = p.user.id // needed for BE
      })
      // fix for datepicker
      payload.occuredAt = payload.occuredAt.toISOString()
      // Clean up "other" fields
      if (payload.trigger !== 'trigger_other') {
        payload.triggerDetails = null
      }
      if (payload.source !== 'source_other') {
        payload.sourceDetails = null
      }

      return payload
    }

    openInNewTabIncidentHalIssue (issue) {
      const params = this.IssuesService.generateRedirectIssueParams(issue)
      this.$window.open(this.$state.href('issueView', params))
    }

    create () {
      const payload = this.prepareIncidentPayload(this.formData)
      console.log('[IncidentFormDialog] > create', payload)

      this.isProcessing = true
      this.IssuesService.createIncidentReportIssue(payload)
        .then(response => {
          const incidentHalIssue = response
          console.log(this.incidentHalIssue)
          this.$mdToast
            .show(this.$mdToast
              .simple()
              .textContent('An incident ticket has been created.')
              .action('Go to HAL')
              .position('top right')
              .hideDelay(5000)
            )
            .then(response => {
              if (response === 'ok') {
                this.openInNewTabIncidentHalIssue(incidentHalIssue)
              }
            })
        })
        .catch(err => {
          console.log(err)
          this.$mdToast.show(this.$mdToast
            .simple()
            .textContent(`An error occured${err.message ? ': ' + err.message : ''}`)
            .position('top right')
            .hideDelay(3000))
        })
        .finally(() => {
          this.isProcessing = false
          this.close()
        })
    }
    update () {
      const payload = this.prepareIncidentPayload(this.formData)
      console.log('[IncidentFormDialog] > update', payload)

      this.isProcessing = true
      this.IssuesService.updateIncidentReportIssue(payload)
        .then(response => {
          this.$mdToast
            .show(this.$mdToast
              .simple()
              .textContent('An incident ticket has been updated.')
              .position('top right')
              .hideDelay(3000)
            )
          this.close(response)
        })
        .catch(err => {
          console.log(err)
          this.$mdToast.show(this.$mdToast
            .simple()
            .textContent(`An error occured${err.message ? ': ' + err.message : ''}`)
            .position('top right')
            .hideDelay(3000))
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    close (attr = false) {
      this.$mdDialog.hide(attr)
    }
  }
}

export default IncidentFormDialog
