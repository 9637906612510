import ElementsComponent from './elements.component'

const ElementsModule = angular.module('cdbl.elements', [
  'ui.router'
])
  .component('cdblElements', ElementsComponent)
  .config(($uiRouterProvider) => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'elements',
        url: '/elements',
        component: 'cdblElements'
      })
  })
  .name

export default ElementsModule
