import templateUrl from './post-comment.dialog.html'

const PostCommentDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class PostCommentDialog {
    constructor ($mdDialog, $mdToast, Configuration, IssuesService) {
      'ngInject'
      this._identify = 'PostCommentDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.IssuesService = IssuesService

      this.isSending = false

      this.formData = {
        content: null
      }
    }

    $onInit () {
      console.log('[PostCommentDialog]', this.actionConfig)
    }

    $onDestroy () {
      console.log('[PostCommentDialog] > onDestroy')
    }

    postComment () {
      this.isSending = true
      console.log('[PostCommentDialog] > sendMessage', this.formData, this.actionConfig)
      this.IssuesService.executeAction(this.actionConfig, {
        message: this.formData
      })
        .then(response => {
          this.formData.text = ''
          this.contactForm.$setPristine()
          this.$mdToast.show(
            this.$mdToast.simple()
              .textContent('Message sent!')
              .position('top right')
          )
          console.log('[PostCommentDialog] > sendMessage > response', response)
          this.$mdDialog.hide()
        }, error => {
          console.log('[PostCommentDialog] > sendMessage > error', error)
          this.$mdToast.show(
            this.$mdToast.simple()
              .textContent('Error occured!')
              .position('top right')
          )
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}

export default PostCommentDialog
