import jsonInflector from 'json-inflector'
import Pusher from 'pusher-js'

const PusherService = class PusherService {
  constructor (
    $timeout,
    Configuration,
    // UserService,
    EventBus
  ) {
    'ngInject'
    this._identify = 'PusherService'
    this.$timeout = $timeout
    this.Configuration = Configuration
    this.EventBus = EventBus
    // TODO: use UserService when Auth is refactored, add auth hooks
    // this.UserService = UserService

    this.pusher = null
    this.channels = {}
    this.userChannelName = null
    this.CHANNEL_NAMES = this.Configuration.pusherChannels
  }

  // Returns channel name based on shortcode and paramId
  getChannelName (shortname, paramId) {
    const channelNameTemplate = this.CHANNEL_NAMES[shortname]
    let channelName = null

    if (!channelNameTemplate) {
      throw Error('[PusherService] > getChannelName > channel template not existing', shortname, paramId)
    }

    if (channelNameTemplate.includes('[id]')) {
      if (typeof paramId === 'undefined') {
        throw Error('[PusherService] > getChannelName > channel template expects ID param, non given', shortname, paramId)
      } else {
        channelName = channelNameTemplate.replace('[id]', paramId)
      }
    } else {
      channelName = channelNameTemplate
    }

    return channelName
  }


  init (accessToken) {
    console.log('[PusherService] > init')
    if (!this.Configuration.pusher) {
      console.warn('[PusherService] > missing pusher config')
      return
    }

    this.pusher = new Pusher(this.Configuration.pusher.appKey, {
      authEndpoint: `${this.Configuration.apiUrl}${this.Configuration.pusher.authEndpoint}`,
      authTransport: 'ajax',
      // cluster: this.Configuration.pusher.cluster,
      // encrypted: true,
      auth: {
        headers: {
          Authorization: 'Bearer ' + accessToken // this.UserService.accessToken
        }
      }
    })

    this.pusher.connection.bind('error', err => {
      if (err.error.data.code === 4004) {
        console.log('[PusherService][connection] detected limit error')
      } else {
        console.log('[PusherService][connection] error', err.error)
      }
    })
  }

  updateAccessToken (accessToken) {
    if (!this.pusher) {
      console.warn('[PusherService] > updateAccessToken > pusher not initialized yet')
      return
    }
    this.pusher.config.auth.headers.Authorization = 'Bearer ' + accessToken // this.UserService.accessToken
    console.log('[PusherService] > updateAccessToken', this.pusher)
  }


  subscribe (channelName) {
    console.log('[PusherService] > subscribe', channelName)

    // Subscribe to channel if not subscribed yet
    if (!this.channels[channelName]) {
      this.channels[channelName] = this.pusher.subscribe(channelName)
    }

    // Return channel
    return this.channels[channelName]
  }

  unsubscribe (channelName) {
    console.log('[PusherService] > unsubscribe', channelName)
    if (channelName && this.channels[channelName]) {
      this.pusher.unsubscribe(channelName)
      delete this.channels[channelName]
    } else {
      console.warn('[PusherService] > unsubscribe > channel not found')
    }
  }

  bind (channelName, eventName, handler) {
    const channel = this.channels[channelName]
    console.log('[PusherService] > bind', channelName, eventName, Boolean(channel))
    if (channel) {
      channel.bind(eventName, handler)
    } else {
      throw Error('[PusherService] > bind > channel does not exist')
    }
  }

  unbind (channelName, eventName, handler) {
    const channel = this.channels[channelName]
    console.log('[PusherService] > unbind', channelName, eventName, Boolean(channel))
    if (channel) {
      channel.unbind(eventName, handler)
    } else {
      console.warn('[PusherService] > unbind > channel does not exist', channelName)
    }
  }

  bindGlobal (channelName, fn) {
    const channel = this.channels[channelName]
    console.log('[PusherService] > bindGlobal', channelName)
    if (channel) {
      channel.bind_global((eventName, event) => {
        // Wrap in $timeout to trigger change on digest
        this.$timeout(fn(eventName, this.processPayload(event)))
      })
    } else {
      throw Error('[PusherService] > bindGlobal > channel does not exist')
    }
  }

  unbindGlobal (channelName) {
    const channel = this.channels[channelName]
    console.log('[PusherService] > bindGlobal', channelName)
    if (channel) {
      channel.unbind_global()
    } else {
      console.warn('[PusherService] > unbind > channel does not exist', channelName)
    }
  }

  unsubscribeAll () {
    console.log('[PusherService] > unsubscribeAll')
  }

  processPayload (payload) {
    return jsonInflector.transform(payload, 'camelizeLower')
  }

  // TODO: use when BE ready
  // // TODO: move this to Auth controller when refactored
  // // should be triggered on each change of user id (ie different user)
  // subscribeUserChannel (id) {
  //   const userId = id // TODO: UserService.userId
  //   this.userChannelName = `private-User.${userId}`

  //   // Subscribe to user channel
  //   this.subscribe(this.userChannelName)

  //   // Subscribe to user channel events
  //   this.bindGlobal(this.userChannelName, (eventName, event) => {
  //     console.log('[pusher][user][notification]', eventName, event)

  //     if (!event || !event.data) {
  //       return
  //     }

  //     const allowedTypes = [
  //       'TaskCommentWasCreated',
  //     ]

  //     if (allowedTypes.includes(event.data.eventType)) {
  //       console.log('[pusher][user][notification] >', event.data.eventType, event)
  //       // TODO: add new notification via NotificationService
  //     } else {
  //       console.log('[pusher][user][notification] > unhandled', event)
  //     }
  //   })
  // }

  // unsubscribeUserChannel () {
  //   this.unsubscribe(this.userChannelName)
  // }
}
export default PusherService
