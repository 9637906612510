(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('search', {
      abstract: true,
      url: '/search',
      template: '<ui-view/>'
    })
    .state('search.users', {
      url: '/users?q&searchType',
      templateUrl: '/app/scripts/search/results.users.html',
      controller: 'SearchResultCtrl'
    })
    .state('search.tasks', {
      url: '/tasks?q&searchType',
      templateUrl: '/app/scripts/search/results.tasks.html',
      controller: 'SearchResultCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
