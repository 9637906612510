import SettingsSuperusersComponent from './settings-superusers.component'
import SettingsSuperusersService from './settings-superusers.service'


const SettingsSuperusersModule = angular.module('cdbl.settings-superusers', [

])
  .component('cdblSettingsSuperusers', SettingsSuperusersComponent)
  .service('SettingsSuperusersService', SettingsSuperusersService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'settings-superusers',
        url: '/settings/user-management',
        component: 'cdblSettingsSuperusers',
        resolve: {
          permissionResolver: ($state, PermissionsService) => {
            'ngInject'

            console.log('$state', $state)
            return PermissionsService.resolver(PermissionsService.canAccess('superusers'))
          }
        }
      })
  })
  .name

export default SettingsSuperusersModule
