(function() {
  'use strict';

  function ReviewCtrl($scope, $location, $stateParams, ReviewsResource) {

    ReviewsResource.getReview($stateParams.id).then(function(response) {
      $scope.review = response;
    });

    $scope.save = function(review) {
      ReviewsResource.updateReview(review.id, review).then(function() { // response is avail to the callback of then
        // success
      });
    };

    $scope.delete = function(review) {
      ReviewsResource.updateReview(review.id, review).then(function() { // response is avail to the callback of then 
        // success
      });
    };

  }

  window.app.controller('ReviewCtrl', ['$scope', '$location', '$stateParams', 'ReviewsResource', ReviewCtrl]);

})();
