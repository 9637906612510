import templateUrl from './referral-attributions.html'
import './referral-attributions.scss'

const ReferralAttributionsComponent = {
  templateUrl,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: class ReferralAttributions {
    constructor ($mdDialog, ReferralAttributionsService) {
      'ngInject'
      this.$mdDialog = $mdDialog
      this.ReferralAttributionsService = ReferralAttributionsService
      this.shortcode = null
      this.partnerInfo = null
      this.error = null
      this.isVerified = false
      this.isLoading = false
      this.confirmationStep = false
    }

    $onInit () {
      console.log('[INFO] onInit:', this.client)
    }

    async verifyShortcode () {
      try {
        this.isLoading = true
        const response = await this.ReferralAttributionsService.verifyShortcode(this.client, this.shortcode)
        this.partnerInfo = response.data
        this.isVerified = true
        this.error = null
      } catch (error) {
        this.partnerInfo = null
        this.isVerified = false
        this.error = error.data
      } finally {
        this.isLoading = false
      }
    }

    async createReferral () {
      try {
        this.isLoading = true
        const response = await this.ReferralAttributionsService.createReferral(this.client, this.shortcode)
        this.client = response.data
        this.apply()
      } catch (error) {
        this.error = error.data
      } finally {
        this.isLoading = false
      }
    }

    cancel () {
      const { client } = this
      this.$mdDialog.cancel({ client, attributed: false })
    }

    resetForm () {
      this.shortcode = null
      this.partnerInfo = null
      this.error = null
      this.isVerified = false
      this.isLoading = false
    }

    onShortcodeChange () {
      this.error = null
    }

    apply () {
      const { client, partnerInfo: partner } = this
      this.$mdDialog.cancel({ client, partner, attributed: true })
    }
  }
}

export default ReferralAttributionsComponent
