import templateUrl from './dialog-project-hire-expert.dialog.html'
import './dialog-project-hire-expert.dialog.scss'

const DialogProjectHireExpert = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogProjectHireExpert {
    constructor ($mdDialog, $mdToast, Configuration, ProjectsService) {
      'ngInject'
      this._identify = 'DialogProjectHireExpert'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.ProjectsService = ProjectsService

      this.isLoading = false
    }

    $onInit () {
      this.isLoading = true
      this.selectedExpert = null
      this.estimatesList = null

      this.ProjectsService.fetchProjectEstimates(this.project.id)
        .then(response => {
          this.estimatesList = response
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    $onDestroy () {

    }

    confirmHireExpert (expert) {
      this.hireExpert(expert) // injected parent function via dialog invoke method
      this.$mdDialog.cancel()
    }


    showHireConfirmation (expert) {
      this.selectedExpert = expert
    }

    hideHireConfirmation () {
      this.selectedExpert = null
    }
  }
}

export default DialogProjectHireExpert
