import templateUrl from './action-task-post-comment.html'
import './action-task-post-comment.scss'

const ActionTaskPostCommentComponent = {
  bindings: {
    task: '<',
    onDone: '&?',
    options: '<?'
  },
  templateUrl,
  controller: class ActionTaskPostCommentComponent {
    constructor ($mdDialog, $document, TokenService, IssueViewService, UserService, ResourceService, ActionTaskPostCommentService) {
      'ngInject'
      this._identify = 'ActionTaskPostCommentComponent'
      this.$mdDialog = $mdDialog
      this.$document = $document
      this.TokenService = TokenService
      this.IssueViewService = IssueViewService
      this.UserService = UserService
      this.ResourceService = ResourceService
      this.ActionTaskPostCommentService = ActionTaskPostCommentService

      this.isSubmitted = false
      this.isProcessing = false

      this.templates = null

      this.formData = {
        template: null,
        content: null,
        private: false,
        searchText: ''
      }

      this.modelOptions = {
        allowInvalid: true,
        updateOn: 'default blur',
        debounce: {
          'default': 500,
          'blur': 0,
          '*': 500
        }
      }

      this.defaultOptions = {
        templateFilter: false,
        enablePrivateComments: false
      }
    }

    $onInit () {
      this.config = {
        ...this.defaultOptions,
        ...this.options
      }

      this.ActionTaskPostCommentService.getTemplates()
        .then(response => {
          console.log('[ActionTaskPostCommentComponent] > getTemplates > response', response)
          this.templates = response

          if (this.config.templateFilter && typeof this.config.templateFilter === 'string') {
            this.templates = this.templates.filter(template => {
              return template.name.toLowerCase().startsWith(this.config.templateFilter)
            })
          }
        }).finally(() => {
          this.isProcessing = false
        })

      this.tokenValues = {
        'cdbl_current_user_first_name': this.UserService.user.firstName,
        'cdbl_current_user_full_name': this.UserService.user.fullName
      }

      this.tokenValidator = [
        'cdbl_current_user_first_name',
        'cdbl_current_user_full_name'
      ]

      // TOOD: temporary solution to get token values for client and contracter
      this.ResourceService
        .getTask(this.task.id)
        .then(response => {
          console.log('[ActionTaskPostCommentComponent] > getTask', response)
          if (response.client) {
            this.tokenValues['cdbl_client_first_name'] = response.client.fullName.split(' ')[0] // TOOD: this should be returned by BE
            this.tokenValues['cdbl_client_full_name'] = response.client.fullName
            this.tokenValidator = [...this.tokenValidator, ...['cdbl_client_first_name', 'cdbl_client_full_name']]
          }

          if (response.contractor) {
            this.tokenValues['cdbl_expert_first_name'] = response.contractor.fullName.split(' ')[0] // TOOD: this should be returned by BE
            this.tokenValues['cdbl_expert_full_name'] = response.contractor.fullName
            this.tokenValidator = [...this.tokenValidator, ...['cdbl_expert_first_name', 'cdbl_expert_full_name']]
          }
        }, err => {
          console.log('[ActionTaskPostCommentComponent] > getTask', err)
        })
      // ---
    }

    selectTemplate (template) {
      this.formData.content = this.formData.template.text
      this.formData.template = null
      this.clearSearchText()
    }

    clearSearchText () {
      this.formData.searchText = ''
    }

    searchTemplateOnKeydown ($event) {
      console.log('searchTemplateOnKeydown', $event)
      const ignoreKeys = ['Escape', 'ArrowDown', 'ArrowUp']
      if (!ignoreKeys.includes($event.key)) {
        $event.stopPropagation()
      }
    }

    postComment () {
      let contentWithReplacedTokens
      try {
        contentWithReplacedTokens = this.TokenService.replace(this.formData.content, this.tokenValidator, this.tokenValues)
        console.log('[ActionTaskPostCommentComponent] > postComment > contentWithReplacedTokens', contentWithReplacedTokens)
      } catch (err) {
        console.log('[ActionTaskPostCommentComponent] > postComment > contentWithReplacedTokens > error', err)
        this.$mdToast.showSimple(`Tokens error! Comment not sent.`)
        return
      }

      console.log('[ActionTaskPostCommentComponent] > postComment')
      this.isProcessing = true

      const payload = {
        comment: {
          text: contentWithReplacedTokens,
          isContractorsOnly: this.formData.private
        }
      }

      this.ActionTaskPostCommentService.postComment(this.task, payload).then(response => {
        console.log('[ActionTaskPostCommentComponent] > postComment > response', response)
        if (this.onDone) {
          this.onDone({ event: { eventName: 'done' } })
        }
      }).finally(() => {
        this.isProcessing = false
      })
    }
  }
}

export default ActionTaskPostCommentComponent
