import templateUrl from './dropdown.html'
import './dropdown.scss'

const DropdownComponent = {
  bindings: {

  },
  templateUrl,
  transclude: true,
  controller: class Dropdown {
    constructor ($transclude, $compile) {
      'ngInject'
      this._identify = 'DropdownComponent'
      this.$transclude = $transclude
      this.$compile = $compile

      this.transcludedContent = null
    }

    $onInit () {
      // this.updateConfig()
      const vm = this
      this.$transclude((clone, scope) => {
        vm.transcludedContent = clone
      })
      this.originatorEv = null
    }

    openMenu ($mdMenu, ev) {
      this.originatorEv = ev
      $mdMenu.open(ev)
    }
  }
}

export default DropdownComponent
