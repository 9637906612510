(function() {
  'use strict';

  function removeEstimate(TasksResource, _) {
    var link = function($scope) {
      $scope.display = true;

      $scope.remove = function(estimate) {
        TasksResource.removeEstimate($scope.task.id, estimate.id).then(function(data) {
          _.remove($scope.estimates, {
            id: estimate.id
          });

          $scope.display = false;
        });
      };
    };

    return {
      restrict: 'E',
      link: link,
      scope: {
        estimate: '=',
        estimates: '=',
        task: '='
      },
      templateUrl: '/app/scripts/tasks/actions/remove_estimate_button.html'
    };
  }

  app.directive('removeEstimate', ['TasksResource', '_', removeEstimate]);
})();