import templateUrl from './issue-view-summary.html'
import './issue-view-summary.scss'

const IssueViewSummaryComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueViewSummary {
    constructor (Configuration, IssuesService) {
      'ngInject'
      this._identify = 'IssueViewSummaryComponent'
      this.Configuration = Configuration
      this.IssuesService = IssuesService
    }
  }
}

export default IssueViewSummaryComponent
