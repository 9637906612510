import IssueResourceUnresponsiveComponent from './resource-unresponsive.component'
import IssueResourceUnresponsiveService from './resource-unresponsive.service'

const ResourceUnresponsiveModule = angular.module('cdbl.resource-unresponsive', [

])
  .component('cdblIssueResourceUnresponsive', IssueResourceUnresponsiveComponent)
  .service('IssueResourceUnresponsiveService', IssueResourceUnresponsiveService)
  .name

export default ResourceUnresponsiveModule
