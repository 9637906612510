import templateUrl from './project-page.html'
import './project-page.scss'

import DialogProjectEditTitleDescription from './dialog-project-edit-title-description'

const ProjectPageComponent = {
  bindings: {
    project: '<project',
    projectId: '<?',
    legacyBridge: '<?'
  },
  templateUrl,
  controller: class ProjectPage {
    constructor ($stateParams, $http, $mdDialog, ToastService, Configuration, ProjectsService, PermissionsService) {
      'ngInject'
      this._identify = 'ProjectPageComponent'
      this.$stateParams = $stateParams
      this.$http = $http
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.Configuration = Configuration
      this.ProjectsService = ProjectsService

      this.project = null
      this.isLoading = false

      this.canRevert = PermissionsService.canCreate('revert-projects')
      this.canOfflinePayments = PermissionsService.canCreate('offline-payments')
    }

    $onInit () {
      this.init()
    }

    init () {
      // If project is not passed as binding check for projectId or state param to load it async
      if (!this.project) {
        const id = typeof this.projectId !== 'undefined' ? this.projectId : this.$stateParams.id
        if (typeof id !== 'undefined' && id >= 0) {
          console.log('[ProjectPageComponent] > load project >', id)
          this.isLoading = true
          this.ProjectsService
            .loadProject(id)
            .then(response => {
              this.project = response
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      }
    }

    refreshProject () {
      this.project = null
      this.init()
    }

    doesNothing () {
      this.ToastService.show('Something would happen if this would be implemented')
    }

    openEditTitleDialog () {
      this.$mdDialog.show({
        ...DialogProjectEditTitleDescription,
        locals: {
          project: this.project
        }
      })
    }
  }
}

export default ProjectPageComponent
