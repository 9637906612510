const TokenService = class TokenService {
  constructor (Configuration) {
    'ngInject'
    this._identify = 'TokenService'
    this.Configuration = Configuration


    // TODO: init token replacement by specifing all supported tokens
    // implement intercom and internal token replacement
    // 1. in controller on form display init tokens - define which are allowed (by context or by array)
    // 2. tokens validator directive - receives object describing valid tokens, it does validation on change
    // 3. in controller on submit replace tokens

    this.allRegex = /{{(.*?)}}/igm
    this.simpleRegex = /{{\s*?(\w+)\s*?}}/im
    this.intercomRegex = /{{\s(\w+)\s\|\s(\w+):\s"([\w|\W]+?)"\s}}/im
  }

  initValidator (tokenList = []) {
    if (!Array.isArray(tokenList)) {
      throw new Error('Token validator argument is not an array!')
    }

    return this.findAllowedTokens(tokenList)
  }

  findAllowedTokens (allowedTokenList) {
    const allowedTokens = []

    if (Array.isArray(allowedTokenList)) {
      allowedTokenList.forEach(t => {
        this.Configuration.templateTokens.tokenList
          .filter(tokenConfig => tokenConfig.name === t)
          .forEach(t => {
            allowedTokens.push(t)
          })
      })
    }

    if (allowedTokenList.tokens) {
      allowedTokenList.tokens.forEach(t => {
        allowedTokens.push(t)
      })
    }

    if (allowedTokenList.tokens) {
      allowedTokenList.tokens.forEach(t => {
        allowedTokens.push(t)
      })
    }

    if (allowedTokenList.type) {
      allowedTokenList.type.forEach(tokenType => {
        this.Configuration.templateTokens.tokenList
          .filter(tokenConfig => tokenConfig.type === tokenType)
          .forEach(t => {
            allowedTokens.push(t)
          })
      })
    }

    if (allowedTokenList.context) {
      allowedTokenList.context.forEach(tokenContext => {
        this.Configuration.templateTokens.tokenList
          .filter(tokenConfig => tokenConfig.context === tokenContext)
          .forEach(t => {
            allowedTokens.push(t)
          })
      })
    }

    return allowedTokens
  }

  validate (text, tokenList) {
    const result = {
      valid: true,
      invalid: false,
      matches: {
        all: [],
        simple: [],
        intercom: []
      },
      invalidTokenName: [],
      invalidTokenPattern: []
    }


    if (typeof text !== 'string') {
      result.errors = ['Not a string']
      result.valid = false
      return result
    }

    if (typeof tokenList === 'undefined') {
      result.errors = ['Missing token validator list']
      result.valid = false
      return result
    }

    const tokenValidator = this.initValidator(tokenList)

    result.matches.all = text.match(this.allRegex)

    if (result.matches.all) {
      // Iterate trough all found tokens and do additional checks
      result.matches.all.forEach(token => {
        const simpleToken = this.simpleRegex.exec(token)
        console.log('token', token, 'simpleToken', simpleToken)

        const itercomToken = this.intercomRegex.exec(token)

        console.log('token', token, 'itercomToken', itercomToken)

        if (simpleToken) {
          // SIMPLE - Check if token is in simple format
          result.matches.simple.push(simpleToken[1])
          let isTokenValid = false
          let invalidTokenName = simpleToken[1]

          tokenValidator.forEach(at => {
            if (at.name === simpleToken[1]) {
              isTokenValid = true
            }
          })

          if (!isTokenValid) {
            result.invalidTokenName.push(invalidTokenName)
            result.valid = false
          }
        } else if (itercomToken) {
          // INTERCOM - Check if token is in intercom format
          result.matches.intercom.push(itercomToken[1])
          let isTokenValid = false
          let invalidTokenName = itercomToken[1]

          tokenValidator.forEach(at => {
            if (at.name === itercomToken[1]) {
              isTokenValid = true

              // Check validity of intercom token pattern argument name and trigger pattern issue
              if (at.argumentName !== itercomToken[2]) {
                isTokenValid = false
                invalidTokenName = `${itercomToken[1]} | ${itercomToken[2]}: "${itercomToken[3]}"`
              }
            }
          })

          if (!isTokenValid) {
            result.invalidTokenName.push(invalidTokenName)
            result.valid = false
          }
        } else {
          // Token doesnt match any of allowed patterns for a token
          result.invalidTokenPattern.push(token)
          result.valid = false
        }
      })
    }

    return result
  }

  replace (text, tokenList, tokenValues) {
    // Check if text is string
    if (typeof text !== 'string') {
      // throw new Error(`Not a string`)
      return text
    }

    if (typeof tokenList === 'undefined') {
      throw new Error('Missing token validator list')
    }

    if (typeof tokenValues === 'undefined') {
      throw new Error('Missing token values')
    }

    const tokenValidator = this.initValidator(tokenList)

    // Check if all CODEABLE tokens have values otherwise return error
    tokenValidator.forEach(tokenConfig => {
      if (tokenConfig.type === 'codeable' && typeof tokenValues[tokenConfig.name] === 'undefined') {
        throw new Error(`Missing token value for "${tokenConfig.name}"`)
      }
    })

    let replacedText = text
    const allTokens = text.match(this.allRegex)

    if (allTokens) {
      allTokens.forEach(token => {
        // console.log('allTokens > token', token)
        if (this.simpleRegex.test(token)) {
          // token valid.. can be replaced
          const simpleToken = this.simpleRegex.exec(token)
          const value = tokenValues[simpleToken[1]]
          if (value) {
            console.log('replaceTokens > replace', simpleToken[1], value)
            replacedText = replacedText.replace(token, value)
          } else {
            throw new Error(`Missing token value for "${token}"`)
          }
        }

        if (this.intercomRegex.test(token)) {
          // token valid.. can be replaced

          // const intercomToken = this.intercomRegex.exec(token)
          // const value = tokenValues[intercomToken[1]]
          // if (value) {
          //   console.log('replaceTokens > replace', intercomToken[1], value)
          //   replacedText = replacedText.replace(token, value)
          // } else {
          //   throw new Error(`Missing token value for "${token}"`)
          // }
        }
      })
    }

    return replacedText
  }
}
export default TokenService
