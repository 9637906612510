(function() {
  'use strict';

  function ErrorCtrl($scope, $modalInstance, error) {
    $scope.error = error;
    $scope.close = function() {
      $modalInstance.close();
    };
  }

  window.app.controller('ErrorCtrl', ['$scope', '$modalInstance', 'error', ErrorCtrl]);
})();
