(function() {
  'use strict';

  function editNote(NotesResource) {

    var link = function($scope) {
      $scope.notes.userId = $scope.userId;

      if ($scope.notes.length === 0) {
        $scope.notes.text = '';
        $scope.notes.createNew = true;
      } else {
        $scope.notes.text = $scope.notes[0].text;
        $scope.notes.id = $scope.notes[0].id;
        $scope.notes.createNew = false;
      }

      $scope.updateNote = function(notes) {
        NotesResource.updateNotes(notes).then(function(note) {
          notes.createNew = false;
          notes.id = note.id;

          $scope.editTag = false;
        });
      };
    };

    return {
      restrict: 'E',
      templateUrl: '/app/scripts/users/edit_note.html',
      scope: {
        notes: '='
      },
      link: link
    };
  }

  app.directive('editNote', ['NotesResource', editNote]);
})();
