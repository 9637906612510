import TemplatesIntercomComponent from './templates-intercom.component'
import TemplatesIntercomService from './templates-intercom.service'

const TemplatesIntercomModule = angular.module('cdbl.settings.templates-intercom', [
  // 'ui.router'
])
  .component('cdblSettingsTemplatesIntercom', TemplatesIntercomComponent)
  .service('TemplatesIntercomService', TemplatesIntercomService)

  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
      // parent: '',
        name: 'settings-templates-intercom',
        url: '/settings/templates-intercom/:templateId',
        params: {
          templateId: { squash: true, value: null, dynamic: true }
        },
        component: 'cdblSettingsTemplatesIntercom'
      })
  })
  .name

export default TemplatesIntercomModule
