import templateUrl from './users-expert-team-member-edit.dialog.html'
import './users-expert-team-member-edit.dialog.scss'

const ExpertTeamMemberEditDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class ExpertTeamMemberEditDialog {
    constructor ($mdDialog, $mdToast, Configuration, ExpertTeamService, ToastService) {
      'ngInject'
      this._identify = 'ExpertTeamMemberEditDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.ExpertTeamService = ExpertTeamService
      this.ToastService = ToastService

      this.isLoading = false
      this.formData = {}
      this.roles = []
    }

    $onInit () {
      this.fetchExpertRoles()
      // Map current values into edit form
      this.formData.name = this.member.name
      this.formData.email = this.member.email
      this.formData.employee = this.member.employee
      this.formData.agreementLink = this.member.agreementLink
      this.formData.state = this.member.state
      this.formData.roles = this.member.roles
      this.formData.location = this.member.location
    }

    $onDestroy () {

    }

    updateMember () {
      this.isProcessing = true
      const config = {
        'verb': this.member.actions.updateMember.method,
        'url': this.member.actions.updateMember.url,
      }

      this.formData.roles = this.formData.roles.map(role => role.id)

      this.ExpertTeamService.updateExpertTeamMember(config, this.formData)
        .then(response => {
          this.$mdDialog.cancel()
          this.ToastService.show('Expert member updated')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...', err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    fetchExpertRoles () {
      this.isProcessing = true
      const config = {
        'verb': this.member.resources.roleTypes.method,
        'url': this.member.resources.roleTypes.url,
      }

      this.ExpertTeamService.fetchExpertRoles(config)
        .then(response => {
          this.roles = response
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...', err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    isOptionDisabled (opt) {
      return this.formData.roles.length >= 3 && !this.formData.roles.find(role => role.id === opt.id)
    }
  }
}

export default ExpertTeamMemberEditDialog
