import templateUrl from './project-details.dialog.html'

const ProjectDetailsDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: true,
  clickOutsideToClose: true,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class ProjectDetailsDialog {
    constructor ($mdDialog, $mdToast, Configuration) {
      'ngInject'
      this._identify = 'ProjectDetailsDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration

      this.opportunityIssue = null
    }

    $onInit () {
      console.log('[ProjectDetailsDialog] > $onInit()')
      console.log('-- issue: ', this.issue)
      console.log('-- project: ', this.projectSummary)
    }

    $onDestroy () {
      console.log('[ProjectDetailsDialog] > onDestroy')
    }
  }
}

export default ProjectDetailsDialog
