import templateUrl from './issue-view-tabs.html'
import './issue-view-tabs.scss'

const IssueViewTabsComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueViewTabs {
    constructor ($state, $stateParams) {
      'ngInject'
      this._identify = 'IssueViewTabsComponent'
      this.$state = $state
      this.$stateParams = $stateParams

      this.currentTab = this.$stateParams.issueTabId ? this.$stateParams.issueTabId : 'notes'
    }

    goToTab (tab) {
      this.currentTab = tab
      this.$state.go('issueView', {
        ...this.$stateParams,
        issueTabId: tab
      })
    }
  }
}

export default IssueViewTabsComponent
