(function() {
  'use strict';

  function listEarningCertificates() {

    return {
      restrict: 'E',
      templateUrl: '/app/scripts/earning-certificates/list.earning-certificates.html',
      scope: '='
    };

  }

  app.directive('listEarningCertificates', listEarningCertificates);
})();
