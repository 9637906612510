(function() {
  'use strict';

  function TagCtrl($scope, $stateParams, TagsResource) {

    TagsResource.getTag($stateParams.id).then(function(tag) {
      $scope.tag = tag;
    });

    $scope.updateTag = function(tag) {
      tag.put();
    };
  }

  window.app.controller('TagCtrl', ['$scope', '$stateParams', 'TagsResource', TagCtrl]);

})();
