(function() {
  'use strict';

  function markPaidSubTaskOffline() {
    return {
      restrict: 'E',
      link: function($scope) {
        $scope.payingofflineSubTask = function(subTask){
          $scope.subTaskPayment  = {
            'type'          : 'paypal',
            'task_type'     : 'sub_task',
            'realPayment'   : false,
            'transaction'     : {
              'id'            : undefined,
              'amount'        : subTask.prices.priceWithClientFee,
              'fees'          : subTask.prices.clientFee,
              'bankCharges'   : '0.00',
              'description'   : ''
            }
          };
          $scope.subTask.payingoffline = true;
        };
      },
      scope: {
        task: '=',
        subTask: '='
      },
      templateUrl: '/app/scripts/tasks/actions/mark_paid_sub_task_offline.html'
    };
  }

  app.directive('markPaidSubTaskOffline', [markPaidSubTaskOffline]);
})();
