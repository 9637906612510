(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
      .state('tags', {
        abstract: true,
        url: '/tags',
        template: '<ui-view/>'
      })
      .state('tags.all', {
        url: '/all',
        templateUrl: '/app/scripts/tags/list.tags.html',
        controller: 'TagsCtrl'
      })
      .state('tags.display', {
        url: '/:id',
        templateUrl: '/app/scripts/tags/display.tag.html',
        controller: 'TagCtrl'
      });
  }

  window.app.config(['$stateProvider', states]);
})();