import templateUrl from './dialog-settings-account-edit.dialog.html'
import './dialog-settings-account-edit.dialog.scss'

const DialogSettingsAccountEdit = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogSettingsAccountEdit {
    constructor ($mdDialog, ToastService, Configuration, SettingsAccountService) {
      'ngInject'
      this._identify = 'DialogSettingsAccountEdit'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.Configuration = Configuration
      this.SettingsAccountService = SettingsAccountService

      this.isLoading = false
      this.error = null
    }

    $onInit () {
      this.formData = {
        email: this.userAccount.email,
        avatar: this.userAccount.avatar
      }

      this.fileSelectConfig = {
        accept: 'image/gif, image/jpeg, image/png',
        previewWidth: 100,
        previewHeight: 100,
        showOriginalModelValue: true
      }
    }

    $onDestroy () {

    }


    save () {
      this.isProcessing = true
      this.error = null
      this.SettingsAccountService.updateSettings(this.formData)
        .then(response => {
          this.ToastService.show('Account successfully updated')
          this.$mdDialog.hide(response)
        })
        .catch(err => {
          this.error = err
          this.ToastService.show('Something went wrong, please try again')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogSettingsAccountEdit
