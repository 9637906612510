import templateUrl from './resource-project-list.html'
import './resource-project-list.scss'
import ProjectDetailsDialog from './project-details.dialog.js'

const IssueResourceProjectListComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,

  controller: class IssueResourceProjectList {
    constructor ($mdDialog, Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourceProjectListComponent'
      this.$mdDialog = $mdDialog
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService

      this.projectList = null
    }

    $onInit () {
      const resourceConfig = this.issue.resources.projects
      console.log('[IssueResourceProjectList > onInit()] issue:', this.issue)

      this.IssueViewService.getResourceProjectList(this.issueId, resourceConfig)
        .then(response => {
          let flatArr = []
          response.forEach(project => {
            flatArr.push(project)
            if (project.subTasks) {
              flatArr = flatArr.concat(project.subTasks)
            }
            delete project.subTasks
          })
          this.projectList = flatArr
        }, error => {
          console.log(error)
        })
    }

    get title () {
      return this.issue.type === 'max_open_projects' ? `Expert's Open Projects` : `Latest Projects`
    }

    isTask (item) {
      return item.type === 'sub_task'
    }

    itemType (item) {
      if (item.type === 'task') {
        return 'Project'
      } else if (item.type === 'sub_task') {
        return item.retainer ? 'Retainer' : 'Subtask'
      }
      return item.type
    }

    openProjectDetailsDialog (projectSummary) {
      console.log('project-summary:', projectSummary)
      console.log('issue:', this.issue)
      const issue = this.issue

      this.$mdDialog
        .show({
          ...ProjectDetailsDialog,
          locals: {
            issue,
            projectSummary
          }
        })
    }
  }
}

export default IssueResourceProjectListComponent
