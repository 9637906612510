import templateUrl from './dialog-settings-account-mfa-disable.dialog.html'
import './dialog-settings-account-mfa-disable.dialog.scss'

const DialogSettingsAccountMfaDisable = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogSettingsAccountMfaDisable {
    constructor ($mdDialog, $mdToast, Configuration, SettingsAccountService) {
      'ngInject'
      this._identify = 'DialogSettingsAccountMfaDisable'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.SettingsAccountService = SettingsAccountService
    }

    $onInit () {
      this.isLoading = false
      this.mfa = {
        completed: false,
        error: null,
      }

      this.formData = {
        password: null,
        otpAttempt: null
      }
    }

    $onDestroy () {

    }

    resetErrors () {
      this.mfa.error = null
    }

    confirmMfaDisable () {
      this.isProcessing = true
      this.resetErrors()
      this.SettingsAccountService.setupMfaDisable(this.formData)
        .then(response => {
          this.mfa.completed = true
        })
        .catch(err => {
          console.log(err)
          this.mfa.error = true
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogSettingsAccountMfaDisable
