import templateUrl from './resource-incident.html'
import './resource-incident.scss'
import IncidentFormDialog from './incident-form.dialog.js'

const ResourceIncidentComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,

  controller: class IssueResourceIncident {
    constructor ($mdDialog, Configuration, IssueViewService, IssuesService) {
      'ngInject'
      this._identify = 'ResourceIncidentComponent'
      this.$mdDialog = $mdDialog
      this.Configuration = Configuration
      this.IssuesService = IssuesService
      this.IssueViewService = IssueViewService

      this.incident = null
      this.datasets = null
      this.isLoading = false
    }

    async $onInit () {
      this.isLoading = true
      await this.IssuesService.getIncidentReportDatasets()
        .then(response => {
          this.datasets = response
        }, error => {
          console.log(error)
        })

      await this.loadIncident()
    }

    loadIncident () {
      // TODO: remove fake data
      // this.incident = this.issue.resources.incident.data
      this.isLoading = true
      return this.IssueViewService.getResourceIncident(this.issue.id)
        .then(response => {
          this.incident = response
        }, error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    openIncidentFormDialog () {
      console.log('openIncidentFormDialog')
      const incidentContextData = {
        incident: this.incident,
        isEdit: true
      }
      this.$mdDialog
        .show({
          ...IncidentFormDialog,
          locals: {
            incidentContextData
          }
        })
        .then(dialogResponse => {
          this.IssuesService.reloadIssueView() // reload whole ticket (because update can change also other HAL resources)
        }, dialogError => {
          console.log('openIncidentFormDialog > err', dialogError)
        })
    }

    renderFieldValue (fieldName, data = null) {
      if (!this.datasets) {
        return `Couldn't load BE datasets. Try reloading.`
      }
      switch (fieldName) {
      case 'source':
        if (this.incident.source === 'source_other') {
          return `Other: ${this.incident.sourceDetails}`
        }
        return this.datasets.sources.find(s => s.id === this.incident.source).value

      case 'trigger':
        if (this.incident.trigger === 'trigger_other') {
          return `Other: ${this.incident.triggerDetails}`
        }
        return this.datasets.triggers.find(t => t.id === this.incident.trigger).value

      case 'status':
        const status = this.datasets.statuses.find(s => s.id === this.incident.status)
        return status ? status.value : 'N/A'

      case 'participantRole':
        const role = data ? this.datasets.participantsRoles.find(r => r.id === data.participantRole) : null
        return role ? role.value : ''
      }

      return 'N/A'
    }
  }
}

export default ResourceIncidentComponent
