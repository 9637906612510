(function() {
  'use strict';

  function UserPreferencesCtrl($scope, $location, $stateParams, usersResource) {

    $scope.onSelectPage = function() {
      console.log('UserPreferencesCtrl#onPageSelect', $scope.user);
    };

		$scope.onSelectPage();

    $scope.isClient = function(user) {
      console.log('UserPreferencesCtrl#isClient', user);
      var isClient = false;

      if (user !== undefined) {
        isClient = (user.role === 'client');
      }

      return isClient;
    };

    $scope.getUserPreferences = function(user) {
      console.log('UserPreferencesCtrl#getUserPreferences', user);

      var params = {id: user.id};
      usersResource.getUserPreferences(params).then(function(preferences) {
        $scope.preferences = preferences.preferences;
        $scope.notifications = preferences.preferences.notifications;
        $scope.marketingEmails = preferences.preferences.notifications.marketing;

        console.log('UserPreferencesCtrl#getUserPreferences: callback: ', $scope.marketingEmails);
      });
    };

    $scope.updateUserPreferences = function(user) {
      console.log('UserPreferencesCtrl#updateUserPreferences', user);
      var params = {id: user.id};
      var updates = { preferences: $scope.preferences };

      usersResource.updateUserPreferences(params, updates).then(function(_emptyResponse) {
       // /sb/ how do I display success?
      });
    };
  }

  window.app.controller('UserPreferencesCtrl', ['$scope', '$location', '$stateParams', 'UsersResource', UserPreferencesCtrl]);

})();
