import templateUrl from './user-unlock-button.html'
import './user-unlock-button.scss'

const UserUnlockButtonComponent = {
  bindings: {
    user: '<'
  },
  templateUrl,
  controller: class UserUnlockButton {
    constructor (UserService, PermissionsService) {
      'ngInject'
      this._identify = 'UserUnlockButtonComponent'
      this.UserService = UserService
      this.PermissionsService = PermissionsService
    }

    $onInit () {
      this.isProcessing = false
      console.log('[UserUnlockButtonComponent] > user', this.user)
    }

    get isLocked () {
      return this.user && this.user.accountLocked
    }

    get lockAccessPermitted () {
      if (!this.UserService.userIsAdmin(this.user)) {
        return true
      }

      return this.PermissionsService.canAccess('admin-lock')
    }

    unlockAccount () {
      this.isProcessing = true
      this.UserService.unlockAccount(this.user.id)
        .then(response => {
          console.log('[UserUnlockButtonComponent] > unlockAccount > response', response)
          this.user.accountLocked = response.accountLocked
        })
        .catch(err => {
          console.log('[UserUnlockButtonComponent] > unlockAccount > err', err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default UserUnlockButtonComponent
