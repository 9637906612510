(function () {
  'use strict'

  function TasksCtrl ($scope, $location, $stateParams, $modal, TasksResource, WatchlistResource, AffiliatesResource, $timeout, ExpertPodsService) {
    // Stupid hack for stupid code, god bless jan and select2
    $timeout(() => {
      $scope.states = ['all', 'published', 'estimated', 'hired', 'paid', 'completed', 'refunded', 'canceled', 'abandoned', 'first timers', 'withheld']
      $scope.qualityReviewStates = ['unassigned', 'assigned', 'approved', 'rejected', 'escalated']
    })

		// Loading tasks + pagination
    $scope.filterState = 'all'
    $scope.proOnly = null
    $scope.withoutPro = null
    $scope.tagName = null

    $scope.referralPlatforms = [{value: 'referoo', name: 'Referoo'}, {value: 'tune', name:'Tune'}]
    $scope.referralPlatformName = null
    $scope.trackingName = null
    $scope.tuneTrackingName = null

    $scope.firstTimerFilters = [{ value: true, name: 'Yes' }, { value: false, name: 'No' }]

    $scope.projectQuality = null
    $scope.filterFailFactor = null
    $scope.filterConvertedToPaid = null

    $scope.firstTimer = {
      projectQuality: '',
      failFactor: '',
      convertedToPaid: ''
    }

    $scope.filterWithExpertPods = Boolean($stateParams.withExpertPods)

    ExpertPodsService.getExpertPodsList()
      .then(response => {
        $scope.podList = response.data
      })
      .catch(err => {
        console.log(err)
      })

    AffiliatesResource.getAffiliatesTrackingNames('referoo').then(function (trackingNames) {
      $scope.affiliateTrackingNames = trackingNames
    })

    AffiliatesResource.getAffiliatesTrackingNames('tune').then(function (trackingNames) {
      $scope.tuneAffiliateTrackingNames = trackingNames
    })

    AffiliatesResource.getTagNames().then(function (tagNames) {
      $scope.tags = tagNames
    })

    $scope.onSelectExpertPods = function () {
      $scope.filterPartnerType = null
      $scope.onSelectPage(1)
    }

    $scope.onSelectPage = function (page) {
      console.log('TasksCtrl -> onSelectPage -> page:', page)

      if ($scope.filterPartnerType) {
        $scope.filterWithExpertPods = null
        $scope.withoutPro = null
        $scope.proOnly = null
      }

      var requestObject = {
        state: $scope.filterState.replace('all', ''),
        page: page.toString(),
        tag: $scope.tagName,
        with_expert_pods: $scope.filterWithExpertPods ? $scope.filterWithExpertPods : null,
        partner_type: $scope.filterPartnerType ? 'agency' : null,
        pod: $scope.filterPod ? $scope.filterPod.id : null,
        qr_status: $scope.filterReviewState
      }

      if ($scope.referralPlatformName === 'referoo') {
        requestObject.trackingName = $scope.trackingName
      }

      if ($scope.referralPlatformName === 'tune') {
        requestObject.partnerName = $scope.tuneTrackingName
      }

      console.log('TasksCtrl -> onSelectPage -> request:', requestObject)

      if ($scope.filterState == 'first timers') {
        if ($scope.firstTimer.projectQuality != '') { requestObject.cs_project_quality = $scope.firstTimer.projectQuality }
        if ($scope.firstTimer.failFactor != '') { requestObject.cs_cancel_task = $scope.firstTimer.failFactor }
        if ($scope.firstTimer.convertedToPaid != '') { requestObject.converted_to_paid = $scope.firstTimer.convertedToPaid }

        console.log(requestObject)
      }

      if ($scope.proOnly) {
        requestObject.pro = 'true'
      }
      if ($scope.withoutPro) {
        requestObject.pro = 'false'
      }
      TasksResource.getTasks(requestObject).then(function (tasks) {
        $scope.tasks = tasks
				// Pagination
        $scope.totalItems = tasks.headers('X-Total-Count')
        $scope.itemsPerPage = 20
        $scope.currentPage = page.toString()
        $scope.numPages = tasks.headers('X-Page-Count')
        $scope.maxSize = 5
      }).catch(err => { console.log(err) })
    }

    $scope.onSelectPage('1')

    $scope.watch = function (task) { // TODO refactor into directive
      if (task.watchlist) {
        WatchlistResource.addWatchlistItem(task.id, 'Task').then(function () {
          task.watchlist = true
        })
      } else {
        WatchlistResource.removeWatchlistItem(task.id, 'Task').then(function () {
          task.watchlist = false
        })
      }
    }

    $scope.firstTimerFilter = function () {
      $scope.onSelectPage(1)
    }

    $scope.stateChange = function () {
      $scope.onSelectPage(1)
    }

    $scope.podChange = function () {
      $scope.filterPod = JSON.parse($scope.filterPod)
      $scope.onSelectPage(1)
    }

    $scope.proFilter = function (pro) {
      $scope.filterPartnerType = null
      if ($scope.withoutPro && $scope.proOnly) {
        if (pro === 'pro-only') {
          $scope.withoutPro = null
        }
        if (pro === 'without') {
          $scope.proOnly = null
        }
      }
      $scope.onSelectPage(1)
    }

    $scope.trackingNameFilter = function (platform) {
      console.log('TasksCtrl -> trackingNameFilter -> platform:', platform)
      if (platform === 'tune') {
        $scope.trackingName = null
      }

      if (platform === 'referoo') {
        $scope.tuneTrackingName = null
      }

      $scope.onSelectPage(1)
    }

    $scope.tagNameFilter = function () {
      $scope.onSelectPage(1)
    }

    $scope.referralPlatformChange = function () {
      const platform = $scope.referralPlatformName
      console.log('TasksCtrl -> referralPlatformChange -> referralPlatform:', platform)

      if (platform === 'tune') {
        $scope.trackingName = null
      }

      if (platform === 'referoo') {
        $scope.tuneTrackingName = null
      }

      if (platform === null || platform === undefined) {
        $scope.tuneTrackingName = null
        $scope.trackingName = null
      }

      $scope.onSelectPage(1)
    }

    $scope.isReferral = function (task) {
      const referralPartner = task.client.referralPartner
      var result = true

      if (referralPartner === undefined) {
        result = false
      }

      if (referralPartner === null) {
        result = false
      }

      return result
    }

    $scope.referralPlatform = function (task) {
      var result = ''

      if ($scope.isReferral(task)) {
        result = task.client.referralPartner.platform
      }

      return result
    }

    $scope.partnerTrackingName = function (task) {
      var result = ''

      if ($scope.isReferral(task)) {
        result = task.client.referralPartner.trackingName
      }

      return result
    }
  }

  window.app.controller('TasksCtrl', ['$scope', '$location', '$stateParams', '$modal', 'TasksResource', 'WatchlistResource', 'AffiliatesResource', '$timeout', 'ExpertPodsService', TasksCtrl])
})()
