/* eslint-disable */

(function() {
  'use strict';

  function MoneyTransfersServer($q, $http, Configuration) {
    const URL_PATH = `${Configuration.apiUrl}/admin/payments`;

    let submitRequest = function(transfer) {
      const url = `${URL_PATH}/${transfer.type}`;
      const payload = { transfer: transfer };

      let responseObject, deferred = $q.defer();

      console.log('-- MoneyTransfersServer#submitRequest', payload);

      $http
        .post(url, payload)
        .then(function(response) {
          deferred.resolve(response.data);
        })
        .catch(function(err) {
          deferred.reject(err);
        });

      return deferred.promise;
    };

    return {
      submitTransferRequest: submitRequest
    };
  }

  window.app.service('MoneyTransfersServer', ['$q', '$http', 'Configuration', MoneyTransfersServer]);
})();
