import templateUrl from './edit-expert-availabiltiy.html'
import './edit-expert-availabiltiy.scss'
import DialogEditExpertAvailabiltiy from '../dialog-edit-expert-availabiltiy'

const EditExpertAvailabiltiyComponent = {
  bindings: {
    id: '<',
  },
  templateUrl,
  controller: class EditExpertAvailabiltiy {
    constructor ($mdDialog, $stateParams) {
      'ngInject'
      this._identify = 'EditExpertAvailabiltiyComponent'
      this.$mdDialog = $mdDialog
      this.$stateParams = $stateParams
    }

    openEditAvailabilityDialog () {
      this.$mdDialog.show({
        ...DialogEditExpertAvailabiltiy,
        locals: {
          id: this.$stateParams.id
        }
      })
    }
  }
}

export default EditExpertAvailabiltiyComponent
