(function () {
  'use strict';

  function feedback(Restangular) {
    var link = function ($scope) {
      $scope.feedbacks = [];

      $scope.onSelect = function (answer, outerIndex) {
        // console.log(answer, outerIndex)
        if (angular.isFunction($scope.onSelectCallback)) {
          $scope.onSelectCallback(answer, outerIndex);
        }
      };

      $scope.onSelectChange = function (answerId, outerIndex) {
        var answer = $scope.feedbackQuestions[outerIndex].answers.find(function (a) {
          return a.id === parseInt(answerId);
        });
        // console.log(answerId, outerIndex, answer, $scope.feedbackQuestions[outerIndex].answers)

        if (angular.isFunction($scope.onSelectCallback) && answer) {
          $scope.onSelectCallback(answer, outerIndex);
        }
      };

      Restangular.one('feedbacks').getList('', {
        type: $scope.type
      }).then(function (questions) {
        let otherQuestion = questions[0].answers.filter(item => item.text === 'Other');
        let answers = questions[0].answers.filter(item => item.text !== 'Other');
        // if we have "other" question put it on the end
        questions[0].answers = answers.concat(otherQuestion);

        $scope.feedbackQuestions = questions;
        if ($scope.preselected) {
          $scope.feedbacks.push({
            id: questions[0].answers[0].id
          });
        }
      });

      $scope.$watch('trigger', function (triggerValue) {
        if (triggerValue) {
          post($scope.feedbacks);
        }
      });

      $scope.$watch('feedbacks', function (feedbacks) {
        if ($scope.autoTrigger) {
          post(feedbacks);
        }
      }, true);

      function post(feedbacks) {
        feedbacks.forEach(function (feedback) {
          if (!feedback || !feedback.id) {
            return;
          }

          var postArray = {
            answerId: feedback.id,
            skipIntercom: true
          };

          if ($scope.task) {
            postArray.subjectId = $scope.task.id;
            postArray.subjectType = 'Task';
          }

          if (feedback.details) {
            postArray.details = feedback.details;
          }

          var requestUrl = $scope.anonymous ? 'anonymous_feedbacks' : 'feedbacks';

          Restangular.all(requestUrl).post(postArray);

          if ($scope.onFeedbackPosted) {
            $scope.onFeedbackPosted();
          }
        });
      }
    };

    return {
      restrict: 'E',
      templateUrl: '/app/scripts/feedback/feedback.html',
      scope: {
        type: '@',
        task: '=',
        trigger: '=',
        autoTrigger: '=',
        onFeedbackPosted: '&',
        anonymous: '=',
        preselected: '=',
        onSelectCallback: '=',
        otherComment: '=',
        fieldType: '@'
      },
      link: link
    };
  }

  app.directive('feedback', ['Restangular', feedback]);
})();
