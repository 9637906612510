(function() {
  'use strict';

  function RefundsTasksCtrl($scope, $modalInstance, Restangular, task) {

		$scope.task = task;

		$scope.close = function() {
			$modalInstance.close();
		};

		$scope.finished = false;
		$scope.transactions = '';

		$scope.postRefund = function(refund) {
			$scope.processing = true;

			Restangular.one('admin').one('tasks', $scope.task.id).all('refunds').post(refund).then(function(transaction) {
				$scope.finished = true;
				$scope.transaction = transaction;
			}).catch(function (errors) {
				$scope.serverErrors = errors;
			}).finally(function() {
				$scope.processing = false;
			});
		};

    $scope.refundOptions = [
      { name: 'Not sure', value: null },
      { name: 'Expert', value: 'expert' },
      { name: 'Client', value: 'client' },
    ];

    $scope.onPercentageChange = function (refund) {
      if (refund.percentage === '1.0') {
        refund.keep_fee = true
      } else {
        refund.keep_fee = false
      }
    }
  }

  window.app.controller('RefundsTasksCtrl', ['$scope', '$modalInstance', 'Restangular', 'task', RefundsTasksCtrl]);

})();
