(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('reviews', {
      url: '/reviews',
      templateUrl: '/app/scripts/reviews/list.reviews.html',
      controller: 'ReviewsCtrl'
    })
    .state('reviews.display', {
      url: '/{id:[0-9]{1,9}}',
      templateUrl: '/app/scripts/reviews/edit.review.html',
      controller: 'ReviewCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
