import templateUrl from './pagination.html'
import './pagination.scss'

const PaginationComponent = {
  bindings: {
    cdblPagination: '<', // needs to be prefixed with `cdbl` because there is a conflict with angular-foundation directive
    options: '<?',
    onPageChange: '&',
    showSummary: '<'
  },
  templateUrl,
  controller: class Pagination {
    constructor () {
      'ngInject'
      this._identify = 'PaginationComponent'
    }

    $onInit () {
      if (typeof this.cdblPagination === 'undefined') {
        throw new Error('Invalid pagination object')
      }

      // TODO check for options (template simple) and if not present use default
    }

    $onChanges (changes) {

    }

    get showPrevious () {
      return this.cdblPagination.currentPage > (this.cdblPagination.firstPage === 0 ? 0 : 1)
    }

    get showNext () {
      return this.cdblPagination.currentPage < (this.cdblPagination.firstPage === 0 ? this.cdblPagination.pageCount - 1 : this.cdblPagination.pageCount)
    }

    get paginationItems () {
      const currentPage = this.cdblPagination.currentPage + (this.cdblPagination.firstPage === 0 ? 1 : 0)
      const lastPage = this.cdblPagination.pageCount - 1 // this.cdblPagination.firstPage === 0 ? (this.pageCount - 1) : this.pageCount

      const delta = 2

      let range = []
      for (let i = Math.max(2, currentPage - delta); i <= Math.min(lastPage, currentPage + delta); i++) {
        range.push(i)
      }

      if (currentPage - delta > 2) {
        range.unshift('...')
      }
      if (currentPage + delta < lastPage) {
        range.push('...')
      }

      range.unshift(1)
      range.push(this.cdblPagination.pageCount)

      return range
    }

    isItemActive (item) {
      return this.cdblPagination.currentPage === (this.cdblPagination.firstPage === 0 ? (item - 1) : item)
    }

    resetCurrentPage () {
      this.cdblPagination.currentPage = this.cdblPagination.firstPage
    }

    notifyParent () {
      if (this.onPageChange) {
        this.onPageChange({
          page: this.cdblPagination.currentPage
        })
      }
    }

    goToPage (index) {
      this.cdblPagination.currentPage = this.cdblPagination.firstPage === 0 ? (index - 1) : index
      console.log('[Pagination] goToPage', this.cdblPagination.currentPage)
      this.notifyParent()
    }

    goToNextPage () {
      this.cdblPagination.currentPage += 1
      console.log('[Pagination] goToNextPage', this.cdblPagination.currentPage)
      this.notifyParent()
    }

    goToPrevPage () {
      this.cdblPagination.currentPage -= 1
      console.log('[Pagination] goToPrevPage', this.cdblPagination.currentPage)
      this.notifyParent()
    }

    goToFirst () {
      this.cdblPagination.currentPage = this.cdblPagination.firstPage
      console.log('[Pagination] goToFirst', this.cdblPagination.currentPage)
      this.notifyParent()
    }

    goToLast () {
      this.cdblPagination.currentPage = this.cdblPagination.firstPage === 0 ? (this.pageCount - 1) : this.cdblPagination.pageCount
      console.log('[Pagination] goToLast', this.cdblPagination.currentPage)
      this.notifyParent()
    }
  }
}

export default PaginationComponent
