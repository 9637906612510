const SettingsSuperusersService = class SettingsSuperusersService {
  constructor ($http, Configuration, AuthService) {
    'ngInject'
    this._identify = 'SettingsSuperusersService'

    this.$http = $http
    this.Configuration = Configuration
    this.AuthService = AuthService
  }

  getUsers () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/superusers`)
      .then(response => response.data)
  }
}
export default SettingsSuperusersService
