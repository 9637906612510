import templateUrl from './action-quality-assessment.html'
import './action-quality-assessment.scss'

// const FEEDBACK_LOW_VIABILITY_ID = 147

const ActionQualityAssessmentComponent = {
  bindings: {
    task: '<',
    onDone: '&'
  },
  templateUrl,
  controller: class ActionQualityAssessment {
    constructor (ActionQualityAssessmentService) {
      'ngInject'
      this._identify = 'ActionQualityAssessmentComponent'
      this.ActionQualityAssessmentService = ActionQualityAssessmentService

      this.optionList = null
      this.isProcessing = false

      this.formData = {
        note: '',
        comment: '',
        qualityAssessment: null,
      }
    }

    $onInit () {
      this.ActionQualityAssessmentService.getOptions(this.task.actions.pqa).then(response => {
        console.log('[ActionQualityAssessmentComponent] > getOptions > response', response)
        this.optionList = response.answers
      }, error => {
        console.log('[ActionQualityAssessmentComponent] > getOptions > error', error)
      })
    }

    // isCommentRequired () {
    //   return this.formData.qualityAssessment && this.formData.qualityAssessment.id === FEEDBACK_LOW_VIABILITY_ID // TODO: this can't be hardcoded to determine
    // }

    setQualityAssessment () {
      this.isProcessing = true
      const payload = {
        answerId: this.formData.qualityAssessment.id,
        details: this.formData.note,
        skipIntercom: true,
        subjectId: this.task.id,
        subjectType: 'Task'
      }

      console.log('[ActionQualityAssessmentComponent] > setQualityAssessment', payload)

      this.ActionQualityAssessmentService.setQualityAssessment(this.task.actions.pqa, payload)
        .then(response => {
          console.log('[ActionQualityAssessmentComponent] > setQualityAssessment > response', response)
          if (this.onDone) {
            this.onDone({ event: { eventName: 'done' } })
          }
        }, error => {
          console.log('[ActionQualityAssessmentComponent] > setQualityAssessment > error', error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default ActionQualityAssessmentComponent


// // Get options
// GET /feedbacks?type=cs_project_quality

// {"question":"CS project quality","custom_comment":true,"answers":[{"id":146,"text":"Junk / spam","input_type":"text","group":null},{"id":147,"text":"Low viability","input_type":"text","group":null},{"id":148,"text":"Medium viability","input_type":"text","group":null},{"id":149,"text":"High viability","input_type":"text","group":null}]}



// // Post selection

// answer_id: 148
// details: "comment text"
// skip_intercom: true
// subject_id: 50416
// subject_type: "Task"


// // Task
// task.csProjectQuality
