import ExpertPodsViewComponent from './expert-pods-view.component'

const ExpertPodsViewModule = angular.module('cdbl.expert-pods-view', [

])
  .component('cdblExpertPodsView', ExpertPodsViewComponent)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        // parent: 'expertPods',
        name: 'expertPods.podList.podView',
        url: '/:id',
        // component: 'cdblExpertPodsView',
        params: {
          id: { squash: true, value: null, dynamic: true }
        },
        views: {
          '@expertPods': 'cdblExpertPodsView'
        },
        data: {
          breadcrumb: 'Expert pod view',
          showBackLink: true,
        },
        resolve: {
          permissionResolver: (Configuration, AuthService) => {
            'ngInject'
            return AuthService.isAuthenticated
          }
        }
      })
  })
  .name

export default ExpertPodsViewModule
