(function() {
  'use strict';

  function trustAsHtml($sce, $filter) {
    return function(text) {

      const sanitizedHtmlText = text; // $filter('marked2html')(text);

      return $sce.trustAsHtml(sanitizedHtmlText);
    };
  }

  app.filter('trustAsHtml', ['$sce', '$filter', trustAsHtml]);
})();
