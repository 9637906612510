// Purpose of thi service - slowly port old auth here. Meanwhile map functions to old auth service.
const AuthService = class AuthService {
  constructor (Configuration, Auth) {
    'ngInject'
    this._identify = 'AuthService'
    this.Configuration = Configuration
    this.AuthLegacy = Auth
  }

  get isAuthenticated () {
    return this.AuthLegacy.isSignedIn()
  }

  get user () {
    return this.AuthLegacy.me()
  }

  get authToken () {
    return this.AuthLegacy.getToken()
  }

  login (formData) {
    const payload = {
      email: formData.username,
      password: formData.password,
      otpAttempt: formData.otpAttempt
    }
    return this.AuthLegacy.signIn(payload)

    // return this.$http
    //   .post(`${this.Configuration.apiUrl}/admin/login`, payload)
    //   .then(response => {
    //     if (response.data.authToken) {
    //       // store token in LS and set update interval
    //       // init pusher
    //     }

    //     return response.data
    //   })
  }

  updateToken (token) {
    return this.AuthLegacy.updateToken(token)
  }
}

export default AuthService
