import ProjectWidgetScopeDocComponent from './project-widget-scope-doc.component'

import ProjectScopeDocService from './project-scope-doc.service'

const ProjectWidgetScopeDocModule = angular.module('cdbl.project-widget-scope-doc', [

])
  .service('ProjectScopeDocService', ProjectScopeDocService)
  .component('cdblProjectWidgetScopeDoc', ProjectWidgetScopeDocComponent)
  .name

export default ProjectWidgetScopeDocModule
