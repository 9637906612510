import ActionTaskPostCommentComponent from './action-task-post-comment.component'
import ActionTaskPostCommentService from './action-task-post-comment.service'

const ActionTaskPostCommentModule = angular.module('cdbl.actions.taskpostcomment', [
  'ui.router',
])
  .component('cdblActionTaskPostComment', ActionTaskPostCommentComponent)
  .service('ActionTaskPostCommentService', ActionTaskPostCommentService)
  .name

export default ActionTaskPostCommentModule
