import ProjectsComponent from './projects.component'
import ProjectsService from './projects.service'

import ProjectsListModule from './projects-list'

import ProjectsListAltModule from './projects-list-alt'

const ProjectsModule = angular.module('cdbl.projects', [
  ProjectsListModule,
  ProjectsListAltModule,
])
  .component('cdblProjects', ProjectsComponent)
  .service('ProjectsService', ProjectsService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        abstract: true,
        name: 'projects',
        url: '/projects',
        component: 'cdblProjects',
        resolve: {
          permissionResolver: ($state, Configuration, AuthService) => {
            'ngInject'
            console.log('[permissionResolver] user', AuthService.user)
            const allow = !Configuration.isBuild && Configuration.CDBL_ENV === 'develop' && AuthService.isAuthenticated && AuthService.user.id === 13
            if (!allow) {
              console.log('[permissionResolver] > reject')
              $state.go('tasks.state')
            }
          }
        }
      })
  })
  .name

export default ProjectsModule
