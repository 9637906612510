import templateUrl from './action-task-publish.html'
import './action-task-publish.scss'

const ActionTaskPublishComponent = {
  bindings: {
    task: '<',
    onDone: '&'
  },
  templateUrl,
  controller: class ActionTaskPublishComponent {
    constructor ($mdDialog, IssueViewService) {
      'ngInject'
      this._identify = 'ActionTaskPublishComponent'
      this.$mdDialog = $mdDialog
      this.IssueViewService = IssueViewService

      this.isSubmitted = false
      this.isProcessing = false
    }

    $onInit () {

    }

    onReleaseForPublication ($event) {
      console.log('[ActionTaskPublishComponent] > onReleaseForPublication')
      this.isProcessing = true
      // const confirmDialog = this.$mdDialog.confirm()
      //   .title('Are you sure you want to publish this project?')
      //   .textContent('All experts will be notified about this project being available.')
      //   .targetEvent($event)
      //   .ok('Publish')
      //   .cancel('Cancel')

      const confirmDialog = {
        escapeToClose: true,
        clickOutsideToClose: true,
        controllerAs: '$ctrl',
        focusOnOpen: false,
        parent: angular.element(document.body),
        template: `<md-dialog>
                    <md-dialog-content class="pa-4">
                       <h2 class="md-title">Are you sure you want to publish this project?</h2>
                       <div class="md-dialog-content-body">All experts will be notified about this project being available.</div>
                    </md-dialog-content>
                    <md-dialog-actions>
                      <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
                      <md-button class="md-warn md-raised" ng-click="$ctrl.$mdDialog.hide()">Publish</md-button>
                    </md-dialog-actions>
                  </md-dialog>`,

        controller: class DialogController {
          constructor ($mdDialog) {
            'ngInject'
            this.$mdDialog = $mdDialog
          }
        }
      }

      this.$mdDialog.show({
        ...confirmDialog,
        locals: {}
      }).then(() => {
        console.log('[ActionTaskPublishComponent] > onReleaseForPublication > confirmDialog > publish')
        this.IssueViewService.executeResourceAction(this.task.actions.publish, {
          'task': {
            'id': this.task.id
          }
        }).then(response => {
          if (this.onDone) {
            this.onDone({ event: { eventName: 'done' } })
          }
        }).finally(() => {
          this.isProcessing = false
        })
      }, () => {
        console.log('[ActionTaskPublishComponent] > onReleaseForPublication > confirmDialog > cancel')
        this.isProcessing = false
      })
    }
  }
}

export default ActionTaskPublishComponent
