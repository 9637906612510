(function() {
  'use strict';

  function PreferredCtrl($scope, $location, $stateParams, $modal, TasksResource, WatchlistResource, AffiliatesResource) {

		// Loading tasks + pagination
		$scope.filters = ['anyone', 'contractor', 'client', 'nobody'];
		$scope.filterState = $location.search().filter || 'anyone';

    $scope.proOnly = null;
    $scope.withoutPro = null;
    $scope.trackingName = null;
    $scope.tagName = null;

    AffiliatesResource.getAffiliatesTrackingNames().then(function(trackingNames) {
      $scope.affiliateTrackingNames = trackingNames;
    });

    console.log(AffiliatesResource);

    AffiliatesResource.getTagNames().then(function(tagNames) {
      $scope.tags = tagNames;
    });

		$scope.onSelectPage = function(page) {
			var requestObject = {
        filter: $scope.filterState,
        page: page.toString(),
        trackingName: $scope.trackingName,
        tag: $scope.tagName
      };
      if ($scope.proOnly) {
        requestObject.pro = 'true';
      }
      if ($scope.withoutPro) {
        requestObject.pro = 'false';
      }
      TasksResource.getTasks(requestObject).then(function(tasks) {
        $scope.tasks = tasks;
				// Pagination
				$scope.totalItems = tasks.headers('X-Total-Count');
				$scope.itemsPerPage = 20;
				$scope.currentPage = page.toString();
				$scope.numPages = tasks.headers('X-Page-Count');
				$scope.maxSize = 5;
			});
		};

		$scope.onSelectPage('1');

    $scope.watch = function(task) { // TODO refactor into directive
      if (task.watchlist) {
        WatchlistResource.addWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = true;
        });
      } else {
        WatchlistResource.removeWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = false;
        });
      }
    };

    $scope.proFilter = function(pro) {
      if ($scope.withoutPro && $scope.proOnly) {
        if (pro === 'pro-only') {
          $scope.withoutPro = null;
        }
        if (pro === 'without') {
          $scope.proOnly = null;
        }
      }
      $scope.onSelectPage(1);
    };

    $scope.trackingNameFilter = function() {
      $scope.onSelectPage(1);
    };

    $scope.tagNameFilter = function() {
      $scope.onSelectPage(1);
    };

  }

  window.app.controller('PreferredCtrl', ['$scope', '$location', '$stateParams', '$modal', 'TasksResource', 'WatchlistResource', 'AffiliatesResource', PreferredCtrl]);

})();
