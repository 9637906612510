import templateUrl from './issue-system.html'
import './issue-system.scss'


const SIDEBAR_ID = 'issue-system-folder-sidebar'
const SIDEBAR_BREAKPOINT = 'gt-sm'
const LS_ISSUE_SEARCH_TOGGLE = 'issues--search--toggle'
const IssueSystemComponent = {
  bindings: {},
  templateUrl,
  controller: class IssueSystem {
    constructor ($mdSidenav, $mdMedia, $scope, $state, $stateParams, Configuration, IssuesService, StorageService, LoadingService) {
      'ngInject'
      this._identify = 'IssueSystemComponent'
      this.$mdSidenav = $mdSidenav
      this.$state = $state
      this.$stateParams = $stateParams

      this.Configuration = Configuration
      this.IssuesService = IssuesService
      this.StorageService = StorageService
      this.LoadingService = LoadingService

      this.isSearchOpen = false

      this.isSidebarClosed = false
      this.isSidebarLocked = $mdMedia(SIDEBAR_BREAKPOINT)
      this.isSidebarOpen = this.isSidebarLocked

      $scope.$watch(() => {
        return $mdSidenav(SIDEBAR_ID).isLockedOpen()
      }, breakpoint => {
        this.isSidebarLocked = breakpoint

        if (breakpoint) {
          this.isSidebarClosed = false
        }
      })
    }

    $onInit () {
      // Subscription to issues global pusher channel is done in IssuesService.init that executes in app.component

      const _isSearchOpen = this.StorageService.get(LS_ISSUE_SEARCH_TOGGLE)
      if (typeof _isSearchOpen === 'undefined') {
        this.isSearchOpen = true
      } else {
        this.isSearchOpen = _isSearchOpen
      }
    }

    reloadCurrentState () {
      this.IssuesService.reloadIssueView()
    }

    toggleSidebar () {
      if (this.isSidebarLocked) {
        this.isSidebarClosed = !this.isSidebarClosed
      } else {
        this.$mdSidenav(SIDEBAR_ID).toggle()
      }
    }

    toggleSearch () {
      this.isSearchOpen = !this.isSearchOpen
      this.StorageService.set(LS_ISSUE_SEARCH_TOGGLE, this.isSearchOpen)
    }
  }
}

export default IssueSystemComponent
