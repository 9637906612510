import templateUrl from './action-refund.html'
import './action-refund.scss'

// import DialogWarning from '@/common/components/dialog-warning'

const ProjectActionRefundComponent = {
  bindings: {
    project: '<?',
    onAction: '<?'
  },
  templateUrl,
  controller: class ProjectActionRefund {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectActionRefundComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }

    get isActionAllowed () {
      return this.ProjectsService.isActionAllowed('refund', this.project)
    }

    // confirmAction () {
    //   this.isProcessing = true
    //   this.ProjectsService.refundProject(this.project.id)
    //     .then(response => {
    //       // TODO: the response should be updated project so FE could update the data for the rest of the application - currently BE does not return anything back
    //       this.ProjectsService.updateProject(this.project, {})
    //       this.ToastService.show('Project refunded')
    //     })
    //     .catch(err => {
    //       this.ToastService.show(err)
    //     })
    //     .finally(() => {
    //       this.isProcessing = false
    //     })
    // }

    openActionDialog () {
      if (this.onAction) {
        this.onAction()
      }
      // const vm = this
      // this.$mdDialog.show({
      //   ...DialogWarning,
      //   locals: {
      //     text: `Are you sure you want to refund project "${this.project.title}"?`,
      //     confirmCallback: function () {
      //       vm.confirmAction()
      //     }
      //   }
      // })
    }
  }
}

export default ProjectActionRefundComponent
