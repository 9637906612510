const CURRENCY_PREFIX = '$'
const CURRENCY_POSTFIX = ''
const PriceFormatFilter = ($filter) => {
  'ngInject'
  return function (amount, showPostfix = false) {
    // Handle undefined and null values
    if (typeof amount === 'undefined' || amount === null) {
      amount = 0
    }

    // Handle number values
    if (typeof amount === 'number') {
      const amountFormattedString = $filter('number')(amount, 2) // round it to 2 decimals
        .toString()            // convert to string
        .replace(/[.]00$/, '') // remove .00 decimals

      return `${CURRENCY_PREFIX}${amountFormattedString}${CURRENCY_POSTFIX}`
    }

    // Handle string values
    if (typeof amount === 'string') {
      const stringExceptions = ['not sure', 'flexible']
      if (stringExceptions.includes(amount)) {
        return amount
      }
      const amountFormattedString = amount.replace(/[.]00$/, '') // remove .00 decimals
      if (amountFormattedString.startsWith(CURRENCY_PREFIX)) {
        return `${amountFormattedString}${CURRENCY_POSTFIX}`
      }

      return `${CURRENCY_PREFIX}${amountFormattedString}${CURRENCY_POSTFIX}` // if its a string it already has $
    }

    // TODO: Handle range values

    // In all other cases return original value
    return amount
  }
}
export default PriceFormatFilter
