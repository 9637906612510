import templateUrl from './project-widget-expert.html'
import './project-widget-expert.scss'

import DialogProjectHireExpert from './dialog-project-hire-expert'
import DialogWarning from '@/common/components/dialog-warning'

const ACTION_UNHIRE = 'unhireExpert'
const ACTION_HIRE = 'hireExpert'
const ProjectWidgetExpertComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetExpert {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectWidgetExpertComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }

    $onInit () {
      this.isProcessing = false
    }

    unhireExpertPrompt () {
      const vm = this
      this.$mdDialog.show({
        ...DialogWarning,
        locals: {
          text: `Are you sure you want to unhire/remove expert "${this.project.contractor.fullName}" from the project? Project will be put into estimated state.`,
          confirmCallback: function () {
            vm.unhireExpert()
          }
        }
      })
    }

    get canUnhireExpert () {
      // TODO move check about contractor to service
      return this.project.contractor && this.ProjectsService.isActionAllowed(ACTION_UNHIRE, this.project)
    }

    get canHireExpert () {
      // TODO move check about contractor to service
      return this.ProjectsService.isActionAllowed(ACTION_HIRE, this.project)
    }

    unhireExpert () {
      // TODO: addd confirm dialog
      this.isProcessing = true
      this.ProjectsService.unhireExpertFromProject(this.project.id)
        .then(response => {
          // TODO: refetch project from BE and update it
          this.project.contractor = null
          this.project.state = 'estimated'

          this.ToastService.show('Expert was unhired. Project is now in estimated state.')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Action is not permitted')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    openHireExpertDialog () {
      // TODO: store dialog in variable and close it only after the expert is hired. Alternatively move hireExpert logic to dialog controller
      this.$mdDialog.show({
        ...DialogProjectHireExpert,
        locals: {
          project: this.project,
          hireExpert: this.hireExpert
        }
      })
    }


    hireExpert (expert) {
      this.isProcessing = true
      this.ProjectsService.hireExpertFromProject(this.project.id, expert.id)
        .then(response => {
          console.log(response)
          // TODO: refetch project from BE and update it
          this.project.contractor = response.expert
          this.project.state = 'hired'
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Action is not permitted')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default ProjectWidgetExpertComponent
