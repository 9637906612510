(function() {
  'use strict';

  function CouponsCtrl($scope, $location, $stateParams, $modalInstance, CouponsResource, client) {
		var original = client;

		$modalInstance.opened.then( function() {
      var requestObject = { };

      $scope.client = JSON.parse( JSON.stringify(client) );

      CouponsResource.getAdminCoupons().then(function (adminCoupons) {
        $scope.adminCoupons = adminCoupons;
      });
		});

    $scope.assignCoupon = function(client) {
      var coupon  = client.couponCode;
    };

    $scope.issueCoupon = function(client) {
      var response, coupon;

      coupon = client.couponCode;
      CouponsResource.issueAdminCoupon(coupon, client, this);
      if (coupon !== undefined) {
        original.coupon.code= coupon;
      }

      $scope.close();
    };

    $scope.close = function() {
      $modalInstance.close();
    };

    var handleSuccess = function() {
      console.log('CouponsCtrl#handleSuccess(): ', client.couponCode, original.coupon.code);
    };

    var handleError = function() {
      console.log('CouponsCtrl#handleError(): ', client.couponCode, original.coupon.code);
    };

    return {
      handleSuccess: handleSuccess,
      handleError: handleError,
    };
  }

  window.app.controller('CouponsCtrl', ['$scope', '$location', '$stateParams', '$modalInstance', 'CouponsResource', 'client', CouponsCtrl]);

})();
