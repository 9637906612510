(function() {
  'use strict';

  function TagsResource(Restangular, $http, Configuration) {
    var getTags = function() {
      return Restangular.all('admin').all('tags').get('');
    };

    var getTag = function(id) {
      return Restangular.all('admin').one('tags', id).get('');
    };

    /**
     *  getTagUsers
     *  tags @String: array of tags, $http will automatically convert it into encoded url query string.
     */
    var getTagUsers = function (tags = null, filterType = null) {
      // return Restangular.all('admin').all('tags').all('users').get('?tags=' + tags);
      const config = {
        params: {
          tags: tags,
          filterType: filterType
        }
      }
      return $http
        .get(`${Configuration.apiUrl}/admin/tags/users`, config)
        .then(response => response.data)
    };

    var addTag = function(tag) {
      return Restangular.all('admin').all('tags').post({
        name: tag.name
      });
    };

    //Send message without promotions
    var sendMessageSimple = function(taskId, userIds, subject, text, adminEmail, mode) {
      return Restangular.all('admin').all('intercom').all('messages').post({
        taskId: taskId,
        userIds: userIds,
        subject: subject,
        text: text,
        adminEmail: adminEmail,
        mode: mode
      });
    };
    var deleteTag = function(id) {
      return Restangular.all('admin').one('tags', id).remove();
    };

    var getNewUsers = function(months) {
      return Restangular.all('admin').all('contractors').one('new', months).getList();
    };

    var getProUsers = function() {
      return Restangular.all('admin').all('contractors').all('pro').getList();
    };

    return {
      getTags: getTags,
      getTag: getTag,
      getTagUsers: getTagUsers,
      sendMessageSimple: sendMessageSimple,
      addTag: addTag,
      deleteTag: deleteTag,
      getNewUsers: getNewUsers,
      getProUsers: getProUsers
    };
  }

  window.app.service('TagsResource', ['Restangular', '$http', 'Configuration', TagsResource]);
})();
