import IssueSystemComponent from './issue-system.component'
import IssuesService from './issue-system.service'

import ButtonCreateIssueComponent from './button-create-issue'

import FolderListModule from './folder-list'
import IssueListModule from './issue-list'
import IssueViewModule from './issue-view'
import IssueToolbarSearchModule from './issue-toolbar-search'
import IssueListWidgetModule from './issue-list-widget'

const IssueSystemModule = angular.module('cdbl.issue-system', [
  'ui.router',
  FolderListModule,

  IssueListModule,
  IssueViewModule,
  IssueToolbarSearchModule,
  IssueListWidgetModule
])

  .component('cdblIssueSystem', IssueSystemComponent)
  .component('cdblIssueSystemCreateButton', ButtonCreateIssueComponent)
  .service('IssuesService', IssuesService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'issues',
        url: '/issues',
        component: 'cdblIssueSystem',
        data: { requiresAuth: true },
        deepStateRedirect: {
          default: { state: 'issueList' }
        }
      })
  })
  .name

export default IssueSystemModule
