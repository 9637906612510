import templateUrl from './hal-icon.html'
import './hal-icon.scss'

import imgHal from './hal_9000.png'
import imgHalDeactivated from './hal_9000_deactivated.png'
import soundHal from './hal_9000_operation.mp3'

const HalIconComponent = {
  bindings: {},
  templateUrl,
  controller: class HalIcon {
    constructor ($timeout) {
      'ngInject'
      this._identify = 'HalIconComponent'

      this.icon = imgHalDeactivated
      this.audio = new Audio(soundHal)
      this.isActive = false

      this.endedHandler = () => {
        $timeout(() => {
          this.audio.currentTime = 0
          this.icon = imgHalDeactivated
          this.isActive = false
          console.log('hal ended')
        })
      }
      this.audio.addEventListener('ended', this.endedHandler)
    }

    $onDestroy () {
      this.audio.removeEventListener('ended', this.endedHandler)
    }

    playSound () {
      this.icon = imgHal
      this.isActive = true
      this.audio.play()
    }
  }
}

export default HalIconComponent
