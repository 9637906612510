const EmailTokensValidatorDirective = function (Configuration, TemplatesIntercomService) {
  'ngInject'


  return {
    restrict: 'A',
    require: 'ngModel',
    // scope: {
    //   onEmailtokensErrors: '&?'
    // },

    // For pre/post DOM manipulation/events
    link ($scope, $element, $attrs, ngModelCtrl) {
      console.log('[EmailTokensValidatorDirective] > link', $attrs.emailtokens)
      ngModelCtrl.$validators.emailTokens = (modelValue, viewValue) => {
        const value = modelValue || viewValue
        const result = TemplatesIntercomService.validateText(value)

        // if ($scope.onEmailtokensErrors) {
        //   let validatorData = {
        //     validatorData: {
        //       fieldName: $attrs.emailtokens !== '' ? $attrs.emailtokens : null,
        //       errors: result.valid ? null : result
        //     }
        //   }

        //   $scope.onEmailtokensErrors( validatorData )
        // }

        // Set private variable on form field - use this to display messages with validator data
        ngModelCtrl._cdblTokenValidator = result

        return result.valid
      }
    }
  }
}

export default EmailTokensValidatorDirective
