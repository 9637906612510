const ExpertPodsService = class ExpertPods {
  constructor ($http, Configuration, PermissionsService) {
    'ngInject'
    this._identify = 'ExpertPods'
    this.$http = $http
    this.Configuration = Configuration
    this.PermissionsService = PermissionsService
  }

  get canEdit () {
    return this.PermissionsService.canEdit('expertPods')
  }

  getExpertPodsList (params = {}) {
    // GET /admin/expert_pods?page=1 // page is optinoal(per_page is also available), when not specified all returned
    const config = {
      params
    }

    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/expert_pods`, config)
      // .then(response => response.data)
  }

  getExpertPod (id, params = {}) {
    // GET /admin/expert_pods?page=1 // page is optinoal(per_page is also available), when not specified all returned
    const config = {
      params
    }

    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/expert_pods/${id}`, config)
      .then(response => response.data)
  }

  bulkUpdate (id, data = {}) {
    const propName = data.importType
    const payload = {}
    payload[propName] = data.bulkText
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/expert_pods/${id}/expert_pods_members/bulk`, payload)
      .then(response => response.data)
  }


  createExpertPod (payload) {
    console.log('🚀 ~ file: expert-pods.service.js:48 ~ ExpertPods ~ createExpertPod ~ data:', payload)
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/expert_pods`, payload)
      .then(response => response.data)
  }

  updateExpertPod (id, payload = {}) {
    return this.$http
      .patch(`${this.Configuration.apiUrl}/admin/expert_pods/${id}`, payload)
      .then(response => response.data)
  }

  activateExpertPod (podId) {
    console.log('🚀 ~ ExpertPods ~ activateExpertPod ~ podId:', podId)
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/expert_pods/${podId}/activation`)
      .then(response => response.data)
  }

  deactivateExpertPod (podId) {
    console.log('🚀 ~ ExpertPods ~ deactivateExpertPod ~ podId:', podId)
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/expert_pods/${podId}/activation`)
      .then(response => response.data)
  }

  addExpertPodBot (podId, payload = {}) {
    console.log('🚀 ~ ExpertPods ~ addExpertPodBot ~ podId:', podId)

    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/pod_bots`, payload)
      .then(response => response.data)
  }

  removeExpertPodBot (podBot) {
    console.log('🚀 ~ ExpertPods ~ removeExpertPodBot ~ podBotId:', podBot.idd)

    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/pod_bots/${podBot.id}`)
      .then(response => response.data)
  }

  getEligibleExperts (id, params = {}) {
    const config = {
      params
    }

    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/expert_pods/${id}/expert_pods_eligible_experts`, config)
      .then(response => response.data)

    // res All eligible(active) experts

    // [
    //   {
    //     "id": 1,
    //     "email": 1,
    //     "full_name": "Full Name",
    //     "avatar": {
    //       "tiny_url": "URL",
    //       "small_url": "URL",
    //       "medium_url": "URL",
    //       "large_url": "URL"
    //     },
    //   }
    // ]
  }

  addMembership (podId, expertId) {
    const payload = {
      memberId: expertId
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/expert_pods/${podId}/expert_pods_members`, payload)
      .then(response => response.data)
  }

  removeMembership (podId, membershipId) {
    // DELETE /admin/expert_pods/123/expert_pods_members/234

    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/expert_pods/${podId}/expert_pods_members/${membershipId}`)
      .then(response => response.data)
  }


  assignExpertPod (projectId, podId) {
    const payload = {
      expertPodId: podId
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/expert_pods_projects`, payload)
      .then(response => response.data)
  }

  unassignExpertPod (projectId, podId) {
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/expert_pods_projects/${podId}`)
      .then(response => response.data)
  }



  // expert pod structure returned on project
  // {
  //   "id": 1,
  //   // ...
  //   "expert_pods_projects": [
  //     {
  //       "id": 222,
  //       "expert_pod": {
  //         "id": 111,
  //         "partners": [{"code": "abc"}],
  //         "name": "PMs",
  //         "created_at": "timestamp",
  //         "updated_at": "timestamp",
  //       },
  //       "auto_assigned": false,
  //       "created_at": "timestamp",
  //       "updated_at": "timestamp"
  //     }
  //   ]
  // }
  //




  // get list of projects with pods
  // GET admin/tasks?with_expert_pods=true&page=1
  //
  // req/res are the same
}

export default ExpertPodsService
