const TokenValidatorDirective = function (Configuration, TokenService) {
  'ngInject'

  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      cdblTokenValidator: '<'
    },
    // For pre/post DOM manipulation/events
    link ($scope, $element, $attrs, ngModelCtrl) {
      console.log('[TokenValidatorDirective] > link', $scope.cdblTokenValidator)
      ngModelCtrl.$validators.cdblTokenValidator = (modelValue, viewValue) => {
        const value = modelValue || viewValue
        const result = TokenService.validate(value, $scope.cdblTokenValidator)

        // Set private variable on form field - use this to display messages with validator data
        ngModelCtrl._cdblTokenValidator = result

        return result.valid
      }
    }
  }
}

export default TokenValidatorDirective
