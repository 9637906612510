(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('withdrawals', {
      url: '/withdrawals/:status',
      params: {
        status: {
          value: 'pending', // default value
        },
      },
      templateUrl: '/app/scripts/withdrawalRequests/withdrawals.html',
      controller: 'WithdrawalsCtrl'
    })
  }

  window.app.config(['$stateProvider', states]);
})();
