(function() {
  'use strict';
  /*globals $, ContractorStatsResource, $filter, _, ngTableParams*/
  /*jshint newcap:false*/

  function ApplicationCtrl($scope, $state, $templateCache, Auth, TagsResource, Configuration) {

    $scope.$watch(Auth.me, function(val) {
      $scope.currentUser = val;

      if (!val) {
        $state.go(Configuration.navigation.loginPage.sref);
      } else {
        $scope.loadTags();
      }
    }, true);

    $scope.isSignedIn = Auth.isSignedIn;
    $scope.signOut = Auth.signOut;

    $scope.search = function(q) {
      var lower = q.toLowerCase();
      var term = '';
      var extractId = function(str) {
        return str.replace(/ /g,'').match(/\d+/g) || false;
      };
      if (lower.indexOf('task:') > -1 && !!extractId(q)) {
        $state.go('tasks.display', {id: extractId(q)});
      } else if (lower.indexOf('user:') > -1 && !!extractId(q)) {
        $state.go('users.display', {id: extractId(q)});
      } else if (lower.indexOf('utm:') > -1) {
        term = lower.replace(/task:/, '').replace(/user:/, '').replace(/utm:/, '');
        $state.go('search.tasks', {q: $.trim(term), searchType: 'utm'});
      } else {
        term = lower.replace(/task:/, '').replace(/user:/, '');
        $state.go('search.users',{q: $.trim(term), searchType: 'users'});
      }
    };

    $scope.loadTags = function() {
      TagsResource.getTags().then(function(data) {
        $scope.tags = data;
      }).catch(err => { console.log(err) });
    };

    $scope.loadAllUsers = function() {
      ContractorStatsResource.getAllContractorStats().then(function(contractors) {
        contractors.forEach(function(contractor) {
          var tasksDone = 0;

          if (_.where($scope.task.sameAffiliateTasksSolvedBy, { id: contractor.user.id }).length !== 0) {
            tasksDone = _.where($scope.task.sameAffiliateTasksSolvedBy, { id: contractor.user.id })[0].tasksDone;
          }

          $scope.filteredUsers.push({
            user: contractor.user,
            projectedRevenue: parseFloat(contractor.projectedRevenue),
            revenueTier: contractor.revenueTier,
            cancelRate: parseFloat(contractor.cancelRate),
            cancelRateTier: contractor.cancelRateTier,
            refundRate: parseFloat(contractor.refundRate),
            refundRateTier: contractor.refundRateTier,
            projectedCompletedTasksCount: parseInt(contractor.projectedCompletedTasksCount),
            estimatedToPaidConRate: parseFloat(contractor.estimatedToPaidConRate),
            calculatedRating: parseFloat(contractor.calculatedRating),
            daysCalculationIsBasedOn: parseInt(contractor.daysCalculationIsBasedOn),
            manualRatingCorrection: contractor.manualRatingCorrection ? parseInt(contractor.manualRatingCorrection) : '',
            tasksDone: tasksDone
          });
        });

        $scope.tableParams = new ngTableParams(
          {
            count: 200,          // count per page
            sorting: {
              projectedRevenue: 'desc'     // initial sorting
            }
          },
          {
            total: $scope.data.length, // length of data
            getData: function($defer, params) {

              var orderedData = params.sorting() ? $filter('orderBy')($scope.filteredUsers, params.orderBy()) : $scope.filteredUsers;

              $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
            }
          }
        );
      });
    };

    $scope.flaggedCount = 0;

  }

  console.log('[ApplicationCtrl.js] > window.app', window.app)

  window.app.controller('ApplicationCtrl', ['$scope', '$state', '$templateCache', 'Auth', 'TagsResource',  'Configuration', ApplicationCtrl]);
})();
