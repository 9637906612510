// import ReferralAttributionsService from './referral-attributions.service'
// .service('ReferralAttributionsService', ReferralAttributionsService)

const ReferralAttributionsService = class ReferralAttributionsService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'ReferralAttributionsService'

    this.$http = $http
    this.Configuration = Configuration
    this.apiUrlBase = Configuration.apiUrl
  }

  verifyShortcode (client, shortcode) {
    console.log('[ReferralAttributionsService] > verifyShortcode', shortcode)

    return this.$http
      .get(this.partnerInfoURL(client, shortcode))
      .then(response => {
        console.log('[ReferralAttributionsService] > verifyShortcode > response', response)
        return response
      }, err => {
        console.error('[ReferralAttributionsService] > verifyShortcode> err', err)
        throw err
      })
  }

  createReferral (client, shortcode) {
    console.log('[ReferralAttributionsService] > createReferral:', shortcode)

    const payload = { id: client.id, shortcode: shortcode }
    return this.$http
      .post(this.createReferralURL(client, shortcode), payload)
      .then(response => {
        console.log('[ReferralAttributionsService] > createReferral > response', response)
        return response
      }, err => {
        console.error('[ReferralAttributionsService] > createReferral> err', err)
        throw err
      })
  }

  partnerInfoURL (client, shortcode) {
    console.log('[ReferralAttributionsService] > partnerInfoURL', shortcode)

    let url = `${this.apiUrlBase}/admin/partners?shortcode=${shortcode}`

    return url
  }

  createReferralURL (client, shortcode) {
    console.log('[ReferralAttributionsService] > createReferralURL', shortcode)

    let url = `${this.apiUrlBase}/admin/referrals`

    return url
  }

  getReferralProjects (clientId) {
    return this.$http
      .get(`${this.apiUrlBase}/admin/referrals/${clientId}/projects`)
      .then(response => {
        return response
      }, err => {
        throw err
      })
  }

  attributeToPastProjects (clientId, projects) {
    const payload = { id: clientId, projectIds: projects }
    return this.$http
      .post(`${this.apiUrlBase}/admin/referrals/${clientId}/projects`, payload)
      .then(response => {
        return response
      }, err => {
        throw err
      })
  }
}

export default ReferralAttributionsService
