(function() {
  'use strict';

  function UsersCtrl($scope, $location, $stateParams, usersResource) {

    $scope.onSelectPage = function(page) {
      usersResource.getUsers({role: $stateParams.role, page: page}).then(function(users) {
        $scope.users = users;
        $scope.showNotes = true;

        if ($stateParams.role === 'vip_client') {
          $scope.users = [];

          users.forEach(function(user) {
            $scope.users.push({
              id: user.id,
              fullName: user.firstName + ' ' + user.lastName,
              email: user.email,
              completedTasksCount: user.count
            });
          });

          $scope.showNotes = false;
        }

        // Pagination
				$scope.totalItems = users.headers('X-Total-Count');
				$scope.itemsPerPage = 20;
				$scope.currentPage = page.toString();
				$scope.numPages = users.headers('X-Page-Count');
				$scope.maxSize = 5;
      });
    };

		$scope.onSelectPage('1');

    $scope.isClient = function(user) {
      var isClient = false;

      if (user !== undefined) {
        isClient = (user.role === 'client');
      }

      return isClient;
    };

  }

  window.app.controller('UsersCtrl', ['$scope', '$location', '$stateParams', 'UsersResource', UsersCtrl]);

})();

// They <!-- html comment -->
