import templateUrl from './client-enrichment-data.dialog.html'

const ClientEnrichmentDataDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: true,
  clickOutsideToClose: true,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class ClientEnrichmentDataDialog {
    constructor ($mdDialog, $mdToast, Configuration) {
      'ngInject'
      this._identify = 'ClientEnrichmentDataDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration

      this.client = null
      this.enrichmentData = null
      this.person = null
      this.company = null
    }

    $onInit () {
      console.log('[ClientEnrichmentDataDialog] > $onInit()')
      console.log('-- client: ', this.client)
      console.log('-- enrichmentData: ', this.enrichmentData)

      this.person = this.enrichmentData.person
      this.company = this.enrichmentData.company
    }

    $onDestroy () {
      console.log('[ClientEnrichmentDataDialog] > onDestroy')
    }
  }
}

export default ClientEnrichmentDataDialog
