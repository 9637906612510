(function() {
  'use strict';

  function RestangularizeElements(Restangular) {
    var restangularizeComment = function(comment) {
      if (!comment || !comment.taskId) {
        return;
      }

      return Restangular.restangularizeElement(null, comment, 'admin/tasks/' + comment.taskId  + '/comments');
    };

    return {
      restangularizeComment: restangularizeComment
    };
  }

  app.service('RestangularizeElements', ['Restangular', RestangularizeElements]);
})();
