import TemplatesCommentComponent from './templates-comment.component'
import TemplatesCommentService from './templates-comment.service'

const TemplatesCommentModule = angular.module('cdbl.settings.templates-comment', [
  // 'ui.router'
])
  .component('cdblSettingsTemplatesComment', TemplatesCommentComponent)
  .service('TemplatesCommentService', TemplatesCommentService)

  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
      // parent: '',
        name: 'settings-templates-comment',
        url: '/settings/templates-comment/:templateId',
        params: {
          templateId: { squash: true, value: null, dynamic: true }
        },
        component: 'cdblSettingsTemplatesComment'
      })
  })
  .name

export default TemplatesCommentModule
