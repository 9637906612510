import templateUrl from './resource-duplicate-clients.html'
import './resource-duplicate-clients.scss'

const IssueResourceDuplicateClientsComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueResourceDuplicateClients {
    constructor (Configuration, IssueViewService, PusherService) {
      'ngInject'
      this._identify = 'IssueResourceDuplicateClientsComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService
      this.PusherService = PusherService

      this.clients = null
    }

    $onInit () {
      this.fetchResourceDuplicateClients()
      this.subscribeToIssueResource()
    }

    $onDestroy () {
      this.unsubscribeToIssueResource()
    }

    subscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.bind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    unsubscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.unbind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    fetchResourceDuplicateClients () {
      this.clients = null
      this.IssueViewService.getResourceClients(this.issue.id)
        .then(response => {
          this.clients = response
        }, error => {
          console.log(error)
        })
    }
  }
}

export default IssueResourceDuplicateClientsComponent
