import templateUrl from './widget.html'
import './widget.scss'

const WidgetComponent = {
  bindings: {
    isProcessing: '<?',
    collapsible: '<?'
  },
  templateUrl,
  transclude: {
    'title': '?widgetTitle',
    'content': '?widgetContent',
    'headerActions': '?widgetHeaderActions',
    'actions': '?widgetActions'
  },
  controller: class Widget {
    constructor () {
      'ngInject'
      this._identify = 'WidgetComponent'
      this.isCollapsed = false
    }

    $onInit () {
      // this.classList = {}
      console.log('[WidgetComponent] > this', this)
    }

    get classList () {
      return {
        'widget--collapsed': this.isCollapsed
      }
    }

    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed
    }
  }
}

export default WidgetComponent
