(function() {
  'use strict';

  function titleCase() {
    return function(text) {
      var words = text.split('_');
      for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
      return words.join(' ');
    };
  }

  app.filter('titleCase', titleCase);
})();
