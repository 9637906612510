import templateUrl from './project-widget-scope-doc.html'
import './project-widget-scope-doc.scss'

import DialogProjectScopeDoc from './dialog-project-scope-doc'

const ProjectWidgetScopeDocComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetScopeDoc {
    constructor ($mdDialog, ToastService, ProjectScopeDocService) {
      'ngInject'
      this._identify = 'ProjectWidgetScopeDocComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectScopeDocService = ProjectScopeDocService
    }

    $onInit () {
      // load scope doc
      this.scopeDoc = null
      this.isProcessing = false

      if (this.isEligible) {
        this.isProcessing = true
        this.ProjectScopeDocService.getScopeDoc(this.project.id)
          .then(response => {
            this.scopeDoc = response
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }

    get isEligible () {
      return this.project && this.project.eligibleForScopeDoc
    }

    get canDeleteScopeDoc () {
      return this.project && this.project.state === 'paid' && this.scopeDoc
    }

    get scopeDocProgressPercent () {
      return this.ProjectScopeDocService.scopeDocProgress('percent', this.scopeDoc)
    }

    get scopeDocProgressCount () {
      return this.ProjectScopeDocService.scopeDocProgress('count', this.scopeDoc)
    }

    deletePrompt () {
      this.$mdDialog
        .show(this.$mdDialog.confirm({
          title: `Delete Scope Doc`,
          textContent: `Are you sure you want to delete scope doc? This operation is irreversable!`,
          ok: 'Yes, DELETE',
          cancel: 'Cancel',
          theme: 'warn'
        }))
        .then(dialogResponse => {
          this.deleteScopeDoc()
        }, dialogResponse => {

        })
    }

    deleteScopeDoc () {
      this.isDeleteInProgress = true
      this.ProjectScopeDocService.deleteScopeDoc(this.project.id)
        .then(response => {
          this.scopeDoc = null
          this.ToastService.show('Scope doc deleted')
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isDeleteInProgress = false
        })
    }

    openViewModal () {
      this.$mdDialog
        .show({
          ...DialogProjectScopeDoc,
          locals: {
            project: this.project,
            scopeDoc: this.scopeDoc
          }
        })
        .then(response => {
          this.ToastService.show('KABOOM')
        })
        .catch(reject => {
          console.log(reject)
        })
    }
  }
}

export default ProjectWidgetScopeDocComponent
