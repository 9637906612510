import templateUrl from './growth-targets.dialog.html'

const GrowthTargetDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class GrowthTargetDialog {
    constructor ($mdDialog, $mdToast, Configuration, GrowthTargetsService, $filter, $mdDateLocale) {
      'ngInject'
      this._identify = 'GrowthTargetDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.GrowthTargetsService = GrowthTargetsService
      this.$filter = $filter
      this.$mdDateLocale = $mdDateLocale
    }

    $onInit () {
      this.originDate = this.item.date
      this.item.date = this.item.date ? new Date(this.item.date) : ''
    }

    saveGrowthTarget () {
      this.isSaving = true
      this.GrowthTargetsService.saveGrowthTarget(this.item, this.originDate)
        .then(response => {
          console.log('[GrowthTargetDialog] > save > response', response)
          this.$mdDialog.hide(response.data)
          this.isSaving = false
        }, error => {
          this.isSaving = false
          const errorMessage = (error.data && error.data.errors[0].message) ||
            'Error occured while saving the form'

          console.log('[GrowthTargetDialog] > save > error', error)
          this.$mdToast.showSimple(errorMessage)
        })
        .then(() => {
          this.GrowthTargetsService.getMonthlyTargets()
        })
    }
  }
}

export default GrowthTargetDialog
