import templateUrl from './project-widget-description.html'
import './project-widget-description.scss'

import DialogProjectEditTitleDescription from '../dialog-project-edit-title-description'

const ProjectWidgetDescriptionComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetDescription {
    constructor ($mdDialog) {
      'ngInject'
      this._identify = 'ProjectWidgetDescriptionComponent'
      this.$mdDialog = $mdDialog
    }

    openEditDialog () {
      this.$mdDialog.show({
        ...DialogProjectEditTitleDescription,
        locals: {
          project: this.project
        }
      })
    }
  }
}

export default ProjectWidgetDescriptionComponent
