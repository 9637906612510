import templateUrl from './dashboard.html'
import './dashboard.scss'

const DashboardComponent = {
  bindings: {},
  templateUrl,
  controller: class Dashboard {
    constructor ($state) {
      'ngInject'

      this._identify = 'DashboardComponent'
      this.$state = $state
    }

    $onInit () {

    }

    isStateActive (glob) {
      return this.$state.includes(glob)
    }
  }
}

export default DashboardComponent
