import ActionQualityAssessmentComponent from './action-quality-assessment.component'
import ActionQualityAssessmentService from './action-quality-assessment.service'

const ActionQualityAssessmentModule = angular.module('cdbl.actions.qualityassessment', [
  'ui.router',
])
  .component('cdblActionQualityAssessment', ActionQualityAssessmentComponent)
  .service('ActionQualityAssessmentService', ActionQualityAssessmentService)
  .name

export default ActionQualityAssessmentModule
