const ActionTaskPostCommentService = class ActionTaskPostCommentService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'ActionTaskPostCommentService'
    this.$http = $http
    this.Configuration = Configuration
  }

  getTemplates (config) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/comment_templates`)
      .then(response => {
        return response.data
      })
  }

  postComment (task, payload) {
    console.log('[ActionTaskPostCommentService] > postComment', task)
    const config = {
      method: 'post',
      url: `${this.Configuration.apiUrl}/admin/tasks/${task.id}/comments`,
      data: payload
    }

    if (task.actions && task.actions.postComment) {
      config.method = task.actions.postComment.verb
      config.url = `${this.Configuration.apiUrl}${task.actions.postComment.url}`
    }

    return this.$http(config)
      .then(response => {
        return response.data
      })
  }
}
export default ActionTaskPostCommentService
