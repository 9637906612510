import { WorkroomInterventionsComponent } from '@/components/dialogs/workroom-interventions'

(function() {

  'use strict';
  /*globals alert, confirm*/
  function TaskCtrl($scope, $stateParams, $location, $anchorScroll, $modal, $mdDialog, Restangular, taskResource, TasksResource, WatchlistResource, TagsResource, ReviewsResource, _, SubTasksResource, UserService, ProjectsService, AuthService, Configuration, StorageService, PermissionsService) {
    $scope.otherComment = {};
    $scope.PQotherComment = {};
    $scope.feedback = [];
    $scope.loading = true;
    $scope.data = {};
    $scope.selectedTags = [];
    $scope.selectedUsers = [];
    $scope.notSelected = true;
    $scope.PQnotSelected = true;
    $scope.submitedCsFailFactorFeedback = false;
    $scope.submitedProjectQuality = false;
    $scope.submitedCsFailFactorFeedbackDone = false;
    $scope.submitedProjectQualityDone = false;

    $scope.review = {};
    $scope.$mdDialog = $mdDialog;

    $scope.ProjectsService = ProjectsService;

    $scope.canRevert = PermissionsService.canCreate('revert-sub-tasks')
    $scope.canOfflinePayments = PermissionsService.canCreate('offline-payments')

    // -----
    // New UI management
    // TODO: add localstorage setting
    $scope.enableNewUI = true // !Configuration.isBuild && Configuration.CDBL_ENV === 'develop' && AuthService.isAuthenticated && AuthService.user.id === 13

    $scope.showNewUI = $scope.enableNewUI && !StorageService.get('projectPageOldUI')

    // Temporary bridge to handle functionality between new and old UI
    $scope.legacyBridge = {
      toggleNewUI: function () {
        $scope.showNewUI = !$scope.showNewUI
        console.log('toggleNewUI', $scope.showNewUI)
        StorageService.set('projectPageOldUI', !$scope.showNewUI)
      },
      openProjectRefundModal: function () {
        console.log('openProjectRefundModal')
        $scope.openTaskRefundModal()
      }
    }
    // -----



    // A quick hack to enable auto tab change to subtasks and mark selected subtask for CS to see
    if ($stateParams.tab) {
      console.log('$stateParams', $stateParams);
      $scope.autoSelectTab = true;
      $scope.activeTab = $stateParams.tab || 'subtasks';

      $scope.activeSubtask = Number($stateParams.subTaskId);
      $location.search('subTaskId', null);
      $location.search('tab', null);

      console.log('TaskCtrl >', $scope.autoSelectTab, $scope.activeTab, $scope.activeSubtask);
    }

    $scope.selectTab = function (tabId, callback) {
      $scope.activeTab = tabId;
      // $scope.autoSelectTab = false;
      console.log('activeTab', tabId);

      if (callback) {
        callback();
      }
    }
    //

    $scope.display = {
      loadedUsers: false,
    };

    $scope.onSelect = function (answer, outerIndex) {
      $scope.notSelected = false;
      if (answer.text === 'Other' || answer.inputType === 'text') {
        $scope.otherComment[outerIndex] = true;
      } else {
        $scope.otherComment[outerIndex] = false;
      }
    };

    $scope.onFeedbackPosted = function () {
      $scope.submitedCsFailFactorFeedbackDone = true;
    };

    $scope.onSelectProjectQuality = function (answer, outerIndex) {
      $scope.PQnotSelected = false;
      if (answer.inputType === 'text') {
        $scope.PQotherComment[outerIndex] = true;
      } else {
        $scope.PQotherComment[outerIndex] = false;
      }
    };

    $scope.onFeedbackPosted = function () {
      $scope.submitedCsFailFactorFeedbackDone = true;
    };

    $scope.PQonFeedbackPosted = function () {
      $scope.submitedProjectQualityDone = true;
    };

    taskResource.task().then(function(task) {
      $scope.task = task;
      $scope.loading = false;
      $scope.tagsFound = [];
      $scope.canceledTask = ($scope.task.state === 'canceled');

      if ($scope.task.description) { // consultation doesn't have description
        $scope.task.description = $scope.task.description.escape();
      }

      if ($scope.task.state !== 'hired') {
        $scope.task.paid = false;
      }

      $scope.task.markPaidOption = 'wire';

      $scope.message = {
        subject: 'Suggested task',
        text: 'Hi {{first_name | fallback:"there"}},\n\nWe have... a task you might be interested in: <a href="https://app.codeable.io/tasks/' + task.id + '">https://app.codeable.io/tasks/' + task.id + '</a>\nThe client has reached out to us and seems eager to start.\n\nBest Regards,\nPer'
      };

      $scope.hasPreferredContractors = $scope.task.preferredContractors.length !== 0;

      $scope.isPublished = false;
      if ($scope.task.publishedAt && !$scope.task.staysPreferred) {
        $scope.isPublished = new Date(parseInt($scope.task.publishedAt + '000')) < new Date();
      }

      $scope.task.tags.forEach(function (tag)
      {
        $scope.tagsFound.push(tag.name);
        $scope.selectedTags.push(tag.name);
      });

      $scope.task.title = $scope.task.title.escape();
    }).catch(err => {
      $scope.loading = false
    });

    $scope.load = function(resourceName) {
      taskResource[resourceName]().then(function(data) {
        $scope[resourceName] = data;
        $scope.data[resourceName] = data;
      });
    };

    $scope.load('commentTemplates');

    $scope.comment = {
      text: ''
    };

    $scope.intercomComment = {
      text: '',
      subject: ''
    };

		// Refunds Modals
    $scope.openTaskRefundModal = function() {
      var modalInstance = $modal.open({
        templateUrl: '/app/scripts/refunds/refundTask.html',
        controller: 'RefundsTasksCtrl',
        resolve: {
          task: function() {
            return $scope.task;
          }
        }
      });

      modalInstance.result.then(function() {
        // modal opened successfully
      }).catch(function() {
        // modal was closed
      });
    };

    $scope.openSubTaskRefundModal = function(task, subTask) {
      var modalInstance = $modal.open({
        templateUrl: '/app/scripts/refunds/refundSubTask.html',
        controller: 'RefundsSubTasksCtrl',
        resolve: {
          task: function() {
            return task;
          },
          subTask: function() {
            return subTask;
          }
        }
      });

      modalInstance.result.then(function() {
        // modal opened successfully
      }).catch(function() {
        // modal was closed
      });
    };

    // Task cancelation
    $scope.confirmCancel = function() {
      $scope.task.one('cancel').put().then(function() {
        $scope.task.cancelling = undefined;
        $scope.task.cancelled = true;
      });
    };

    // Task abandon
    $scope.confirmAbandon = function() {
      $scope.task.one('abandon').put().then(function() {
        $scope.task.abandoning = undefined;
        $scope.task.state = 'abandoned';
      });
    };
    // Sub-task abandon
    $scope.confirmAbandonSubTask = function(subtask, task) {
      console.log(subtask);
      SubTasksResource.abandonSubTask(task.id, subtask.id).then(function(data) {
        subtask.state = 'abandoned';
        console.log(data);
      });

    };

    // Sub-task cancellation
    $scope.confirmCancelSubTask = function(subtask, project) {
      console.log(subtask);
      SubTasksResource.cancelSubTask(subtask.id).then(function(data) {
        subtask.state = 'cancelled';
        console.log(data);
      });

    };
    var errorModal;
    // Task revert
    $scope.confirmRevert = function() {
      $scope.task.one('revert_to_previous_state').put().then(function(task) {
        $scope.task.reverting = false;
        $scope.task.state = task.state;
      }).catch(function(err) {
        errorModal = $modal.open({
          templateUrl: '/app/scripts/modals/error.html',
          controller: 'ErrorCtrl',
          resolve: {
            error: function() {
              return { message: err.data.error || err.data.errors[0].message };
            }
          }
        });
      });
    };

    // Task mark as paid
    $scope.markPaid = function(paymentType) {
      $scope.task.one('mark_as_paid').customPUT({
        paymentType: paymentType,
        taskType: 'task'
      }).then(function() {
        $scope.task.paying = undefined;
        $scope.task.state = 'paid';
      });
    };
    $scope.markPaidOffline = function(paymentType) {
      console.log(paymentType);
      $scope.task.one('mark_as_paid').customPUT({
        paymentType: paymentType,
        taskType: 'task',
        real_payment: true
      }).then(function() {
        $scope.task.paying = undefined;
        $scope.task.state = 'paid';
      });
    };

    // Publishing a preferred Task for all Contractors before the 24-hr period is over
    $scope.confirmPublishPreferred = function() {
      $scope.task.one('publish_preferred_task').put().then(function() {
        $scope.task.publishing = undefined;
        $scope.hasPreferredContractors = false;
      });
    };

    // Task completion
    $scope.confirmComplete = function() {
      $scope.task.one('complete').put().then(function() {
        $scope.task.completing = undefined;
        $scope.task.completed = true;
      });
    };

    // Remove contractor from task
    $scope.confirmRemoveContractor = function() {
      $scope.task.one('remove_contractor').put()
        .then(response => {
          $scope.task.removingContractor = undefined
          $scope.task.removedContractor = true
        })
        .catch(err => {
          $mdDialog.show(
            $mdDialog.alert()
              .clickOutsideToClose(true)
              .theme('warn')
              .title('Action is not permitted')
              .textContent(err.data.message)
              .ok('Close')
          )
        })
    }

    $scope.watch = function(task) { // TODO refactor into directive
      if (!task.watchlist) {
        WatchlistResource.addWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = true;
        });
      } else {
        WatchlistResource.removeWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = false;
        });
      }
    };

    // Switch pro for published task
    $scope.proSwitch = function(task) {
      $scope.task.one('switch_pro').customPUT({
          pro: !task.pro
      }).then(function() {
        task.pro = !task.pro;
      });
    };

    $scope.taskKinds = ['theme', 'plugin', 'install', 'consultation', 'project', 'uncategorized'];

    $scope.updateTask = function() {
      $scope.task.put().then(function(response) {
        $scope.editingKind = undefined;
        $scope.editingBasePrice = undefined;
        $scope.editingTitle = undefined;
        $scope.editingDescription = undefined;
        $scope.editingUrgency = undefined;

        $scope.task = response;
      });
    };

    $scope.updateTaskPrice = function(price) {
      if (price === '' || price === '0' || price === '0.00') {
        alert('Price can\'t be empty');
      } else {
        $scope.task.price = price;
        delete $scope.task.staysPreferred;
        $scope.updateTask();
      }
    };

    $scope.updateTaskTitle = function(title) {
      if (title === '') {
        alert('Title can\'t be empty');
      } else {
        $scope.task.title = title;
        $scope.updateTask();
      }
    };

    $scope.updateTaskDescription = function(description) {
      if (description === '') {
        alert('Description can\'t be empty');
      } else {
        $scope.task.description = description;
        $scope.updateTask();
      }
    };

    $scope.updateTaskDeadline = function(urgency) {
      $scope.task.urgency = urgency;
      $scope.updateTask();
    };
    $scope.updatePreffered = function(preffered) {
      if(preffered === 'true'){
        $scope.task.staysPreferred = true;
      }else{
        $scope.task.staysPreferred = false;
      }
      $scope.editingPreffered = false;
      $scope.updateTask();
    };
    $scope.updateClientFeePercentage = function(feePercentage) {
      if (feePercentage === '' || feePercentage === '0' || feePercentage === '0.00' || feePercentage > 100) {
        alert('Percentage can\'t be empty or above 100');
      } else if (feePercentage >= 1) {
        $scope.task.feePercentage = parseFloat(feePercentage / 100);
        $scope.updateTask();
      } else {
        $scope.updateTask();
      }

      $scope.setClientFeePercentage = false;
    };

    $scope.updateContractorFeePercentage = function(feePercentage) {
      if (feePercentage === '' || feePercentage === '0' || feePercentage === '0.00' || feePercentage > 100) {
        alert('Percentage can\'t be empty or above 100');
      } else if (feePercentage >= 1) {
        $scope.task.contractorFeePercentage = parseFloat(feePercentage / 100);
        $scope.updateTask();
      } else {
        $scope.updateTask();
      }

      $scope.setContractorFeePercentage = false;
    };

    $scope.convertConsultationToNormal = function(task, oldValue) {
      if (oldValue === 'consultation'){
        $scope.updateTaskPrice('00.00');
      }

      TasksResource.convertConsultationToNormal(task.id, task.kind).then(function(task) {
        $scope.task = task;
        $scope.editingKind = false;
      }, function () {
        alert('There were some problems please contact CS')
      });
    };

    $scope.postReviewError = [];
    $scope.postReview = function(review, reviewForm) {
      var payload = {
        task_id: $scope.task.id,
        reviewer_id: review.reviewerId,
        comment: review.comment,
        score: review.score
      };

      if (review.reviewerId === $scope.task.client.id) {
        payload.communication = review.communication;
        payload.responsiveness = review.responsiveness;
      }

      console.log('postReview', payload);

      ReviewsResource.postReview(payload).then(function(data) {
        if ($scope.task.client.id === review.reviewerId) {
          data.reviewee = $scope.task.contractor;
        } else {
          data.reviewee = $scope.task.client;
        }
        $scope.reviews.push(data);
        $scope.resetReviewData(reviewForm);
      });
    };

    $scope.editExistingReview = function (review) {
      $scope.review.editMode = true;
      $scope.review.id = review.id;
      $scope.review.reviewerId = review.reviewer.id;
      $scope.review.comment = review.comment;
      $scope.review.score = review.score.value;
      $scope.review.communication = review.communication.value;
      $scope.review.responsiveness = review.responsiveness.value;

      console.log('editExistingReview', $scope.review);
    };

    $scope.updateReview = function(review, reviewForm) {
      var payload = {
        comment: review.comment,
        score: review.score
      };

      if (review.reviewerId === $scope.task.client.id) {
        payload.communication = review.communication
        payload.responsiveness = review.responsiveness
      }

      ReviewsResource.updateReview(review.id, payload).then(function(response) {
        var updatedReviewIndex = $scope.reviews.findIndex(function(r) {
          return r.id === review.id;
        });
        if (updatedReviewIndex > -1) {
          $scope.reviews[updatedReviewIndex] = response;
        }
        console.log('updateReview > updateReview', updatedReviewIndex, response, reviewForm);
        $scope.resetReviewData(reviewForm);
      });
    };

    $scope.resetReviewData = function (reviewForm) {
      $scope.review = {};
      // reset form
      reviewForm.$setPristine(); // needs to be passed as argument because of scope soup
    };

    $scope.deleteReview = function(review) {
      if (confirm('Are you sure you would like to delete this review?')) {
        ReviewsResource.deleteReview(review.id).then(function() {
          review.hide = true;
        });
      }
    };

    $scope.csFailFactorFeedback = function(task) {
       $scope.submitedCsFailFactorFeedback = true;
    };
    $scope.csProjectQuality = function(task) {
       $scope.submitedProjectQuality = true;
    };

    $scope.revertSubtask = function(subTask) {
      Restangular
      .one('admin')
      .one('tasks', subTask.parentTask.id)
      .one('sub_tasks', subTask.id)
      .one('revert_to_previous_state')
      .put()
      .then(function(response) {
        /* overwrite subtask in $scope to reflect changes immediately */
        $scope.task.subtasks[$scope.task.subtasks.indexOf(subTask)] = response;
      }).catch(function(err) {
        errorModal = $modal.open({
          templateUrl: '/app/scripts/modals/error.html',
          controller: 'ErrorCtrl',
          resolve: {
            error: function() {
              return { message: err.data.error || err.data.errors[0].message };
            }
          }
        });
      });
    };

    $scope.openInterventionsDialog = function () {
      const project = $scope.task

      console.log('-- opening InterventionsDialog',  project);

      $scope
        .$mdDialog
        .show({
          ...WorkroomInterventionsComponent,
          locals: {
            project: project
          }
        })
        .then(response => {
          console.log('dialogResponse', response)
        }, closed  => {
        })
    };

    $scope.stateEventDate = function (subTask) {
      return subTask.stateEventDate || subTask.updatedAt;
    };

  }

  window.app.controller('TaskCtrl', ['$scope', '$stateParams', '$location', '$anchorScroll', '$modal', '$mdDialog', 'Restangular', 'taskResource', 'TasksResource', 'WatchlistResource', 'TagsResource', 'ReviewsResource', '_', 'SubTasksResource', 'UserService', 'ProjectsService', 'AuthService', 'Configuration', 'StorageService', 'PermissionsService', TaskCtrl]);

})();
