import templateUrl from './dialog-expert-pods-create-pod-bot.dialog.html'
import './dialog-expert-pods-create-pod-bot.dialog.scss'

const DialogExpertPodsCreatePodBot = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogExpertPodsCreatePodBot {
    constructor ($mdDialog, $mdToast, Configuration, ExpertPodsService) {
      'ngInject'
      this._identify = 'DialogExpertPodsCreatePodBot'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.ExpertPodsService = ExpertPodsService
    }

    $onInit () {
      this.isLoading = false
      this.urlPattern = new RegExp('^(http[s]?:\\/\\/)(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,15}[\\.]{0,1}(\\/.*)?(\\?.*)?$')
      this.isEdit = true
      this.formData = {
        podId: this.expertPod.id,
        channelName: '',
        channelUrl: ''
      }
      if (this.expertPod.podBot) {
        const podBot = JSON.parse(JSON.stringify(this.expertPod.podBot))
        this.formData = { ...this.formData, ...podBot }
      }
    }

    create () {
      console.log('🚀 ~ file: expert-pods-create-pod-bot.dialog.js ~ create')

      this.error = null
      this.isProcessing = true
      this.ExpertPodsService.addExpertPodBot(this.expertPod.id, this.formData)
        .then(response => {
          console.log('🚀 ~ file: expert-pods-create-pod-bot.dialog.js ~ create ~ closing')

          this.close(response)
          this.$mdToast.show(this.$mdToast.simple({ textContent: 'New pod bot created', position: 'top right' }))
        })
        .catch((err) => {
          console.log('🚀 ~ file: expert-pods-create-pod-bot.dialog.js ~ create ~ error')

          this.error = this.errorHandler(err)
          this.$mdToast.show(this.$mdToast.simple({ textContent: 'Something went wrong', position: 'top right' }))
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    errorHandler (err) {
      console.log('🚀 ~ file: expert-pods-create-pod-bot.dialog.js ~ create ~ error', err)

      if (err.data.error === 'validation_failed' && err.data.errors) {
        const messages = err.data.errors.map(e => [e.field, e.message].join(': '))
        return [...messages]
      }
      return [err.data.error]
    }

    close (attr = false) {
      this.$mdDialog.hide(attr)
    }
  }
}

export default DialogExpertPodsCreatePodBot
