import templateUrl from './listing-item.html'
import './listing-item.scss'

const ListingItemComponent = {
  bindings: {
    data: '<?'
  },
  transclude: {
    top: '?listingItemTop',
    title: '?listingItemTitle',
    meta: '?listingItemMeta',
    actions: '?listingItemActions',
    bottom: '?listingItemBottom',
  },
  templateUrl,
  controller: class ListingItem {
    constructor () {
      'ngInject'
      this._identify = 'ListingItemComponent'
    }
  }
}

export default ListingItemComponent
