import templateUrl from './projects-list.html'
import './projects-list.scss'

const ProjectsListComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectsList {
    constructor ($location, $stateParams, $httpParamSerializer, ProjectsService, PaginationService) {
      'ngInject'
      this._identify = 'ProjectsListComponent'
      this.$location = $location
      this.$stateParams = $stateParams
      this.$httpParamSerializer = $httpParamSerializer
      this.ProjectsService = ProjectsService
      this.PaginationService = PaginationService
    }

    $onInit () {
      this.projectsList = null
      this.isLoading = false

      this.sortField = 'updatedAt'
      this.sortOrder = 'des'
      this.filters = {
        search: '',
        searchEntity: {
          selected: 'project',
          options: [
            {
              value: 'project'
            },
            {
              value: 'client'
            },
            {
              value: 'expert'
            }
          ]
        },
        state: {
          selected: '',
          options: [
            {
              value: 'created'
            },
            {
              value: 'published'
            },
            {
              value: 'estimated'
            },
            {
              value: 'hired'
            },
            {
              value: 'paid'
            },
            {
              value: 'completed'
            },
            {
              value: 'canceled'
            },
            {
              value: 'abandoned'
            },
            {
              value: 'withheld'
            },
          ]
        },
        visibility: '',
        pro: '',
        npc: {
          firstTimers: false,
          quality: false,
          failFactor: false,
          convertedToPaid: false
        },
        tags: {
          selected: null,
          options: [
            {
              value: 'tag1'
            },
            {
              value: 'tag2'
            },
            {
              value: 'tag3'
            }
          ]
        },
        referooPartner: {
          selected: null,
          options: [
            {
              value: 'partner1'
            },
            {
              value: 'partner2'
            },
            {
              value: 'partner3'
            }
          ]
        }
      }
      this.defaultFilters = { ...this.filters }

      this.pagination = this.PaginationService.init({
        itemsPerPage: 10,
        mode: 'server'
      })

      // Process pagination from URL
      console.log('[ProjectsListComponent]', this.$stateParams.page)
      if (this.$stateParams['page']) {
        this.pagination.setCurrentPage(this.$stateParams['page'])
      }

      if (this.$stateParams['state']) {
        this.filters.state.selected = this.$stateParams['state']
      }

      this.fetchProjectsList()
    }

    resetFilters () {
      this.filters = { ...this.defaultFilters }
      this.pagination.setCurrentPage(1)
      this.onFilterChanged()
    }

    onFilterChanged (value) {
      // reset state filter as npc filter overrides it for BE endpoint, it should be separate parameter
      if (this.filters.npc.firstTimers) {
        this.filters.state.selected = ''
      }

      this.fetchProjectsList()
    }


    filtersToParams () {
      const params = {}
      // state
      params.state = this.filters.state.selected ? this.filters.state.selected : null

      // visibility
      if (this.filters.visibility === 'expertPods') {
        params.withExpertPods = true
      } else if (this.filters.visibility === 'preferred') {
        params.preferred = true
      }

      // pro
      if (this.filters.pro === 'withPro') {
        params.pro = true
      } else if (this.filters.pro === 'withoutPro') {
        params.pro = false
      }

      // npc
      if (this.filters.npc.firstTimers) {
        params.state = 'first timers'
      }
      params.converted_to_paid = this.filters.npc.convertedToPaid ? this.filters.npc.convertedToPaid : null
      params.cs_project_quality = this.filters.npc.quality ? this.filters.npc.quality : null
      params.cs_cancel_task = this.filters.npc.failFactor ? this.filters.npc.failFactor : null

      // referooPartner
      params.tracking_name = this.filters.referooPartner.selected ? this.filters.referooPartner.selected : null

      // tags
      params.tag = this.filters.tags.selected ? this.filters.tags.selected : null

      return params
    }

    fetchProjectsList () {
      const params = this.pagination ? {
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage,
        ...this.filtersToParams()
      } : {}

      this.isLoading = true
      this.ProjectsService.getProjectsList(params)
        .then(response => {
          this.pagination.updateFromHeaders(response)
          this.projectsList = response.data

          // update URL
          this.$location.search({})
          this.$location.search(params)
          // Object.keys(params)
          //   .forEach(key => {
          //     this.$location.search(key, params[key])
          //   })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    onPageChangeHandler () {
      this.fetchProjectsList()
    }


    openPreviewDialog (projectListing, projectList) {
      this.ProjectsService.openPreviewDialog(projectListing, projectList)
    }
  }
}

export default ProjectsListComponent
