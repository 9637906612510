(function() {
  'use strict';

  function SearchResultCtrl($scope, $location, $stateParams, SearchResultResource) {
    
    $scope.searchTerm = $location.search().q;

    if ($stateParams.searchType === 'users') {
      SearchResultResource.searchUsers({q: $location.search().q}).then(function(response) {

        $scope.users = response;

      });
    } else if ($stateParams.searchType === 'utm') {
      SearchResultResource.searchUtmTasks({q: $location.search().q}).then(function(response) {

        $scope.tasks = response;

      });

      SearchResultResource.getPossibleSearchUtms().then(function(utms) {
        $scope.utms = utms;
      });
    }
        
  }

  window.app.controller('SearchResultCtrl', ['$scope', '$location', '$stateParams', 'SearchResultResource', SearchResultCtrl]);
	
})();
