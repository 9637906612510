(function() {
  'use strict';

  function listTransactions() {

    return {
      restrict: 'E',
      templateUrl: '/app/scripts/transactions/list.transactions.html',
      scope: '='
    };

  }

  app.directive('listTransactions', listTransactions);
})();