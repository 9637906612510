(function() {
  'use strict';

  function CreateEmailTemplateCtrl($scope, $location, $state, $stateParams, SettingsResource) {
    
    $scope.save = function(tpl) {
      $scope.saving = true;
      SettingsResource.createEmailTemplate({text: tpl.text, subject: tpl.name}).then(function(response) {
        $scope.saving = false;
        $state.go('settings.emailTemplates');
      });
    };
    
  }

  window.app.controller('CreateEmailTemplateCtrl', ['$scope', '$location', '$state', '$stateParams', 'SettingsResource', CreateEmailTemplateCtrl]);
	
})();
