import templateUrl from './resource-subtask.html'
import './resource-subtask.scss'

const IssueResourceSubtaskComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueResourceSubtask {
    constructor (Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourceSubtaskComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService

      this.subTask = null
    }

    $onInit () {
      this.IssueViewService.getResourceSubtask(this.issue.id)
        .then(response => {
          this.subTask = response
        }, error => {
          console.log(error)
        })
    }
  }
}

export default IssueResourceSubtaskComponent
