import templateUrl from './settings-unlayer-templates.html'
import './settings-unlayer-templates.scss'

const SettingsUnlayerTemplatesComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsUnlayerTemplates {
    constructor ($timeout, Configuration, UserService, SettingsUnlayerTemplatesService) {
      'ngInject'
      this._identify = 'SettingsUnlayerTemplatesComponent'

      this.$timeout = $timeout
      this.Configuration = Configuration
      this.UserService = UserService
      this.SettingsUnlayerTemplatesService = SettingsUnlayerTemplatesService
    }

    $onInit () {
      this.filters = {
        search: ''
      }

      this.showWarning = this.Configuration.isDevelopment

      this.gridOptions = {
        enableHiding: false,
        rowHeight: 80,
        minimumColumnSize: 200,
        appScopeProvider: this,
        onRegisterApi: function (gridApi) {
          this.gridApi = gridApi
          this.gridApi.core.handleWindowResize()
        },
        data: [],
        columnDefs: [
          { name: ' ', minWidth: 50, maxWidth: 50, cellTemplate: `<input type="checkbox" ng-model="row.entity.selected"></input>` },
          { name: 'Id', minWidth: 100, maxWidth: 100, field: 'id' },
          { name: 'Template name', field: 'name' },
          { name: 'Last edited Unlayer', maxWidth: 300, cellTemplate: `<span>{{ row.entity.unlayerLastUpdatedAt | dateFormat }}</span>` },
          { name: 'Last synced Mandril', maxWidth: 300, cellTemplate: `
            <span>
              <span ng-show="row.entity.mandrillLastUpdatedAt">{{ row.entity.mandrillLastUpdatedAt | dateFormat }}</span>
              <span ng-hide="row.entity.mandrillLastUpdatedAt">N/A</span>
            </span>` },
          { name: 'Needs syncing', maxWidth: 300, cellTemplate: `
            <span>
              <span ng-hide="row.entity.isSyncing || row.entity.error">{{ grid.appScope.isUnsynced(row.entity) ? 'I need to be synced!!!' : 'Synced up good!' }}</span>
              <span ng-show="row.entity.isSyncing">Syncing...</span>
              <span ng-show="row.entity.error">Error: {{  row.entity.error }}</span>
            </span>`
          }
        ]
      }


      this.isLoading = true
      this.errorGetTemplates = null
      this.SettingsUnlayerTemplatesService.getTemplates()
        .then(response => {
          console.log(response)
          this.templateList = response
          this.gridOptions.data = this.templateList
        })
        .catch(err => {
          this.errorGetTemplates = this.errorMessageMap(err.data)
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    isUnsynced (template) {
      return template.mandrillLastUpdatedAt === null || template.mandrillLastUpdatedAt < template.unlayerLastUpdatedAt
    }

    onFilterHandler () {
      const result = this.templateList.filter(t => t.name.includes(this.filters.search))
      this.gridOptions.data = result
    }
    clearFilters () {
      this.filters.search = ''
      this.onFilterHandler()
    }

    get isAnyTemplateSelected () {
      if (!this.templateList) {
        return false
      }

      const count = this.templateList.filter(t => t.selected).length
      return count > 0
    }

    get isAnyTemplateUnsynced () {
      if (!this.templateList) {
        return false
      }

      const count = this.templateList.filter(t => this.isUnsynced(t)).length
      return count > 0
    }

    async syncAllOutdatedTemplates () {
      const allOutdatedTemplates = this.templateList.filter(t => this.isUnsynced(t))
      console.log('syncAllOutdatedTemplates', allOutdatedTemplates)

      await this.syncTemplatesSequentially(allOutdatedTemplates)
      console.log('syncAllOutdatedTemplates > COMPLETED')
    }

    async syncTemplatesSequentially (templatesList) {
      // Run promises sequentially
      this.isProcessing = true
      for (let i = 0, len = templatesList.length; i < len; i++) {
        templatesList[i].isSyncing = true
        templatesList[i].error = null
        await this.SettingsUnlayerTemplatesService.syncTemplate(templatesList[i])
          .then(response => {
            templatesList[i].unlayerLastUpdatedAt = response.unlayerLastUpdatedAt
            templatesList[i].mandrillLastUpdatedAt = response.mandrillLastUpdatedAt
            console.log('syncTemplatesSequentially > synced', templatesList[i].id)
          })
          .catch(err => {
            templatesList[i].error = this.errorMessageMap(err.data)
          })
          .finally(() => {
            templatesList[i].isSyncing = false
          })

        this.$timeout()
      }
      this.isProcessing = false
    }

    // TODO convert this into service method for app wide use with sensible default messages + ability to provide a custom map
    errorMessageMap (err) {
      console.log('errorMessageMap', err)
      let message = 'Something went wrong'
      if (typeof err !== 'object' || !err.reason) {
        return message
      }

      const errorCode = `${err.error}|${err.reason}`
      switch (errorCode) {
      case 'forbidden|not_authorized_environment':
        message = 'Not authorized to sync on this environment.'
        break
      case 'forbidden|not_authorized':
        message = 'Not authorized to sync'
        break
      default:
        message = err.message ? err.message : `${err.error} - ${err.reason}`
      }

      return message
    }

    async syncSelectedTemplates () {
      const selectedTemplates = this.templateList.filter(t => t.selected)
      console.log('syncSelectedTemplates', selectedTemplates)

      await this.syncTemplatesSequentially(selectedTemplates)

      // Unselect all templates after done with syncing
      this.templateList.forEach(t => {
        t.selected = false
      })
      console.log('syncSelectedTemplates > COMPLETED')
    }
  }
}

export default SettingsUnlayerTemplatesComponent
