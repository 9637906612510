const CompareToDirective = function () {
  'ngInject'
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      target: '=compareTo'
    },
    link ($scope, $element, $attrs, originalModelCtrl) {
      const compare = () => {
        const targetValue = (typeof $scope.target === 'object' && $scope.target.hasOwnProperty('$viewValue')) ? $scope.target.$viewValue : $scope.target
        return originalModelCtrl.$viewValue === targetValue
      }

      $scope.$watch(compare, function (newValue) {
        originalModelCtrl.$setValidity('compare', newValue)
      })
    }
  }
}

export default CompareToDirective
