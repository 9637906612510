/* eslint-disable */
const ProjectAttachmentsService = class ProjectAttachmentsService {
  constructor ($http, $window, Configuration) {
    'ngInject'

    this.$http = $http
    this.$window = $window
    this.Configuration = Configuration
  }

  getAttachmentList (projectId, params = null) {
    const config = {
      params
    }
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/attachments`, config)
      .then(response => {
        return response.data
      })
  }

  deleteAttachment (projectId, attachmentId) {
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/attachments/${attachmentId}`)
      .then(response => {
        return response.data
      })
  }

  getAttachmentDownloadSignedUrl (projectId, attachmentId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/attachments/${attachmentId}`)
      .then(response => {
        return response.data
      })
  }
  downloadAttachment (projectId, attachmentId) {
    return this.getAttachmentDownloadSignedUrl(projectId, attachmentId)
      .then(response => {
        if (response.url) {
          this.$window.open(response.url, '_blank')
          this.$window.focus() // to keep focus in current tab
        }
        return response
      })
  }

  extractFilenameOnly (filenameRaw, offset = 0) {
    if (typeof filenameRaw === 'string') {
      let tmp = filenameRaw.split(/(.[^.]+)$/i)
      if (tmp[0].length - offset < 2) {
        offset = Math.min(tmp[0].length - 2, 0)
      }
      let re = new RegExp('(.{' + offset + '}.[^.]+)$', 'i') //  /(.[^.]+)$/i; //
      let list = filenameRaw.split(re)
      return list[0]
    }

    return filenameRaw
  }

  extractFileExtensionOnly (filenameRaw, offset = 0) {
    if (typeof filenameRaw === 'string') {
      let tmp = filenameRaw.split(/(.[^.]+)$/i)
      if (tmp[0].length - offset < 2) {
        offset = Math.min(tmp[0].length - 2, 0)
      }
      let re = new RegExp('(.{' + offset + '}.[^.]+)$', 'i') //  /(.[^.]+)$/i; //
      let list = filenameRaw.split(re)
      return list[1]
    }

    return filenameRaw
  }
}
export default ProjectAttachmentsService
