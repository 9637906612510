(function() {
  'use strict';

  function CohortKpisCtrl($scope, $location, $stateParams, KpisResource) {
    
    KpisResource.cohortData().then(function(response) {
      $scope.cohortData = response;
    });
    
    $scope.editAdwordsCost = function(cohort) {
      var index = _.findIndex($scope.cohortData, function(cd) {
        return cd.id == cohort.id;
      });
      if (cohort.updating==undefined) {
        $scope.cohortData[index].updating = true;
      } else {
        KpisResource.updateCohort(cohort.id, {adwords_cost: cohort.adwordsCost}).then(function(response) {
          $scope.cohortData[index] = response;
          $scope.cohortData[index].updating = undefined;
        });
      }
    };
    
    $scope.editPageVisits = function(cohort) {
      var index = _.findIndex($scope.cohortData, function(cd) {
        return cd.id == cohort.id;
      });
      if (cohort.updatingVisits==undefined) {
        $scope.cohortData[index].updatingVisits = true;
      } else {
        KpisResource.updateCohort(cohort.id, {unique_page_visitor_count: cohort.uniquePageVisitorCount}).then(function(response) {
          $scope.cohortData[index] = response;
          $scope.cohortData[index].updatingVisits = undefined;
        });
      }
    };
    
  }

  window.app.controller('CohortKpisCtrl', ['$scope', '$location', '$stateParams', 'KpisResource', CohortKpisCtrl]);
	
})();
