// import ISSUES_CONFIG from '../issue-system.config'
import IssueListComponent from './issue-list.component'

const IssueListModule = angular.module('cdbl.issue-list', [
  'ui.router'
])
  .component('cdblIssueList', IssueListComponent)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        parent: 'issues',
        name: 'issueList',
        url: '/:folderId/:subFolderId?type',
        params: {
          folderId: { squash: false, value: 'all' },
          subFolderId: { squash: false, value: 'unassigned' }
        },
        views: {
          issueList: 'cdblIssueList'
        }
      })
  })
  .name

export default IssueListModule
