/* eslint-disable */
(function() {
  'use strict';

  function messageForm(IntercomResource, TagsResource, TasksResource, $stateParams, UserService, Configuration) {
    return {
      restrict: 'E',
      replace: true,
      templateUrl: '/app/scripts/tasks/messageexperts/post.message.html',
      link: function($scope) {
        $scope.intercomComment.text = `Hey {{ first_name | fallback:"there" }},\n\nWill you please check back in on this one: ${Configuration.appUrl}/tasks/${$stateParams.id}\n\nThis is just a friendly check in, but could you please reply back to me, so I know you\'re on it?\n\nThanks,\n {{ message.author.first_name }}`;

        $scope.processing = false;
        var taskResource = TasksResource.getTask($stateParams.id, ['activities'], true);
        taskResource.activities().then(activities => {
          const EXPERT_ROLE = 'contractor' // TODO: add user method to service to check for role on user objects

          // Extract experts from activities
          let interestedExperts = activities
            .filter(activity => {
              return activity.trackable && (activity.trackable.estimator || (activity.trackable.user && activity.trackable.user.role === EXPERT_ROLE))
            })
            .map(activity => {
              if (activity.trackable.estimator) {
                return activity.trackable.estimator
              }

              return activity.trackable.user
            })

          // Add preferred contractors
          if ($scope.task.preferredContractors && $scope.task.preferredContractors.length) {
            interestedExperts = [...interestedExperts, ...$scope.task.preferredContractors]
          }

          // Make unique list of experts
          interestedExperts = interestedExperts.reduce((uniqueList, user) => {
            return uniqueList.find(u => u.id === user.id) ? uniqueList : [...uniqueList, user]
          }, [])

          $scope.interestedExperts = interestedExperts
        });

        IntercomResource.getUsers().then(function(users) {
          $scope.intercomUsers = users;

          const currentUser = $scope.intercomUsers.find(u => u.email === UserService.user.email);
          if (currentUser) {
            $scope.selectedIntercomUser = currentUser ? currentUser : null;
            $scope.intercomComment.adminEmail = $scope.selectedIntercomUser.email;
          }
        });
        $scope.setIntercomUser = function(comment, user) {
          comment.adminEmail = user.email;
        };
        $scope.setIntercomComment = function(comment, template) {
          comment.text = template.text.replace('{{task_id}}', $scope.task.id).replace('{{task_id}}', $scope.task.id);
          comment.subject = template.subject;
        };
        $scope.postComment = function(taskId, text, isContractorsOnly) {
          $scope.task.all('comments').post({ text: text, isContractorsOnly: isContractorsOnly }).then(function(comment) {
            comment.verb = 'create_comment';
            $scope.activities.unshift(comment);

            $scope.comment.text = '';
          });
        };
        $scope.sendMessage = function(taskId, users, intercomComment) {
          $scope.processing = true;
          if(intercomComment.text !== ''){
            if(intercomComment.subject !== ''){
              if(typeof intercomComment.adminEmail !== 'undefined'){
                var _users = [];
                users.forEach(function(user) {
                  _users.push(user.id);
                });
                if(_users.length > 0){
                  $scope.sending = 'Sending...';
                  if(typeof intercomComment.mode === 'undefined' || intercomComment.mode === false){
                    intercomComment.mode = 'inapp';
                  }
                TagsResource.sendMessageSimple(taskId, _users, intercomComment.subject, intercomComment.text, intercomComment.adminEmail, intercomComment.mode).then(
                  function(data) {

                  console.log(data);
                  $scope.sending = '';
                  $scope.processing = false;
                }, function(e){
                  $scope.sending = 'Error from server: "'+e.data.errors[0].message+'" Try sending again.';
                  $scope.processing = false;

                  console.log(e);
                });
              }else{
                alert(' Pick a user');
                $scope.processing = false;
              }
            }else{
                alert('Please pick a from');
                $scope.processing = false;

            }
          }else{
              alert('Please enter a subject');
              $scope.processing = false;

          }
        }else{
            alert('Please enter text');
            $scope.processing = false;

        }
        };
      }
    };
  }

  app.directive('messageForm', ['IntercomResource', 'TagsResource', 'TasksResource', '$stateParams', 'UserService', 'Configuration', messageForm]);
})();
