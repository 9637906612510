
const NgFormDirective = () => {
  return {
    require: 'form',
    link: function (scope, elem, attr, form) {
      form.hasError = formField => {
        return hasError(formField)
      }

      function hasError (formField) {
        let field = formField
        if (typeof formField === 'string') {
          field = form[formField]
        }
        if (angular.isDefined(field)) {
          return field.$invalid && (field.$dirty || field.$touched)
        }
        return false
      }
    }
  }
}

export default NgFormDirective
