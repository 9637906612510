(function() {
  'use strict';

  function limitText() {
    return function (value, splitByWords, max) {
      if (!value) { return ''; }

      max = parseInt(max, 10);

      if (!max) { return value; }
      if (value.length <= max) { return value; }

      value = value.substr(0, max);

      if (splitByWords) {
        var lastspace = value.lastIndexOf(' ');

        if (lastspace !== -1) {
          value = value.substr(0, lastspace);
        }
      }

      return value + ' ...';
    };
  }

  app.filter('limitText', limitText);
})();
