const SettingsAccountService = class SettingsAccountService {
  constructor ($window, $http, Configuration, AuthService, ToastService) {
    'ngInject'
    this._identify = 'SettingsAccountService'
    this.$window = $window
    this.$http = $http
    this.Configuration = Configuration
    this.AuthService = AuthService
    this.ToastService = ToastService
  }

  getSettings () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/account_details`)
      .then(response => response.data)
      .catch(err => {
        console.log(err)
        this.ToastService.show('Something went wrong.')
      })
  }

  updateSettings (formData) {
    const fd = new this.$window.FormData()
    const config = {
      url: `${this.Configuration.apiUrl}/admin/account_details`,
      method: 'patch',
      transformRequest: angular.identity,
      disableRequestDataInterceptor: true,
      headers: {
        'Content-Type': undefined
      }
    }

    if (formData.currentPassword) {
      fd.append('current_password', formData.currentPassword)
    }

    if (formData.newPassword) {
      fd.append('new_password', formData.newPassword)
    }

    if (formData.email) {
      fd.append('email', formData.email)
    }

    if (formData.avatar && formData.avatar[0]) {
      fd.append('avatar', formData.avatar[0])
    }

    return this.$http({ ...config, data: fd })
      .then(response => {
        const authToken = response.headers(this.Configuration.auth.tokenHeaderKey)
        if (authToken) {
          this.AuthService.updateToken(authToken)
        }

        return response.data
      })
  }

  setupMfaInit () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/mfa/new`)
      .then(response => response.data)
  }

  setupMfaEnable (formData) {
    const payload = {
      ...formData
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/mfa`, payload)
      .then(response => {
        // update auth token form headers
        const token = response.headers('auth-token')
        this.AuthService.updateToken(token)
        return response.data
      })
  }


  setupMfaDisable (formData) {
    const payload = {
      ...formData
    }
    return this.$http
      .delete(`${this.Configuration.apiUrl}/mfa`, {
        data: payload,
        // headers: {
        //   'Content-Type': 'application/json'
        // }
      })
      .then(response => response.data)
  }
}
export default SettingsAccountService
