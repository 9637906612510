// Purpose of thi service - slowly port old auth here. Meanwhile map functions to old auth service.
const ToastService = class ToastService {
  constructor ($mdToast) {
    'ngInject'
    this._identify = 'ToastService'
    this.$mdToast = $mdToast
  }

  show (content) {
    let text = ''
    if (typeof content === 'object' && content.data && content.data.errors) {
      text = content.data.errors[0].message
    }

    if (typeof content === 'string') {
      text = content
    }

    this.$mdToast.show(this.$mdToast.simple({ textContent: `${text}`, position: 'top right' }))
  }
}

export default ToastService
