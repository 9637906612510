import templateUrl from './token-preview.html'
import './token-preview.scss'

const IS_TOKEN_PREVIEW_OPEN = 'is-token-preview-open'
const TokenPreviewComponent = {
  bindings: {
    content: '<',
    validator: '<',
    values: '<'
  },
  templateUrl,
  controller: class TokenPreview {
    constructor ($window, $mdToast, TokenService, StorageService) {
      'ngInject'
      this._identify = 'TokenPreviewComponent'
      this.$window = $window
      this.$mdToast = $mdToast
      this.TokenService = TokenService
      this.StorageService = StorageService

      this.collapsed = true
      this._content = ''
      this._validator = null
      this.previewText = null
      this.errorMessage = null
    }

    toggleCollapse () {
      this.collapsed = !this.collapsed
      this.StorageService.set(IS_TOKEN_PREVIEW_OPEN, !this.collapsed)

      if (!this.collapsed) {
        this.refreshPreview()
      } else {
        this.previewText = null
      }
    }


    refreshPreview () {
      try {
        this.previewText = this.TokenService.replace(this._content, this._validator, this.values)
        this.errorMessage = null
      } catch (err) {
        this.errorMessage = err.message
        this.previewText = null
      }

      console.log('[TokenPreviewComponent] > refreshPreview', this.previewText)
    }

    $onInit () {
      this._validator = this.validator
      this.collapsed = !this.StorageService.get(IS_TOKEN_PREVIEW_OPEN)
    }

    $onChanges (changes) {
      console.log('[TokenPreviewComponent] > $onChanges', changes)
      if (changes.content) {
        this._content = changes.content.currentValue
      }
      if (changes.validator) {
        this._validator = changes.validator.currentValue
      }
      this.refreshPreview()
    }
  }
}

export default TokenPreviewComponent
