(function() {
  'use strict';

  function newlines() {
    return function(text) {
      if (text) {
        return text.replace(/\n/g, '<br />');
      }
    };
  }

  app.filter('newlines', newlines);
})();
