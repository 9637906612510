(function() {
  'use strict';

  function TagsCtrl($scope, TagsResource) {
    TagsResource.getTags().then(function(data) {
      $scope.tags = data;
    }).catch(err => { console.log(err) });

    $scope.addTag = function(tag) {
      TagsResource.addTag(tag).then(function(tag) {
        $scope.tags.unshift(tag);
      });
    };
  }

  window.app.controller('TagsCtrl', ['$scope', 'TagsResource', TagsCtrl]);

})();
