import templateUrl from './resource-payment-transaction.html'
import './resource-payment-transaction.scss'

const IssueResourcePaymentTransactionComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueResourcePaymentTransaction {
    constructor (Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourcePaymentTransactionComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService
      this.transactionProvider = 'Unknown provider'

      this.paymentTransaction = null
    }

    $onInit () {
      this.IssueViewService.getResourcePaymentTransaction(this.issue.id)
        .then(response => {
          this.paymentTransaction = response

          if (this.paymentTransaction.url.includes('paymill')) {
            this.transactionProvider = 'Paymill'
          } else if (this.paymentTransaction.url.includes('paypal')) {
            this.transactionProvider = 'Paypal'
          }
        }, error => {
          console.log(error)
        })
    }
  }
}

export default IssueResourcePaymentTransactionComponent
