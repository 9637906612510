import templateUrl from './dialog-project-edit-title.dialog.html'
import './dialog-project-edit-title.dialog.scss'

const DialogProjectEditTitleDescription = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogProjectEditTitleDescription {
    constructor ($mdDialog, ToastService, Configuration, ProjectsService) {
      'ngInject'
      this._identify = 'DialogProjectEditTitleDescription'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.Configuration = Configuration
      this.ProjectsService = ProjectsService

      this.isLoading = false
    }

    $onInit () {
      this.formData = {
        title: this.project.title,
        description: this.project.description
      }
    }

    $onDestroy () {

    }

    showEditConfirmation () {
      this.showConfirmation = true
    }
    hideEditConfirmation () {
      this.showConfirmation = false
    }

    confirmEdit () {
      this.isProcessing = true
      this.ProjectsService.updateProjectInfo(this.project.id, this.formData)
        .then(response => {
          // update project with new fresh data from BE
          this.ProjectsService.updateProject(this.project, response)
          this.$mdDialog.cancel()
          this.ToastService.show('Project info updated')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogProjectEditTitleDescription
