const BudgetRangeFormatFilter = function (Configuration, priceFormatFilter) {
  'ngInject'
  return function (budgetRange) {
    let budgetLabel = 'N/A'

    if (budgetRange && typeof budgetRange === 'object' && typeof budgetRange.min !== 'undefined' && budgetRange.min !== null) {
      budgetLabel = `From ${priceFormatFilter(budgetRange.min)}`
    }

    return budgetLabel
  }
}
export default BudgetRangeFormatFilter
