import templateUrl from './resource-client.html'
import './resource-client.scss'
import ClientEnrichmentDataDialog from './client-enrichment-data.dialog.js'

const IssueResourceClientComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,

  controller: class IssueResourceClient {
    constructor ($mdDialog, Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourceClientComponent'
      this.$mdDialog = $mdDialog
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService

      this.client = null
      this.clientEnrichmentData = null
    }

    $onInit () {
      this.IssueViewService.getResourceClient(this.issue.id)
        .then(response => {
          this.client = response
        }, error => {
          console.log(error)
        })
    }

    actionEnrichClientData () {
      console.log('[IssueResourceClient] > actionEnrichClientData')

      this.IssueViewService.getResourceClientEnrichmentData(this.issue.id, this.client)
        .then(response => {
          console.log(response)
          this.clientEnrichmentData = response
          this.openClientEnrichmentDataDialog(response)
        }, error => {
          console.log(error)
        })
    }

    openClientEnrichmentDataDialog (enrichmentData) {
      console.log('client-enrichment-data:', enrichmentData)
      const client = this.client

      this.$mdDialog
        .show({
          ...ClientEnrichmentDataDialog,
          locals: {
            client,
            enrichmentData
          }
        })
    }
  }
}

export default IssueResourceClientComponent
