const DateFormatFilter = (moment) => {
  'ngInject'
  return function (date, format = 'short', diffDate) {
    const momentDate = moment(date)
    switch (format) {
    case 'full':
      format = 'MMM Do YYYY - HH:mm:ss'
      break
    case 'short':
      format = 'MMM Do YYYY - HH:mm'
      break
    case 'date':
      format = 'MMM Do YYYY'
      break
    case 'time':
      format = 'HH:mm'
      break
    case 'diff':
      if (!diffDate) {
        return null
      }

      return momentDate.from(moment(diffDate))
    case 'from':
      return momentDate.from(moment(), true)
    case 'ago':
      return momentDate.fromNow()
    }

    return momentDate.format(format)
  }
}
export default DateFormatFilter
