(function() {
  'use strict';

  function DataInterceptor($q, _) {
    var convertCase = function(obj, type) {
      // If the response is an array, it needs
      // to be iterated through each item and process it
      var convertArray = function(arr) {
        var processed = [];

        _(arr).each(function(item) {
          if (!_.isArray(item) && !_.isObject(item)) {
            processed.push(item);
          } else {
            processed.push(convertCase(item, type));
          }
        });

        return processed;
      };

      // Meat of the function, goes through every key:value pair
      // in an object to create a new, 'type' one
      var convertObject = function(obj) {
        var output = {};

        _(obj).forEach(function(val, key) {
          var newKey = String.prototype[type].call(key);

          if (_.isObject(val)) {
            output[newKey] = convertCase(val, type);
          } else {
            output[newKey] = val;
          }
        });

        return output;
      };

      // After defined all possible scenarios (having obj | array)
      // run one of the two outcomes, process the object or the array
      if (_.isArray(obj)) {
        return convertArray(obj);
      }

      return convertObject(obj);
    };

    return {
      request: function(config) {
        if (!config.disableRequestDataInterceptor && (config.method === 'POST' || config.method === 'PUT' || config.method === 'DELETE' || config.method === 'PATCH')) {
          if (config.data) {
            config.data = convertCase(config.data, 'toSnakeCase');
            config.headers['content-type'] = 'application/json; charset=utf-8'
          }
        }

        return config || $q.when(config);
      },
      response: function(response) {
        // Some objects don't have headers (like HTML templates)
        if (response.disableResponseDataInterceptor || !response.headers) {
          return response || $q.when(response);
        }

        var headers = response.headers();

        if (headers['content-type'] === 'application/json; charset=utf-8') {
          response.data = convertCase(response.data, 'toCamelCase');
        }

        return response;
      },
      convertCase: convertCase
    };
  }

  app.factory('DataInterceptor', ['$q', '_', DataInterceptor]);

  app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('DataInterceptor');
  }]);
})();