import templateUrl from './project-widget-complexity-urgency.html'
import './project-widget-complexity-urgency.scss'

import DialogProjectEditComplexityUrgency from './dialog-project-edit-complexity-urgency'

const urgencyListV1Options = [
  {
    id: 0,
    label: 'Urgent'
  },
  {
    id: 1,
    label: 'Today or tomorrow'
  },
  {
    id: 2,
    label: 'Moderate'
  },
  {
    id: 3,
    label: 'Flexible'
  }
]

const urgencyListV2Options = [
  {
    id: 0,
    label: 'Urgent'
  },
  {
    id: 2,
    label: 'Moderate'
  },
  {
    id: 3,
    label: 'Flexible'
  }
]

const complexityOptions = [
  {
    id: 'low',
    label: 'Low'
  },
  {
    id: 'medium',
    label: 'Medium'
  },
  {
    id: 'high',
    label: 'High'
  }
]



const ProjectWidgetComplexityUrgencyComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetComplexityUrgency {
    constructor ($mdDialog) {
      'ngInject'
      this._identify = 'ProjectWidgetComplexityUrgencyComponent'
      this.$mdDialog = $mdDialog
    }

    $onInit () {
      this.urgencyOptions = this.project.submissionFormVersion >= '2.0' ? urgencyListV2Options : urgencyListV1Options
      this.complexityOptions = complexityOptions
    }

    parseUrgency (urgencyId) {
      if (urgencyId === null || urgencyId === undefined) {
        return 'N/A'
      }

      const urgency = this.urgencyOptions.find(u => u.id === Number(urgencyId))

      if (urgency) {
        return urgency.label
      }

      return 'N/A'
    }


    openEditDialog () {
      this.$mdDialog.show({
        ...DialogProjectEditComplexityUrgency,
        locals: {
          project: this.project,
          complexityOptions: this.complexityOptions,
          urgencyOptions: this.urgencyOptions
        }
      })
    }
  }
}

export default ProjectWidgetComplexityUrgencyComponent
