import templateUrl from './resource-project-feedback.html'
import './resource-project-feedback.scss'

const ALLOWED_FEEDBACK_TYPES = [
  'cancel_task',
  'no_estimate'
]

const IssueResourceProjectFeedbackComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,

  controller: class IssueResourceProjectFeedback {
    constructor (Configuration, IssueViewService, PusherService, $timeout) {
      'ngInject'
      this._identify = 'IssueResourceProjectFeedbackComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService
      this.PusherService = PusherService
      this.$timeout = $timeout

      this.feedbackList = null

      // Declare listeners
      const vm = this
      this.resourceUpdatedListener = function (event) {
        vm.$timeout(() => {
          vm.fetchResourceFeedback()
        })
      }
    }

    $onInit () {
      this.fetchResourceFeedback()
      this.subscribeToIssueResource()
    }

    $onDestroy () {
      this.unsubscribeToIssueResource()
    }

    subscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.bind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    unsubscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.unbind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    fetchResourceFeedback () {
      this.feedbackList = null
      this.IssueViewService.getResourceProjectFeedback(this.issue.id)
        .then(response => {
          this.feedbackList = response
            .filter(f => ALLOWED_FEEDBACK_TYPES.includes(f.type))
            // aggregate the answers per type and per user id
            .reduce((acc, item) => {
              if (item.type === 'no_estimate') {
                // check if any item already has this type and is from same user
                const existingItem = acc.find(accItem => accItem.type === item.type && accItem.user.id === item.user.id)
                if (existingItem) {
                  // merge answers and handle custom answer
                  existingItem.answer += `<br> ${item.answer}`
                  return acc
                }
              }
              acc.push(item)
              return acc
            }, [])
        }, error => {
          console.log(error)
        })
    }

    feedbackType (feedback) {
      switch (feedback.type) {
      case 'cancel_task':
        return 'Project canceled feedback'
      case 'no_estimate':
        return 'Project no estimate feedback'
      }

      return feedback.type
    }
  }
}

export default IssueResourceProjectFeedbackComponent
