const ResourceService = class ResourceService {
  constructor (Configuration, $http) {
    'ngInject'
    this._identify = 'ResourceService'
    this.Configuration = Configuration
    this.$http = $http
  }

  getTask (taskId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/tasks/${taskId}`)
      .then(response => {
        return response.data
      })
  }


  getUser (userId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/users/${userId}`)
      .then(response => {
        return response.data
      })
  }
}
export default ResourceService
