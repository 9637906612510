import templateUrl from './list-selector.html'
import './list-selector.scss'

const ListSelectorComponent = {
  bindings: {
    selectedList: '<', // array
    optionsList: '<', // array
    onAddHandler: '&?',  // function
    onRemoveHandler: '&?', // function
    searchSelected: '<?',
    searchOptions: '<?',
    exclusiveComparator: '<?', // comparator function

    // labels
    labelSelectedList: '@?',
    labelOptionsList: '@?',

    // messages
    messageEmptySelectedList: '@?',
    messageEmptyOptionsList: '@?',
    messageEmptySearchSelectedList: '@?',
    messageEmptySearchOptionsList: '@?',
  },
  transclude: {
    'title': '?listSelectorTitle',

    'selectedItemAvatar': '?listSelectorSelectedItemAvatar',
    'selectedItemTitle': '?listSelectorSelectedItemTitle',
    'selectedItemMeta': '?listSelectorSelectedItemMeta',
    'selectedItemActions': '?listSelectorSelectedItemActions',

    'optionsItemAvatar': '?listSelectorOptionsItemAvatar',
    'optionsItemTitle': '?listSelectorOptionsItemTitle',
    'optionsItemMeta': '?listSelectorOptionsItemMeta',
    'optionsItemActions': '?listSelectorOptionsItemActions',
  },
  templateUrl,
  controller: class ListSelector {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'ListSelectorComponent'
      this.EventEmitter = EventEmitter

      this.searchTextSelected = ''
      this.searchTextOptions = ''

      this.orderBySelected = 'id'
      this.orderByOptions = 'id'
    }

    $onInit () {
      this.labelSelectedList = this.labelSelectedList || 'Selected items'
      this.labelOptionsList = this.labelOptionsList || 'Available items'

      const vm = this
      if (typeof this.exclusiveComparator !== 'function' && this.exclusiveComparator === true) {
        this.exclusiveComparator = (selectedItem, optionsItem) => selectedItem.id === optionsItem.id
      }

      // console.log('[ListSelectorComponent] > exclusiveComparator', this.exclusiveComparator)
      this.filterExclusive = (optionsItem, index, array) => {
        if (this.exclusiveComparator) {
          const match = Boolean(vm.selectedList.find(selectedItem => {
            return vm.exclusiveComparator(selectedItem, optionsItem)
          }))

          if (match) {
            return false
          }
        }

        return true
      }
    }
    addToList (item) {
      if (this.onAddHandler) {
        this.onAddHandler(this.EventEmitter({ item: item }))
      }
    }

    removeFromList (item) {
      if (this.onRemoveHandler) {
        this.onRemoveHandler(this.EventEmitter({ item: item }))
      }
    }
  }
}

export default ListSelectorComponent
