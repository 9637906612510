(function() {
  'use strict';

  function reverse() {
    return function(items) {
      return items.slice().reverse();
    };
  }

  app.filter('reverse', reverse);
})();
