import IssueViewComponent from './issue-view.component'
import IssueViewService from './issue-view.service'

import IssueViewSummaryComponent from './issue-view-summary'
import IssueViewNotesModule from './issue-view-notes'
import IssueViewTabsHistory from './issue-view-history'
import IssueViewTabsModule from './issue-view-tabs'

import IssueResourceTaskComponent from './resources/resource-task'
import IssueResourceDuplicateTasksComponent from './resources/resource-duplicate-tasks'
import IssueResourcePaymentTransactionComponent from './resources/resource-payment-transaction'
import IssueResourcePaymentEventsComponent from './resources/resource-payment-events'
import IssueResourceClientComponent from './resources/resource-client'
import IssueResourceClientStatsComponent from './resources/resource-client-stats'
import IssueResourceDuplicateClientsComponent from './resources/resource-duplicate-clients'
import IssueResourceSubtaskComponent from './resources/resource-subtask'
import IssueResourceExpertComponent from './resources/resource-expert'
import IssueResourceProjectFeedbackComponent from './resources/resource-project-feedback'
import IssueResourceProjectListComponent from './resources/resource-project-list'
import IssueResourceExpertTeamChangelogComponent from './resources/resource-expert-team-changelog'
import IssueResourceReviewComponent from './resources/resource-review'

import IssueResourceIncidentComponent from './resources/resource-incident'
import ButtonCreateIncidentIssueComponent from './resources/resource-incident/button-create-incident/button-create-incident.component'


import IssueResourceUnresponsiveModule from './resources/resource-unresponsive'


const IssueViewModule = angular.module('cdbl.issue-view', [
  'ui.router',
  IssueViewNotesModule,
  IssueViewTabsHistory,
  IssueViewTabsModule,

  IssueResourceUnresponsiveModule
])

  .component('cdblIssueView', IssueViewComponent)
  .service('IssueViewService', IssueViewService)

  // Supporting components
  .component('cdblIssueViewSummary', IssueViewSummaryComponent)
  .component('cdblIssueResourceClient', IssueResourceClientComponent)
  .component('cdblIssueResourceClientStats', IssueResourceClientStatsComponent)
  .component('cdblIssueResourceDuplicateClients', IssueResourceDuplicateClientsComponent)
  .component('cdblIssueResourcePaymentTransaction', IssueResourcePaymentTransactionComponent)
  .component('cdblIssueResourcePaymentEvents', IssueResourcePaymentEventsComponent)
  .component('cdblIssueResourceTask', IssueResourceTaskComponent)
  .component('cdblIssueResourceDuplicateTasks', IssueResourceDuplicateTasksComponent)
  .component('cdblIssueResourceSubtask', IssueResourceSubtaskComponent)
  .component('cdblIssueResourceExpert', IssueResourceExpertComponent)
  .component('cdblIssueResourceProjectFeedback', IssueResourceProjectFeedbackComponent)
  .component('cdblIssueResourceProjectList', IssueResourceProjectListComponent)
  .component('cdblIssueResourceExpertTeamChangelog', IssueResourceExpertTeamChangelogComponent)
  .component('cdblIssueResourceReview', IssueResourceReviewComponent)
  .component('cdblIssueResourceIncident', IssueResourceIncidentComponent)
  .component('cdblIssueSystemCreateIncidentButton', ButtonCreateIncidentIssueComponent)

  .config($uiRouterProvider => {
    'ngInject'

    $uiRouterProvider.stateRegistry
      .register({
        parent: 'issueList',
        name: 'issueView',
        url: '/:issueId/:issueTabId',
        params: {
          issueTabId: { squash: true, value: 'notes', dynamic: true }
        },
        views: {
          'issueView@issues': 'cdblIssueView'
        }
      })
  })
  .name


export default IssueViewModule
