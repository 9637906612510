import templateUrl from './expert-team.html'
import './expert-team.scss'
import ExpertTeamMemberEditDialog from './users-expert-team-member-edit/users-expert-team-member-edit.dialog'

const ExpertTeamComponent = {
  bindings: {
    user: '<'
  },
  templateUrl,
  controller: class ExpertTeam {
    constructor (ExpertTeamService, $mdDialog, ToastService) {
      'ngInject'
      this._identify = 'ExpertTeamComponent'
      this.ExpertTeamService = ExpertTeamService
      this.loading = false
      this.team = null
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService

      this.gridOptions = {
        enableHiding: false,
        rowHeight: 80,
        minimumColumnSize: 150,
        appScopeProvider: this,
        onRegisterApi: function (gridApi) {
          this.gridApi = gridApi
          this.gridApi.core.handleWindowResize()
        },
        data: [],
        columnDefs: [
          { name: 'Name', field: 'name' },
          { name: 'Roles', field: 'roles', cellTemplate: '<div ng-repeat="item in row.entity[col.field]">{{item.name}}</div>' },
          { name: 'Email', field: 'email' },
          { name: 'Contract Type', field: 'employee', cellTemplate: '<div>{{row.entity[col.field] ? "Employee" : "Contractor"}}</div>' },
          { name: 'Agreement Link', field: 'agreementLink' },
          { name: 'Location', field: 'location' },
          { name: 'State', field: 'state' },
          { name: '', field: 'edit', enableSorting: false, enableColumnMenu: false, cellTemplate: '<div style="text-align: center;"><cdbl-button ng-click="grid.appScope.openEditDialog(row)">Edit</cdbl-button>' },
        ]
      }
    }

    $onInit () {
      this.getTeamData()
    }

    getTeamData () {
      this.loading = true
      this.ExpertTeamService.fetchExpertTeam(this.user.id)
        .then(response => {
          this.team = response
          this.gridOptions.data = response.members
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    }

    toggleTeamStatus (teamEnabled) {
      this.teamEnabled = !teamEnabled
      const teamStatus = this.teamEnabled ? 'deleteEnablement' : 'createEnablement'
      const config = {
        'verb': this.team.actions[teamStatus].method,
        'url': this.team.actions[teamStatus].url,
      }

      this.ExpertTeamService.toggleEnablement(config)
        .then(response => {
          this.ToastService.show('Team enablement updated')
        })
        .catch(err => {
          this.ToastService.show('Something went wrong...', err)
        })
    }

    openEditDialog (row) {
      this.$mdDialog.show({
        ...ExpertTeamMemberEditDialog,
        locals: {
          member: row.entity,
          teamId: this.team.id
        }
      }).then(dialogResponse => {
      }, dialogError => {
        // Refresh table data after update was done
        this.getTeamData()
      })
    }
  }
}

export default ExpertTeamComponent
