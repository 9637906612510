/* eslint-disable */
(function() {
  'use strict';

  function WithdrawalsCtrl($scope, $location, $stateParams, $modal, $interpolate, WithdrawalsResource, PaginationService) {
		// Loading + pagination
		$scope.states = ['pending', 'confirmed', 'cancelled'];
		$scope.filterState = $stateParams.status || 'pending';

    $scope.loadingNewTasks = false;
    $scope.pagination = PaginationService.init({
      itemsPerPage: 20,
      mode: 'server'
    });

		$scope.onSelectPage = function(page) {
      $scope.loadingNewTasks = true;

      var status  = $scope.filterState;
			var params = {
        status: status,
        page: page,
        per_page: $scope.pagination.itemsPerPage
      };



      WithdrawalsResource
        .getWithdrawalRequests(params)
        .then(response => {
          console.log('[WithdrawalsCtrl] > onSelectPage > response', params, response);
          $scope.pagination.updateFromHeaders(response);

          $scope.withdrawals = response.data;
          // DEPRECATED: Pagination
          // $scope.itemsPerPage = 20;
          // $scope.maxSize = 5;
          // $scope.totalItems = withdrawals.headers('X-Total-Count');
          // $scope.numPages = withdrawals.headers('X-Page-Count');
          // $scope.currentPage = page.toString();
			  })
        .finally(() => {
          $scope.loadingNewTasks = false;
        });
		};

    $scope.filter = function() {
      $scope.onSelectPage('1');
    };

		$scope.onSelectPage('1');

		// Confirm Payment
    $scope.openConfirmationModal = function(withdrawal) {
      $scope.withdrawal = withdrawal;

      $modal.open({
        templateUrl: '/app/scripts/withdrawalRequests/confirmationModal.html',
        controller: 'WithdrawalRequestConfirmationCtrl',
        resolve: {
          withdrawal: function() {
            return $scope.withdrawal;
          }
        }
      });
    };

    $scope.openCancellationModal = function(withdrawal) {
      $scope.withdrawal = withdrawal;

      $modal.open({
        templateUrl: '/app/scripts/withdrawalRequests/cancellationModal.html',
        controller: 'WithdrawalRequestCancellationCtrl',
        resolve: {
          withdrawal: function() {
            return $scope.withdrawal;
          }
        }
      });
    };

    // $scope.expertCellContent = function(withdrawal) {
    //   var cellContent = withdrawal.expertName + ' (' + withdrawal.expertEmail + ')';

    //   return cellContent;
    // };

    $scope.paymentMethodIsWire  = function(withdrawal) {
      return (withdrawal.paymentMethod === 'wire');
    };

    $scope.paymentMethodCellContent = function(withdrawal) {
      var cellContent = withdrawal.paymentMethod.toUpperCase();

      if ( !$scope.paymentMethodIsWire(withdrawal) ) {
        cellContent = cellContent + ' (' + withdrawal.expertPaymentAccountEmail + ')';
      }
      return cellContent;
    };

    $scope.total  = function() {
      if (angular.isArray($scope.withdrawals)) {
        return $scope.withdrawals.reduce(
          function(t, withdrawal) {
            return t + (withdrawal.amount * 1);
          }, 0);
      }
      return ''
    };

  }

  window.app.controller('WithdrawalsCtrl', ['$scope', '$location', '$stateParams', '$modal', '$interpolate', 'WithdrawalsResource', 'PaginationService', WithdrawalsCtrl]);

})();
