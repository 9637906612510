import templateUrl from './dialog-project-edit-budget.dialog.html'
import './dialog-project-edit-budget.dialog.scss'

const DialogProjectEditBudget = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogProjectEditBudget {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'DialogProjectEditBudget'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService

      this.isLoading = false
      this.showConfirmation = false
    }

    $onInit () {
      const budgetExists = typeof this.project.budget !== 'undefined' && this.project.budget !== null

      this.budgetFormatAsRange = Boolean(this.project.budget < 0)

      this.formData = {
        budget: budgetExists ? this.project.budget : null,
        predictedBudget: { ...this.project.budgetRange }
      }
    }

    $onDestroy () {

    }

    toggleBudgetFormat () {
      this.budgetFormatAsRange = !this.budgetFormatAsRange
    }

    showEditConfirmation () {
      this.showConfirmation = true
    }
    hideEditConfirmation () {
      this.showConfirmation = false
    }

    confirmEdit () {
      this.isProcessing = true
      this.ProjectsService.updateProjectBudget(this.project.id, this.formData)
        .then(response => {
          // update project with new fresh data from BE
          this.ProjectsService.updateProject(this.project, response)
          this.$mdDialog.cancel()
          this.ToastService.show('Project budget updated')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogProjectEditBudget
