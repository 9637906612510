import templateUrl from './task-comments.dialog.html'

const TaskCommentsDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class TaskCommentsDialog {
    constructor ($mdDialog, $mdToast, Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'TaskCommentsDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService

      this.commentList = null
      this.isLoading = false
    }

    $onInit () {
      console.log('[TaskCommentsDialog]', this.issue, this.task)
      this.fetchResourceTaskComments()
    }

    fetchResourceTaskComments () {
      this.isLoading = true
      this.commentList = null
      this.IssueViewService.getResourceTaskComments(this.issue.id)
        .then(response => {
          this.commentList = response.taskComments
          this.isLoading = false
        }, error => {
          console.log(error)
          this.isLoading = false
        })
    }

    $onDestroy () {
      console.log('[TaskCommentsDialog] > onDestroy')
    }
  }
}

export default TaskCommentsDialog
