(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
      .state('subTasks', {
        url: '/subTasks',
        templateUrl: '/app/scripts/subTasks/subTasks.html',
        controller: 'SubTasksCtrl'
      })
      .state('subTasksRetainers', {
        url: '/subTasksRetainers',
        templateUrl: '/app/scripts/subTasks/subTasksRetainers.html',
        controller: 'subTasksRetainersCtrl'
      });
  }

  window.app.config(['$stateProvider', states]);
})();
