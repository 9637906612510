(function() {
  'use strict';

  function UserKpisCtrl($scope, $location, $stateParams, KpisResource) {

    KpisResource.userStats().then(function(response) {
      $scope.monthlyNewSignups = response.monthlyNewSignups;
      $scope.monthlyNewPayingCustomers = response.monthlyNewPayingCustomers;
      
      $scope.newPayingClientsChart = {
        options: {
            chart: {
                type: 'column'
            }
        },
        xAxis: {
          categories: _.map(response.monthlyNewPayingCustomers, function(arr) {
                return arr[0]+"";
              })
        },
        series: [{
              name: "Monthly new paying Clients",
              type: 'column',
              data: _.map(response.monthlyNewPayingCustomers, function(arr) {
                return [arr[0]+"", arr[1]];
              })
          }],
        title: {
            text: 'Monthly new paying customers'
        },

        loading: false
      };
      
      $scope.newSignupsChart = {
        options: {
            chart: {
                type: 'column'
            }
        },
        xAxis: {
          categories: _.map(response.monthlyNewSignups, function(arr) {
                return arr[0]+"";
              })
        },
        series: [{
              name: "Monthly new signups",
              type: 'column',
              data: _.map(response.monthlyNewSignups, function(arr) {
                return [arr[0]+"", arr[1]];
              })
          }],
        title: {
            text: 'Monthly new Signups'
        },

        loading: false
      };
      
    });

    
  }

  window.app.controller('UserKpisCtrl', ['$scope', '$location', '$stateParams', 'KpisResource', UserKpisCtrl]);
	
})();
