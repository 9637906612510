import AuthLoginComponent from './auth-login.component'

const AuthLoginModule = angular.module('cdbl.auth.auth-login', [

])
  .component('cdblAuthLogin', AuthLoginComponent)
  .config(($uiRouterProvider, $urlRouterProvider) => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'auth.login',
        url: '/login',
        component: 'cdblAuthLogin'
      })
  })
  .name

export default AuthLoginModule
