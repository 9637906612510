import templateUrl from './earning-certificates.html'
import './earning-certificates.scss'

const EarningCertificatesComponent = {
  bindings: {
    user: '<'
  },
  templateUrl,
  controller: class EarningCertificates {
    constructor (EarningsCertificateService, $mdDialog, ToastService, $window) {
      'ngInject'
      this._identify = 'EarningCertificatesComponent'
      this.EarningsCertificateService = EarningsCertificateService
      this.loading = false
      this.team = null
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.$window = $window
    }

    $onInit () {
      this.gridOptions = {
        enableHiding: false,
        rowHeight: 80,
        minimumColumnSize: 150,
        appScopeProvider: this,
        onRegisterApi: function (gridApi) {
          this.gridApi = gridApi
          this.gridApi.core.handleWindowResize()
        },
        data: [],
        columnDefs: [
          { name: 'Certificate #', field: 'id' },
          { name: 'Period', cellTemplate: '<span> {{ row.entity.startDate }} - {{ row.entity.endDate }} </span>' },
          { name: 'Requested on', cellTemplate: '<span> {{ row.entity.createdAt | dateFormat }} </span>' },
          { name: ' ', cellTemplate: `<div ng-show="row.entity.certificatePdfUrl.url">
            <cdbl-button class="button-link" ng-click="grid.appScope.downloadCertificate(row)">
                View and Download
            </cdbl-button>
            </div>
            <div ng-show="!row.entity.certificatePdfUrl.url">
              Pending
            </div>`
          },
        ]
      }
      this.getCertificates()
    }

    getCertificates () {
      this.loading = true
      this.EarningsCertificateService.fetchEarningCertificates(this.user.id)
        .then(response => {
          this.certificates = response
          this.gridOptions.data = this.certificates
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }

    downloadCertificate (cert) {
      this.$window.open(cert.entity.certificatePdfUrl && cert.entity.certificatePdfUrl.url, '_blank')
    }
  }
}

export default EarningCertificatesComponent
