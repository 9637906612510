import templateUrl from './project-widget-expert-pods.html'
import './project-widget-expert-pods.scss'

import ManageExpertPodsProjectDialog from './dialog-manage-expert-pods-project'
const ProjectWidgetExpertPodsComponent = {
  bindings: {
    project: '<',
    isWidget: '<'
  },
  templateUrl,
  controller: class ProjectWidgetExpertPods {
    constructor ($mdDialog, $mdToast, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectWidgetExpertPodsComponent'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.ProjectsService = ProjectsService
    }

    $onInit () {
      this.expertPodList = []
      this.isProcessing = false
    }

    get hasExpertPods () {
      return this.ProjectsService.hasExpertPods(this.project)
    }

    get hasPreferredExperts () {
      return this.ProjectsService.hasPreferredExperts(this.project)
    }

    publishForAllPrompt () {
      this.$mdDialog
        .show(this.$mdDialog.confirm({
          title: `Publish project for all`,
          textContent: `Are you sure you want to publish project for all experts, #${this.project.id} - "${this.project.title}"?`,
          ok: 'Yes, publish for all',
          cancel: 'No',
          theme: 'warn'
        }))
        .then(dialogResponse => {
          this.publishForAll()
        }, dialogResponse => {

        })
    }


    openEditModal () {
      this.$mdDialog
        .show({
          ...ManageExpertPodsProjectDialog,
          locals: {
            project: this.project
          }
        })
        .then(response => {
          console.log(response)
          this.$mdToast.show(this.$mdToast.simple({ textContent: 'Expert pod added', position: 'top right' }))
        })
        .catch(reject => {
          console.log(reject)
        })
    }
  }
}

export default ProjectWidgetExpertPodsComponent
