// TODO: update this filter ... see the urgency/complexity widget ... move options to config

const ProjectComplexityFormatFilter = function () {
  'ngInject'
  return function (urgencyId) {
    let urgencyString = 'N/A'

    switch (urgencyId) {
    case 'low':
      urgencyString = 'Low'
      break
    case 'medium':
      urgencyString = 'Medium'
      break
    case 'high':
      urgencyString = 'High'
      break
    }

    return urgencyString
  }
}

export default ProjectComplexityFormatFilter
