(function() {
  'use strict';

  function budgetToString() {
    return function(budget) {
      if (budget === null) {
        budget = 'not sure or flexible';
      } else if (budget === -1) {
        budget = 'up to $200';
      } else if (budget === -2) {
        budget = '$200 - $600';
      } else if (budget === -3) {
        budget = '$600 - $1000';
      } else if (budget === -4) {
        budget = 'more than $1000';
      } else if (budget === -5) {
        budget = 'not sure';
      } else if (budget === -6) {
        budget = 'flexible';
      } else if (budget === -7) {
        budget = '$1000 - $3000';
      } else if (budget === -8) {
        budget = '$3000 - $5000';
      } else if (budget === -9) {
        budget = '$5000 - $7000';
      } else if (budget === -10) {
        budget = '$7000 - $9000';
      } else if (budget === -11) {
        budget = 'up to $500';
      } else if (budget === -12) {
        budget = '$500 - $1000';
      } else if (budget === -13) {
        budget = '$1000 - $3000';
      } else if (budget === -14) {
        budget = '$3000 - $10000';
      } else if (budget === -15) {
        budget = 'more than $10000';
      } else if (budget === -3000) {
        budget = '$3000 - $5000';
      } else if (budget === -5000) {
        budget = 'more than $10000';
      } else if (budget === -7000) {
        budget = '$7000 - $10000';
      }  else if (budget === -10000) {
        budget = '$10000 - $50000';
      }  else if (budget === -50000) {
        budget = 'more than $50000"';
      } else {
        budget = '$' + budget;
      }
      return budget;
    };
  }

  app.filter('budgetToString', budgetToString);
})();
