import templateUrl from './action-abandon.html'
import './action-abandon.scss'

import DialogWarning from '@/common/components/dialog-warning'

const ProjectActionAbandonComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectActionAbandon {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectActionAbandonComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }

    $onInit () {

    }

    get isActionAllowed () {
      return this.ProjectsService.isActionAllowed('abandon', this.project)
    }

    confirmAction () {
      this.isProcessing = true
      this.ProjectsService.abandonProject(this.project.id)
        .then(response => {
          // TODO: the response should be updated project so FE could update the data for the rest of the application - currently BE does not return anything back
          this.ProjectsService.updateProject(this.project, { state: 'abandoned' })
          this.ToastService.show('Project abandoned')
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    openActionDialog () {
      const vm = this
      this.$mdDialog.show({
        ...DialogWarning,
        locals: {
          text: `Are you sure you want to abandon project "${this.project.title}"?`,
          confirmCallback: function () {
            vm.confirmAction()
          }
        }
      })
    }
  }
}

export default ProjectActionAbandonComponent
