import templateUrl from './action-mark-paidoffline.html'
import './action-mark-paidoffline.scss'

import DialogProjectMarkPaidoffline from './dialog-project-mark-paidoffline'

const ProjectActionMarkPaidofflineComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectActionMarkpaidoffline {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectActionMarkPaidofflineComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }

    get isActionAllowed () {
      return this.ProjectsService.isActionAllowed('markPaidOffline', this.project)
    }

    confirmAction () {
      this.isProcessing = true
      this.ProjectsService.cancelProject(this.project.id)
        .then(response => {
          // TODO: the response should be updated project so FE could update the data for the rest of the application - currently BE does not return anything back
          this.ProjectsService.updateProject(this.project, {})
          this.ToastService.show('Project canceled')
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    openMarkPaidOfflineDialog () {
      this.$mdDialog.show({
        ...DialogProjectMarkPaidoffline,
        locals: {
          project: this.project
        }
      })
    }
  }
}

export default ProjectActionMarkPaidofflineComponent
