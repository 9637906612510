// Currently used to group user related components

// import UsersComponent from './users.component'

import UserUnlockButtonModule from './user-unlock-button'
import ExpertTeamModule from './expert-team'
import EarningsCertificateModule from './earning-certificates'

const UsersModule = angular.module('cdbl.users', [
  UserUnlockButtonModule,
  ExpertTeamModule,
  EarningsCertificateModule,
])
  // .component('cdblUsers', UsersComponent)
  .name

export default UsersModule
