(function() {
  'use strict';

  function TasksResource($q, Restangular, ResourceManipulation) {
		var local = {};
    var task;

    var getTasks = function(options) {
      var filter = Object.assign({}, options);
      delete filter.trackingName;
      delete filter.partnerName;

      if (options.trackingName) {
        filter.tracking_name = options.trackingName;
      }
      if (options.partnerName) {
        filter.partner_name = options.partnerName;
      }

      return Restangular.all('admin').all('tasks').get('', filter);
    };

    var getTask = function(id, options, manualLoad) {
      task = Restangular.all('admin').one('tasks', id);
      var returnObject = {};

      if (!manualLoad) {
        options.forEach(function(option) {
          var resource = local['get' + option.capitaliseFirstLetter()].call(this);
          returnObject[option] = ResourceManipulation.resolveError(resource);
        });

        return $q.all(returnObject);
      } else {
        options.forEach(function(option) {
          var resource = local['get' + option.capitaliseFirstLetter()];
          returnObject[option] = resource;
        });

        return returnObject;
      }
    };

    local.getTask = function() {
      return task.get()
    };

    local.getComments = function() {
      return task.all('comments').getList();
    };

    local.getCommentTemplates = function() {
      return Restangular.all('admin').all('comment_templates').getList();
    };

    local.getReviews = function() {
      return task.all('reviews').getList();
    };

    local.getTransactions = function() {
      return task.all('transactions').getList();
    };

    local.getEstimates = function() {
      return task.all('estimates').getList();
    };

    local.getTransitions = function() {
      return task.all('state_transitions').getList();
    };

    local.getActivities = function() {
      return task.all('activities').getList();
    };

    local.getAttachments = function() {
      return task.all('attachments').getList();
    };

    local.getTickets = function () {
      return task.all('tickets').getList()
    }

    var removeAttachment = function(task, attachment) {
      Restangular.all('admin').one('tasks', task.id).one('attachments', attachment.id).remove();
    };

    var createInvitation = function(taskId, userId) {
      return Restangular.all('admin').one('tasks', taskId).post('invitations', {
        userId: userId
      });
    };

    var removeEstimate = function(taskId, estimateId) {
      return Restangular.all('admin').one('tasks', taskId).one('estimates', estimateId).remove();
    };

    var convertConsultationToNormal = function(taskId, kind) {
      return Restangular.all('admin').one('tasks', taskId).customPUT({
        kind: kind
      });
    };

    return {
      getTasks: getTasks,
      getTask: getTask,
      removeAttachment: removeAttachment,
      removeEstimate: removeEstimate,
      createInvitation: createInvitation,
      convertConsultationToNormal: convertConsultationToNormal
    };
  }

  window.app.service('TasksResource', ['$q', 'Restangular', 'ResourceManipulation', TasksResource]);
})();
