import templateUrl from './occurrence-details.dialog.html'

const OccurrenceDetailsDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: true,
  clickOutsideToClose: true,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class OccurrenceDetailsDialog {
    constructor ($mdDialog, $mdToast, Configuration, IssueResourceUnresponsiveService) {
      'ngInject'
      this._identify = 'OccurrenceDetailsDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.IssueResourceUnresponsiveService = IssueResourceUnresponsiveService

      this.occurrenceIssue = null
    }

    $onInit () {
      console.log('[OccurrenceDetailsDialog]', this.issueSummary)

      this.IssueResourceUnresponsiveService.getOccurrence(this.issueSummary)
        .then(response => {
          this.occurrenceIssue = response
        })
    }

    $onDestroy () {
      console.log('[OccurrenceDetailsDialog] > onDestroy')
    }

    saveTemplate () {

    }
  }
}

export default OccurrenceDetailsDialog
