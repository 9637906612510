import templateUrl from './workroom-interventions.html'
import './workroom-interventions.scss'

const WorkroomInterventionsComponent = {
  templateUrl,
  bindToController: true,
  controllerAs: '$ctrl',
  controller: class WorkroomInterventions {
    constructor ($mdDialog, WorkroomInterventionsService) {
      'ngInject'
      this._identify = 'WorkroomInterventionsComponent'
      this.$mdDialog = $mdDialog
      this.WorkroomInterventionsService = WorkroomInterventionsService

      this.selectedScenario = null
      this.selectedMethod = null
      this.interventionNote = null
      this.interventionSnippet = null

      this.interventionScenarios = null

      this.isLoading = false
    }

    $onInit () {
      console.log('[INFO] onInit: ', this.project)

      // Instantiate interventions array if not returned by BE
      if (!this.project.interventions) {
        this.project.interventions = []
      }

      this.fetchInterventionScenarios(this.project)
      this.resetForm()
    }

    fetchInterventionScenarios (project) {
      console.log('[INFO] fetchInterventionScenarios: ', project)
      this.isLoading = true
      this.WorkroomInterventionsService
        .fetchInterventionScenarios(this.project)
        .then(response => {
          console.log('[INFO][REMOTE] fetchInterventionScenarios > response: ', response)

          this.interventionScenarios = response
          this.selectedScenario = this.interventionScenarios[0] // select first on load
          this.updateSelectedScenarioMethod()
        }, error => {
          console.error('[ERROR] fetchInterventions: ', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    addIntervention () {
      console.log('[INFO] addIntervention: ', this.project, this.selectedMethod)
      const formData = {
        selectedMethod: this.selectedMethod,
        interventionNote: this.interventionNote,
        interventionSnippet: this.interventionSnippet
      }

      this.isLoading = true
      this
        .WorkroomInterventionsService
        .addIntervention(this.project, formData)
        .then(response => {
          console.log('[INFO] addIntervention: ', response)

          // Improvement: to update UI without additional endpoint response should be actual intervention
          // this.project.interventions.push(response)
          // --

          // Currently pooling BE for new list of interventions because POST response return different object than list (see above)
          this.fetchInterventions()
          // --

          this.resetForm()
        }, error => {
          console.error('[ERROR] addIntervention: ', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    removeIntervention (intervention) {
      console.log('[INFO] removeIntervention: ', intervention, this.project)

      this.isLoading = true
      this
        .WorkroomInterventionsService
        .removeIntervention(this.project, intervention)
        .then(response => {
          console.log('[INFO] removeIntervention: ', response)

          const interventionIndex = this.project.interventions.findIndex(i => i.id === intervention.id)
          console.log('[INFO] removeIntervention >', interventionIndex)
          if (interventionIndex > -1) {
            this.project.interventions.splice(interventionIndex, 1)
          }
        }, error => {
          console.error('[ERROR] removeIntervention: ', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    onScenarioChanged () {
      console.log('[INFO] onScenarioChanged: ', this.selectedScenario)
      this.updateSelectedScenarioMethod()
    }

    updateSelectedScenarioMethod () {
      console.log('[INFO] updateSelectedScenarioMethods: ', this.selectedScenario)
      this.selectedMethod = this.selectedScenario.methods[0]
    }

    fetchInterventions () {
      this.WorkroomInterventionsService
        .fetchInterventions(this.project)
        .then(response => {
          this.project.interventions = response.interventions
        })
    }

    resetForm () {
      this.interventionNote = null
      this.interventionSnippet = null
    }
  }
}

export default WorkroomInterventionsComponent
