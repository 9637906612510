const ProjectScopeDocService = class ProjectScopeDocService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'ProjectScopeDocService'
    this.$http = $http
    this.Configuration = Configuration
  }


  // Helpers
  scopeDocProgress (type = 'percent', scopeDoc = null) {
    if (scopeDoc) {
      const countItems = (acc, item) => {
        acc += 1 + item.scopeDocChildItems.length

        return acc
      }

      const countItemsDone = (acc, item) => {
        if (item.finishedAt) {
          acc++
        }

        if (item.scopeDocChildItems.length) {
          acc += item.scopeDocChildItems.reduce(countItemsDone, 0)
        }

        return acc
      }
      const itemsTotal = scopeDoc.scopeDocItems.reduce(countItems, 0)
      const itemsDone = scopeDoc.scopeDocItems.reduce(countItemsDone, 0)

      return type === 'percent' ? `${Math.round(itemsDone / itemsTotal * 100)}%` : `${itemsDone}/${itemsTotal}`
    }

    return '0 / 0'
  }

  // API

  getScopeDoc (projectId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/scope_doc`)
      .then(response => {
        if (response.data && Object.keys(response.data).length === 0) {
          return null // handle empty object case (TODO: look into data intercepter)
        }

        return response.data
      })
  }

  deleteScopeDoc (projectId) {
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/tasks/${projectId}/scope_doc`)
      .then(response => {
        if (response.data && Object.keys(response.data).length === 0) {
          return null // handle empty object case (TODO: look into data intercepter)
        }

        return response.data
      })
  }
}

export default ProjectScopeDocService
