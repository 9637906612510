(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('alerts', {
      url: '/task_alerts',
      templateUrl: '/app/scripts/alerts/list.alerts.html',
      controller: 'AlertsCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
