import GrowthTargetsComponent from './growth-targets.component'
import GrowthTargetsService from './growth-targets.service'

const GrowthTargetsModule = angular.module('cdbl.growth-targets', [

])
  .component('cdblGrowthTargets', GrowthTargetsComponent)
  .service('GrowthTargetsService', GrowthTargetsService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'growthTargets',
        url: '/growthTargets',
        component: 'cdblGrowthTargets'
      })
  })
  .name

export default GrowthTargetsModule
