(function() {
  'use strict';

  function colorFilter() {
    return function (value, type) {
      var color = '';

      if (type === 'string') {
        switch (value) {
          case 'S':
            color = '#00e600';

            break;
          case 'A':
            color = '#abe199';

            break;
          case 'B':
            color = '#f8fb43';

            break;
          case 'C':
            color = '#f1bf00';

            break;
        }
      } else if (type === 'number') {
        if (value >= 4.99) {
          color = '#00e600';
        } else if (value > 4) {
          color = '#a3ec00';
        } else if (value > 3) {
          color = '#ebe100';
        } else {
          color = '#f29100';
        }
      }

      return color;
    };
  }

  app.filter('colorFilter', colorFilter);
})();