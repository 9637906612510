import templateUrl from './settings-superusers.html'
import './settings-superusers.scss'

const SettingsAccountComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsAccount {
    constructor (UserService, SettingsSuperusersService) {
      'ngInject'
      this._identify = 'SettingsAccountComponent'

      this.UserService = UserService
      this.SettingsSuperusersService = SettingsSuperusersService
    }

    $onInit () {
      this.filters = {
        search: ''
      }

      this.gridOptions = {
        enableHiding: false,
        rowHeight: 80,
        minimumColumnSize: 200,
        appScopeProvider: this,
        onRegisterApi: function (gridApi) {
          this.gridApi = gridApi
          this.gridApi.core.handleWindowResize()
        },
        data: [],
        columnDefs: [
          { name: 'Id', field: 'id' },
          { name: 'Name', field: 'fullName' },
          { name: 'Email', field: 'email' },
          { name: 'Current sign in time', field: 'currentSignInAt', cellTemplate: `<span>{{ row.entity[col.field] | dateFormat }}</span>` },
          { name: 'Last sign in time', field: 'lastSignInAt', cellTemplate: `<span>{{ row.entity[col.field] | dateFormat }}</span>` },
          { name: 'Current sign in IP', field: 'currentSignInIp' },
          { name: 'Last sign in IP', field: 'lastSignInIp' },
          { name: 'Account active', field: 'active', cellTemplate: `<span>{{ row.entity.active ? 'Yes' : 'No' }}</span>` },
          { name: 'Account locked', field: 'accessLocked', cellTemplate: `<span>{{ row.entity.accessLocked ? 'Yes' : 'No' }}</span>` },
          { name: '2FA status', field: 'otpRequiredForLogin', cellTemplate: `<span>{{ row.entity.otpRequiredForLogin ? 'Enabled' : 'Disabled' }}</span>` },
          { name: 'Permissions', field: 'permissionsAsString' }
        ]
      }

      // fetch fresh user data
      this.isLoading = true
      this.SettingsSuperusersService.getUsers()
        .then(response => {
          response.forEach(user => {
            const permissionsList = []
            Object.entries(user.permissions).forEach(([resourceName, permissions]) => {
              const actions = Object.entries(permissions)
                .filter(([permsissionAction, value]) => value)
                .map(([permsissionAction, value]) => permsissionAction)
                .join(', ')
              permissionsList.push(`${resourceName} (${actions})`)
            })

            user.permissionsAsString = permissionsList.length > 0 ? permissionsList.join(', ') : 'N/A'
          })

          this.userList = response
          this.gridOptions.data = response
          console.log('gridOptions', this.gridOptions)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}

export default SettingsAccountComponent
