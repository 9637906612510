(function() {
  'use strict';

  function KpisCtrl($scope, $location, $stateParams, KpisResource) {

    $scope.days = 7;

    $scope.$watch('days', function(){
      KpisResource.taskCounts({days: $scope.days}).then(function(counts) {
        $scope.tasksChartConfig = {
                options: {
                    chart: {
                        type: 'column'
                    }
                },
                xAxis: {
                  type: 'datetime',
                  dateTimeLabelFormat: {
                    day: '%m-%d'
                  }
                },
                series: counts.series,
                title: {
                    text: 'Projects in the last '+$scope.days.toString()+' days'
                },
                loading: false,
                colors: ['#47475C', '#90ee7e']
            };

      });
    });
    KpisResource.taskCounts({days: $scope.days}).then(function(counts) {
      $scope.tasksChartConfig = {
              options: {
                  chart: {
                      type: 'column'
                  }
              },
              xAxis: {
                type: 'datetime',
                dateTimeLabelFormat: {
                  day: '%m-%d'
                }
              },
              series: counts.series,
              title: {
                  text: 'Projects in the last '+$scope.days.toString()+' days'
              },
              loading: false,
              colors: ['#47475C', '#90ee7e']
          };

    });

  }

  window.app.controller('KpisCtrl', ['$scope', '$location', '$stateParams', 'KpisResource', KpisCtrl]);

})();
