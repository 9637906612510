import ExpertTeamComponent from './expert-team.component'
import ExpertTeamService from './expert-team.service'

const ExpertTeamModule = angular.module('cdbl.users-expert-team', [

])
  .component('cdblExpertTeam', ExpertTeamComponent)
  .service('ExpertTeamService', ExpertTeamService)
  .name

export default ExpertTeamModule
