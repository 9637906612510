import templateUrl from './project-widget-type.html'
import './project-widget-type.scss'

const ProjectWidgetTypeComponent = {
  bindings: {
    project: '<',
    inline: '<',
    dropdownActions: '<'
  },
  templateUrl,
  controller: class ProjectWidgetType {
    constructor (ProjectsService) {
      'ngInject'
      this._identify = 'ProjectWidgetTypeComponent'
      this.ProjectsService = ProjectsService

      this.isProcessing = false
    }

    get canConvert () {
      return this.project && (this.project.state === 'created' || this.project.state === 'published')
    }

    get convertToText () {
      return this.ProjectsService.isConsultation(this.project) ? 'project' : 'consultation'
    }

    get typeText () {
      return this.project.projectType === null ? 'none' : this.project.projectType
    }

    get subjectText () {
      return this.project.projectSubject === null ? 'none' : this.project.projectSubject
    }

    get isProject () {
      return this.project.projectType !== 'consultation'
    }

    convert () {
      if (this.isProcessing) {
        return
      }

      this.isProcessing = true
      this.ProjectsService.projectConvertType(this.project.id)
        .then(response => {
          this.ProjectsService.updateProject(this.project, response)
        })
        .catch(err => {
          console.log('[ProjectWidgetTypeComponent] > convert > err', err)
          // TODO use toast to display error message (sync with group on how to display such messages)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default ProjectWidgetTypeComponent
