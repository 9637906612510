import templateUrl from './resource-review.html'
import './resource-review.scss'

const IssueResourceReviewComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,

  controller: class IssueResourceReview {
    constructor (Configuration, IssueViewService, PusherService, $timeout) {
      'ngInject'
      this._identify = 'IssueResourceReviewComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService
      this.PusherService = PusherService
      this.$timeout = $timeout

      this.feedbackList = null

      // Declare listeners
      const vm = this
      this.resourceUpdatedListener = function (event) {
        vm.$timeout(() => {
          vm.fetchResourceFeedback()
        })
      }
    }

    $onInit () {
      this.fetchResourceFeedback()
      this.subscribeToIssueResource()
    }

    $onDestroy () {
      this.unsubscribeToIssueResource()
    }

    subscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.bind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    unsubscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.unbind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    fetchResourceFeedback () {
      this.feedbackList = null
      this.IssueViewService.getResourceReview(this.issue.id)
        .then(response => {
          this.review = response
        }, error => {
          console.log(error)
        })
    }

    feedbackType (feedback) {
      switch (feedback.type) {
      case 'cancel_task':
        return 'Project canceled feedback'
      case 'no_estimate':
        return 'Project no estimate feedback'
      }

      return feedback.type
    }
  }
}

export default IssueResourceReviewComponent
