import templateUrl from './listing.html'
import './listing.scss'

const ListingComponent = {
  bindings: {},
  transclude: true,
  templateUrl,
  controller: class Listing {
    constructor () {
      'ngInject'
      this._identify = 'ListingComponent'
    }
  }
}

export default ListingComponent
