// import soundFillingYourInbox from '@/assets/sounds/filling-your-inbox.mp3'
import soundStairs from '@/assets/sounds/stairs.mp3'

const SoundService = class SoundService {
  constructor (Configuration, Auth, $timeout) {
    'ngInject'
    this._identify = 'SoundService'
    this.Configuration = Configuration
    this.Auth = Auth
    this.$timeout = $timeout


    this.sounds = {
      notification: new Audio(soundStairs)
    }

    this.isActive = false
  }

  endedHandler (evt) {
    this.$timeout(() => {
      this.isActive = false
      console.log('[SoundService] > ended', evt)
    })
  }

  play (soundId) {
    if (!this.isActive) {
      this.isActive = true

      this.sounds[soundId].addEventListener('ended', this.endedHandler.bind(this), { once: true })
      this.sounds[soundId].soundId = soundId
      this.sounds[soundId].play()
    }
  }
}
export default SoundService
