import SettingsUnlayerTemplatesComponent from './settings-unlayer-templates.component'
import SettingsUnlayerTemplatesService from './settings-unlayer-templates.service'


const SettingsUnlayerTemplatesModule = angular.module('cdbl.settings-unlayer-templates', [

])
  .component('cdblSettingsUnlayerTemplates', SettingsUnlayerTemplatesComponent)
  .service('SettingsUnlayerTemplatesService', SettingsUnlayerTemplatesService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'settings-unlayer-templates',
        url: '/settings/unlayer-templates',
        component: 'cdblSettingsUnlayerTemplates',
        resolve: {
          permissionResolver: ($state, PermissionsService) => {
            'ngInject'

            console.log('$state', $state)
            return PermissionsService.resolver(PermissionsService.canAccess('unlayerTemplates'))
          }
        }
      })
  })
  .name

export default SettingsUnlayerTemplatesModule
