(function () {
  'use strict'

  function EarningCertificatesResource ($q, Restangular) {
    var getEarningCertificates = function (options) {
      console.log('request', options)
      return Restangular.all('admin').all('earning-certificates').get('', options)
    }

    return {
      getEarningCertificates: getEarningCertificates
    }
  }

  window.app.service('EarningCertificatesResource', ['$q', 'Restangular', EarningCertificatesResource])
})()
