(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider.state('inactive_contractors', {
      url: '/inactive_contractors',
      templateUrl: '/app/scripts/inactiveContractors/list.inactive.contractors.html',
      controller: 'InactiveContractorsCtrl',
      resolve: {
        inactiveContractors: ['InactiveContractorsResource', function(InactiveContractorsResource) {
          return InactiveContractorsResource.getInactiveContractors();
        }]
      }
    });
  }

  window.app.config(['$stateProvider', states]);
})();