import defaultAvatarImage from '@/assets/img/defaultAvatarImage.jpg'
import transparentImage from '@/assets/img/transparentImage.png'
import systemAvatarImage from '@/assets/img/systemAvatarImage.png'

import jsonInflector from 'json-inflector'
// import IssuesConfig from './issue-system.config.js'

const IssuesService = class IssuesService {
  constructor ($q, $http, $state, $stateParams, $timeout, Configuration, PusherService, EventBus, UserService, LoadingService, SoundService) {
    'ngInject'
    this._identify = 'IssuesService'
    this.$q = $q
    this.$http = $http
    this.$state = $state
    this.$stateParams = $stateParams
    this.$timeout = $timeout
    this.Configuration = Configuration
    this.PusherService = PusherService
    this.EventBus = EventBus
    this.UserService = UserService
    this.LoadingService = LoadingService
    this.SoundService = SoundService

    this.currentUserHasInbox = false

    this.folderList = null
    this.issueList = null
    this.inboxUserList = null // used for avatars and data about users using issue system
    this.inboxList = null
    this.issueListPagination = null
    this.issue = null

    // Default field content for main issue actions, TODO: move to config file and map here
    this.fieldsConfig = {
      status: {
        disabled: true, // to control disabled state of field but to still allow values to be displayed
        values: [
          {
            status: 'open',
            name: 'Open',
            disabled: true
          },
          {
            status: 'resolved',
            name: 'Resolved',
            disabled: true
          }
        ]
      },
      assignee: {
        values: []
      },
      snooze: {
        disabled: true, // to control disabled state of field but to still allow values to be displayed
        values: []
      }
    }

    this.redirectOnInit = true

    this.issuesAllowedEvents = this.Configuration.issueSystem.pusherIssueAllowedEvents
    this.issuesCreatedEvents = this.Configuration.issueSystem.pusherIssueCreatedEvents
    this.issuesUpdatedEvents = this.Configuration.issueSystem.pusherIssueUpdatedEvents
  }

  get issue () {
    return this._issue
  }

  set issue (data) {
    this._issue = data
  }


  // Init function that executes in app.component only if user is logged in
  init () {
    console.log('[IssuesService] > init')
    return this.getInboxList()
      .then(response => {
        this.subscribeToIssues()
      })
  }

  // Destroy function that executes in app.component if user is logged out
  destroy () {
    console.log('[IssuesService] > destroy')
    this.unsubscribeFromIssues()

    this.folderList = []
    this.issueList = null
    this.issueListPagination = null
    this.inboxUserList = null
    this.issue = null
    this.inboxList = null
  }

  // Pusher global issues channel subscription - for updating inboxes, issue list, navigation top unassigned issue count
  subscribeToIssues () {
    const channelName = this.PusherService.getChannelName('issues')
    const channel = this.PusherService.subscribe(channelName)
    this.PusherService.bindGlobal(channelName, (eventName, event) => {
      // shim that wrapps event name
      event = {
        eventName: eventName,
        ...event
      }

      // Preprocess event data if its the issue payload
      if (
        this.issuesCreatedEvents.includes(eventName) ||
        this.issuesUpdatedEvents.includes(eventName)) {
        event.data = this.processIssueResponse(event.data)

        // Ignore all child issues events that has parent property set
        if (event.data.parent) {
          return
        }
      }

      // Update inboxes on all allowed global events
      if (this.issuesAllowedEvents.includes(eventName)) {
        // TODO: implement this with new channels for inboxes and new events when BE ready, currently we are pooling endpoint
        this.getInboxList()
      }

      // If issue list is not initialized (not on HAL pages) do not try to add it or update it. This is due to using not enough of pusher
      // channels.
      // TODO: add and use separate global channel for HAL header notification updates
      if (!this.issueList) {
        return
      }

      if (this.issuesCreatedEvents.includes(eventName)) {
        // Process issue data
        console.log('[IssuesService] > pusher > NEW ISSUE', eventName, event, this.$stateParams)
        const existingIssue = this.issueList.find(issue => issue.id === event.data.id)
        if (!existingIssue) {
          this.addIssueToList(event.data)
        }

        this.SoundService.play('notification')
      } else if (this.issuesUpdatedEvents.includes(eventName)) {
        // Process issue data
        console.log('[IssuesService] > pusher > UPDATE ISSUE', eventName, event)

        // Update issue in current list if conditions apply
        const issueIndex = this.issueList.findIndex(issue => issue.id === event.data.id)
        if (issueIndex > -1) {
          this.issueList[issueIndex] = { ...this.issueList[issueIndex], ...event.data }
        } else {
          // Move issue to another inbox folder but keep it for current user in same inbox and folder (do not remove it)
          // Issue not in current issueList add it to list if conditions apply
          this.addIssueToList(event.data)
        }
      } else {
        console.warn('[IssuesService] > pusher > unhandled', channelName, eventName, event)
      }
    })

    return channel
  }

  unsubscribeFromIssues () {
    const channelName = this.PusherService.CHANNEL_NAMES.issues
    this.PusherService.unsubscribe(channelName)
  }

  // Api methods
  getInboxList () {
    console.log('[IssuesService] > getInboxList', this.$stateParams)
    // TODO: map folder id to /issues filter url string
    this.LoadingService.start('IssuesService::getInboxList')
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/in-boxes`)
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > getInboxList > response', response)

        // Create assignee list in fieldsConfig...
        // TODO: it would be better that BE returns config with assigne list and FE builds the inboxes instead
        this.fieldsConfig.assignee.values = response.data
        this.inboxList = response.data
        this.folderList = this.generateFolderStructure(response.data)
        return this.folderList
      })
      .finally(() => {
        this.LoadingService.stop('IssuesService::getInboxList')
      })
  }

  getIssueList (folderId = null, subFolderId = null, params = {}) {
    console.log('[IssuesService] > getIssueList', this.$stateParams)
    folderId = folderId || this.$stateParams.folderId
    subFolderId = subFolderId || this.$stateParams.subFolderId

    const searchQuery = {}
    const allowedQueryParams = ['type']

    allowedQueryParams.forEach(p => {
      if (this.$stateParams[p]) {
        searchQuery[p] = this.$stateParams[p]
      }
    })

    console.log('searchQuery > ', searchQuery)
    params = {
      ...{ page: 1, per_page: 1000 },
      ...params,
      ...this.buildFilterParams({ folderId, subFolderId, searchQuery })
    }



    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues`, { params })
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > getIssueList > response', response)
        this.issueListPagination = {
          pageCount: response.headers('x-page-count'),
          itemsPerPage: response.headers('x-records-per-page'),
          totalCount: response.headers('x-total-count')
        }
        this.issueList = response.data
        return response
      }, err => {
        console.log('[IssuesService] > getIssueList > err', err)
        return err
      })
  }


  getIssue (issueId) {
    console.log('[IssuesService] > getIssue', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}`)
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > getIssue > response', response)

        // Update issue object with response
        this.issue = this.processIssueResponse(response.data)
        return this.issue
      }, err => {
        console.error('[IssuesService] > getIssue > err', err)
        return err
      })
  }

  executeAction (config, payload, processAsIssue = false) {
    console.log('[IssuesService] > executeAction', config, payload)
    return this.$http({
      method: config.verb,
      url: `${this.Configuration.apiUrl}${config.url}`,
      data: payload
    })
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > executeAction > response', response)

        if (processAsIssue) {
          // Process response
          this.issue = this.processIssueResponse(response.data)
          // Update issue in current list if neccessary
          this.updateIssueInList(this.issue)
          return this.issue
        }

        return response.data
      }, err => {
        console.log('[IssuesService] > executeAction > err', err)
        return err
      })
  }

  // general use for fetching resources in issue system to hydrate the data from original responses
  callEndpoint (config, payload = {}) {
    console.log('[IssuesService] > executeEndpoint', config, payload)
    config = {
      verb: 'get',
      ...config
    }

    return this.$http({
      method: config.verb,
      url: `${this.Configuration.apiUrl}${config.url}`,
      data: payload
    })
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > executeEndpoint > response', response)
        return response.data
      }
      // DO NOT INTERCEPT ERROR HERE
      // , err => {
      //   console.log('[IssuesService] > executeEndpoint > err', err)
      //   throw Error(err)
      // }
      )
  }

  // DEVELOPMENT ONLY
  demoCreateIssue () {
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/util/issues`)
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > createDemoIssue > response', response)
      }, err => {
        console.log('[IssuesService] > createDemoIssue > err', err)
        return err
      })
  }
  get canConditionClear () {
    return this.issue && this.issue.type === 'non_production'
  }
  demoConditionClear () {
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/util/issues/${this.issue.id}`)
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > createDemoIssue > response', response)
      }, err => {
        console.log('[IssuesService] > createDemoIssue > err', err)
        return err
      })
  }
  // ---

  // CREATE AN INCIDENT ISSUE MANUALLY
  createIncidentReportIssue (payload = {}) {
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/cs/incidents`, payload)
      .then(response => {
        console.log('[IssuesService] > createIncidentIssue > response', response)
        return response.data
      })
  }
  updateIncidentReportIssue (payload = {}) {
    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/cs/incidents/${payload.id}`, payload)
      .then(response => {
        console.log('[IssuesService] > createIncidentIssue > response', response)
        return response.data
      }, err => {
        console.log('[IssuesService] > createIncidentIssue > err', err)
        return err
      })
  }
  checkIncidents (data = {}) {
    // const deferred = this.$q.defer()
    // this.$timeout(() => {
    //   deferred.resolve({ count: 5 })
    // }, 2000)
    // return deferred.promise
    const params = {
      fields: []
    }
    if (data.user && data.user.role === 'client') {
      params.fields.push('number_of_incidents_involved')
    } else if (data.user) {
      params.fields.push('number_of_incidents_responsible')
    } else if (data.project) {
      params.fields.push('number_of_incidents')
    }
    if (!data.user && !data.project) {
      throw Error('Missing user or project data')
    }

    const urlSlug = data.user ? `users/${data.user.id}/stat` : data.project ? `projects/${data.project.id}/stat` : ''
    return this.$http({
      method: 'get',
      url: `${this.Configuration.apiUrl}/admin/${urlSlug}`,
      params,
      paramSerializer: '$httpParamSerializerJQLike'
    })
      .then(response => {
        console.log('[IssuesService] > createIncidentIssue > response', response)
        return { count: response.data.numberOfIncidents || response.data.numberOfIncidentsInvolved || response.data.numberOfIncidentsResponsible }
      }, err => {
        console.log('[IssuesService] > createIncidentIssue > err', err)
        return err
      })
  }

  getIncidentReportDatasets () {
    console.log('[IssueViewService] > getIncidentReportDatasets')
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/incident_resources`)
      .then(response => {
        console.log('[IssueViewService] > getIncidentReportDatasets > response', response)
        // Remove unwanted null object from BE
        // response.data.participantsRoles = response.data.participantsRoles.filter(r => r.id !== null)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getIncidentReportDatasets > err', err)
        throw err
      })
  }


  // CREATE AN OPPORTUNITY ISSUE MANUALLY
  createOpportunityIssue (clientId) {
    const payload = {
      client_id: clientId
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/cs/opportunities`, payload)
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > createOpportunityIssue > response', response)
        return response.data
      }, err => {
        console.log('[IssuesService] > createOpportunityIssue > err', err)
        return err
      })
  }

  // GET OPPORTUNITY ISSUE
  getOpportunityIssue (clientId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/opportunities?client_id=${clientId}`)
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssuesService] > getOpportunityIssue > response', response)
        return response.data
      }, err => {
        console.log('[IssuesService] > getOpportunityIssue > err', err)
        return err
      })
  }


  // UTILITY METHODS
  clearIssue () {
    this.issue = null
  }

  processIssueResponse (issueResponse) {
    console.log('[IssuesService] > processIssueResponse', issueResponse.id)
    // --- Process issue object

    // Raname "project" resource into task until naming migration is completed, currently we have BE that returns
    // "task" and "project" resource
    if (issueResponse && issueResponse.resources && issueResponse.resources.project) {
      issueResponse.resources.task = issueResponse.resources.project
      delete issueResponse.resources.project
    }




    // Create a mapped object from action array
    if (issueResponse.actions && issueResponse.actions.constructor.name === 'Array') {
      issueResponse.actions = issueResponse.actions.reduce((acc, item) => {
        acc[item.name] = item
        return acc
      }, {})

      issueResponse.actions = jsonInflector.transform(issueResponse.actions, 'camelizeLower')
    }

    // STATUS ACTION
    // Inject values (not returened by BE) for status field, toggle if field is disabled based on existance in BE response
    if (!issueResponse.actions || !issueResponse.actions.status) {
      issueResponse.actions.status = this.fieldsConfig.status
    } else {
      const statusFieldAllowedValues = issueResponse.actions.status.values
      issueResponse.actions.status.values = [ ...this.fieldsConfig.status.values ]
      issueResponse.actions.status.disabled = false // set to false as field should not be disabled if BE returned this action
      statusFieldAllowedValues.forEach(item => {
        const action = issueResponse.actions.status.values.find(defaultItem => defaultItem.status === item.status)
        action.disabled = false
      })
    }

    // SNOOZE ACTION
    // Inject values (not returened by BE) for status field, toggle if field is disabled based on existance in BE response
    if (!issueResponse.actions || !issueResponse.actions.snooze) {
      issueResponse.actions.snooze = this.fieldsConfig.snooze
    } else {
      let values = []
      if (issueResponse.actions.snooze.values && issueResponse.actions.snooze.values.snooze) {
        issueResponse.actions.snooze.values.snooze.forEach(snoozeValue => {
          let name = `${snoozeValue} hour${snoozeValue > 1 ? 's' : ''}`
          const days = snoozeValue / 24
          if (snoozeValue === 0) {
            return // ignore value 0 as its handled differently
          } else if (days >= 1) {
            name = `${Math.floor(days)} day${days === 1 ? '' : 's'}${snoozeValue % 24 === 0 ? '' : ' ' + snoozeValue % 24 + 'h'}`
          }

          values.push({
            value: snoozeValue,
            name: name
          })
        })
      }
      issueResponse.actions.snooze.values = values
      issueResponse.actions.snooze.disabled = false // set to false as field should not be disabled if BE returned this action
    }

    return issueResponse
  }

  updateIssueInList (updatedIssue) {
    console.log('[IssuesService] > updateIssueInList', updatedIssue.id)
    const index = this.issueList.findIndex(issue => issue.id === updatedIssue.id)
    // Check if updated issue is in active list and update it
    if (index > -1) {
      this.issueList[index] = {
        ...this.issueList[index],
        ...{
          status: updatedIssue.status,
          assignedTo: updatedIssue.assignedTo,
          assignedAt: updatedIssue.assignedAt,
          openedAt: updatedIssue.openedAt
        }
      }
    }
  }

  reloadIssueView () {
    this.$state.reload('issueView')
  }

  buildFilterParams (config) {
    const params = {}

    if (config.folderId) {
      // Map folder id to /issues filter url string
      const folderSplit = config.folderId.split(':')
      const folder = folderSplit[0]
      const inboxId = folderSplit[1]

      switch (folder) {
      case 'all':
        break
      case 'inbox':
        params.assignee_id = inboxId
        break
      }
    }

    // Map subfolderID to filter url
    if (config.subFolderId) {
      switch (config.subFolderId) {
      case 'all':
        break
      case 'unassigned':
        params.assigned = false
        break
      case 'open':
        params.status = 'open'
        params.snoozed = false
        break
      case 'resolved':
        params.status = 'resolved'
        params.snoozed = false
        break
      case 'snoozed':
        params.snoozed = true
        break
      }
    }

    return { ...params, ...config.searchQuery }
  }

  generateFolderStructure (inboxList) {
    const teamInboxes = inboxList.inboxes.filter(inbox => inbox.id !== this.UserService.userId)
    let myInbox = inboxList.inboxes.find(inbox => inbox.id === this.UserService.userId)


    const result = [
      {
        id: 'all:unassigned',
        sref: `issueList({ folderId: 'all', subFolderId: 'unassigned' })`,
        title: `Unassigned`,
        icon: 'alert-octagon',
        badge: {
          className: 'badge--important',
          value: inboxList.summary.unassigned.count
        }
      }
    ]

    if (myInbox) {
      this.currentUserHasInbox = true
      result.push({
        id: `inbox:me`,
        header: `Me`,
        icon: 'account',
        avatar: this.inboxUserAvatar(this.UserService.userId),
        // className: 'folder--me',
        list: [
          {
            sref: `issueList({ folderId: 'inbox:${this.UserService.userId}', subFolderId: 'open' })`,
            title: `Open`,
            icon: 'folder-open',
            badge: {
              className: 'badge--primary',
              value: myInbox.summary.open.count
            }
          },
          {
            sref: `issueList({ folderId: 'inbox:${this.UserService.userId}', subFolderId: 'snoozed' })`,
            title: `Snoozed`,
            icon: 'alarm-snooze',
            badge: {
              className: 'badge--subtle',
              value: myInbox.summary.snoozed.count
            }
          },
          {
            sref: `issueList({ folderId: 'inbox:${this.UserService.userId}', subFolderId: 'resolved' })`,
            title: `Resolved`,
            icon: 'check',
            badge: {
              className: 'badge--subtle',
              value: myInbox.summary.resolved.count
            }
          },
          {
            sref: `issueList({ folderId: 'inbox:${this.UserService.userId}', subFolderId: 'all' })`,
            title: `All`,
            icon: 'folder',
            badge: {
              className: 'badge--subtle',
              value: myInbox.summary.all.count
            }
          }
        ]
      })
    }


    teamInboxes.forEach(inbox => {
      result.push({
        id: `inbox:${inbox.id}`,
        header: `${inbox.fullName}`,
        icon: 'account',
        // avatar: this.inboxUserAvatar(inbox.id),
        badge: {
          className: 'badge--primary',
          value: inbox.summary.open.count
        },
        // className: '',
        list: [
          {
            sref: `issueList({ folderId: 'inbox:${inbox.id}', subFolderId: 'open' })`,
            title: `Open`,
            icon: 'folder-open',
            badge: {
              className: 'badge--primary',
              value: inbox.summary.open.count
            }
          },
          {
            sref: `issueList({ folderId: 'inbox:${inbox.id}', subFolderId: 'snoozed' })`,
            title: `Snoozed`,
            icon: 'alarm-snooze',
            badge: {
              className: 'badge--subtle',
              value: inbox.summary.snoozed.count
            }
          },
          {
            sref: `issueList({ folderId: 'inbox:${inbox.id}', subFolderId: 'resolved' })`,
            title: `Resolved`,
            icon: 'check',
            badge: {
              className: 'badge--subtle',
              value: inbox.summary.resolved.count
            }
          },
          {
            sref: `issueList({ folderId: 'inbox:${inbox.id}', subFolderId: 'all' })`,
            title: `All`,
            icon: 'folder',
            badge: {
              className: 'badge--subtle',
              value: inbox.summary.all.count
            }
          }
        ]
      })
    })


    result.push({
      id: 'all',
      header: `All`,
      icon: 'folder-multiple',
        // className: 'folder--all',
      list: [
        {
          sref: `issueList({ folderId: 'all', subFolderId: 'open' })`,
          title: `Open`,
          icon: 'folder-open',
          badge: {
            className: 'badge--primary',
            value: inboxList.summary.open.count
          }
        },
        {
          sref: `issueList({ folderId: 'all', subFolderId: 'snoozed' })`,
          title: `Snoozed`,
          icon: 'alarm-snooze',
          badge: {
            className: 'badge--subtle',
            value: inboxList.summary.snoozed.count
          }
        },
        {
          sref: `issueList({ folderId: 'all', subFolderId: 'resolved' })`,
          title: `Resolved`,
          icon: 'check',
          badge: {
            className: 'badge--subtle',
            value: inboxList.summary.resolved.count
          }
        },
        {
          sref: `issueList({ folderId: 'all', subFolderId: 'all' })`,
          title: `All`,
          icon: 'folder',
          badge: {
            className: 'badge--subtle',
            value: inboxList.summary.all.count
          }
        }
      ]
    })


    console.log('[IssuesService] > generateFolderStructure', result)

    return result
  }


  // Add issue to current issueList (on updated issue event - that comes from another inbox)
  addIssueToList (eventData) {
    const folderId = this.$stateParams.folderId
    const subFolderId = this.$stateParams.subFolderId
    if (folderId === 'all') {
      // current route is summary inbox
      if (subFolderId === 'all' ||
          (subFolderId === 'unassigned' && !eventData.assignedTo) ||
          subFolderId === eventData.status) {
        this.issueList.unshift(eventData)
      }
    } else if (eventData.assignedTo && folderId === `inbox:${eventData.assignedTo.id}`) {
      // current route is user inbox
      if (subFolderId === 'all' ||
          subFolderId === eventData.status) {
        this.issueList.unshift(eventData)
      }
    }
  }

  /**
   * Function to return avtar based on user id from inbox list of users. Should be slowly deprecated and replaced with userAvatar function.
   * @param  {Integer} userId
   * @param  {Boolean} fallbackImage [description]
   * @return {String}                avatar url that can be either from user inbox or a default avatar
   */
  inboxUserAvatar (userId, fallbackImage = true) {
    if (userId === 'system' || userId === null || typeof userId === 'undefined') {
      return systemAvatarImage
    }

    const userInbox = this.inboxList.inboxes.find(user => user.id === userId || user.email === userId)

    if (userInbox && userInbox.avatars) {
      return userInbox.avatars.largeUrl ? userInbox.avatars.largeUrl : userInbox.avatars.mediumUrl
    }

    return fallbackImage ? defaultAvatarImage : transparentImage
  }

  /**
   * Function to return full name based on user id from inbox list of users. Should be slowly deprecated and replaced with userName function.
   * @param  {Integer} userId
   * @return {String}        Full name of user or fallback
   */
  inboxUserName (userId) {
    if (userId === 'system' || userId === null || typeof userId === 'undefined') {
      return 'System'
    }

    const userInbox = this.inboxList.inboxes.find(user => user.id === userId || user.email === userId)

    return userInbox ? userInbox.fullName : `[fullName missing for ${userId}]`
  }

  get issuesUnassignedCount () {
    return this.inboxList ? this.inboxList.summary.unassigned.count : 0
  }

  redirectIfIssueMoved () {
    if (!this.redirectOnInit) {
      return
    }

    // TODO: redirect on init does not take into account if you navigate away from page .. should be reviewed and improved
    this.redirectOnInit = false // force redirect only on page load (on init)

    console.log('[IssuesService] > redirectIfIssueMoved', { ...this.$stateParams })

    const params = {
      ...this.$stateParams, // keep all existing params
      ...this.generateRedirectIssueParams(this.issue) // change folder and subfolder params
    }

    // navigate to deepest view in issueView template - default state for it is set in state definition
    this.$state.go('issueView', params)
  }

  generateRedirectIssueParams (issue) {
    const params = {
      issueId: issue.id,
      folderId: 'all',
      subFolderId: 'all'
    }

    if (issue.assignedTo) {
      params.folderId = `inbox:${issue.assignedTo.id}`
      params.subFolderId = issue.status
    } else {
      params.subFolderId = 'unassigned'
    }

    return params
  }

  redirectIfIssueIsChild () {
    if (this.issue.parent) {
      console.log('[IssuesService] > redirectIfIssueIsChild', { ...this.$stateParams })
      const params = {
        ...this.$stateParams,
        issueId: this.issue.parent.id
      }

      // navigate to deepest view in issueView template - default state for it is set in state definition
      this.$state.go('issueView', params)
    }
  }
}
export default IssuesService
