(function() {
  'use strict';

  function SearchResultResource($q, Restangular) {

		var searchUsers = function(options) {
			return Restangular.all('admin').all('search').all('users').get('', options);
    };

		var searchUtmTasks = function(options) {
			return Restangular.all('admin').all('search').all('utm_tasks').get('', options);
		};

    var getPossibleSearchUtms = function() {
      return Restangular.all('/admin/utms').get('');
    };

    return {
      searchUsers: searchUsers,
      searchUtmTasks: searchUtmTasks,
      getPossibleSearchUtms: getPossibleSearchUtms,
    };
  }

  window.app.service('SearchResultResource', ['$q', 'Restangular', SearchResultResource]);
})();
