import templateUrl from './settings.html'
import './settings.scss'

const SettingsComponent = {
  bindings: {},
  templateUrl,
  controller: class Settings {
    constructor () {
      'ngInject'
      this._identify = 'SettingsComponent'
    }
  }
}

export default SettingsComponent
