const HeaderSearchResultsService = class HeaderSearchResultsService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'HeaderSearchResultsService'
    this.$http = $http
    this.Configuration = Configuration
  }


  searchUsers (params) {
    const options = {
      params
    }
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/search/users`, options)
      .then(response => response)
  }

  searchTasks (q) {
    const options = {
      q: q
    }
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/search/utm_tasks`, options)
      .then(response => response)
  }

  // getUtmList () {
  //   return this.$http
  //     .get(`${this.Configuration.apiUrl}/admin/utms`)
  //     .then(response => response)
  // }
}
export default HeaderSearchResultsService
