import EarningsCertificatesComponent from './earning-certificates.component'
import EarningsCertificateService from './earning-certificates.service'

const EarningsCertificatesModule = angular.module('cdbl.users-earnings-certificates', [

])
  .component('cdblEarningsCertificates', EarningsCertificatesComponent)
  .service('EarningsCertificateService', EarningsCertificateService)
  .name

export default EarningsCertificatesModule
