import templateUrl from './dialog-warning.dialog.html'
import './dialog-warning.dialog.scss'

import warningImage from '@/assets/img/warning-meme.gif'

const DialogWarning = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogWarning {
    constructor ($mdDialog, ToastService, Configuration) {
      'ngInject'
      this._identify = 'DialogWarning'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.Configuration = Configuration

      this.isLoading = false
    }

    $onInit () {
      this.warningImage = warningImage

      // Default value for this.text if it is not injected by $mdDialog locals object on invoke
      if (!this.text) {
        this.text = 'Are you sure you want to proceed?'
      }

      if (!this.title) {
        this.title = 'Warning'
      }
    }

    confirm () {
      if (this.confirmCallback) {
        this.confirmCallback()
      }
      this.$mdDialog.cancel()
    }

    $onDestroy () {

    }
  }
}

export default DialogWarning
