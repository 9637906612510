(function() {
  'use strict';

  function WithdrawalRequestCancellationCtrl($scope, $modalInstance, WithdrawalsResource, withdrawal) {

		$scope.withdrawal = withdrawal;

		$scope.close = function() {
			$modalInstance.close();
		};

		$scope.cancelWithdrawalRequest = function(withdrawal) {
      console.log('-- cancelWithdrawalRequest', withdrawal);

      $scope.withdrawal.status= 'canceling';
      WithdrawalsResource.cancelWithdrawalRequest(withdrawal);

      $scope.close();
		};

  }

  window.app.controller('WithdrawalRequestCancellationCtrl', ['$scope', '$modalInstance', 'WithdrawalsResource', 'withdrawal', WithdrawalRequestCancellationCtrl]);

})();
