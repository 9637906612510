import fallbackAvatarImage from '@/assets/img/defaultAvatarImage.jpg'
import defaultImage from '@/assets/img/transparentImage.png'

const OnErrorSrcDirective = function (Configuration) {
  'ngInject'
  return {
    restrict: 'A',
    link ($scope, $element, $attrs, ngModelCtrl) {
      $scope.$on('$destroy', () => {
        $element.unbind('error load')
      })

      // $element.bind('load', () => {
      //   $scope.$destroy()
      // })

      $element.bind('error', () => {
        let fallbackSrc = $attrs.onErrorSrc ? $attrs.onErrorSrc : defaultImage
        if ($attrs.onErrorSrc === 'avatar') {
          fallbackSrc = fallbackAvatarImage
        }
        if ($attrs.src !== fallbackSrc) {
          console.log('[OnErrorSrcDirective]', fallbackSrc, $element)
          $attrs.$set('src', fallbackSrc)
        }

        $scope.$destroy()
      })
    }
  }
}

export default OnErrorSrcDirective
