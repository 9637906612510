import templateUrl from './dialog-project-edit-prices.dialog.html'
import './dialog-project-edit-prices.dialog.scss'

const DialogProjectEditPrices = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogProjectEditPrices {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'DialogProjectEditPrices'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService

      this.isLoading = false
      this.showConfirmation = false
    }

    $onInit () {
      this.formData = {
        basePrice: this.project.prices.basePrice,
        clientFeePercentage: this.project.prices.clientFeePercentage,
        expertFeePercentage: this.project.prices.contractorFeePercentage,
      }
    }

    $onDestroy () {

    }

    showEditConfirmation () {
      this.showConfirmation = true
    }
    hideEditConfirmation () {
      this.showConfirmation = false
    }

    get canEditBasePrice () {
      return !['paid', 'completed', 'refunded', 'abandoned'].includes(this.project.state)
    }

    confirmEdit () {
      this.isProcessing = true
      this.ProjectsService.updateProjectPricing(this.project.id, this.formData)
        .then(response => {
          // update project with new fresh data from BE
          this.ProjectsService.updateProject(this.project, response)
          this.$mdDialog.cancel()
          this.ToastService.show('Project prices updated')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogProjectEditPrices
