import HeaderSearchResultsComponent from './header-search-results.component'
import HeaderSearchResultsService from './header-search-results.service'

const HeaderSearchResultsModule = angular.module('cdbl.header-search-results', [

])
  .component('cdblHeaderSearchResults', HeaderSearchResultsComponent)
  .service('HeaderSearchResultsService', HeaderSearchResultsService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
      // parent: '',
        name: 'header-search',
        url: '/header-search?q&type',
        params: {
          q: { squash: true, value: null, dynamic: true },
          type: { squash: true, value: null, dynamic: true },
        },
        component: 'cdblHeaderSearchResults',
        // views: {
        //   '@projects': 'cdblProjectPage'
        // },
        resolve: {
          permissionResolver: ($state, Configuration, AuthService) => {
            'ngInject'
            const allow = AuthService.isAuthenticated
            if (!allow) {
              console.log('[permissionResolver] > reject')
              $state.go('tasks.state')
            }
          }
        }
      })
  })
  .name

export default HeaderSearchResultsModule
