(function() {
  'use strict';

  function EmailTemplatesCtrl($scope, $timeout, SettingsResource, _) {
        
    SettingsResource.emailTemplates().then(function(response) {
      $scope.emailTemplates = [];

      response.forEach(function(template) {
        template.expose = false;

        $scope.emailTemplates.push(template);
      })
    });
    
    $scope.remove = function(tpl) {
      SettingsResource.deleteEmailTemplate(tpl.id).then(function(response) {
        _.remove($scope.emailTemplates, {
          id: tpl.id
        });
      });
    };
    
  }

  window.app.controller('EmailTemplatesCtrl', ['$scope', '$timeout', 'SettingsResource', '_', EmailTemplatesCtrl]);
	
})();
