(function() {
  'use strict';

  window.app.constant('Configuration', {
    env: 'staging',
    apiUrl: 'https://api.staging.codeable.io',
    auth: {
      tokenHeaderKey: 'auth-token',
    },
    settings: {
      tokenRenewalInterval: 21600
    },
    referooApiUrl: 'https://referoo.co'
  });

})();
