(function() {
	'use strict';

	function retainerActions($timeout, Restangular, ProjectsService) {
		return {
      scope: {
        retainer: '='
      },
      replace: true,
			restrict: 'E',
			templateUrl: '/app/scripts/tasks/retainers/retainer-actions.html',
      link: function(scope, elem, attrs) {
        scope.actionList = [
          // Release funds
          // {
          //   actionName: 'release_funds',
          //   buttonText: 'Release funds to expert',
          //   inProgress: false,
          //   response: {
          //     status: null,
          //     message: null
          //   },
          //   canShow: function () {
          //     const actionConfig = scope.retainer.actions.find(action => action.name === this.actionName)
          //     // condition to show this action
          //     if (actionConfig && !(this.response.status === 'success')) {
          //       return true;
          //     }
          //     return false;
          //   },
          //   onConfirm: function () {
          //     const vm = this;

          //     // callback to execute on confirm
          //     const actionConfig = scope.retainer.actions.find(action => action.name === this.actionName)
          //     console.log('retainerActions > onConfirm', this.actionName, actionConfig);

          //     // remove first part of url because of "restangular" nonsense
          //     let url = actionConfig.url.split('/');
          //     url.splice(0,2);

          //     Restangular.all('admin').customPOST(null, url.join('/'))
          //       .then(response => {
          //         console.log('retainerActions > onConfirm > success', this.actionName, response);
          //         this.response = {
          //           message: 'Succesfully released funds. Please reload.',
          //           status: 'success'
          //         }
          //       })
          //       .catch(err => {
          //         console.log('retainerActions > onConfirm > error', this.actionName, err.data);
          //         this.response = {
          //           message: 'Error occured. Please reload.',
          //           status: 'error'
          //         }
          //       });

          //   },
          //   onCancel: function () {
          //     // callback to execute on cancel
          //     console.log('retainerActions > onCancel', this.actionName);
          //   }
          // },
          {
            actionName: 'refund_last_payment',
            buttonText: 'Refund last payment',
            inProgress: false,
            response: {
              status: null,
              message: null
            },
            canShow: function () {
              const actionConfig = scope.retainer.actions.find(action => action.name === this.actionName)
              // condition to show this action
              if (actionConfig && !(this.response.status === 'success')) {
                return true;
              }
              return false;
            },
            onConfirm: function () {
              const vm = this;

              // callback to execute on confirm
              const actionConfig = scope.retainer.actions.find(action => action.name === this.actionName)
              console.log('retainerActions > onConfirm', this.actionName, actionConfig);

              // remove first part of url because of "restangular" nonsense
              let url = actionConfig.url.split('/');
              url.splice(0,2);

              Restangular.all('admin').customPOST(null, url.join('/'))
                .then(response => {
                  console.log('retainerActions > onConfirm > success', this.actionName, response);
                  scope.retainer.actions = scope.retainer.actions.filter(action => action.name !== this.actionName);
                })
                .catch(err => {
                  console.log('retainerActions > onConfirm > error', this.actionName, err.data);
                  this.response = {
                    message: 'Error occured. Please reload.',
                    status: 'error'
                  }
                });

            },
            onCancel: function () {
              // callback to execute on cancel
              console.log('retainerActions > onCancel', this.actionName);
            }
          },

        ]

        scope.closeResponseMessage = function (action) {
          action.response.message = null;
        }

        scope.activateAction = function (action) {
          if (!action.canShow()) {
            // check if this action can be activated
            console.log('retainerActions > activateAction > conditions to activate not met', this.actionName);
            return;
          }
          action.inProgress = true;
        }

        scope.cancelAction = function (action) {
          action.inProgress = false;

          if (action.onCancel) {
            action.onCancel();
          }
        }

        scope.confirmAction = function (action) {
          action.inProgress = false;

          if (action.onConfirm) {
            action.onConfirm();
          }
        }
        console.log('retainerActions > link > retainer', scope.retainer);
      }
		};
	}

  app.directive('retainerActions', ['$timeout', 'Restangular', retainerActions]);
})();
