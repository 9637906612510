import templateUrl from './header-search-results.html'
import './header-search-results.scss'

const HeaderSearchResultsComponent = {
  bindings: {},
  templateUrl,
  controller: class HeaderSearchResults {
    constructor ($stateParams, $timeout, HeaderSearchResultsService, ToastService, PaginationService) {
      'ngInject'
      this._identify = 'HeaderSearchResultsComponent'
      this.$stateParams = $stateParams
      this.$timeout = $timeout
      this.ToastService = ToastService
      this.PaginationService = PaginationService
      this.HeaderSearchResultsService = HeaderSearchResultsService
    }


    async $onInit () {
      this.pagination = this.PaginationService.init({
        itemsPerPage: 20,
        mode: 'server'
      })

      this.searchResults = {
        enityType: null,
        list: []
      }

      this.search()
    }

    get searchString () {
      return this.$stateParams.q ? this.$stateParams.q : null
    }
    get searchType () {
      return this.$stateParams.type ? this.$stateParams.type : null
    }

    getEntityType (searchType) {
      if (['project', 'task'].includes(searchType)) {
        return 'project'
      }

      return 'user'
    }


    async search () {
      if (!this.searchString) {
        return
      }

      let searchString = this.searchString.trim().toLowerCase()
      let searchType = this.searchType

      if (searchType === 'project') {
        this.ToastService.show('Projects are currently searchable only by ID')
      } else if (searchType === 'user' || searchType === 'email') {
        this.resetSearchResults()
        this.isLoading = true
        const params = {
          page: this.pagination.currentPage,
          per_page: this.pagination.itemsPerPage,
          q: searchString,
          type: searchType
        }
        await this.HeaderSearchResultsService.searchUsers(params)
          .then(response => {
            this.pagination.updateFromHeaders(response)
            this.searchResults.enityType = this.getEntityType(searchType)
            this.searchResults.list = response.data
          })
        this.isLoading = false
        this.$timeout()
      }
    }

    onPageChangeHandler () {
      this.search()
    }

    resetSearchResults () {
      this.searchResults.enityType = null
      this.searchResults.list = []
    }
  }
}

export default HeaderSearchResultsComponent
