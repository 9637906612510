(function() {
  'use strict';

  function cancelTask() {
    return {
      restrict: 'E',
      templateUrl: '/app/scripts/tasks/actions/cancel_button.html',
      replace: true
    };
  }

  app.directive('cancelTask', cancelTask);
})();

(function() {
  'use strict';

  function abandonTask() {
    return {
      restrict: 'E',
      templateUrl: '/app/scripts/tasks/actions/abandon_button.html',
      replace: true
    };
  }

  app.directive('abandonTask', abandonTask);
})();
(function() {
  'use strict';

  function abandonSubTask() {
    return {
      restrict: 'E',
      templateUrl: '/app/scripts/tasks/actions/abandon_button_sub_task.html',
    };
  }

  app.directive('abandonSubTask', abandonSubTask);
})();
(function() {
  'use strict';

  function cancelSubTask() {
    return {
      restrict: 'E',
      templateUrl: '/app/scripts/tasks/actions/cancel_button_sub_task.html'
    };
  }

  app.directive('cancelSubTask', cancelSubTask);
})();
(function() {
  'use strict';

  function revertTask() {
    return {
      restrict: 'E',
      link: function($scope, elem, attrs) {
        $scope.revertTaskButtonText = attrs.text;
      },
      templateUrl: '/app/scripts/tasks/actions/revert_button.html',
      replace: true
    };
  }

  app.directive('revertTask', revertTask);
})();

(function() {
  'use strict';

  function publishPreferred() {
    return {
      restrict: 'E',
      templateUrl: '/app/scripts/tasks/actions/publish_preferred_button.html',
      replace: true
    };
  }

  app.directive('publishPreferred', publishPreferred);
})();

(function() {
	'use strict';

	function completeTask() {
		return {
			restrict: 'E',
			templateUrl: '/app/scripts/tasks/actions/complete_button.html',
      replace: true
		};
	}

  app.directive('completeTask', completeTask);
})();

(function() {
	'use strict';

	function markPaid() {
		return {
			restrict: 'E',
			templateUrl: '/app/scripts/tasks/actions/mark_paid_button.html',
      replace: true
		};
	}

  app.directive('markPaid', markPaid);
})();
(function() {
	'use strict';

	function markPaid() {
		return {
			restrict: 'E',
			templateUrl: '/app/scripts/tasks/actions/mark_paid_offline_button.html',
      replace: true
		};
	}

  app.directive('markPaidOffline', markPaid);
})();

(function() {
	'use strict';

	function refundTask() {
		return {
			restrict: 'E',
			templateUrl: '/app/scripts/tasks/actions/refund_button.html',
      replace: true
		};
	}

  app.directive('refundTask', refundTask);
})();

(function() {
	'use strict';

	function removeContractor() {
		return {
			restrict: 'E',
			templateUrl: '/app/scripts/tasks/actions/remove_contractor_button.html',
      replace: true
		};
	}

  app.directive('removeContractor', removeContractor);
})();
