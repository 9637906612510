import templateUrl from './dialog-settings-account-change-password.dialog.html'
import './dialog-settings-account-change-password.dialog.scss'

const DialogSettingsAccountChangePassword = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogSettingsAccountChangePassword {
    constructor ($mdDialog, ToastService, Configuration, SettingsAccountService) {
      'ngInject'
      this._identify = 'DialogSettingsAccountChangePassword'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.Configuration = Configuration
      this.SettingsAccountService = SettingsAccountService

      this.isLoading = false
      this.error = null
    }

    $onInit () {

    }

    $onDestroy () {

    }

    changePassword () {
      const payload = {
        newPassword: this.formData.newPassword,
        currentPassword: this.formData.currentPassword
      }

      this.isProcessing = true
      this.error = null
      this.SettingsAccountService.updateSettings(payload)
        .then(response => {
          this.ToastService.show('Password changed')
          this.$mdDialog.cancel()
        })
        .catch(err => {
          this.error = err
          this.ToastService.show('Something went wrong, check you entered correct password')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogSettingsAccountChangePassword
