import templateUrl from './noticebar.html'
import './noticebar.scss'

const NoticebarComponent = {
  bindings: {
    isVisible: '<'
  },
  templateUrl,
  transclude: true,
  controller: class Noticebar {
    constructor () {
      'ngInject'
      this._identify = 'NoticebarComponent'
    }

    $onInit () {
      this.isVisible = this.isVisible || false
    }
  }
}

export default NoticebarComponent
