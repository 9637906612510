(function() {
  'use strict';

  function PaymentsResource($q, Restangular) {
    
		var getPayments = function(options) {
			return Restangular.all('admin').all('payments').get('', options);
		};

    return {
      getPayments: getPayments
    };
  }

  window.app.service('PaymentsResource', ['$q', 'Restangular', PaymentsResource]);
})();
