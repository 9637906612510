import templateUrl from './resource-payment-events.html'
import './resource-payment-events.scss'

const IssueResourcePaymentEventsComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,

  controller: class IssueResourcePaymentEvents {
    constructor (Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourcePaymentEventsComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService

      this.paymentEvents = null
    }

    $onInit () {
      this.IssueViewService.getResourcePaymentEvents(this.issue.id)
        .then(response => {
          this.paymentEvents = response || []
        }, error => {
          console.log(error)
        })
    }


    mainResourceName () {
      let name = 'Task'

      if (this.issue.resources.subTask) {
        let retainer = this.issue.resources.subTask.retainer
        name = retainer ? 'Retainer' : 'SubTask'
      }

      return name
    }
  }
}

export default IssueResourcePaymentEventsComponent
