(function() {
  'use strict';

  function linkfy() {
    return function(text){
      return text.replace(/<a\s+href=/gi, '<a target="_blank" href=');
    };
  }

  app.filter('linkfy', [linkfy]);
})();
