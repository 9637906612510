const ActionQualityAssessmentService = class ActionQualityAssessmentService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'ActionQualityAssessmentService'
    this.$http = $http
    this.Configuration = Configuration
  }

  getOptions (config) {
    return this.$http
      .get(`${this.Configuration.apiUrl}${config.url}`)
      .then(response => {
        // let data = null

        if (response.data[0]) {
          return response.data[0]
        }

        throw Error('Response > missing data')
      })
  }

  setQualityAssessment (config, payload) {
    return this.$http
      .post(`${this.Configuration.apiUrl}${config.url}`, payload)
      // .then(response => {

      // })
  }
}
export default ActionQualityAssessmentService
