const IssueResourceUnresponsiveService = class IssueResourceUnresponsiveService {
  constructor ($http, Configuration, IssuesService) {
    'ngInject'
    this._identify = 'IssueResourceUnresponsiveService'

    this.$http = $http
    this.Configuration = Configuration
    this.IssuesService = IssuesService

    this.occurrencesList = null
  }

  getOccurrencesList (config) {
    console.log('[ResourceUnresponsiveService] > getOccurrencesList', config)
    this.occurrencesList = null
    return this.$http
      .get(`${this.Configuration.apiUrl}${config.url}`)
      .then(response => {
        console.log('[ResourceUnresponsiveService] > getOccurrencesList > response', response)

        this.occurrencesList = response.data.map(occurrenceIssue => this.IssuesService.processIssueResponse(occurrenceIssue))
        return this.occurrencesList
      }, err => {
        console.error('[ResourceUnresponsiveService] > getOccurrencesList > err', err)
        return err
      })
  }


  getOccurrence (issueSummary) {
    console.log('[ResourceUnresponsiveService] > getOccurrence', issueSummary)
    return this.$http
      .get(`${this.Configuration.apiUrl}${issueSummary.url}`)
      .then(response => {
        console.log('[ResourceUnresponsiveService] > getOccurrence > response', response)

        const data = this.IssuesService.processIssueResponse(response.data)

        return data
      }, err => {
        console.error('[ResourceUnresponsiveService] > getOccurrence > err', err)
        return err
      })
  }
}
export default IssueResourceUnresponsiveService
