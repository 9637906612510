import templateUrl from './issue-list-widget.html'
import './issue-list-widget.scss'

const IssueListWidgetComponent = {
  bindings: {
    data: '<'
  },
  templateUrl,
  controller: class IssueListWidget {
    constructor (Configuration, $timeout, $http) {
      'ngInject'
      this._identify = 'IssueListWidgetComponent'
      this.Configuration = Configuration
      this.$timeout = $timeout
      this.$http = $http

      this.formData = {
        search: '',
        filterByStatus: 'all',
        filterByType: 'all'
      }
      this.ticketList = null
      this.typeOptions = JSON.parse(JSON.stringify(this.Configuration.issueSystem.issueToolbarSearch.fields.type.options))
    }
    async $onInit () {
      // https://api.develop.codeable.io/admin/tasks/1138/tickets
      console.log('[IssueListWidgetComponent] > $onInit')
      await this.getIssueList()
      this.updateCounts()
    }

    updateCounts () {
      if (this.ticketList) {
        this.typeOptions.forEach(option => {
          option.count = this.ticketList.filter(t => t.type === option.id).length
        })
      }
    }

    filterByType () {
      const filterByType = this.formData.filterByType
      return function (ticket) {
        if (filterByType === 'all') {
          return true
        }
        // Check if the item's propertyName matches the selectedFilter
        return ticket.type === filterByType
      }
    }

    filterByStatus () {
      const filterByStatus = this.formData.filterByStatus
      return function (ticket) {
        if (filterByStatus === 'all') {
          return true
        }
        // Check if the item's propertyName matches the selectedFilter
        return ticket.status === filterByStatus
      }
    }

    getIssueList () {
      if (!this.data && !(this.data.user || this.project)) {
        return
      }

      const slug = this.data.user ? 'users' : 'tasks'
      const id = this.data.user ? this.data.user.id : this.data.project.id
      const params = {}
      this.isLoading = true
      console.log('[IssueListWidgetComponent] > getIssueList', `${this.Configuration.apiUrl}/admin/${slug}/${id}/tickets`)
      return this.$http
        .get(`${this.Configuration.apiUrl}/admin/${slug}/${id}/tickets`, { params })
        .then(response => {
          console.log('[IssueListWidgetComponent] > getIssueList > response', response)
          this.ticketList = response.data
        }, err => {
          console.log('[IssueListWidgetComponent] > getIssueList > err', err)
          return err
        })
        .finally(() => {
          this.isLoading = false
          this.$timeout()
        })
    }
  }
}

export default IssueListWidgetComponent
