(function() {
  'use strict';

  function PaymentsCtrl($scope, $location, $stateParams, $modal, PaymentsResource) {

    PaymentsResource.getPayments().then(function(payments) {
      $scope.payments = payments;
    });
    
  }

  window.app.controller('PaymentsCtrl', ['$scope', '$location', '$stateParams', '$modal', 'PaymentsResource', PaymentsCtrl]);
	
})();
