const PaginationService = class PaginationService {
  constructor (Configuration) {
    'ngInject'

    this.Configuration = Configuration
  }

  init (options = {
    mode: 'server', // server side uses first page index 1, while pagination of array uses first page index 0
    itemsPerPage: 50,
    itemsPerPageOptions: [
      25,
      50,
      100
    ],
    paginationArray: null
  }) {
    const firstPage = options.mode === 'array' ? 0 : 1

    return {
      currentPage: firstPage,
      firstPage: firstPage,

      mode: options.mode || 'server',
      paginationArray: options.paginationArray || null,

      itemsPerPage: options.itemsPerPage || 50,
      itemsPerPageOptions: options.itemsPerPageOptions || [ 25, 50, 100 ],

      totalCount: null,

      _pageCount: null,
      get pageCount () {
        return this.mode === 'array' ? Math.ceil(this.paginationArray.length / this.itemsPerPage) : this._pageCount
      },

      get canLoadPrevious () {
        return this.currentPage > this.firstPage
      },

      get canLoadNext () {
        return this.currentPage < this.pageCount
      },

      reset () {
        this.currentPage = firstPage
      },
      setItemsPerPage (num) {
        this.itemsPerPage = num
      },
      getCurrentPage () {
        return options.mode === 'array' ? this.currentPage + 1 : this.currentPage
      },

      setCurrentPage (num) {
        const page = parseInt(num, 10)
        if (typeof page !== 'number' || isNaN(page)) {
          return
        }

        this.currentPage = Math.max(page, this.firstPage) // make sure that page
      },

      getItemsFromTo () {
        const current = options.mode === 'array' ? this.currentPage : this.currentPage - 1

        const fromItem = current * this.itemsPerPage + 1
        const toItem = Math.min(current * this.itemsPerPage + this.itemsPerPage, this.totalCount)

        return `${fromItem} - ${toItem}`
      },
      getItemsOfTotal () {
        return `${this.getItemsFromTo()} of total ${this.totalCount}`
      },
      updateFromHeaders (response) {
        this._pageCount = Number(response.headers('x-page-count'))
        this.itemsPerPage = Number(response.headers('x-records-per-page'))
        this.totalCount = Number(response.headers('x-total-count'))
      },
      updatePaginationArray (newArr) {
        this.paginationArray = newArr
      }
    }
  }
}
export default PaginationService
