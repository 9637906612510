import IssueViewNotesComponent from './issue-view-notes.component'
// import IssueViewNotesService from './issue-view-notes.service'

const IssueViewNotesModule = angular.module('cdbl.issue-view-notes', [
    // 'ui.router'
])
  .component('cdblIssueViewNotes', IssueViewNotesComponent)
  // .service('IssueViewNotesService', IssueViewNotesService)

  // .config($uiRouterProvider => {
  //   'ngInject'

  // })
  .name

export default IssueViewNotesModule
