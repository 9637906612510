import templateUrl from './template-edit.dialog.html'

const TemplateEditDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class TemplateEditDialog {
    constructor ($mdDialog, $mdToast, Configuration, TemplatesCommentService) {
      'ngInject'
      this._identify = 'TemplateEditDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.TemplatesCommentService = TemplatesCommentService

      this.emailTokenErrors = {}
      this.tokenValidation = null
      this.modelOptions = {
        updateOn: 'default blur',
        debounce: {
          'default': 500,
          'blur': 0,
          '*': 500
        }
      }

      this.tokenValidator = [
        'cdbl_current_user_first_name',
        'cdbl_current_user_full_name',
        'cdbl_client_first_name',
        'cdbl_client_full_name',
        'cdbl_expert_first_name',
        'cdbl_expert_full_name',
        'cdbl_workroom_url'
      ]

      this.isNameFieldSupported = false
    }

    $onInit () {
      console.log('[TemplateEditDialog]', this.item)

      if (!this.item) {
        this.item = {
          name: '',
          text: ''
        }
      }

      this.isNameFieldSupported = true // this.item.hasOwnProperty('name')
    }

    $onDestroy () {
      console.log('[TemplateEditDialog] > onDestroy')
    }

    saveTemplate () {
      this.isSaving = true
      this.TemplatesCommentService.saveTemplate(this.item)
        .then(response => {
          console.log('[TemplateEditDialog] > save > response', response)
          this.$mdDialog.hide(response.data)
          this.isSaving = false
        }, error => {
          console.log('[TemplateEditDialog] > save > error', error)
          this.$mdToast.showSimple('Error occured while saving template')
          this.isSaving = false
        })
    }
  }
}

export default TemplateEditDialog


// Hello {{first_name | fallback:"there"}}!

// Your task ID is {{ task_id }}.

// Best regards,
// {{   author_name  |    fallback : "Codeable"  }}
