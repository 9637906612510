import templateUrl from './badge.html'
import './badge.scss'

const BadgeComponent = {
  bindings: {},
  transclude: true,
  templateUrl,
  controller: class Badge {
    constructor () {
      'ngInject'
      this._identify = 'BadgeComponent'
    }
  }
}

export default BadgeComponent
