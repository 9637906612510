// TODO: update this filter ... see the urgency/complexity widget ... move options to config

const ProjectUrgencyFormatFilter = function ($filter, UserService) {
  'ngInject'
  return function (urgencyId, newMapping = true) {
    let urgencyString = ''

    switch (urgencyId) {
    case 0:
      urgencyString = 'Urgent'
      break
    case 1:
      urgencyString = 'Today or tomorrow'
      break
    case 2:
      if (newMapping) {
        urgencyString = 'Moderate'
      } else {
        urgencyString = 'In a week'
      }
      break
    case 3:
      if (newMapping) {
        urgencyString = 'Flexible'
      } else {
        urgencyString = 'Anytime'
      }
      break
    default:
      if (newMapping) {
        urgencyString = 'N/A'
      } else {
        urgencyString = `by ${$filter('date')(urgencyId * 1000, 'MMMM dd yyyy')}`
      }
      break
    }

    return urgencyString
  }
}
export default ProjectUrgencyFormatFilter
