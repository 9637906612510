(function() {
  'use strict';

  function NotesResource(Restangular) {
    var getNotes = function(options) {
      // /admin/notes {note_subject_id: USER_ID, note_subject_type: "User"}
      return Restangular.all('admin').all('notes').get('', options);
    };

    var updateNotes = function(notes) {
      if (notes.createNew) {
        return Restangular.all('admin').all('notes').post({
          noteSubjectType: 'User',
          noteSubjectId: notes.userId,
          text: notes.text
        })
      } else {
        return Restangular.all('admin').one('notes', notes.id).customPUT({
          text: notes.text
        });
      }
    };

    return {
      getNotes: getNotes,
      updateNotes: updateNotes
    };
  }

  window.app.service('NotesResource', ['Restangular', NotesResource]);
})();
