(function() {
  'use strict';

  function SettingsResource($q, Restangular) {

    var createOrUpdateRevenueTarget = function(options) {
      return Restangular.all('admin').all('kpis').all('revenue_targets').post(options);
    };


    var commentTemplates = function() {
      return Restangular.all('admin').all('comment_templates').get('');
    };
    
    var commentTemplate = function(id) {
      return Restangular.all('admin').one('comment_templates', id).get('');
    };
    
    var updateCommentTemplate = function(id, options) {
      return Restangular.all('admin').one('comment_templates', id).put(options);
    };
    
    var createCommentTemplate = function(options) {
      return Restangular.all('admin').all('comment_templates').post(options);
    };
    
    var deleteCommentTemplate = function(id) {
      return Restangular.all('admin').one('comment_templates', id).remove();
    };


    var emailTemplates = function() {
      return Restangular.all('admin').all('email_templates').get('');
    };
    
    var emailTemplate = function(id) {
      return Restangular.all('admin').one('email_templates', id).get('');
    };
    
    var updateEmailTemplate = function(id, options) {
      return Restangular.all('admin').one('email_templates', id).put(options);
    };
    
    var createEmailTemplate = function(options) {
      return Restangular.all('admin').all('email_templates').post(options);
    };
    
    var deleteEmailTemplate = function(id) {
      return Restangular.all('admin').one('email_templates', id).remove();
    };


    var intercomTemplates = function() {
      return Restangular.all('admin').all('intercom_templates').get('');
    };

    var intercomTemplate = function(id) {
      return Restangular.all('admin').one('intercom_templates', id).get('');
    };

    var updateIntercomTemplate = function(id, options) {
      return Restangular.all('admin').one('intercom_templates', id).put(options);
    };

    var createIntercomTemplate = function(options) {
      return Restangular.all('admin').all('intercom_templates').post(options);
    };

    var deleteIntercomTemplate = function(id) {
      return Restangular.all('admin').one('intercom_templates', id).remove();
    };

    return {
      createOrUpdateRevenueTarget: createOrUpdateRevenueTarget,
      commentTemplates: commentTemplates,
      commentTemplate: commentTemplate,
      updateCommentTemplate: updateCommentTemplate,
      createCommentTemplate: createCommentTemplate,
      deleteCommentTemplate: deleteCommentTemplate,
      emailTemplates: emailTemplates,
      emailTemplate: emailTemplate,
      updateEmailTemplate: updateEmailTemplate,
      createEmailTemplate: createEmailTemplate,
      deleteEmailTemplate: deleteEmailTemplate,
      intercomTemplates: intercomTemplates,
      intercomTemplate: intercomTemplate,
      updateIntercomTemplate: updateIntercomTemplate,
      createIntercomTemplate: createIntercomTemplate,
      deleteIntercomTemplate: deleteIntercomTemplate
    };
  }

  window.app.service('SettingsResource', ['$q', 'Restangular', SettingsResource]);
})();
