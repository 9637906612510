(function() {
  'use strict';

  function InactiveContractorsResource(Restangular) {
    var getInactiveContractors = function() {
      return Restangular.all('admin').all('contractors').all('inactive').getList();
    };

    return {
      getInactiveContractors: getInactiveContractors
    };
  }

  window.app.service('InactiveContractorsResource', ['Restangular', InactiveContractorsResource]);
})();