(function() {
  'use strict';

  function EditEmailTemplateCtrl($scope, $location, $stateParams, SettingsResource) {
    
    SettingsResource.emailTemplate($stateParams.id).then(function(response) {
      $scope.emailTemplate = response;
    });
    
    $scope.save = function(tpl) {
      $scope.saving = true;
      SettingsResource.updateEmailTemplate(tpl.id, {text: tpl.text, subject: tpl.subject}).then(function(response) {
        $scope.saving = false;
      });
    };
    
  }

  window.app.controller('EditEmailTemplateCtrl', ['$scope', '$location', '$stateParams', 'SettingsResource', EditEmailTemplateCtrl]);
	
})();
