import ActionQualityAssessmentModule from './action-quality-assessment'
import ActionTaskPublishModule from './action-task-publish'
import ActionTaskPostCommentModule from './action-task-post-comment'


const ActionsModule = angular.module('cdbl.actions', [
  'ui.router',
  ActionQualityAssessmentModule,
  ActionTaskPublishModule,
  ActionTaskPostCommentModule
])
  .name

export default ActionsModule
