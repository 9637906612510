import templateUrl from './project-header.html'
import './project-header.scss'

const ProjectHeaderComponent = {
  bindings: {
    project: '<?',
    refreshProject: '&?'
  },
  templateUrl,
  controller: class ProjectHeader {
    constructor ($mdDialog, $mdToast) {
      'ngInject'
      this._identify = 'ProjectHeaderComponent'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
    }

    cancelProjectPrompt () {
      this.$mdDialog
        .show(this.$mdDialog.confirm({
          title: `Cancel project`,
          textContent: `Are you sure you want to cancel #${this.project.id} - "${this.project.title}"?`,
          ok: 'Yes, cancel project',
          cancel: 'No',
          theme: 'warn'
        }))
        .then(dialogResponse => {
          this.cancelProject()
        }, dialogResponse => {

        })
    }

    cancelProject () {
      this.$mdToast.show(this.$mdToast.simple({ textContent: 'Project canceled', position: 'top right' }))
      // TODO: connect to api
    }
  }
}

export default ProjectHeaderComponent
