(function() {
  'use strict';
  /*globals moment*/
  function TransactionsCtrl($scope, $location, $stateParams, Restangular, TransactionsResource) {

		$scope.states = ['client_deposit', 'offline_client_deposit', 'task_completion', 'contractor_withdrawal', 'partial_refund', 'full_refund', 'full_refund_keep_fee'];
    $scope.filterState = $stateParams.type || 'client_deposit';

    var startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);

    $scope.dateRange = {
      start_date: moment().subtract(7, 'days').format('DD-MM-YYYY'),
      end_date: moment().add(1, 'days').format('DD-MM-YYYY')
    };

		$scope.onSelectPage = function(page) {
			TransactionsResource.getTransactions({
        type: $scope.filterState.replace('all', ''),
        page: page.toString(),
        start_date: $scope.dateRange.start_date,
        end_date: $scope.dateRange.end_date
      }).then(function(transactions) {
				$scope.transactions = transactions;
				// Pagination
        $scope.totalpage =  0;
        angular.forEach(transactions, function(value, key){
          var creditone = parseFloat(value.creditAmounts[0].amount) * 1;
          var credittwo = parseFloat(value.creditAmounts[1].amount) * 1;
          var creditamounttotal = creditone + credittwo;
          $scope.totalpage = $scope.totalpage + creditamounttotal;
          var momentdate =  moment(value.createdAt).utcOffset(0).format('MMMM DD, YYYY');
          value.formattedDate = momentdate ;
        });
				$scope.totalItems = transactions.headers('X-Total-Count');
				$scope.itemsPerPage = 20;
				$scope.currentPage = page.toString();
				$scope.numPages = transactions.headers('X-Page-Count');
				$scope.maxSize = 5;
			});
		};

		$scope.onSelectPage('1');

    $scope.filter = function() {
      $scope.onSelectPage('1');
    };
  }

  window.app.controller('TransactionsCtrl', ['$scope', '$location', '$stateParams', 'Restangular', 'TransactionsResource', TransactionsCtrl]);

})();
