import 'babel-polyfill'
import './styles.scss'
import './vendors.legacy.js'
import './vendors.js'
// import '@uirouter/angularjs'
import { DSRPlugin } from '@uirouter/dsr'
import { RejectType } from '@uirouter/angularjs'
import 'angular-material'
// import 'angular-mocks'
import 'angular-messages'
import 'angular-animate'
import 'angular-loading-bar/build/loading-bar.js'
import 'angular-loading-bar/build/loading-bar.css'
import 'angular-bind-html-compile'
import 'angular-material-expansion-panel'
import 'angular-material-expansion-panel/dist/md-expansion-panel.css'
import 'angular-chart.js'
import 'assets/static/mdi.svg'
import 'ui-select'
import 'angular-sanitize'
import 'ng-material-datetimepicker'



import Configuration from './app.config'
import { primaryPalette, primaryPaletteHues, accentPalette, accentPaletteHues, greenPalette, graphitePalette, warnPaletteHues } from './app.theme.js'
import AppComponent from './app.component'
import ComponentsModule from './components'
import CommonModule from './common'


import * as Sentry from '@sentry/browser'
import { Angular as AngularIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

console.log('[main] app init > NODE_ENV', process.env.NODE_ENV)
console.log('[main] Configuration', Configuration)


// Make sure to call Sentry.init after importing AngularJS.
// You can also pass {angular: AngularInstance} to the Integrations.Angular() constructor.
// Read more: https://docs.sentry.io/platforms/javascript/guides/angular/angular1/
Sentry.init({
  debug: false,
  dsn: Configuration.NODE_ENV === 'production' ? Configuration.sentry.dns : null,
  release: Configuration.REVISION,
  environment: Configuration.CDBL_ENV,
  integrations: [
    new AngularIntegration(),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 0.05,
  ignoreErrors: [
    '/AuthorizationPermissionError/',
    '/Error: ResizeObserver loop limit exceeded/',
    '/Error: ResizeObserver loop completed with undelivered notifications./'
  ],
  // denyUrls: [
  //   // Chrome extensions
  //   /extensions\//i,
  //   /^chrome:\/\//i,
  // ]
})

if (Configuration.NODE_ENV === 'development') {
  Sentry.setTag('local', 'true')
}

const app = angular
  .module('CodeableAdminApp', [
    ComponentsModule,
    CommonModule,

    // Dependencies
    'ui.router',
    'ngMaterial',
    'ngMessages',

    'ngSentry',
    'angular-loading-bar',
    'angular-bind-html-compile',
    'material.components.expansionPanels',
    'chart.js',
    'ui.select',

    // 'ngMockE2E',

    // Legacy dependencies
    'restangular',
    'LocalStorageModule',
    'mm.foundation',
    'angularMoment',
    'highcharts-ng',
    'ngTable',
    'checklist-model',
    'ui.select2',
    'ngSanitize',
    'ui.grid',
    'ui.grid.edit',
    'ngMaterialDatePicker'
  ])
  .constant('Configuration', Configuration)


  // Aria config
  .config(($mdAriaProvider) => {
    'ngInject'

    $mdAriaProvider.disableWarnings()
  })

  // Mock BE config
  // TODO only for development
  // .run($httpBackend => {
  //   'ngInject'
  //   $httpBackend.whenHEAD(/.*/).passThrough()
  //   $httpBackend.whenGET(/.*/).passThrough()
  //   $httpBackend.whenPOST(/.*/).passThrough()
  //   $httpBackend.whenPUT(/.*/).passThrough()
  //   $httpBackend.whenPATCH(/.*/).passThrough()
  //   $httpBackend.whenDELETE(/.*/).passThrough()
  // })

  // Router config
  .config(($locationProvider, $uiRouterProvider, $urlRouterProvider, $httpProvider) => {
    'ngInject'

    /*
        Set hashbang to empty string (as links are generated like that in other apps ie: /#/...). This is neccessary to
        match legacy links. Its important this setting is above html5Mode so that it can be auto replaced by app to use
        normal HTML5 urls without hashbang.
    */
    $locationProvider.hashPrefix('')
    $locationProvider.html5Mode(true) // will use html5 navigation and also remove hashbang from url

    $uiRouterProvider.plugin(DSRPlugin)

    $uiRouterProvider.trace.enable(1)

    $urlRouterProvider.otherwise('/login')

    $httpProvider.defaults.withCredentials = true
  })

  .run(($transitions, $state, AuthService, Configuration) => {
    'ngInject'

    $transitions.onStart({}, transition => {
      console.log('RUN > $transitions > authenticated:', AuthService.isAuthenticated, 'from: ', transition.from().name, 'to: ', transition.to().name, transition)
      if (transition.to().name !== 'auth.login' && !AuthService.isAuthenticated) {
        // User isn't authenticated. Redirect to a new Target State
        return transition.router.stateService.target('auth.login')
      }
    })

    $transitions.onError({}, transition => {
      const err = transition.error()
      console.log('RUN > $transitions > onError:', err, transition, RejectType)
      switch (err.type) {
      case RejectType.ERROR:
        // do error stuff
        $state.go(Configuration.navigation.defaultPage.sref, Configuration.navigation.defaultPage.params)
        break
      case RejectType.SUPERSEDED:
      case RejectType.IGNORED:
        // ignore
        break
      }
    })
  })

  // Material UI config
  .config(($mdThemingProvider, $mdInkRippleProvider) => {
    'ngInject'

    // $mdThemingProvider.definePalette('CodeablePrimaryPalette', {
    //   '50': 'e7f5fb',
    //   '100': 'c3e5f6',
    //   '200': '9bd4f0',
    //   '300': '72c3ea',
    //   '400': '54b6e6',
    //   '500': '36a9e1',
    //   '600': '30a2dd',
    //   '700': '2998d9',
    //   '800': '228fd5',
    //   '900': '167ecd',
    //   'A100': 'feffff',
    //   'A200': 'cbe7ff',
    //   'A400': '98d0ff',
    //   'A700': '7fc4ff',
    //   'contrastDefaultColor': 'light',
    //   'contrastDarkColors': [
    //     // '50',
    //     // '100',
    //     // '200',
    //     // '300',
    //     // '400',
    //     // '500',
    //     // '600',
    //     // 'A100',
    //     // 'A200',
    //     // 'A400',
    //     // 'A700'
    //   ],
    //   'contrastLightColors': [
    //     '700',
    //     '800',
    //     '900'
    //   ]
    // })


    $mdThemingProvider.definePalette('CodeablePrimaryPalette', primaryPalette)
    $mdThemingProvider.definePalette('CodeableAccentPalette', accentPalette)
    $mdThemingProvider.definePalette('graphite', graphitePalette)
    $mdThemingProvider.definePalette('green', greenPalette)

    $mdThemingProvider.theme('codeableDark', 'default')
      .primaryPalette('CodeableAccentPalette')
      .dark()

    $mdThemingProvider
      .theme('default')
      .primaryPalette('CodeablePrimaryPalette', primaryPaletteHues)
      .accentPalette('CodeableAccentPalette', accentPaletteHues)
      .warnPalette('red', warnPaletteHues)

    $mdThemingProvider
      .theme('warn')
      .primaryPalette('red', warnPaletteHues)
  })

  // Loading bar config
  .config(cfpLoadingBarProvider => {
    'ngInject'
    cfpLoadingBarProvider.includeSpinner = false
  })


  // Icons config
  .config($mdIconProvider => {
    'ngInject'
    $mdIconProvider
      .defaultIconSet('/assets/static/mdi.svg', 24)
  })

  // ChartJS settings
  .config(ChartJsProvider => {
    'ngInject'
    ChartJsProvider.setOptions({
      animation: {
        duration: 0
      },
      colors: [ '#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360' ]
    })
  })

  // TODO: after legacy LS is ported to new one
  // Prefix LS provider
  // .config(function (localStorageServiceProvider) {
  //   'ngInject'
  //   localStorageServiceProvider.setPrefix('cdbl')
  // })


  .run(($mdTheming) => {
    'ngInject'
    $mdTheming.generateTheme('codeableDark')
  })

  .run(($locale) => {
    'ngInject'
    $locale.NUMBER_FORMATS.GROUP_SEP = ','
    $locale.NUMBER_FORMATS.DECIMAL_SEP = '.'
  })

  // Define angular providers/dependencies
  // TODO refactor this to use imports instead of global window objects
  .value('_', window._)
  .value('Tether', window.Tether)
  .value('Highcharts', window.Highcharts)
  .value('marked', window.marked)
  .value('moment', window.moment)

  // Create app component
  .component('app', AppComponent)

// Expose app to window object (needed for legacy part of app)
window.app = app

// Require legacy app files needs to happen after refactored app is configured
require('./app.legacy.js')


