import templateUrl from './dialog-project-edit-complexity-urgency.dialog.html'
import './dialog-project-edit-complexity-urgency.dialog.scss'

const DialogProjectEditComplexityUrgency = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogProjectEditComplexityUrgency {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'DialogProjectEditComplexityUrgency'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService

      this.isLoading = false
      this.showConfirmation = false
    }

    $onInit () {
      this.formData = {
        complexity: typeof this.project.complexity !== 'undefined' ? this.project.complexity : null,
        urgency: typeof this.project.urgency !== 'undefined' ? this.project.urgency : null
      }
    }

    $onDestroy () {

    }


    showEditConfirmation () {
      this.showConfirmation = true
    }
    hideEditConfirmation () {
      this.showConfirmation = false
    }

    confirmEdit () {
      this.isProcessing = true
      this.ProjectsService.updateProjectComplexityUrgency(this.project.id, this.formData)
        .then(response => {
          // update project with new fresh data from BE
          this.ProjectsService.updateProject(this.project, response)
          this.$mdDialog.cancel()
          this.ToastService.show('Project complexity and urgency updated')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong...')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default DialogProjectEditComplexityUrgency
