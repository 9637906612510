(function() {
  'use strict';

  function capitaliseFirstLetter() {
    return function(text) {
      return text[0].toUpperCase() + text.slice(1);
    };
  }

  app.filter('capitaliseFirstLetter', capitaliseFirstLetter);
})();