(function() {
  'use strict';

  function SubTasksResource(Restangular) {

    var getSubTasks = function(taskId, options) {
      return Restangular.all('admin').get('sub_tasks', options);
    };

    var getSubTasksRetainers = function(taskId, options) {
      return Restangular.all('admin').get('retainers', options);
    };
    var completeSubTask = function(taskId, subTaskId) {
      return Restangular.all('admin').one('tasks', taskId).one('sub_tasks', subTaskId).one('complete').customPUT();
    };
    var abandonSubTask = function(taskId, subTaskId) {
      return Restangular.all('admin').one('tasks', taskId).one('sub_tasks', subTaskId).one('abandon').customPUT();
    };
    var cancelSubTask = function(subTaskId) {
      return Restangular.all('admin').one('sub_tasks', subTaskId).remove();
    };

    var markPaid = function(subTaskId, paymentType) {
      return Restangular.all('admin').one('tasks', subTaskId).one('mark_as_paid').customPUT({
        paymentType: paymentType,
        taskType: 'sub_task'
      });
    };

    return {
      getSubTasks:          getSubTasks,
      completeSubTask:      completeSubTask,
      getSubTasksRetainers: getSubTasksRetainers,
      abandonSubTask:       abandonSubTask,
      cancelSubTask:        cancelSubTask,
      markPaid:             markPaid
    };
  }

  window.app.service('SubTasksResource', ['Restangular', SubTasksResource]);
})();
