(function() {
  'use strict';

  function AlertsResource($q, Restangular) {
    
		var taskAlerts = function() {
			return Restangular.all('admin').all('task_issues').get('');
		};
    
    return {
      taskAlerts: taskAlerts
    };
  }

  window.app.service('AlertsResource', ['$q', 'Restangular', AlertsResource]);
})();
