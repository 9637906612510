import templateUrl from './project-widget-preferred-experts.html'
import './project-widget-preferred-experts.scss'

import DialogWarning from '@/common/components/dialog-warning'

const ProjectWidgetPreferredExpertsComponent = {
  bindings: {
    project: '<',
    isWidget: '<'
  },
  templateUrl,
  controller: class ProjectWidgetPreferredExperts {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectWidgetPreferredExpertsComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }
    get isPreferred () {
      return this.project.preferredContractors && this.project.preferredContractors.length > 0
    }

    get canPublishForAll () {
      return this.isPreferred && ['created', 'published', 'estimated'].includes(this.project.state)
    }


    publishForAllPrompt () {
      const vm = this
      this.$mdDialog.show({
        ...DialogWarning,
        locals: {
          text: `Are you sure you want to publish project for all experts, #${this.project.id} - "${this.project.title}"?`,
          confirmCallback: function () {
            vm.publishForAll()
          }
        }
      })
    }

    publishForAll () {
      this.isProcessing = true
      this.ProjectsService.projectPreferredPublishForAll(this.project.id)
        .then(response => {
          // reset preferred contractors array
          this.project.preferredContractors = [] // TODO: ideally BE should return updated project as a response
          this.ToastService.show('Project was published for all experts')
        })
        .catch(err => {
          console.log(err)
          this.ToastService.show('Something went wrong, please reload and try again')
        })
        .finally(() => {
          this.isProcessing = false
        })
    }


    openEditModal () {
      this.ToastService.show('Something would happen if this would be implemented')
    }
  }
}

export default ProjectWidgetPreferredExpertsComponent
