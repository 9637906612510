export default {
  states: [
    {
      id: 'created',
      name: 'Created',
      actions: ['watch', 'withhold']
    },

    {
      id: 'published',
      name: 'Published',
      actions: ['cancel', 'watch', 'withhold']
    },

    {
      id: 'estimated',
      name: 'Estimated',
      actions: ['cancel', 'watch', 'hireExpert']
    },

    {
      id: 'hired',
      name: 'Hired',
      actions: ['cancel', 'unhireExpert', 'markPaidOffline', 'watch']
    },

    {
      id: 'paid',
      name: 'Paid',
      actions: ['refund', 'cancel', 'complete', 'abandon', 'unhireExpert', 'watch']
    },

    {
      id: 'completed',
      name: 'Completed',
      actions: ['revert', 'watch']
    },

    {
      id: 'refunded',
      name: 'Refunded',
      actions: ['watch']
    },

    {
      id: 'canceled',
      name: 'Canceled',
      actions: ['revert', 'watch']
    },

    {
      id: 'abandoned',
      name: 'Abandoned',
      actions: ['revert', 'watch']
    },

    {
      id: 'withheld',
      name: 'Withheld',
      actions: ['publish']
    }
  ]
}
