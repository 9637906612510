import templateUrl from './issue-view-notes.html'
import './issue-view-notes.scss'

const IssueViewNotesComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueViewNotes {
    constructor ($mdToast, PusherService, IssuesService, IssueViewService) {
      'ngInject'
      this._identify = 'IssueViewNotesComponent'
      this.$mdToast = $mdToast
      this.PusherService = PusherService
      this.IssuesService = IssuesService
      this.IssueViewService = IssueViewService


      this.isProcessing = false
      this.fieldConfig = {
        note: {
          maxlength: 500,
          rows: 2
        }
      }
      this.formData = {
        text: ''
      }

      this.pusherChannelName = null
      this.notesList = null


      // Pusher callback handlers
      const vm = this
      this.pusherHandlerNoteAdded = function (event) {
        const processedData = vm.PusherService.processPayload(event.data)
        vm.addNewNote(processedData)
      }
    }

    $onInit () {
      console.log('[IssueViewNotesComponent] > $onInit')

      // Get notes list
      this.IssueViewService.getNotesList(this.issue.id)
        .then(response => {
          this.notesList = response || []
        }, error => {
          console.log(error)
        })

      // Get correct channel name
      this.pusherChannelName = this.PusherService.getChannelName('issue', this.issue.id)

      // Subscribe to notes events for this issue
      this.PusherService.bind(this.pusherChannelName, 'issue-note-added', this.pusherHandlerNoteAdded)
    }

    $onDestroy () {
      // Unsubscribe from notes events for this issue
      console.log('unsubscribe from issue-note-added event')
      this.PusherService.unbind(this.pusherChannelName, 'issue-note-added', this.pusherHandlerNoteAdded)
    }

    createNote () {
      this.isProcessing = true
      console.log('[IssueViewNotesComponent] > createNote', this.formData)
      this.IssuesService.executeAction(this.issue.actions.notes, this.formData, false)
        .then(response => {
          this.formData.text = ''
          this.noteForm.$setPristine()

          this.addNewNote(response)
          console.log('[IssueViewNotesComponent] > response', response)
        }, error => {
          console.log('[IssueViewNotesComponent] > error', error)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    // Add note to the list if doesnt exist yet - solves timing race between pusher event and request response
    addNewNote (noteInstance) {
      const exists = this.notesList.find(item => item.id === noteInstance.id)
      if (!exists) {
        this.notesList.push(noteInstance)
      }
    }
  }
}

export default IssueViewNotesComponent
