(function() {
  'use strict';

  function states($stateProvider, $urlRouterProvider) {
    $stateProvider
    .state('sign_in', {
      url: '/sign_in',
      templateUrl: '/app/scripts/auth/sign_in.html',
      controller: 'SignInCtrl'
    });

    // $urlRouterProvider.otherwise('/sign_in');
  }

  window.app.config(['$stateProvider', '$urlRouterProvider', states]);
})();
