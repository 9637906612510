const NavigationService = class NavigationService {
  constructor ($q, Configuration, PermissionsService) {
    'ngInject'
    this._identify = 'NavigationService'
    this.Configuration = Configuration
    this.PermissionsService = PermissionsService

    this._sidebarNavigation = []
  }

  init () {
    this._sidebarNavigation = this.Configuration.navigation.sidebar.filter(navItem => {
      if (navItem.permission) {
        return this.PermissionsService.canAccess(navItem.permission)
      }
      return true
    })

    console.log('[NavigationService] > init', this._sidebarNavigation)
  }

  get navigation () {
    return this._sidebarNavigation
  }
}
export default NavigationService
