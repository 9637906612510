import templateUrl from './resource-task.html'
import './resource-task.scss'

import TaskCommentsDialog from '@/components/dialogs/dialog-task-comments'

const IssueResourceTaskComponent = {
  bindings: {
    issue: '<',
    resourceTask: '<'
  },
  templateUrl,
  controller: class IssueResourceTask {
    constructor ($mdDialog, $timeout, Configuration, IssueViewService, PusherService, UserService) {
      'ngInject'
      this._identify = 'IssueResourceTaskComponent'
      this.$mdDialog = $mdDialog
      this.$timeout = $timeout
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService
      this.PusherService = PusherService
      this.UserService = UserService

      this.isProcessing = false
      this.task = null

      // Declare listeners
      const vm = this
      this.resourceUpdatedListener = function (event) {
        vm.$timeout(() => {
          vm.resourceUpdatedHandler(event)
        })
      }


      this.actionPostCommentOptions = {
        templateFilter: 'hal -'
      }
    }

    $onInit () {
      if (this.resourceTask) {
        // Use provided resource, do not load issue resource object
        // This is a workaround if we want to display project info that we already have
        // from some other place (ie listing) and we do not have this project resource
        // available on issue (ie aggreagated projects on an issue))
        // As a consequence the resource will not be live updated if it changes.

        this.task = this.resourceTask
      } else {
        // Load resource from issue object resources url

        this.fetchResourceTask()
        this.subscribeToIssueResource()
      }
    }

    $onDestroy () {
      this.unsubscribeToIssueResource()
    }

    subscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.bind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    unsubscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.unbind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    resourceUpdatedHandler (event) {
      console.log('[IssueResourceTaskComponent] > resourceUpdatedHandler', event)
      if (event.data && event.data.resources && event.data.resources.task) {
        if (event.user && event.user.id !== this.UserService.user.id) {
          this.fetchResourceTask()
        }
      }
    }

    fetchResourceTask () {
      this.task = null
      this.IssueViewService.getResourceTask(this.issue.id)
        .then(response => {
          this.task = response
        }, error => {
          console.log(error)
        })
    }

    openTaskCommentDialog () {
      this.$mdDialog
        .show({
          ...TaskCommentsDialog,
          locals: {
            issue: this.issue,
            task: this.task
          }
        })
        .then(dialogResponse => {
          console.log('dialogResponse', dialogResponse)
        }, dialogError => {
          console.log('dialogError', dialogError)
        })
    }

    // onReleaseForPublication ($event) {
    //   console.log('[IssueResourceTask] > onReleaseForPublication')
    //   this.isProcessing = true
    //   // const confirmDialog = this.$mdDialog.confirm()
    //   //   .title('Are you sure you want to publish this project?')
    //   //   .textContent('All experts will be notified about this project being available.')
    //   //   .targetEvent($event)
    //   //   .ok('Publish')
    //   //   .cancel('Cancel')

    //   const confirmDialog = {
    //     escapeToClose: true,
    //     clickOutsideToClose: true,
    //     controllerAs: '$ctrl',
    //     focusOnOpen: false,
    //     parent: angular.element(document.body),
    //     template: `<md-dialog>
    //                 <md-dialog-content class="pa-4">
    //                    <h2 class="md-title">Are you sure you want to publish this project?</h2>
    //                    <div class="md-dialog-content-body">All experts will be notified about this project being available.</div>
    //                 </md-dialog-content>
    //                 <md-dialog-actions>
    //                   <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
    //                   <md-button class="md-warn md-raised" ng-click="$ctrl.$mdDialog.hide()">Publish</md-button>
    //                 </md-dialog-actions>
    //               </md-dialog>`,

    //     controller: class DialogController {
    //       constructor ($mdDialog) {
    //         'ngInject'
    //         this.$mdDialog = $mdDialog
    //       }
    //     }
    //   }

    //   this.$mdDialog.show({
    //     ...confirmDialog,
    //     locals: {}
    //   }).then(() => {
    //     console.log('[IssueResourceTask] > onReleaseForPublication > confirmDialog > publish')
    //     this.IssueViewService.executeResourceAction(this.task.actions.releaseForPublication, {
    //       'task': {
    //         'id': this.task.id
    //       }
    //     }).finally(() => {
    //       this.isProcessing = false
    //     })
    //   }, () => {
    //     console.log('[IssueResourceTask] > onReleaseForPublication > confirmDialog > cancel')
    //     this.isProcessing = false
    //   })
    // }

    showQualityAssessment () {
      return (this.task && this.task.actions && this.task.actions.pqa)
              // !this.issue.conditionCleared &&
              // this.issue.type === 'project_quality_assessement'
    }
  }
}

export default IssueResourceTaskComponent
