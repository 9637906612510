import templateUrl from './dialog-project-scope-doc.dialog.html'
import './dialog-project-scope-doc.dialog.scss'

const DialogProjectScopeDoc = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogProjectScopeDoc {
    constructor ($mdDialog, $mdToast, Configuration, ProjectScopeDocService) {
      'ngInject'
      this._identify = 'DialogProjectScopeDoc'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.ProjectScopeDocService = ProjectScopeDocService

      this.isLoading = false
    }

    $onInit () {

    }

    get scopeDocProgress () {
      return this.ProjectScopeDocService.scopeDocProgress(this.scopeDoc)
    }
  }
}

export default DialogProjectScopeDoc
