import templateUrl from './resource-duplicate-tasks.html'
import './resource-duplicate-tasks.scss'

const IssueResourceDuplicateTasksComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueResourceDuplicateTasks {
    constructor (Configuration, IssueViewService, PusherService) {
      'ngInject'
      this._identify = 'IssueResourceDuplicateTasksComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService
      this.PusherService = PusherService

      this.duplicate = null
    }

    $onInit () {
      this.fetchResourceDuplicateTasks()
      this.subscribeToIssueResource()
    }

    $onDestroy () {
      this.unsubscribeToIssueResource()
    }

    subscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.bind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    unsubscribeToIssueResource () {
      const channelName = this.PusherService.getChannelName('issue', this.issue.id)
      this.PusherService.unbind(channelName, 'issue-resource-updated', this.resourceUpdatedListener)
    }

    fetchResourceDuplicateTasks () {
      this.duplicate = null
      this.IssueViewService.getResourceDuplicate(this.issue.id)
        .then(response => {
          this.duplicate = response
        }, error => {
          console.log(error)
        })
    }
  }
}

export default IssueResourceDuplicateTasksComponent
