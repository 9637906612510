const TemplatesIntercomService = class TemplatesIntercomService {
  constructor ($http, Configuration, LoadingService) {
    'ngInject'
    this._identify = 'TemplatesIntercomService'
    this.$http = $http
    this.Configuration = Configuration
    this.LoadingService = LoadingService

    this.allRegex = /{{(.*?)}}/igm
    this.simpleRegex = /{{\s*?(\w+)\s*?}}/im
    this.complexRegex = /{{\s(\w+)\s\|\s(\w+):"([\w|\W]+?)"\s}}/im
  }

  replaceTokens (text, tokenValues) {
    let replacedText = text
    const allTokens = text.match(this.allRegex)

    if (allTokens) {
      allTokens.forEach(token => {
        // console.log('allTokens > token', token)
        if (this.simpleRegex.test(token)) {
          // token valid.. can be replaced
          const simpleToken = this.simpleRegex.exec(token)
          const value = tokenValues[simpleToken[1]]
          if (value) {
            console.log('replaceTokens > replace', simpleToken[1], value)
            replacedText = replacedText.replace(token, value)
          } else {
            console.log('replaceTokens > no value for token', token)
          }
        }

        if (this.complexRegex.test(token)) {
          // token valid.. can be replaced
          const complexToken = this.complexRegex.exec(token)
          const value = tokenValues[complexToken[1]]
          if (value) {
            console.log('replaceTokens > replace', complexToken[1], value)
            replacedText = replacedText.replace(token, value)
          } else {
            console.log('replaceTokens > no value for token', token)
          }
        }
      })
    }

    return replacedText
  }

  validateText (text) {
    const result = {
      valid: true,
      invalid: false,
      matches: {
        all: [],
        simple: [],
        complex: []
      },
      invalidTokenName: [],
      invalidTokenPattern: []
    }

    if (typeof text !== 'string') {
      result.errors = ['Not a string']
      return result
    }

    // Testing text
    /*
        Hello {{first_name | fallback:"there"}}!

        Your task ID is {{ task_id }}.

        Best regards,
       {{   author_name  |    fallback : "Codeable"  }}
    */

    const validTokens = this.Configuration.templateTokens


    const allTokens = text.match(this.allRegex)
    result.matches.all = allTokens
    console.log('allTokens', allTokens)

    if (allTokens) {
      // Iterate trough all found tokens and do additional checks
      allTokens.forEach(token => {
        if (this.simpleRegex.test(token) || this.complexRegex.test(token)) {
          // Simple token matches needs
          const simpleToken = this.simpleRegex.exec(token)
          result.matches.simple.push(simpleToken)
          console.log('simpleToken', simpleToken)

          const complexToken = this.complexRegex.exec(token)
          result.matches.complex.push(complexToken)
          console.log('complexToken', complexToken)

          let isTokenValid = false
          let invalidTokenName = null

          if (simpleToken) {
            validTokens.simple.forEach(t => {
              if (simpleToken[1] === t.name) {
                isTokenValid = true
              } else {
                invalidTokenName = simpleToken[1]
              }
            })

            // INTERCOM DOES NOT SUPPORT TOKEN WITHOUT FALLBACK
            // Check also for simple version of complex token
            // validTokens.complex.forEach(t => {
            //   if (simpleToken[1] === t.name) {
            //     isTokenValid = true
            //   } else {
            //     invalidTokenName = simpleToken[1]
            //   }
            // })
          }

          if (complexToken) {
            validTokens.complex.forEach(t => {
              if (complexToken[1] === t.name) {
                isTokenValid = true
              } else {
                invalidTokenName = complexToken[1]
              }

              // Check validity of argument name and trigger pattern issue
              if (complexToken[2] !== t.argumentName) {
                isTokenValid = false
                invalidTokenName = `${complexToken[1]} | ${complexToken[2]}: "${complexToken[3]}"`
              }
            })
          }

          if (!isTokenValid) {
            result.invalidTokenName.push(invalidTokenName)
            result.valid = false
          }
        } else {
          // Token doesnt match any of allowed patterns for a token
          result.invalidTokenPattern.push(token)
          result.valid = false
        }
      })
    }

    result.invalid = !result.valid
    return result
  }

  getTemplateList () {
    const loaderName = 'TemplatesIntercomService::getTemplateList'
    this.LoadingService.start(loaderName)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/intercom_templates`)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  getTemplate (templateId) {
    const loaderName = 'TemplatesIntercomService::getTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/intercom_templates/${templateId}`)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  updateTemplate (templateId, payload) {
    const loaderName = 'TemplatesIntercomService::getTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .put(`${this.Configuration.apiUrl}/admin/intercom_templates/${templateId}`, payload)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  createNewTemplate (payload) {
    const loaderName = 'TemplatesIntercomService::createNewTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/intercom_templates`, payload)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  saveTemplate (template) {
    const payload = {
      subject: template.subject,
      text: template.text,
      name: template.name ? template.name : null
    }

    if (template.hasOwnProperty('id')) {
      return this.updateTemplate(template.id, payload)
    } else {
      return this.createNewTemplate(payload)
    }
  }

  deleteTemplate (templateId) {
    const loaderName = 'TemplatesIntercomService::deleteTemplate'
    this.LoadingService.start(loaderName)
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/intercom_templates/${templateId}`)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }
}
export default TemplatesIntercomService


