(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('watchlist', {
      url: '/watchlist',
      templateUrl: '/app/scripts/watchlist/watchlist.html',
      controller: 'WatchlistCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
