import IssueViewTabsComponent from './issue-view-tabs.component'
// import IssueViewTabsService from './issue-view-tabs.service'

const IssueViewTabsModule = angular.module('cdbl.issue-view-tabs', [
  // 'ui.router'
])
  .component('cdblIssueViewTabs', IssueViewTabsComponent)
// .service('IssueViewTabsService', IssueViewTabsService)

// .config($uiRouterProvider => {
//   'ngInject'
//   $uiRouterProvider.stateRegistry
//     .register({
//       parent: 'issueView',
//       name: 'issueViewTabs',
//       url: '/:issueTabId',
//       params: {
//         issueTabId: { squash: false, value: 'notes' }
//       }
//     })
// })
  .name

export default IssueViewTabsModule
