import templateUrl from './project-preview.dialog.html'

const ProjectPreviewDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class ProjectPreviewDialog {
    constructor ($mdDialog, $mdToast, Configuration, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectPreviewDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.ProjectsService = ProjectsService

      this.isLoading = false
    }

    $onInit () {
      // Passed into controller via dialog envoker
      // this.projectListing
      // this.projectList

      console.log('[ProjectPreviewDialog]', this.issue, this.task)
      this.fetchProject(this.projectListing.id)
    }

    fetchProject (id) {
      this.isLoading = true
      this.project = null
      this.ProjectsService.loadProject(id)
        .then(response => {
          this.project = response
          this.isLoading = false
        }, error => {
          console.log(error)
          this.isLoading = false
        })
    }

    goToPrevious () {

    }

    goToNext () {

    }

    $onDestroy () {
      console.log('[ProjectPreviewDialog] > onDestroy')
    }
  }
}

export default ProjectPreviewDialog
