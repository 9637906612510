(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('settings', {
      abstract: true,
      url: '/settings',
      template: '<ui-view/>'
    })
    .state('settings.emailTemplates', {
      url: '/email_templates',
      templateUrl: '/app/scripts/settings/emailTemplates/list.emailTemplates.html',
      controller: 'EmailTemplatesCtrl'
    })
    .state('settings.editEmailTemplate', {
      url: '/email_templates/{id:[0-9]{1,9}}',
      templateUrl: '/app/scripts/settings/emailTemplates/edit.emailTemplate.html',
      controller: 'EditEmailTemplateCtrl'
    })
    .state('settings.createEmailTemplate', {
      url: '/email_templates/new',
      templateUrl: '/app/scripts/settings/emailTemplates/create.emailTemplate.html',
      controller: 'CreateEmailTemplateCtrl'
    });
  }

  window.app.config(['$stateProvider', states]);
})();
