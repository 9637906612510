(function() {
  'use strict';

  function priceSum() {
    var link = function($scope) {
      function findProperty(obj, path) {
        var args = path.split('.'), i, l;

        for (i = 0, l = args.length; i < l; i++) {
          if (!obj.hasOwnProperty(args[i])) {
            return;
          }

          obj = obj[args[i]];
        }

        return obj;
      }

      $scope.priceSum = 0;

      $scope.list.forEach(function(item) {
        $scope.priceSum += parseFloat(findProperty(item, $scope.attribute));
      });
    };

    return {
      restrict: 'E',
      link: link,
      template: '<strong>{{priceSum | priceFormat}}</strong>',
      scope: {
        list: '=',
        attribute: '@'
      }
    };

  }

  app.directive('priceSum', priceSum);
})();