import jsonInflector from 'json-inflector'

const IssueViewService = class IssueViewService {
  constructor (
    $q,
    $timeout,
    $http,
    Configuration,
    PusherService,
    IssuesService
  ) {
    'ngInject'
    this._identify = 'IssueViewService'

    this.$q = $q
    this.$timeout = $timeout

    this.$http = $http
    this.Configuration = Configuration
    this.PusherService = PusherService
    this.IssuesService = IssuesService
  }

  get issue () {
    return this.IssuesService.issue
  }
  set issue (data) {
    this.IssuesService.issue = data
  }


  // Pusher issues-ID channel subscribe
  subscribeToIssue (issueId, handlers) {
    const channelName = this.PusherService.getChannelName('issue', issueId)
    const channel = this.PusherService.subscribe(channelName)
    this.PusherService.bindGlobal(channelName, (eventName, event) => {
      if (this.Configuration.issueSystem.pusherIssueUpdatedEvents.includes(eventName)) {
        // Process issue data
        const eventData = this.IssuesService.processIssueResponse(event.data)
        console.log('[IssueViewService] > pusher > UPDATE ISSUE', eventName, event)

        // Update current issue in view if conditions apply
        if (this.issue && this.issue.id === eventData.id) {
          this.issue = { ...this.issue, ...eventData }
        }

        // Trigger global pusher issue event handlers
        if (handlers && handlers.update) {
          handlers.update()
        }

        console.log('[IssueViewService] > pusher >', channelName, eventName, event)
      } else {
        console.warn('[IssueViewService] > pusher > unallowed', channelName, eventName, event)
      }
    })

    return channel
  }

  // Pusher issues-ID channel unsubscribe
  unsubscribeFromIssue (issueId) {
    const channelName = this.PusherService.getChannelName('issue', issueId)
    this.PusherService.unsubscribe(channelName)
  }

  // Attach resource to current task in view
  attachResource (issueId, resourceName, resource) {
    if (this.issue && this.issue.id === issueId) {
      this.issue.resources[resourceName].data = resource
    }
  }

  // Issue notes
  getNotesList (issueId) {
    console.log('[IssueViewService] > getNotesList', issueId)
    this.notesList = null
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/notes`)
      .then(response => {
        console.log('[IssueViewService] > getNotesList > response', response)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getNotesList > err', err)
        throw err
      })
  }

  // Issue history
  getHistoryList (issueId) {
    console.log('[IssueViewService] > getHistoryList', issueId)
    this.notesList = null
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/events`)
      .then(response => {
        console.log('[IssueViewService] > getHistoryList > response', response)

        return response.data
      }, err => {
        console.error('[IssueViewService] > getHistoryList > err', err)
        throw err
      })
  }


  // Resources
  getResourceClient (issueId) {
    console.log('[IssueViewService] > getResourceClient', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/client`)
      .then(response => {
        console.log('[IssueViewService] > getResourceClient > response', response)
        this.attachResource(issueId, 'client', response.data)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourceClient > err', err)
        throw err
      })
  }

  getResourceClientStats (issueId) {
    console.log('[IssueViewService] > getResourceClientStats', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/client-stats`)
      .then(response => {
        console.log('[IssueViewService] > getResourceClientStats > response', response)
        this.attachResource(issueId, 'client', response.data)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourceClientStats > err', err)
        throw err
      })
  }

  getResourceProjectList (issueId, resourceConfig) {
    console.log('[IssueViewService] > getResourceProjectList', issueId, resourceConfig)
    return this.$http
      .get(`${this.Configuration.apiUrl}${resourceConfig.url}`)
      .then(response => {
        console.log('[IssueViewService] > getResourceProjectList > response', response)
        this.attachResource(issueId, 'client', response.data)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourceProjectList > err', err)
        throw err
      })
  }

  getResourceExpert (issueId) {
    console.log('[IssueViewService] > getResourceExpert', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/expert`)
      .then(response => {
        console.log('[IssueViewService] > getResourceExpert > response', response)
        this.attachResource(issueId, 'expert', response.data)
        return response.data
      })
      .catch(err => {
        console.error('[IssueViewService] > getResourceExpert > err', err)
        throw err
      })
  }

  getResourceExpertTeamChangelog (issueId, pagination) {
    console.log('[IssueViewService] > getResourceExpertTeamChangelog', issueId)
    const params = pagination ? {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage
    } : {}

    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/experts_teams_changelog`, {
        params
      })
      .then(response => {
        console.log('[IssueViewService] > getResourceExpertTeamChangelog > response', response)
        pagination.updateFromHeaders(response)
        this.attachResource(issueId, 'expertsTeamsChangelog', response.data)
        return response.data
      })
      .catch(err => {
        console.error('[IssueViewService] > getResourceExpertTeamChangelog > err', err)
        throw err
      })
  }

  getResourceReview (issueId) {
    console.log('[IssueViewService] > getResourceReview', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/review`)
      .then(response => {
        console.log('[IssueViewService] > getResourceReview > response', response)
        this.attachResource(issueId, 'review', response.data)
        return response.data
      })
      .catch(err => {
        console.error('[IssueViewService] > getResourceReview > err', err)
        throw err
      })
  }


  getResourcePaymentEvents (issueId) {
    console.log('[IssueViewService] > getResourcePaymentEvents', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/payment-events`)
      .then(response => {
        console.log('[IssueViewService] > getResourcePaymentEvents > response', response)
        this.attachResource(issueId, 'paymentEvents', response.data)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourcePaymentEvents > err', err)
        throw err
      })
  }


  getResourcePaymentTransaction (issueId) {
    console.log('[IssueViewService] > getResourcePaymentTransaction', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/payment-transaction`)
      .then(response => {
        console.log('[IssueViewService] > getResourcePaymentTransaction > response', response)
        this.attachResource(issueId, 'paymentTransaction', response.data)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourcePaymentTransaction > err', err)
        throw err
      })
  }


  getResourceSubtask (issueId) {
    console.log('[IssueViewService] > getResourceSubtask', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/sub-task`)
      .then(response => {
        console.log('[IssueViewService] > getResourceSubtask > response', response)
        this.attachResource(issueId, 'subTask', response.data)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourceSubtask > err', err)
        throw err
      })
  }


  getResourceTask (issueId) {
    console.log('[IssueViewService] > getResourceTask', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/task`)
      .then(response => {
        console.log('[IssueViewService] > getResourceTask > response', response)
        const data = this.processResourceResponse(response.data)
        this.attachResource(issueId, 'task', data)
        return data
      }, err => {
        console.error('[IssueViewService] > getResourceTask > err', err)
        throw err
      })
  }

  getResourceDuplicate (issueId) {
    console.log('[IssueViewService] > getResourceDuplicate', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/duplicate`)
      .then(response => {
        console.log('[IssueViewService] > getResourceDuplicate > response', response)

        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourceDuplicate > err', err)
        throw err
      })
  }

  getResourceClients (issueId) {
    console.log('[IssueViewService] > getResourceClients', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/clients`)
      .then(response => {
        console.log('[IssueViewService] > getResourceClients > response', response)

        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourceClients > err', err)
        throw err
      })
  }


  getResourceTaskComments (issueId) {
    console.log('[IssueViewService] > getResourceTaskComments', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/task/comments`)
      .then(response => {
        console.log('[IssueViewService] > getResourceTaskComments > response', response)
        const data = this.processResourceResponse(response.data)
        this.attachResource(issueId, 'taskComments', data)
        return this.processResourceResponse(data)
      }, err => {
        console.error('[IssueViewService] > getResourceTaskComments > err', err)
        throw err
      })
  }

  getResourceProjectFeedback (issueId) {
    console.log('[IssueViewService] > getResourceProjectFeedback', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/task/feedback`)
      .then(response => {
        console.log('[IssueViewService] > getResourceProjectFeedback > response', response)

        return response.data.taskFeedback
      }, err => {
        console.error('[IssueViewService] > getResourceProjectFeedback > err', err)
        throw err
      })
  }

  getResource (resourceConfig) {
    console.log('[IssueViewService] > getResource', resourceConfig.url)
    return this.$http({
      method: resourceConfig.verb || 'get',
      url: `${this.Configuration.apiUrl}${resourceConfig.url}`
    })
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.log('[IssueViewService] > getResource > err', resourceConfig, err)
        throw err
      })
  }


  executeResourceAction (config, payload, processResource = false) {
    console.log('[IssueViewService] > executeResourceAction', config, payload)
    return this.$http({
      method: config.verb,
      url: `${this.Configuration.apiUrl}${config.url}`,
      data: payload
    })
      .then(response => {
        // TODO: update when dataInterceptor ready
        console.log('[IssueViewService] > executeResourceAction > response', response)

        if (processResource) {
          // Process response
          const resource = this.processResourceResponse(response.data)
          return resource
        }

        return response.data
      }, err => {
        console.log('[IssueViewService] > executeResourceAction > err', err)
        throw err
      })
  }

  getResourceClientEnrichmentData (issueId, client) {
    console.log('[IssueViewService] > getResourceClientEnrichmentData', client.id)

    return this.$http
      .get(`${this.Configuration.apiUrl}${client.actions.enrich.url}`)
      .then(response => {
        console.log('[IssueViewService] > getResourceClientEnrichmentData > response', response)
        return response.data
      }, err => {
        console.error('[IssueViewService] > getResourceClientEnrichmentData > err', err)
        throw err
      })
  }

  getResourceIncident (issueId) {
    console.log('[IssueViewService] > getResourceIncident', issueId)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/cs/issues/${issueId}/resources/incident`)
      .then(response => {
        console.log('[IssueViewService] > getResourceIncident > response', response)
        const data = this.processResourceResponse(response.data)
        this.attachResource(issueId, 'incident', data)
        return data
      }, err => {
        console.error('[IssueViewService] > getResourceIncident > err', err)
        throw err
      })
  }

  processResourceResponse (resourceResponse) {
    console.log('[IssueViewService] > processResourceResponse', resourceResponse)
    // --- Process issue object
    // Create a mapped object from action array
    if (resourceResponse.actions && resourceResponse.actions.constructor.name === 'Array') {
      resourceResponse.actions = resourceResponse.actions.reduce((acc, item) => {
        acc[item.name] = item
        return acc
      }, {})

      resourceResponse.actions = jsonInflector.transform(resourceResponse.actions, 'camelizeLower')
    }

    // DEMO EXAMPLE OF INJECTION ADDITIONAL DATA
    // Inject values (not returened by BE) for status field, add allowed values based on BE original response
    // if (resourceResponse.actions.status) {
    //   const statusFieldAllowedValues = resourceResponse.actions.status.values
    //   resourceResponse.actions.status.values = [ ...this.fieldsConfig.status.values ]

    //   statusFieldAllowedValues.forEach(item => {
    //     const action = resourceResponse.actions.status.values.find(defaultItem => defaultItem.status === item.status)
    //     action.allowed = true
    //   })
    // }

    return resourceResponse
  }
}
export default IssueViewService
