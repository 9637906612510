import ProjectActionAbandonModule from './action-abandon'
import ProjectActionCancelModule from './action-cancel'
import ProjectActionCompleteModule from './action-complete'
import ProjectActionMarkPaidofflineModule from './action-mark-paidoffline'
import ProjectActionRefundModule from './action-refund'
import ProjectActionRevertModule from './action-revert'
import ProjectActionWatchModule from './action-watch'
import ProjectActionWithholdModule from './action-withhold'

const ProjectActionsModule = angular.module('cdbl.projects.project-actions', [
  ProjectActionAbandonModule,
  ProjectActionCancelModule,
  ProjectActionCompleteModule,
  ProjectActionMarkPaidofflineModule,
  ProjectActionRefundModule,
  ProjectActionRevertModule,
  ProjectActionWatchModule,
  ProjectActionWithholdModule,
])
  .name

export default ProjectActionsModule
