import WorkroomInterventionsService from './workroom-interventions.service'

const WorkroomInterventionsModule = angular.module('cdbl.workroom-interventions', [
  'ui.router'
])

  .service('WorkroomInterventionsService', WorkroomInterventionsService)
  .name

export default WorkroomInterventionsModule
