import templateUrl from './resource-expert-team-changelog.html'
import './resource-expert-team-changelog.scss'

const IssueResourceExpertTeamChangelogComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueResourceExpert {
    constructor (Configuration, PaginationService, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourceExpertTeamChangelogComponent'
      this.Configuration = Configuration
      this.PaginationService = PaginationService
      this.IssueViewService = IssueViewService

      this.changelog = null
    }

    $onInit () {
      this.pagination = this.PaginationService.init({
        itemsPerPage: 20,
        mode: 'server'
      })

      this.fetchData()
    }

    fetchData () {
      this.serverError = null
      this.isLoading = true
      return this.IssueViewService.getResourceExpertTeamChangelog(this.issue.id, this.pagination)
        .then(response => {
          console.log(response)
          this.changelog = response
        })
        .catch(err => {
          this.serverError = err
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    onPageChangeHandler () {
      this.fetchData()
    }


    itemChangesAsText (item) {
      const result = []
      Object.keys(item.changeset).forEach(key => {
        let newValue = item.changeset[key][1]
        if (Array.isArray(newValue)) {
          console.log(newValue)
          // newValue = newValue.reduce()
          newValue = newValue.map(item => typeof item === 'object' ? (item.name ? item.name : item.title ? item.title : item.id) : item).join(', ')
        } else if (typeof newValue === 'object') {
          newValue = newValue.name ? newValue.name : (newValue.title ? newValue.title : newValue.id)
        }
        result.push(`${key} (${newValue})`)
      })
      return result.join(', ')
    }

    itemTypeAsText (item) {
      return item.itemType.split('::').join(' ')
    }
  }
}

export default IssueResourceExpertTeamChangelogComponent
