(function() {
  'use strict';
  /*jshint newcap:false*/
  function ContractorStatsCtrl($scope, $filter, ngTableParams, UsersResource, contractorStats) {
    $scope.data = [];

    contractorStats.forEach(function(contractor) {
      $scope.data.push({
        user: contractor.user,
        projectedRevenue: parseFloat(contractor.projectedRevenue),
        revenueTier: contractor.revenueTier,
        cancelRate: parseFloat(contractor.cancelRate),
        cancelRateTier: contractor.cancelRateTier,
        refundRate: parseFloat(contractor.refundRate),
        refundRateTier: contractor.refundRateTier,
        projectedCompletedTasksCount: parseInt(contractor.projectedCompletedTasksCount),
        estimatedToPaidConRate: parseFloat(contractor.estimatedToPaidConRate),
        calculatedRating: parseFloat(contractor.calculatedRating),
        daysCalculationIsBasedOn: parseInt(contractor.daysCalculationIsBasedOn),
        manualRatingCorrection: contractor.manualRatingCorrection ? parseInt(contractor.manualRatingCorrection) : ''
      });
    });

    $scope.tableParams = new ngTableParams(
      {
        count: 200,          // count per page
        sorting: {
          projectedRevenue: 'desc'     // initial sorting
        }
      },
      {
        total: $scope.data.length, // length of data
        getData: function($defer, params) {
          var orderedData = params.sorting() ? $filter('orderBy')($scope.data, params.orderBy()) : $scope.data;

          $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        }
      }
    );

    $scope.editManualRatingCorrection = function(user) {
      if (!user.manualRatingCorrection) {
        user.edit = false;
      }

      UsersResource.updateUserAttributes(user.user.id, {
        manualRatingCorrection: parseInt(user.manualRatingCorrection)
      }).then(function() {
        user.edit = false;
      });
    };
  }

  window.app.controller('ContractorStatsCtrl', ['$scope', '$filter', 'ngTableParams', 'UsersResource', 'contractorStats', ContractorStatsCtrl]);

})();
