const LoadingService = class LoadingService {
  constructor (Configuration, $q) {
    'ngInject'
    this._identify = 'LoadingService'
    this.Configuration = Configuration
    this.$q = $q

    this._loaders = {}
    this._defer = {}
  }

  isActive (name) {
    return this._loaders[name] || false
  }

  // getter function with argument
  loader (name) {
    // return loader defer or instantly reolvable promise as a fallback
    // console.log('[LoadingService] > loader', name, this._defer[name])
    return this._defer[name] ? this._defer[name].promise : new Promise((resolve, reject) => {
      resolve({
        event: this.isActive(name)
      })
    })
  }

  start (name) {
    this._loaders[name] = true
    this._defer[name] = this.$q.defer()
    // console.log('[LoadingService] > start', name, this._defer[name])
    this._loaders = { ...this._loaders[name] }
  }

  stop (name) {
    this._loaders[name] = false
    this._loaders = { ...this._loaders[name] }

    // console.log('[LoadingService] > stop', name, this._defer[name])
    if (this._defer[name]) {
      this._defer[name].resolve({
        event: this.isActive(name)
      })
    }
  }
}
export default LoadingService
