const GrowthTargetsService = class GrowthTargetsService {
  constructor ($http, Configuration, LoadingService, $filter) {
    'ngInject'
    this._identify = 'GrowthTargetsService'
    this.$http = $http
    this.Configuration = Configuration
    this.LoadingService = LoadingService
    this.$filter = $filter
    this.monthlyTargetsList = []
  }

  getMonthlyTargets (params, date) {
    const config = {
      params
    }
    const loaderName = 'GrowthTargetsService::getMonthlyTargets'
    this.LoadingService.start(loaderName)
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/monthly_targets/${date || ''}`, config)
      .then(response => {
        this.monthlyTargetsList = angular.isArray(response.data) ? response.data : [response.data]
        return response
      })
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  updateGrowthTarget (date, payload) {
    const loaderName = 'GrowthTargetsService::updateGrowthTarget'
    this.LoadingService.start(loaderName)
    return this.$http
      .patch(`${this.Configuration.apiUrl}/admin/monthly_targets/${date}`, payload)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  createNewGrowthTarget (payload) {
    const loaderName = 'GrowthTargetsService::createNewGrowthTarget'
    this.LoadingService.start(loaderName)
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/monthly_targets`, payload)
      .finally(() => {
        this.LoadingService.stop(loaderName)
      })
  }

  saveGrowthTarget (item, date) {
    const payload = {
      date: this.$filter('date')(item.date, 'yyyy-MM'),
      target_revenue: item.targetRevenue,
      target_npcs: item.targetNpcs,
    }

    if (item.hasOwnProperty('id')) {
      return this.updateGrowthTarget(date, payload)
    } else {
      return this.createNewGrowthTarget(payload)
    }
  }
}
export default GrowthTargetsService


