import templateUrl from './manage-expert-pods-project.dialog.html'
import './manage-expert-pods-project.dialog.scss'

const ManageExpertPodsProjectDialog = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: true,
  fullscreen: false,
  multiple: true,
  parent: angular.element(document.body),
  controller: class ManageExpertPodsProjectDialog {
    constructor ($mdDialog, $mdToast, ExpertPodsService, ProjectsService) {
      'ngInject'
      this._identify = 'TaskCommentsDialog'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.ExpertPodsService = ExpertPodsService
      this.ProjectsService = ProjectsService

      this.isLoading = false
      this.availablePodList = []
    }

    $onInit () {
      this.isLoading = true
      this.ExpertPodsService.getExpertPodsList({ active: true })
        .then(response => {
          this.availablePodList = response.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    listSelectorExclusiveComparator (selectedItem, optionsItem) {
      return selectedItem.expertPod.id === optionsItem.id
    }

    renderMeta (expertPod) {
      let metaString = `#${expertPod.id}, `
      metaString += expertPod.partners.map(p => p.code).join(', ')

      if (expertPod.description) {
        metaString += `, ${expertPod.description}`
      }
      return metaString
    }

    assignExpertPod (item) {
      const pod = item
      this.ExpertPodsService.assignExpertPod(this.project.id, pod.id)
        .then(response => {
          this.project.expertPodsProjects.push(response)

          // Reset preferred contractors
          // TODO trigger full project refetch
          this.project.preferredContractors = []

          this.$mdToast.show(this.$mdToast.simple({ textContent: 'Expert pod assigned', position: 'top right' }))
        })
        .catch(err => {
          console.log(err)
        })
    }

    unassignExpertPod (item) {
      const podMembership = item
      this.ExpertPodsService.unassignExpertPod(this.project.id, podMembership.id)
        .then(response => {
          const index = this.project.expertPodsProjects.findIndex(p => p.id === podMembership.id)
          if (index > -1) {
            this.project.expertPodsProjects.splice(index, 1)
          }

          this.$mdToast.show(this.$mdToast.simple({ textContent: 'Expert pod unassigned', position: 'top right' }))
        })
        .catch(err => {
          console.log(err)
        })
    }


    get canUnassignPod () {
      return this.project && this.project.expertPodsProjects.length > 1
    }
  }
}

export default ManageExpertPodsProjectDialog
