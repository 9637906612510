// import WorkroomInterventionsService from './workroom-interventions.service'
// .service('WorkroomInterventionsService', WorkroomInterventionsService)

const WorkroomInterventionsService = class WorkroomInterventionsService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'WorkroomInterventionsService'

    this.$http = $http
    this.Configuration = Configuration
    this.apiUrlBase = Configuration.apiUrl
  }

  fetchInterventionScenarios (project) {
    console.log('[WorkroomInterventionsService] > fetchInterventionScenarios', project.id)

    const url = `${this.apiUrlBase}/admin/intervention-scenarios`

    return this.$http
      .get(url)
      .then(response => {
        console.log('[WorkroomInterventionsService] > fetchInterventionScenarios > response', response)
        return response.data.scenarios
      }, err => {
        console.error('[WorkroomInterventionsService] > fetchInterventionScenarios> err', err)
        return err
      })
  }

  addIntervention (project, formData) {
    console.log('[WorkroomInterventionsService] > addIntervention', formData, project.id)

    const payload = {
      intervention_method_id: formData.selectedMethod.id,
      note: formData.interventionNote,
      snippet: formData.interventionSnippet
    }

    return this.$http
      .post(this.projectInterventionsURL(project), { intervention: payload }) // Potential TODO: it would be better if BE does not impose encapsulation of payload
      .then(response => {
        console.log('[WorkroomInterventionsService] > addIntervention > response', response)
        return response.data
      }, err => {
        console.error('[WorkroomInterventionsService] > addIntervention > err', err)
        return err
      })
  }

  removeIntervention (project, intervention) {
    console.log('[WorkroomInterventionsService] > removeIntervention', intervention, project.id)

    return this.$http
      .delete(this.projectInterventionsURL(project, intervention))
      .then(response => {
        console.log('[WorkroomInterventionsService] > removeIntervention > response', response)
        return response.data
      }, err => {
        console.error('[WorkroomInterventionsService] > removeIntervention > err', err)
        return err
      })
  }

  fetchInterventions (project) {
    console.log('[WorkroomInterventionsService] > fetchInterventions', project.id)

    return this.$http
      .get(this.projectInterventionsURL(project))
      .then(response => {
        console.log('[WorkroomInterventionsService] > fetchInterventions > response', response)
        return response.data
      }, err => {
        console.error('[WorkroomInterventionsService] > fetchInterventions> err', err)
        return err
      })
  }

  projectInterventionsURL (project, intervention = false) {
    console.log('[WorkroomInterventionsService] > projectInterventionsURL', project.id, intervention)

    let url = `${this.apiUrlBase}/admin/tasks/${project.id}/interventions`

    if (intervention !== false) {
      url = `${url}/${intervention.id}`
    }

    return url
  }
}

export default WorkroomInterventionsService
