import FolderListComponent from './folder-list.component'

const FolderListModule = angular.module('cdbl.folder-list', [
  // 'ui.router'
])
  .component('cdblFolderList', FolderListComponent)
// .config($uiRouterProvider => {
//   'ngInject';
//   $uiRouterProvider.stateRegistry
//     .register({
//       // parent: '',
//       name: 'folder-list',
//       url: '/folder-list',
//       component: 'cdblFolderList'
//     });
// })
  .name

export default FolderListModule
