(function() {
  'use strict';

  function run($rootScope, $location, Restangular, Configuration, Auth, _) {
    Restangular.setBaseUrl(Configuration.apiUrl);

    // response interceptor to add headers to response resource
    Restangular.addResponseInterceptor(function (data, operation, what, url, response) {
      if (typeof data === 'object' && response.headers) {
        console.log('Restangular > interceptor', data, response, url, operation)
        data.headers = response.headers;
      }
      return data;
    });

    // Restangular.setDefaultHttpFields({cache: true});

    // enumerate routes that don't need authentication
    var routesThatDontRequireAuth = ['login'];

    // check if current location matches route
    var routeClean = function(route) {
      return _.find(routesThatDontRequireAuth,
        function (noAuthRoute) {
          return _.str.startsWith(route, noAuthRoute);
        });
    };

    $rootScope.$on('$routeChangeStart', function() {
      // if route requires auth and user is not logged in
      if (!routeClean($location.url()) && !Auth.isSignedIn()) {
        Auth.signOut();
      }
    });

  }

  window.app.run(['$rootScope', '$location', 'Restangular', 'Configuration', 'Auth', '_', run]);
})();
