(function() {
  'use strict';
  /*globals $location*/
  function SignInCtrl($scope, $state, $stateParams, Auth) {
    if (Auth.me()) {
      $state.go('issueList', { folderId: 'all', subFolderId: 'unassigned' });
    }

    $scope.signIn = function(user) {
      Auth.signIn(user).then(function() {
        if ($stateParams.redirect) {
          $location.url($stateParams.redirect);
        } else {
          $state.go('issueList', { folderId: 'all', subFolderId: 'unassigned' });
        }
      });
    };
  }

  window.app.controller('SignInCtrl', ['$scope', '$state', '$stateParams', 'Auth', SignInCtrl]);

})();
