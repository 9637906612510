/* eslint-disable */
(function() {
  'use strict';

  function WatchlistCtrl($scope, $location, $stateParams, WatchlistResource, PaginationService) {
    $scope.loadingNewTasks = false;
    $scope.pagination = PaginationService.init({
      itemsPerPage: 20,
      mode: 'server'
    });

		$scope.onSelectPage = function(page) {
      $scope.loadingNewTasks = true;
      const params = {
        page: page,
        per_page: $scope.pagination.itemsPerPage
      };

			WatchlistResource.getWatchlist(params)
        .then(response => {
          console.log('[WatchlistCtrl] > onSelectPage > response', params, response);
          $scope.pagination.updateFromHeaders(response);

  				$scope.watchlist = response.data;
  			})
        .finally(() => {
          $scope.loadingNewTasks = false;
        });
		};

		$scope.onSelectPage('1');

    $scope.watch = function(task) { // TODO refactor into directive
      if (task.watchlist) {
        WatchlistResource.addWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = true;
        });
      } else {
        WatchlistResource.removeWatchlistItem(task.id, 'Task').then(function() {
          task.watchlist = false;
        });
      }
    };

  }

  window.app.controller('WatchlistCtrl', ['$scope', '$location', '$stateParams', 'WatchlistResource', 'PaginationService', WatchlistCtrl]);

})();
