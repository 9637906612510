(function() {
  'use strict';

  function ContractorStatsResource(ResourceManipulation, Restangular) {
    var getAllContractorStats = function() {
      return Restangular.all('admin').all('contractor_stats').getList();
    };

    var getContractorStats = function(contractorId) {
      var data = Restangular.all('admin').one('contractor_stats', contractorId).get();

      return ResourceManipulation.resolveError(data);
    };
    
    var getContractorYearlyStats = function(contractorId) {
      var data = Restangular.all('admin').one('contractor-yearly-stats', contractorId).get();

      return ResourceManipulation.resolveError(data);
    };

    var getAllContractorStatsPerTask = function(taskId) {
      return Restangular.all('admin').one('contractor-stats', taskId).get('');
    };

    return {
      getAllContractorStats: getAllContractorStats,
      getContractorStats: getContractorStats,
      getAllContractorStatsPerTask: getAllContractorStatsPerTask,
      getContractorYearlyStats: getContractorYearlyStats
    };
  }

  window.app.service('ContractorStatsResource', ['ResourceManipulation', 'Restangular', ContractorStatsResource]);
})();