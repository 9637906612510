import templateUrl from './project-widget-budget.html'
import './project-widget-budget.scss'

import DialogProjectEditBudget from './dialog-project-edit-budget'

const ProjectWidgetBudgetComponent = {
  bindings: {
    project: '<',
    inline: '<'
  },
  templateUrl,
  controller: class ProjectWidgetBudget {
    constructor ($mdDialog) {
      'ngInject'
      this._identify = 'ProjectWidgetBudgetComponent'
      this.$mdDialog = $mdDialog

      this.budgetList = [
        {
          id: -1,
          label: 'up to $200 USD',
          enabled: false
        },
        {
          id: -2,
          label: '$200 - $600 USD',
          enabled: false
        },
        {
          id: -3,
          label: '$600 - $1,000 USD',
          enabled: false
        },
        {
          id: -4,
          label: 'more than $1,000 USD',
          enabled: false
        },
        {
          id: -5,
          label: 'not sure',
          enabled: true
        },
        {
          id: -6,
          label: 'flexible',
          enabled: false
        },
        {
          id: -10,
          label: '$7,000 - $9,000 USD',
          enabled: false
        },
        {
          id: -13,
          label: '$1,000 - $3,000 USD',
          enabled: false
        },
        {
          id: -14,
          label: '$3,000 - $10,000 USD',
          enabled: false
        },
        {
          id: -15,
          label: 'more than $10,000 USD',
          enabled: false
        },

        {
          id: -3000,
          label: '$3,000 - $5,000 USD',
          enabled: false
        },
        {
          id: -5000,
          label: '$5,000 - $7,000 USD',
          enabled: false
        },

        // enabled
        {
          id: -11,
          label: 'up to $500 USD',
          enabled: true
        },
        {
          id: -12,
          label: '$500 - $1,000 USD',
          enabled: true
        },
        {
          id: -7,
          label: '$1,000 - $3,000 USD',
          enabled: true
        },
        {
          id: -8,
          label: '$3,000 - $5,000 USD',
          enabled: true
        },
        {
          id: -9,
          label: '$5,000 - $7,000 USD',
          enabled: true
        },
        {
          id: -7000,
          label: '$7,000 - $10,000 USD',
          enabled: true
        },
        {
          id: -10000,
          label: '$10,000 - $50,000 USD',
          enabled: true
        },
        {
          id: -50000,
          label: 'more than $50,000 USD',
          enabled: true
        }
      ]
    }

    get showLegacyBudget () {
      return this.project && this.project.submissionFormVersion < '2.0'
    }

    openEditDialog () {
      this.$mdDialog.show({
        ...DialogProjectEditBudget,
        locals: {
          project: this.project,
          budgetList: this.budgetList,
          showLegacyBudget: this.showLegacyBudget
        }
      })
    }

    parseBudget (budgetValue) {
      const budget = this.budgetList.find(b => b.id === budgetValue)

      if (budget) {
        return budget.label
      }

      return budgetValue
    }
  }
}

export default ProjectWidgetBudgetComponent
