import templateUrl from './projects.html'
import './projects.scss'

const ProjectsComponent = {
  bindings: {},
  templateUrl,
  controller: class Projects {
    constructor () {
      'ngInject'
      this._identify = 'ProjectsComponent'
    }
  }
}

export default ProjectsComponent
