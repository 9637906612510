const UserService = class UserService {
  constructor ($http, Configuration, AuthService) {
    'ngInject'
    this._identify = 'UserService'
    this.$http = $http
    this.Configuration = Configuration
    this.AuthService = AuthService
  }

  get user () {
    // if (this.AuthService.user) {
    //   this.AuthService.user.permissions = {
    //     superusers: {
    //       read: true
    //     }
    //   }
    // }
    return this.AuthService.user
  }

  get userId () {
    return this.user.id
  }

  userHasRole (user) {
    return user && user.role
  }

  userIsAdmin (user) {
    if (!this.userHasRole(user)) {
      return false
    }

    return ['superuser'].includes(user.role)
  }
  userIsExpert (user) {
    if (!this.userHasRole(user)) {
      return false
    }
    return ['contractor', 'expert'].includes(user.role)
  }
  userIsClient (user) {
    if (!this.userHasRole(user)) {
      return false
    }
    return ['client'].includes(user.role)
  }

  // Unlock user account
  unlockAccount (userId) {
    return this.$http
      .delete(`${this.Configuration.apiUrl}/admin/users/${userId}/lock`)
      .then(response => response.data)
  }

  /**
   * Update currently logged in user object with fresh user data
   * @param  {object} user New user data
   * @return {object} user Updated user data
   */
  update (user) {
    if (user && typeof user === 'object') {
      Object.assign(this.user, user)
    }

    return this.user
  }

  // -------------------------------------



  // -------------------------------------
  // API

  fetchUser (userId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/users/${userId}`)
      .then(response => response.data)
  }

  fetchCurrentUser () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/me`)
      .then(response => response.data)
  }
}
export default UserService
