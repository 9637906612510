(function() {
  'use strict';

  function hireContractor(TasksResource, _) {
    var link = function($scope) {
      $scope.display = true;

      if (_.where($scope.invitations, { user: { id: $scope.contractor.id } }).length !== 0) {
        $scope.display = false;
      }

      $scope.hire = function(contractor) {
        TasksResource.createInvitation($scope.task.id, contractor.id).then(function(data) {
          data.user = contractor;
          $scope.invitations.push(data);
          $scope.display = false;
        });
      };
    };

    return {
      restrict: 'E',
      link: link,
      scope: {
        contractor: '=',
        task: '=',
        invitations: '='
      },
      templateUrl: '/app/scripts/tasks/actions/hire_contractor_button.html'
    };
  }

  app.directive('hireContractor', ['TasksResource', '_', hireContractor]);
})();