(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider
    .state('users', {
      abstract: true,
      url: '/users',
      template: '<ui-view/>'
    })
    .state('users.role', {
      url: '?role',
      templateUrl: '/app/scripts/users/list.users.html',
      controller: 'UsersCtrl'
    })
    .state('users.display', {
      url: '/:id',
      templateUrl: '/app/scripts/users/display.user.html',
      controller: 'UserCtrl',
      resolve: {
        userResource: ['UsersResource', '$stateParams', function(UsersResource, $stateParams) {
          return UsersResource.getUser($stateParams.id, ['user', 'transactions', 'tasks', 'reviews', 'kpis', 'estimatedContractorTasks', 'commentedContractorTasks', 'tickets', 'earningCertificates'], true);
        }]
      }
    })
    .state('users.email', {
      url: '/:id/email',
      templateUrl: '/app/scripts/users/emails/send.user.email.html',
      controller: 'UserEmailCtrl',
      resolve: {
        userResource: ['UsersResource', '$stateParams', function(UsersResource, $stateParams) {
          return UsersResource.getUser($stateParams.id, ['user'], false);
        }]
      }
    });
  }

  window.app.config(['$stateProvider', states]);
})();
