import templateUrl from './project-widget-client.html'
import './project-widget-client.scss'

const ProjectWidgetClientComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectWidgetClient {
    constructor ($window, UserService) {
      'ngInject'
      this._identify = 'ProjectWidgetClientComponent'
      this.$window = $window
      this.UserService = UserService
    }

    $onInit () {
      this.client = null

      if (this.project && this.project.client) {
        this.UserService.fetchUser(this.project.client.id)
          .then(response => {
            this.client = response
          })
          .catch(err => {
            console.log(err)
          })
      }
    }

    actionOpenInIntercom () {
      this.$window.open(this.client.intercomLink, '_blank')
    }
  }
}

export default ProjectWidgetClientComponent
