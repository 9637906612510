import templateUrl from './expert-pods-list.html'
import './expert-pods-list.scss'
import DialogExpertPodsCreateEdit from '../dialog-expert-pods-create-edit'

const ExpertPodsListComponent = {
  bindings: {},
  templateUrl,
  controller: class ExpertPodsList {
    constructor ($location, $state, $stateParams, $mdDialog, ExpertPodsService, PaginationService) {
      'ngInject'
      this._identify = 'ExpertPodsListComponent'
      this.$location = $location
      this.$state = $state
      this.$stateParams = $stateParams
      this.$mdDialog = $mdDialog
      this.ExpertPodsService = ExpertPodsService
      this.PaginationService = PaginationService
    }

    $onInit () {
      this.podList = null
      this.isLoading = false

      this.pagination = this.PaginationService.init({
        itemsPerPage: 10,
        mode: 'server'
      })

      // Process pagination from URL
      if (this.$stateParams['page']) {
        this.pagination.setCurrentPage(this.$stateParams['page'])
      }

      this.fetchPodList()
    }

    createNewPod () {
      this.$mdDialog.show({
        ...DialogExpertPodsCreateEdit,
        locals: {
          expertPod: null
        }
      }).then(dialogResponse => {
        console.log('🚀 ~ file: expert-pods-list.component.js:44 ~ ExpertPodsList ~ createNewPod ~ dialogResponse:', dialogResponse)
        if (dialogResponse) {
          this.$state.go('expertPods.podList.podView', { id: dialogResponse.id })
        }
      }, dialogError => {
        console.log('🚀 ~ file: expert-pods-list.component.js:48 ~ ExpertPodsList ~ createNewPod ~ dialogError:', dialogError)
      })
    }

    onPageChangeHandler () {
      this.fetchPodList()
    }

    fetchPodList () {
      const params = this.pagination ? {
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage
      } : {}

      this.isLoading = true
      this.ExpertPodsService.getExpertPodsList(params)
        .then(response => {
          this.pagination.updateFromHeaders(response)
          this.podList = response.data

          this.$location.search('page', this.pagination.currentPage)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}

export default ExpertPodsListComponent
