import templateUrl from './expert-pods-view.html'
import './expert-pods-view.scss'

import DialogExpertPodsBulkEdit from '../dialog-expert-pods-bulk-edit'
import DialogExpertPodsCreateEdit from '../dialog-expert-pods-create-edit'
import DialogExpertPodsCreatePodBot from '../dialog-expert-pods-create-pod-bot'

const ExpertPodsViewComponent = {
  bindings: {
    podId: '<?'
  },
  templateUrl,
  controller: class ExpertPodsView {
    constructor ($stateParams, $mdToast, ExpertPodsService, $mdDialog) {
      'ngInject'
      this._identify = 'ExpertPodsViewComponent'
      this.$stateParams = $stateParams
      this.$mdToast = $mdToast
      this.$mdDialog = $mdDialog
      this.ExpertPodsService = ExpertPodsService

      this.isLoading = false
      this.expertPod = null
      this.eligibleExpertsList = null

      this.searchTextOptions = ''
      this.searchTextSelected = ''

      this.listSelectorExclusiveComparator = (selectedItem, optionsItem) => selectedItem.member.id === optionsItem.id
    }

    $onInit () {
      this.id = this.podId || this.$stateParams.id
      console.log('[ExpertPodsViewComponent] > this.id', this.id)

      this.loadExpertPod(this.id)
    }

    openDialogEdit () {
      this.$mdDialog.show({
        ...DialogExpertPodsCreateEdit,
        locals: {
          expertPod: this.expertPod
        }
      }).then(dialogResponse => {
        console.log('🚀 ~ file: expert-pods-view.component.js:71 ~ ExpertPodsView ~ openDialogEdit ~ dialogResponse:', dialogResponse)
        if (dialogResponse) {
          this.loadExpertPod(this.id)
        }
      }, dialogError => {
        console.log('openIncidentFormDialog > err', dialogError)
      })
    }

    openDialogBulkEdit () {
      console.log('🚀 ~ file: expert-pods-view.component.js:38 ~ ExpertPodsView ~ openDialogBulkEdit ~ openDialogBulkEdit')
      this.$mdDialog.show({
        ...DialogExpertPodsBulkEdit,
        locals: {
          id: this.id
        }
      }).then(dialogResponse => {
        console.log('🚀 ~ file: expert-pods-view.component.js:71 ~ ExpertPodsView ~ openDialogBulkEdit ~ dialogResponse:', dialogResponse)
        this.loadExpertPod(this.id)
      }, dialogError => {
        console.log('openIncidentFormDialog > err', dialogError)
      })
    }

    loadExpertPod (podId) {
      this.isLoading = true
      this.ExpertPodsService.getExpertPod(podId)
        .then(response => {
          this.expertPod = response

          this.loadEligibleExperts()
        })
        .catch(err => {
          this.error = err && err.data && err.data.message ? err.data.message : ''
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    loadEligibleExperts () {
      this.ExpertPodsService.getEligibleExperts(this.expertPod.id)
        .then(response => {
          this.eligibleExpertsList = response
        })
        .catch(err => {
          console.log(err)
        })
    }

    addMembership ($event) {
      this.ExpertPodsService.addMembership(this.expertPod.id, $event.item.id)
        .then(response => {
          // add membership to to the list
          this.expertPod.expertPodsMembers.push(response)
        })
        .catch(err => {
          console.log(err)
        })
    }

    removeMembership ($event) {
      this.ExpertPodsService.removeMembership(this.expertPod.id, $event.item.id)
        .then(response => {
          // remove membership from the list
          const membershipIndex = this.expertPod.expertPodsMembers.findIndex(m => m.id === $event.item.id)
          if (membershipIndex > -1) {
            this.expertPod.expertPodsMembers.splice(membershipIndex, 1)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    openDialogDeactivate () {
      console.log('[ActionTaskPublishComponent] > onReleaseForPublication')
      this.isProcessing = true
      const confirmDialog = {
        escapeToClose: true,
        clickOutsideToClose: true,
        controllerAs: '$ctrl',
        focusOnOpen: false,
        parent: angular.element(document.body),
        template: `<md-dialog>
                    <md-dialog-content class="pa-4">
                       <h2 class="md-title">Are you sure you want to deactivate this expert pod?</h2>
                       <div class="md-dialog-content-body">Proceed only if you know what you are doing!</div>
                    </md-dialog-content>
                    <md-dialog-actions>
                      <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
                      <md-button class="md-warn md-raised" ng-click="$ctrl.$mdDialog.hide()">Deactivate</md-button>
                    </md-dialog-actions>
                  </md-dialog>`,

        controller: class DialogController {
          constructor ($mdDialog) {
            'ngInject'
            this.$mdDialog = $mdDialog
          }
        }
      }

      this.$mdDialog.show({
        ...confirmDialog,
        locals: {}
      }).then(() => {
        this.isProcessing = true
        this.ExpertPodsService.deactivateExpertPod(this.id)
          .then(response => {
            this.expertPod = response
            this.$mdToast.show(this.$mdToast.simple({ textContent: 'Expert pod deactivated', position: 'top right' }))
            console.log('🚀 ~ file: expert-pods-view.component.js:189 ~ ExpertPodsView ~ this.ExpertPodsService.deactivateExpertPod ~ response:', response)
          })
          .catch(err => {
            this.$mdToast.show(this.$mdToast.simple({ textContent: 'Something went wrong', position: 'top right' }))
            console.log('🚀 ~ file: expert-pods-view.component.js:190 ~ ExpertPodsView ~ this.ExpertPodsService.deactivateExpertPod ~ err:', err)
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    }

    openDialogActivate () {
      console.log('[ActionTaskPublishComponent] > onReleaseForPublication')
      this.isProcessing = true
      const confirmDialog = {
        escapeToClose: true,
        clickOutsideToClose: true,
        controllerAs: '$ctrl',
        focusOnOpen: false,
        parent: angular.element(document.body),
        template: `<md-dialog>
                    <md-dialog-content class="pa-4">
                       <h2 class="md-title">Are you sure you want to activate this expert pod?</h2>
                       <div class="md-dialog-content-body">Proceed only if you know what you are doing!</div>
                    </md-dialog-content>
                    <md-dialog-actions>
                      <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
                      <md-button class="md-warn md-raised" ng-click="$ctrl.$mdDialog.hide()">Activate</md-button>
                    </md-dialog-actions>
                  </md-dialog>`,

        controller: class DialogController {
          constructor ($mdDialog) {
            'ngInject'
            this.$mdDialog = $mdDialog
          }
        }
      }

      this.$mdDialog.show({
        ...confirmDialog,
        locals: {}
      }).then(() => {
        this.isProcessing = true
        this.ExpertPodsService.activateExpertPod(this.id)
          .then(response => {
            this.expertPod = response
            this.$mdToast.show(this.$mdToast.simple({ textContent: 'Expert pod activated', position: 'top right' }))
            console.log('🚀 ~ file: expert-pods-view.component.js:189 ~ ExpertPodsView ~ this.ExpertPodsService.activateExpertPod ~ response:', response)
          })
          .catch(err => {
            this.$mdToast.show(this.$mdToast.simple({ textContent: 'Something went wrong', position: 'top right' }))
            console.log('🚀 ~ file: expert-pods-view.component.js:190 ~ ExpertPodsView ~ this.ExpertPodsService.activateExpertPod ~ err:', err)
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    }

    openDialogAddPodBot () {
      console.log('🚀 ~ file: expert-pods-view.component.js ~ ExpertPodsView ~ openDialogAddPodBot ~ expertPod: ', this.expertPod.id)
      this.$mdDialog.show({
        ...DialogExpertPodsCreatePodBot,
        locals: {
          expertPod: this.expertPod,
          podBot: this.expertPod.podBot
        }
      }).then(dialogResponse => {
        console.log('🚀 ~ file: expert-pods-view.component.js ~ ExpertPodsView ~ openDialogAddPodBot ~ dialogResponse:', dialogResponse)
        if (dialogResponse) {
          this.loadExpertPod(this.id)
        }
      }, dialogError => {
        console.log('openIncidentFormDialog > err', dialogError)
      })
    }

    openDialogRemovePodBot () {
      console.log('[Admin::ExpertPodsComponent] > removePodBot')

      this.isProcessing = true
      const confirmDialog = {
        escapeToClose: true,
        clickOutsideToClose: true,
        controllerAs: '$ctrl',
        focusOnOpen: false,
        parent: angular.element(document.body),
        template: `<md-dialog>
                    <md-dialog-content class="pa-4">
                       <h2 class="md-title">Are you sure you want to remove this pod bot?</h2>
                       <div class="md-dialog-content-body">Proceed only if you know what you are doing!</div>
                    </md-dialog-content>
                    <md-dialog-actions>
                      <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
                      <md-button class="md-warn md-raised" ng-click="$ctrl.$mdDialog.hide()">Remove PodBot</md-button>
                    </md-dialog-actions>
                  </md-dialog>`,

        controller: class DialogController {
          constructor ($mdDialog) {
            'ngInject'
            this.$mdDialog = $mdDialog
          }
        }
      }

      this.$mdDialog.show({
        ...confirmDialog,
        locals: {}
      }).then(() => {
        this.isProcessing = true
        this.ExpertPodsService.removeExpertPodBot(this.expertPod.podBot)
          .then(response => {
            this.expertPod = response
            this.$mdToast.show(this.$mdToast.simple({ textContent: 'PodBot removed', position: 'top right' }))
            console.log('🚀 ~ file: expert-pods-view.component.js ~ ExpertPodsView ~ this.ExpertPodsService.removeExpertPodBot ~ response:', response)
          })
          .catch(err => {
            this.$mdToast.show(this.$mdToast.simple({ textContent: 'Something went wrong', position: 'top right' }))
            console.log('🚀 ~ file: expert-pods-view.component.js ~ ExpertPodsView ~ this.ExpertPodsService.removeExpertPodBot ~ err:', err)
          })
          .finally(() => {
            this.isProcessing = false
          })
      })
    }
  }
}

export default ExpertPodsViewComponent
