import IssueViewHistoryComponent from './issue-view-history.component'
// import IssueViewHistoryService from './issue-view-history.service'

const IssueViewHistoryModule = angular.module('cdbl.issue-view-history', [
  // 'ui.router'
])
  .component('cdblIssueViewHistory', IssueViewHistoryComponent)
  // .service('IssueViewHistoryService', IssueViewHistoryService)

  // .config($uiRouterProvider => {
  //   'ngInject'
  //   $uiRouterProvider.stateRegistry
  //     .register({
  //       // parent: '',
  //       name: 'issue-view-history',
  //       url: '/issue-view-history',
  //       component: 'cdblIssueViewHistory'
  //     })
  // })
  .name

export default IssueViewHistoryModule
