/* eslint-disable */
(function() {
  'use strict';

  function WithdrawalsResource($q, $http, Restangular, Configuration) {

		var getWithdrawals = function(params = {}) {
      return $http.get(`${Configuration.apiUrl}/admin/withdrawal_requests`, {params});
		};

    var cancelWithdrawalRequest = function(withdrawal) {
      var responseObject;

			responseObject  = Restangular.
                    one('admin').
                    one('withdrawal_requests', withdrawal.id).
                    remove();

      handleCancellationResponse(responseObject);
    };

    var handleCancellationResponse = function(responseObject) {
      console.log('-- WithdrawalsResource#handleCancellationResponse', responseObject);
    };

    var confirmWithdrawalRequestPayment = function(withdrawal) {
      var responseObject, payload, input;

      payload = {
          status :                'confirm',
          requested_amount :      withdrawal.amount,
          payment_fees :          withdrawal.paymentFees,
          payment_method :        withdrawal.paymentMethod,
          provider_payment_id :   withdrawal.paymentId,
          payment_account_email : withdrawal.expertPaymentAccountEmail };
      input = { withdrawal: payload };

			responseObject  = Restangular.
                    one('admin').
                    one('withdrawal_requests', withdrawal.id).
                    customPUT(input);  // `customPUT` because of the nested payload

      handleConfirmationResponse(responseObject);
    };

    var handleConfirmationResponse = function(responseObject) {
      console.log('-- WithdrawalsResource#handleConfirmationResponse', responseObject);
    };

    return {
      getWithdrawalRequests: getWithdrawals,
      confirmWithdrawalRequest: confirmWithdrawalRequestPayment,
      cancelWithdrawalRequest: cancelWithdrawalRequest,
    };

  }

  window.app.service('WithdrawalsResource', ['$q', '$http', 'Restangular', 'Configuration', WithdrawalsResource]);
})();
