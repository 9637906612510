(function() {
  'use strict';

  function IntercomResource(Restangular) {
    function getUsers() {
      return Restangular.all('admin').all('intercom').all('admins').get('');
    }

    return {
      getUsers: getUsers
    };
  }

  window.app.service('IntercomResource', ['Restangular', IntercomResource]);
})();