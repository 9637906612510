
const OnFileSelectDirective = function ($compile) {
  'ngInject'
  return {
    restrict: 'A',
    scope: {
      onFileSelect: '<'
    },
    link: function ($scope, $element, $attrs) {
      const selectHandler = (event) => {
        $scope.onFileSelect(event)
      }
      $element.on('change', selectHandler)
      $element.on('$destroy', function () {
        $element.off()
      })
    }
  }
}

export default OnFileSelectDirective
