const EarningsCertificateService = class EarningsCertificateService {
  constructor ($http, Configuration) {
    'ngInject'
    this._identify = 'EarningsCertificateService'
    this.$http = $http
    this.Configuration = Configuration
  }

  fetchEarningCertificates (expertId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/admin/experts_earnings_certificates?page=1&per_page=20&q={"expert_id_eq":${expertId}}`)
      .then(response => response.data)
  }

  generateEarningCertificate (payload) {
    return this.$http
      .post(`${this.Configuration.apiUrl}/admin/experts_earnings_certificates`, payload)
      .then(response => {
        return response.data
      })
  }
}
export default EarningsCertificateService
