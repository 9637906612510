import templateUrl from './action-withhold.html'
import './action-withhold.scss'

const ProjectActionWithholdComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectActionWithhold {
    constructor ($mdDialog, ToastService, ProjectsService) {
      'ngInject'
      this._identify = 'ProjectActionWithholdComponent'
      this.$mdDialog = $mdDialog
      this.ToastService = ToastService
      this.ProjectsService = ProjectsService
    }

    isActionAllowed (action) {
      return this.ProjectsService.isActionAllowed(action, this.project)
    }

    withholdProject () {
      this.isProcessing = true

      this.ProjectsService.withholdProject(this.project.id)
        .then(response => {
          this.ToastService.show('Project withheld')
          this.ProjectsService.updateProject(this.project, { ...response })
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    releaseProject () {
      this.isProcessing = true

      this.ProjectsService.releaseProject(this.project.id)
        .then(response => {
          this.ToastService.show('Project released')
          this.ProjectsService.updateProject(this.project, { ...response })
        })
        .catch(err => {
          this.ToastService.show(err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default ProjectActionWithholdComponent
