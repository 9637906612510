(function() {
  'use strict';
  /*jshint newcap:false*/

  function InactiveContractorsCtrl($scope, $filter, ngTableParams, inactiveContractors) {
    $scope.data = [];

    inactiveContractors.forEach(function(contractor) {
      $scope.data.push({
        id: contractor.id,
        name: contractor.firstName + ' ' + contractor.lastName,
        noLogin1Month: contractor.noLogin_1Month,
        noCommentOrEstimate2Weeks: contractor.noCommentOrEstimate_2Weeks,
        noHired3Weeks: contractor.noHired_3Weeks
      });
    });

    $scope.tableParams = new ngTableParams(
      {
        page: 1,            // show first page
        count: 25,          // count per page
        sorting: {
          name: 'asc'     // initial sorting
        }
      },
      {
        total: $scope.data.length, // length of data
        getData: function($defer, params) {
          var orderedData = params.sorting() ? $filter('orderBy')($scope.data, params.orderBy()) : $scope.data;

          $defer.resolve(orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
        }
      }
    );
  }

  window.app.controller('InactiveContractorsCtrl', ['$scope', '$filter', 'ngTableParams', 'inactiveContractors', InactiveContractorsCtrl]);

})();
