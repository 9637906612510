import HalIconComponent from './hal-icon.component'
// import HalIconService from './hal-icon.service';

const HalIconModule = angular.module('cdbl.hal-icon', [
  // 'ui.router'
])
  .component('cdblHalIcon', HalIconComponent)
// .service('HalIconService', HalIconService)

// .config($uiRouterProvider => {
//   'ngInject';
//   $uiRouterProvider.stateRegistry
//     .register({
//       // parent: '',
//       name: 'hal-icon',
//       url: '/hal-icon',
//       component: 'cdblHalIcon'
//     });
// })
  .name

export default HalIconModule
