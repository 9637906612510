import templateUrl from './resource-expert.html'
import './resource-expert.scss'

const IssueResourceExpertComponent = {
  bindings: {
    issue: '<'
  },
  templateUrl,
  controller: class IssueResourceExpert {
    constructor (Configuration, IssueViewService) {
      'ngInject'
      this._identify = 'IssueResourceExpertComponent'
      this.Configuration = Configuration
      this.IssueViewService = IssueViewService

      this.expert = null
    }

    $onInit () {
      this.IssueViewService.getResourceExpert(this.issue.id)
        .then(response => {
          this.expert = response
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}

export default IssueResourceExpertComponent
