import templateUrl from './resource-unresponsive.html'
import './resource-unresponsive.scss'

import OccurrenceDetailsDialog from './occurrence-details.dialog.js'
const IssueResourceUnresponsiveComponent = {
  bindings: {
    issue: '<',
    user: '@'
  },
  templateUrl,
  controller: class ResourceUnresponsive {
    constructor ($mdToast, $mdDialog, IssuesService, IssueResourceUnresponsiveService, PusherService) {
      'ngInject'
      this._identify = 'IssueResourceUnresponsiveComponent'
      this.$mdToast = $mdToast
      this.$mdDialog = $mdDialog
      this.IssuesService = IssuesService
      this.IssueResourceUnresponsiveService = IssueResourceUnresponsiveService
      this.PusherService = PusherService
      this.isLoading = false
      this.isProcessing = false
      this.occurrences = {
        data: null
      }
      this.formData = {
        rootCause: null
      }


      this.pusherChannelName = null

      // Pusher callback handlers that can get also unbind on destroy
      const vm = this
      this.pusherHandlerIssueOpened = function (event) {
        const processedIssue = vm.IssuesService.processIssueResponse(vm.PusherService.processPayload(event.data))
        // Add only if its child event and it has parent attribute in payload
        if (processedIssue.parent) {
          vm.addNewOccurrence(processedIssue)
        }
      }
    }

    $onInit () {
      console.log('[IssueResourceUnresponsiveComponent] > $onInit > fetch data', this.issue.occurrences)
      // Get correct channel name - parent issue channel
      this.pusherChannelName = this.PusherService.getChannelName('issue', this.issue.id)

      this.isLoading = true
      this.IssueResourceUnresponsiveService.getOccurrencesList({ url: this.issue.resources.occurrences.url })
        .then(response => {
          console.log(response)
          this.occurrences.data = response || []

          // Subscribe to issue-opened events for this parent issue - if child occurrences are added
          this.PusherService.bind(this.pusherChannelName, 'issue-opened', this.pusherHandlerIssueOpened)

          // TODO: subscribe to each occurrence issues channels and intercept the issue-cleared event
        }, error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    $onDestroy () {
      // Unsubscribe to notes events for this issue
      this.PusherService.unbind(this.pusherChannelName, 'issue-opened', this.pusherHandlerIssueOpened)

      // TODO unsubscribe from all occurrence issues channels
    }

    onRootCauseChange (item) {
      this.isProcessing = true
      const payload = {
        issue: {
          rootCause: item.rootCause
        }
      }

      console.log('[IssueView] > onRootCauseChange', item.rootCause, payload)
      this.IssuesService.executeAction(item.actions.rootCause, payload).finally(() => {
        this.isProcessing = false
      })
    }

    openDetailsDialog (issueSummary) {
      this.$mdDialog
        .show({
          ...OccurrenceDetailsDialog,
          locals: {
            issueSummary
          }
        })
    }

    addNewOccurrence (occurrenceInstance) {
      const exists = this.occurrences.data.find(item => item.id === occurrenceInstance.id)
      if (!exists) {
        this.occurrences.data.push(occurrenceInstance)
        // TODO: subscribe to new occurrence issue channels and intercept the issue-cleared event
      }
    }
  }
}

export default IssueResourceUnresponsiveComponent
