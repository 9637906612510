import templateUrl from './issue-list.html'
import './issue-list.scss'

const IssueListComponent = {
  bindings: {},
  templateUrl,
  controller: class IssueList {
    constructor (IssuesService, PaginationService) {
      'ngInject'
      this._identify = 'IssueListComponent'
      this.IssuesService = IssuesService
      this.PaginationService = PaginationService

      this.isLoading = false

      this.pagination = this.PaginationService.init({
        itemsPerPage: 50,
        mode: 'server',
        itemsPerPageOptions: [
          25,
          50,
          100,
          300
        ]
      })
    }

    $onInit () {
      // TODO: load issueList based on folder
      console.log('[IssueListComponent] > init')
      this.loadIssueList()
    }

    loadIssueList (page = 1) {
      const params = {
        page: page,
        per_page: this.pagination.itemsPerPage
      }
      this.isLoading = true
      this.IssuesService.getIssueList(null, null, params)
        .then(response => {
          console.log('[IssueListComponent] > response', response)
          this.pagination.updateFromHeaders(response)
        }, error => {
          console.log('[IssueListComponent] > error', error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    onPageChangeHandler (direction) {
      console.log('[IssueListComponent] > onPageChangeHandler', direction)

      if (direction === 'previous') {
        this.pagination.currentPage = this.pagination.currentPage - 1
      } else if (direction === 'next') {
        this.pagination.currentPage = this.pagination.currentPage + 1
      }

      this.loadIssueList(this.pagination.currentPage)
    }

    get issueList () {
      return this.IssuesService.issueList
    }

    // payment failures should be shown on top
    failedPaymentComparator (a, b) {
      if (a.value === 'payment_failure' && b.value !== 'payment_failure') {
        return -1
      }
      if (b.value === 'payment_failure' && a.value !== 'payment_failure') {
        return 1
      }
      return 0
    }

    issueStyle (item) {
      if (item.status === 'open' && item.type === 'payment_failure') {
        if (item.additionalData) {
          let amount = parseFloat(item.additionalData['amount'])
          if (amount >= 1000) {
            return 'issue-priority--important'
          }
        }
      } else {
        return ''
      }
    }

    issueTitle (item) {
      const issueTitle = item.title

      if (item.type === 'incident' && item.resources.incident.id) {
        return `${issueTitle} #${item.resources.incident.id}`
      }

      if (item.type !== 'duplicates') {
        return issueTitle
      }

      const clients = item.resources.multipleClients
      const dupeCount = item.resources.duplicatesCount.split(' ')[0]

      return `Duplicate Projects (${dupeCount}) - ${clients}`
    }

    projectTitle (item) {
      const project = item.resources.task || item.resources.project
      if (!project) {
        return null
      }

      return `#${project.id}: ${project.title}`
    }

    displayUserName (item) {
      const resources = item.resources

      return resources.client || resources.expert
    }

    incidentParticipantsNames (item) {
      const incident = item.resources.incident
      if (incident && incident.id) {
        return `${incident.participants ? incident.participants.join(', ') : 'N/A'}`
      }
      return 'N/A'
    }

    userName (item) {
      let user = item.resources.client

      if (item.type === 'unresponsive_expert') {
        user = item.resources.expert
      }

      if (!user) {
        return null
      }

      return user.fullName
    }

    assigneeName (item) {
      const assignee = item.assignedTo
      if (!assignee) {
        return null
      }

      return assignee.fullName
    }

    taskIsReferral (item) {
      if (!item) {
        return false
      }

      const task = item.resources.task

      return task && task.partnerName
    }

    partnerName (item) {
      if (!item.resources.task) {
        return null
      }

      return item.resources.task.partnerName
    }

    assigneeAvatar (item) {
      if (!item.assignedTo || !item.assignedTo.avatars) {
        return null
      }

      const avatarUrl = item.assignedTo.avatars.tinyUrl

      if (!avatarUrl) {
        return null
      }

      return avatarUrl
    }
  }
}

export default IssueListComponent
