import templateUrl from './token-help.html'
import './token-help.scss'

const IS_TOKEN_HELP_OPEN = 'is-token-help-open'
const TokenHelpComponent = {
  bindings: {
    validator: '<'
  },
  templateUrl,
  controller: class TokenHelp {
    constructor ($window, $mdToast, TokenService, StorageService) {
      'ngInject'
      this._identify = 'TokenHelpComponent'
      this.$window = $window
      this.$mdToast = $mdToast
      this.TokenService = TokenService
      this.StorageService = StorageService

      this.collapsed = true

      this.mappedTokens = null
    }

    toggleCollapse () {
      this.collapsed = !this.collapsed
      this.StorageService.set(IS_TOKEN_HELP_OPEN, !this.collapsed)
    }

    mapTokens (validatorList) {
      if (typeof this.validator === 'undefined') {
        return {}
      }

      const tokenValidator = this.TokenService.initValidator(validatorList)
      this.mappedTokens = tokenValidator.reduce((result, current) => {
        if (!result[current.type]) {
          result[current.type] = [] // If this type wasn't previously stored
        }

        result[current.type].push(current)
        return result
      }, {})
    }


    copyToClipboard ($event) {
      console.log('copyToClipboard', $event)
      const el = this.$window.document.createElement('textarea')
      el.value = $event.currentTarget.innerText.trim()
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      this.$window.document.body.appendChild(el)
      el.select()

      try {
        // Now that we've selected the anchor text, execute the copy command
        const successful = this.$window.document.execCommand('copy')
        const msg = successful ? 'successfully. CTRL/CMD + V to paste it' : 'unsuccessfully'
        this.$mdToast.showSimple(`Token copied to clipboard ${msg}.`)
      } catch (err) {
        console.log('Oops, unable to copy token.')
      }

      this.$window.document.body.removeChild(el)
    }

    $onInit () {
      this.mapTokens(this.validator)
      this.collapsed = !this.StorageService.get(IS_TOKEN_HELP_OPEN)
    }

    $onChanges (changes) {
      console.log('[TokenPreviewComponent] > $onChanges', changes)
      if (changes.validator) {
        this.mapTokens(changes.validator.currentValue)
      }
    }
  }
}

export default TokenHelpComponent
