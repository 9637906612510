import templateUrl from './button.html'
import './button.scss'

const ButtonComponent = {
  bindings: {
    isProcessing: '<?',
    styleSmall: '<?',
    styleLarge: '<?',
    styleSecondary: '<?',
    styleWarning: '<?',
    styleTextNormal: '<?',
    iconLeft: '@?',
    iconRight: '@?',
    onClick: '&?',
    ngDisabled: '<?', // passthrough ng-disabled binding
    // uiSref: '@?', // passthrough ui-sref binding
  },
  templateUrl,
  transclude: true,
  controller: class Button {
    constructor () {
      'ngInject'
      this._identify = 'ButtonComponent'
    }

    $onInit () {
      this.styleSmall = this.styleSmall || false
      this.styleLarge = this.styleLarge || false
      this.styleWarning = this.styleWarning || false

      // TODO: make it reactive if binding change with onChanges hook
      this.classList = {
        'cdbl__button--small': this.styleSmall,
        'cdbl__button--large': this.styleLarge,
        'cdbl__button--warning': this.styleWarning,
        'cdbl__button--secondary': this.styleSecondary,
        'cdbl__button--text-normal': this.styleTextNormal
      }
    }
  }
}

export default ButtonComponent
