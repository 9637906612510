(function() {
  'use strict';

  function states($stateProvider) {
    $stateProvider.state('contractor_stats', {
      url: '/contractor_stats',
      templateUrl: '/app/scripts/contractorStats/list.contractor.stats.html',
      controller: 'ContractorStatsCtrl',
      resolve: {
        contractorStats: ['ContractorStatsResource', function(ContractorStatsResource) {
          return ContractorStatsResource.getAllContractorStats();
        }]
      }
    });
  }

  window.app.config(['$stateProvider', states]);
})();