import templateUrl from './app.component.html'
import './app.component.scss'
import logoImage from '@/assets/img/logo-white.svg'


const SIDEBAR_BREAKPOINT = 'gt-md'
const SIDEBAR_NAVIGATION = 'app--sidebar--navigation'
// const SIDEBAR_NOTIFICATIONS = 'app--sidebar--notifications'
// const SIDEBAR_NOTIFICATIONS_BREAKPOINT = 'gt-md'

const AppComponent = {
  templateUrl,
  controller: class AppController {
    constructor (Configuration, $http, $timeout, $mdSidenav, $mdDialog, $mdMenu, $mdMedia, $scope, $state, IssuesService, UserService, NavigationService, StorageService, AuthService) {
      'ngInject'

      this.Configuration = Configuration
      this.name = 'AppComponent'
      this.$state = $state
      this.$scope = $scope
      this.$mdSidenav = $mdSidenav
      this.$mdDialog = $mdDialog
      this.$mdMenu = $mdMenu
      this.IssuesService = IssuesService
      this.UserService = UserService
      this.NavigationService = NavigationService
      this.StorageService = StorageService
      this.AuthService = AuthService

      this.$state = $state
      this.$timeout = $timeout

      this.isAppLoaded = false

      this.canSidebarBeLocked = false
      this.isSidebarClosed = true
      this.isSidebarLocked = $mdMedia(SIDEBAR_BREAKPOINT)


      this.logoImage = logoImage
      this.toolbarSearchInput = ''

      this.isSidebarClosed = false
      this.isSidebarLocked = $mdMedia(SIDEBAR_BREAKPOINT)




      // this.isNotificationsSidebarClosed = true
      // this.isNotificationsSidebarLocked = $mdMedia(SIDEBAR_NOTIFICATIONS_BREAKPOINT)

      // $scope.$watch(() => {
      //   return $mdSidenav(SIDEBAR_NOTIFICATIONS).isLockedOpen()
      // }, breakpoint => {
      //   this.isNotificationsSidebarLocked = breakpoint

      //   if (breakpoint) {
      //     this.isNotificationsSidebarClosed = false
      //   }
      // })
    }

    $onInit () {
      console.log('[AppComponent] > $onInit', this.Configuration)

      // If user is already authenticated on app init, refresh user object that is being fetched from LS
      if (this.AuthService.isAuthenticated) {
        this.UserService.fetchCurrentUser()
          .then(response => {
            console.log('kaboooom', response)
            this.UserService.update(response)
            this.StorageService.set('User', this.UserService.user)
            this.NavigationService.init()
          })
      }

      // TODO: rework this when refactoring auth
      this.$scope.$watch(() => {
        return this.UserService.user
      }, (newVal, oldVal) => {
        console.log('[AppComponent] > $watch > UserService.user', newVal, oldVal)
        if (newVal) {
          this.NavigationService.init()

          this.IssuesService.init()
            .finally(() => {
            // Set app loaded so that main app is loaded
              this.isAppLoaded = true

            // Init logic
              this.$scope.$watch(() => {
                return this.$mdSidenav(SIDEBAR_NAVIGATION).isLockedOpen()
              }, breakpoint => {
                this.isSidebarLocked = breakpoint
                if (breakpoint) {
                  this.isSidebarClosed = false
                }
              })
            })
        } else {
          this.IssuesService.destroy()
          this.isAppLoaded = true
        }
      })
    }

    $doCheck () {
      // console.log('[AppComponent] > $doCheck')
    }

    toggleNavigationSidebar () {
      console.log('$mdSidenav > toggle')
      if (this.isSidebarLocked) {
        this.isSidebarClosed = !this.isSidebarClosed
      } else {
        this.$mdSidenav(SIDEBAR_NAVIGATION).toggle()
      }
    }

    // toggleNotificationsSidebar () {
    //   console.log('$mdSidenav > toggle')
    //   if (this.isNotificationsSidebarLocked) {
    //     this.isNotificationsSidebarClosed = !this.isNotificationsSidebarClosed
    //   } else {
    //     this.$mdSidenav(SIDEBAR_NOTIFICATIONS).toggle()
    //   }
    // }

    openMenu ($mdMenu, ev) {
      $mdMenu.open(ev)
    }

    isAppViewActive () {
      const result = this.Configuration.layout.newLayoutStates.find(stateGlob => this.$state.$current.includes[stateGlob])
      return result
    }

    closeAppSidebar () {
      this.$mdSidenav(SIDEBAR_NAVIGATION).close()
    }


    get issuesUnassignedCount () {
      return this.IssuesService.issuesUnassignedCount
    }
  }
}
export default AppComponent
