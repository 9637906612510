import templateUrl from './attachment.html'
import './attachment.scss'

const AttachmentComponent = {
  bindings: {
    projectId: '<',
    attachment: '<data', // attachement name is taken as there is a legacy directive with same name - TODO: after done with new attachments remove old one and rename this to attachment
    showIcon: '<',
    canDownload: '<',
    canDelete: '<',
    onDownload: '&',
    onDelete: '&',
    showDeleteDialog: '<',
    inline: '<',
    showMeta: '<'
  },
  templateUrl,
  controller: class Attachment {
    constructor (ProjectAttachmentsService, UserService, EventEmitter, $mdDialog, $element) {
      'ngInject'
      this._identify = 'AttachmentComponent'
      this.ProjectAttachmentsService = ProjectAttachmentsService
      this.UserService = UserService
      this.EventEmitter = EventEmitter
      this.$element = $element
      this.$mdDialog = $mdDialog
    }

    $onInit () {
      const name = this.attachment.name || this.attachment.attachment // handle the delete attachment activity data structure - TODO: should be fixed on BE
      this.fileName = this.ProjectAttachmentsService.extractFilenameOnly(name, 2)
      this.fileExt = this.ProjectAttachmentsService.extractFileExtensionOnly(name, 2)

      this.showIcon = this.showIcon || false
      this.showDeleteDialog = typeof this.showDeleteDialog !== 'undefined' ? this.showDeleteDialog : true
      this.canDelete = typeof this.canDelete !== 'undefined' ? this.canDelete : false // by default only same user can delete
      this.canDownload = typeof this.canDownload !== 'undefined' ? this.canDownload : true // by default true
      this.showMeta = typeof this.showMeta !== 'undefined' ? this.showMeta : false

      // Special case for activities where attachment is listed however it can be already deleted.
      // Not showing link prevents users from triggering 404 error and redirects
      if (this.attachment.deleted) {
        this.canDownload = false
      }

      this.canDownload = this.canDownload && this.attachment.provider !== 's3'

      if (this.inline) {
        this.$element[0].classList.add('inline-attachment')
      }

      console.log('[AttachmentComponent]', this.showDeleteDialog)
    }

    get showActions () {
      return this.canDelete || false
    }

    get signedUrlDownload () {
      if (this.attachment.url) {
        return this.attachment.url
      }
      return null
    }

    download () {
      console.log('[AttachmentComponent] > downloadAttachment > START')
      this.ProjectAttachmentsService
        .downloadAttachment(this.projectId, this.attachment.id)
        .then(response => {
          console.log('[AttachmentComponent] > downloadAttachment > RESOLVE downloadAttachment', response)

          if (this.onDownload) {
            this.onDownload(this.EventEmitter({ attachment: this.attachment }))
          }
        })
        .catch(err => { console.error(err) })
    }

    deleteAttachment () {
      this.ProjectAttachmentsService
        .deleteAttachment(this.projectId, this.attachment.id)
        .then(response => {
          console.log('[AttachmentComponent] > downloadAttachment > RESOLVE deleteAttachment', response)
          if (this.onDelete) {
            this.onDelete(this.EventEmitter({ attachment: this.attachment }))
          }
        })
        .catch(err => { console.error(err) })
    }

    deleteTrigger () {
      this.$mdDialog
        .show(this.$mdDialog.confirm({
          title: `Delete an attachment`,
          textContent: `Are you sure you want to delete "${this.fileName}${this.fileExt}"?`,
          ok: 'DELETE',
          cancel: 'Cancel',
          theme: 'warn'
        }))
        .then(dialogResponse => {
          this.deleteAttachment()
        }, dialogResponse => {
          // this.$mdToast.showSimple('Rejected', dialogResponse)
        })
    }
  }
}

export default AttachmentComponent
