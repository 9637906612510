import templateUrl from './dialog-expert-pods-create-edit.dialog.html'
import './dialog-expert-pods-create-edit.dialog.scss'

const DialogExpertPodsCreateEdit = {
  templateUrl,
  controllerAs: '$ctrl',
  bindToController: true,
  escapeToClose: false,
  clickOutsideToClose: false,
  fullscreen: false,
  parent: angular.element(document.body),
  controller: class DialogExpertPodsCreateEdit {
    constructor ($mdDialog, $mdToast, Configuration, ExpertPodsService) {
      'ngInject'
      this._identify = 'DialogExpertPodsCreateEdit'
      this.$mdDialog = $mdDialog
      this.$mdToast = $mdToast
      this.Configuration = Configuration
      this.ExpertPodsService = ExpertPodsService
    }

    $onInit () {
      this.isLoading = false
      this.urlPattern = new RegExp('^(http[s]?:\\/\\/)(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,15}[\\.]{0,1}(\\/.*)?(\\?.*)?$')
      this.isEdit = (this.expertPod && this.expertPod.id) || false
      this.formData = {
        name: '',
        role: null, // wait for sinclair/geronimo feature
        publiclyVisible: false,
        displayName: '',
        applicationUrl: '',
        description: '',
        partners: []
      }
      if (this.expertPod) {
        const pod = JSON.parse(JSON.stringify(this.expertPod)) // todo: use structuredClone after updating node
        this.formData = { ...this.formData, ...pod }
      }
      this.datasets = {
        podRoles: [{ id: 'delivery', value: 'Delivery' }, { id: 'review', value: 'Review' }]
      }
    }

    addPartner () {
      this.formData.partners.push({ code: '' })
    }
    removePartner (index) {
      this.formData.partners.splice(index, 1)
    }

    create () {
      this.error = null
      this.isProcesing = true
      this.ExpertPodsService.createExpertPod(this.formData)
        .then(response => {
          this.close(response)
          this.$mdToast.show(this.$mdToast.simple({ textContent: 'New pod created', position: 'top right' }))
        })
        .catch((err) => {
          this.error = this.errorHandler(err)
          this.$mdToast.show(this.$mdToast.simple({ textContent: 'Something went wrong', position: 'top right' }))
        })
        .finally(() => {
          this.isProcesing = false
        })
    }

    update () {
      this.error = null
      this.isProcesing = true
      this.ExpertPodsService.updateExpertPod(this.expertPod.id, this.formData)
        .then(response => {
          this.close(true)
          this.$mdToast.show(this.$mdToast.simple({ textContent: 'Pod updated', position: 'top right' }))
        })
        .catch((err) => {
          this.error = this.errorHandler(err)
          this.$mdToast.show(this.$mdToast.simple({ textContent: 'Something went wrong', position: 'top right' }))
        })
        .finally(() => {
          this.isProcesing = false
        })
    }

    errorHandler (err) {
      if (err.data.error === 'validation_failed' && err.data.errors) {
        const messages = err.data.errors.map(e => [e.field, e.message].join(': '))
        return [...messages]
      }
      return [err.data.error]
    }

    close (attr = false) {
      this.$mdDialog.hide(attr)
    }
  }
}

export default DialogExpertPodsCreateEdit
