(function() {
  'use strict';

  function DeleteAccountCtrl($scope, $location, $stateParams, $modalInstance, UsersResource, client) {
    $scope.processing = false;
    $scope.done = false;
    $scope.error = false;

    $scope.close = function() {
      $modalInstance.close();
    };

    $scope.delete = function() {
      $scope.processing = true;

      UsersResource.deleteUser(client.id).then(
        function(response) {
          console.log("Success", response);
          $scope.done = true;
          client.purged = true;
        })
        .catch(function(errors) {
          console.log("Errors", errors);
          client.purged = false;
          $scope.error = true;
        })
        .finally(function() {
          console.log("Finally");
          $scope.processing = false;
        });
    }

    $scope.goback = function() {
      window.history.back();
      $scope.close();
    }
  }

  window.app.controller('DeleteAccountCtrl', ['$scope', '$location', '$stateParams', '$modalInstance', 'UsersResource', 'client', DeleteAccountCtrl]);

})();
