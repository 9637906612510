const PermissionsService = class PermissionsService {
  constructor ($q, UserService) {
    'ngInject'
    this._identify = 'PermissionsService'
    this.$q = $q
    this.UserService = UserService
  }

  // Permissions checks need to return promise
  canAccess (featureName) {
    const user = this.UserService.user
    const result = Boolean(user && user.permissions && user.permissions[featureName] && (user.permissions[featureName].read || user.permissions[featureName].write))
    console.log('[PermissionsService] > canAccess >', featureName, result, user)
    return result
  }

  canEdit (featureName) {
    const user = this.UserService.user
    const result = Boolean(user && user.permissions && user.permissions[featureName] && (user.permissions[featureName].update || user.permissions[featureName].write))
    console.log('[PermissionsService] > canEdit >', featureName, result, user)
    return result
  }

  canCreate (featureName) {
    const user = this.UserService.user
    const result = Boolean(user && user.permissions && user.permissions[featureName] && user.permissions[featureName].write)
    console.log('[PermissionsService] > canCreate >', featureName, result, user)
    return result
  }


  canDelete (featureName) {
    const user = this.UserService.user
    const result = Boolean(user && user.permissions && user.permissions[featureName] && user.permissions[featureName].delete)
    console.log('[PermissionsService] > canDelete >', featureName, result, user)
    return result
  }

  resolver (result) {
    const deferred = this.$q.defer()
    if (result) {
      deferred.resolve()
    } else {
      deferred.reject(new Error('PermissionsError'))
    }
    return deferred.promise
  }
}
export default PermissionsService
