// Vendors
import 'angular'
import 'jquery'
import 'moment'

import '@uirouter/angularjs'
import 'restangular'
import 'angular-ui-select2/src/select2.js'
import 'angular-resource'
import 'angular-sanitize'
import 'angular-route'
import 'angular-moment/angular-moment.js'
import 'angular-local-storage'
import 'lodash'
import 'tether/tether.js'
import 'fuse.js'
import 'angular-foundation'
import 'd3'
import 'highcharts-ng'
import 'marked'

import './vendors-legacy/select2.js'
import './vendors-legacy/ng-table-0.4.3.min.js'
import './vendors-legacy/ng-table-0.4.3.min.css'
import './vendors-legacy/checklist-model-0.1.3.js'
import './vendors-legacy/highstock.js'
import './vendors-legacy/foundation-datepicker.js'
import './vendors-legacy/foundation-5.5.3/js/foundation.min.js'
import './vendors-legacy/foundation-5.5.3/js/vendor/fastclick.js'
import './vendors-legacy/foundation-5.5.3/js/vendor/jquery.cookie.js'
import './vendors-legacy/foundation-5.5.3/js/vendor/placeholder.js'
// import './vendors-legacy/foundation-5.5.3/js/vendor/modernizr.js' // not working in webpack
