import templateUrl from './issue-toolbar-search.html'
import './issue-toolbar-search.scss'

const IssueToolbarSearchComponent = {
  bindings: {},
  templateUrl,
  controller: class IssueToolbarSearch {
    constructor ($state, Configuration) {
      'ngInject'
      this._identify = 'IssueToolbarSearchComponent'
      this.$state = $state

      this.formData = {}

      this.fields = { ...Configuration.issueSystem.issueToolbarSearch.fields }

      // Add filtering for Demo issues
      if (Configuration.isDevelopment) {
        this.fields.type.options.push({
          id: 'non_production',
          name: 'Non Production Test'
        })
      }
    }

    $onInit () {
      const params = this.$state.params
      this.formData.type = params.type ? params.type : null
    }

    onFieldChange () {
      console.log('onFieldChange', this.formData, this.$state.params)
      this.$state.go('issueList', { ...this.$state.params, ...this.formData })
    }
  }
}

export default IssueToolbarSearchComponent
