import SettingsAccountComponent from './settings-account.component'
import SettingsAccountService from './settings-account.service'


const SettingsAccountModule = angular.module('cdbl.settings-account', [

])
  .component('cdblSettingsAccount', SettingsAccountComponent)
  .service('SettingsAccountService', SettingsAccountService)
  .config($uiRouterProvider => {
    'ngInject'
    $uiRouterProvider.stateRegistry
      .register({
        name: 'settings-account',
        url: '/settings/account',
        component: 'cdblSettingsAccount'
      })
  })
  .name

export default SettingsAccountModule
