(function() {
  'use strict';

  function SubTasksCtrl($scope, SubTasksResource) {

    $scope.onSelectPage = function(page) {
      var requestObject = {
        retainers: true,
        page: page
      };
      SubTasksResource.getSubTasks({}, requestObject).then(function(subTasks) {
        $scope.subTasks = subTasks;
        // Pagination
        $scope.totalItems = subTasks.headers('X-Total-Count');
        $scope.itemsPerPage = 20;
        $scope.currentPage = page.toString();
        $scope.numPages = subTasks.headers('X-Page-Count');
        $scope.maxSize = 5;
      });
    };

    $scope.onSelectPage('1');

  }

  window.app.controller('subTasksRetainersCtrl', ['$scope', 'SubTasksResource', SubTasksCtrl]);

})();
