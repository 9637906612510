import DevModule from './dev'


import AuthModule from './auth'
import ActionsModule from './actions'
import DashboardModule from './dashboard'
import IssueSystemModule from './issue-system'
import SettingsModule from './settings'
import { WorkroomInterventionsModule } from './dialogs/workroom-interventions'
import ExpertPodsModule from './expert-pods'
import ProjectsModule from './projects'
import GrowthTargetsModule from './growth-targets'
import EditExpertAvailabiltiyModule from './users/edit-expert-availabiltiy'
import { ReferralAttributionsModule } from './dialogs/referrals'

import UsersModule from './users'
import ProjectPageModule from './project-page'
import HeaderSearchModule from './header-search'
import HeaderSearchResultsModule from './header-search-results'

const ComponentsModule = angular.module('app.components', [
  DevModule,
  AuthModule,
  ActionsModule,
  DashboardModule,
  IssueSystemModule,
  SettingsModule,
  WorkroomInterventionsModule,
  ReferralAttributionsModule,
  ExpertPodsModule,
  ProjectsModule,
  UsersModule,
  ProjectPageModule,
  GrowthTargetsModule,
  EditExpertAvailabiltiyModule,
  HeaderSearchModule,
  HeaderSearchResultsModule
])
  .name

export default ComponentsModule
