(function() {
  'use strict';

  function AffiliatesResource($q, Restangular) {

    var getAffiliates = function(platform) {
      console.log('-- AffiliatesResource#getAffiliates() -> platform:', platform);
      var platformFilter = { 'platform' : platform }
      var affiliates = Restangular.all('admin').all('affiliates').get('tracking-names', platformFilter ).catch(err => { console.log(err) });
      console.log('-- AffiliatesResource#getAffiliates():', affiliates);

      return affiliates;
    };

    var getTags = function() {
      var tags = Restangular.all('admin').get('tags').catch(err => { console.log(err) });

      return tags;
    };

    return {
      getAffiliatesTrackingNames: getAffiliates,
      getTagNames: getTags
    };
  }

  window.app.service('AffiliatesResource', ['$q', 'Restangular', AffiliatesResource]);

})();
