/* eslint-disable */
(function() {
  'use strict';

  function WatchlistResource($q, $http, Restangular, Configuration) {

    var getWatchlist = function(params = {}) {
      // return Restangular.all('admin').all('watchlist').getList();
      return $http.get(`${Configuration.apiUrl}/admin/watchlist`, { params });
    };

    var addWatchlistItem = function(item_id, item_type) {
      return Restangular.all('admin').all('watchlist').post({
        item_id: item_id,
        item_type: item_type
      });
    };

    var removeWatchlistItem = function(item_id, item_type) {
      return Restangular.all('admin').all('watchlist').remove({
        item_id: item_id,
        item_type: item_type
      });
    };

    return {
      getWatchlist: getWatchlist,
      addWatchlistItem: addWatchlistItem,
      removeWatchlistItem: removeWatchlistItem
    };
  }

  window.app.service('WatchlistResource', ['$q', '$http', 'Restangular', 'Configuration', WatchlistResource]);
})();
