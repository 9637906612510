import templateUrl from './project-attachments.html'
import './project-attachments.scss'

const ProjectAttachmentsComponent = {
  bindings: {
    project: '<',
    showTitle: '<'
  },
  templateUrl,
  controller: class ProjectAttachments {
    constructor (ProjectAttachmentsService) {
      'ngInject'
      this._identify = 'ProjectAttachmentsComponent'
      this.ProjectAttachmentsService = ProjectAttachmentsService
    }

    $onInit () {
      this.isLoading = false
      this.attachmentList = null

      this.getProjectAttachments()
    }

    onDeleteAttachment ($event) {
      const index = this.attachmentList.findIndex(a => a.id === $event.attachment.id)
      if (index > -1) {
        this.attachmentList.splice(index, 1)
      }
    }


    getProjectAttachments () {
      this.isLoading = true
      this.ProjectAttachmentsService
        .getAttachmentList(this.project.id, this.params)
        .then(response => {
          this.attachmentList = response
        })
        .catch(err => { console.error(err) })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}

export default ProjectAttachmentsComponent
